import { useDispatch, useSelector } from "react-redux";
import { removeMedia } from "../../app/slices/mediaUploaderData";
import {
  addCurrentImageCardInd,
  addIsDeleteAssetId,
  addIsDeleteMainId,
  addIsMediaDeletePopup,
} from "../../app/slices/singleStates";
import axios from "axios";
import { useEffect, useState } from "react";
import { Alert } from "antd";
import { addStoredData } from "../../apis/postApis";
import { RootState } from "../../app/store";

const ImageCard = ({
  ind,
  styles,
  type,
  text,
  mediaType,
  url,
  assetId,
  mainId,
  id,
  setCurrentSelect,
  bountyId,
}: any) => {
  // redux config
  const dispatch = useDispatch();
  // Local state for alerts
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>(false);
  // Close alerts after 4 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAlertSuccess(false);
      setIsAlertError(false);
    }, 4000);
    return () => clearTimeout(timeout);
  }, [isAlertSuccess, isAlertError]);
  // Get lock status from Redux store
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });
  // Function to add image to the current bounty
  const addImage = () => {
    const formData = new FormData();

    axios
      .post(
        `${addStoredData}/${bountyId}/${id}`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setIsAlertSuccess(true); // Show the alert
        }
      })
      .catch((err) => {
        console.log("oerre", err.response.data.message);
        setErrorMsg(err.response.data.message);
        setIsAlertError(true);
      });
  };

  return (
    <>
      {/* Success alert */}
      <div>
        {isAlertSuccess && (
          <div className="upload-alert" style={{ top: "13%", left: "67%" }}>
            <Alert
              message="Asset Added to Current Bounty Successfully"
              type="success"
              style={{ fontSize: "1.2rem" }}
              showIcon
              closable
              onClose={() => setIsAlertSuccess(false)}
            />
          </div>
        )}
      </div>
      {/* Error alert */}
      <div>
        {isAlertError && (
          <div className="upload-alert">
            <Alert
              message={errorMsg}
              type="error"
              showIcon
              closable
              onClose={() => setIsAlertError(false)}
            />
          </div>
        )}
      </div>
      {/* Image card */}
      <div className="FM__imageCard" style={styles}>
        <div className="FM__IC__header">
          {type === "SA" ? (
            <div> </div>
          ) : (
            <b className="FM__IC__Order">
              <b>{ind}</b>
            </b>
          )}
          {/* Remove button */}
          <button
            style={
              isLock
                ? { border: "none", cursor: "not-allowed" }
                : { border: "none" }
            }
            className="FM__IC__Remove"
            disabled={isLock}
            onClick={() => {
              if (type !== "SA") {
                if (url.split(":")[0] == "blob") {
                  dispatch(removeMedia(ind - 1));
                } else {
                  dispatch(addCurrentImageCardInd(ind));
                  dispatch(addIsMediaDeletePopup(true));
                  dispatch(addIsDeleteAssetId(assetId));
                  dispatch(addIsDeleteMainId(mainId));
                }
              } else {
                addImage();
              }
            }}
          >
            <b>{type !== "SA" ? "-" : "+"}</b>
          </button>
        </div>
        {/* Text content */}
        {text ? (
          type === "SA" ? (
            <></>
          ) : (
            <div className="FM__IC__footer">{text}</div>
          )
        ) : (
          ""
        )}
        {/* Media content */}
        {mediaType === "image" ? (
          <img
            src={url}
            className="mediaUPloaer___card__img mediaUPloaer___card__media"
          />
        ) : (
          <></>
        )}
        {mediaType === "video" ? (
          <video
            controls
            src={url}
            className="mediaUPloaer___card__vdo mediaUPloaer___card__media"
          />
        ) : (
          <></>
        )}
        {mediaType === "audio" ? (
          <audio
            controls
            src={url}
            className="mediaUPloaer___card__ado mediaUPloaer___card__media"
          />
        ) : (
          <></>
        )}
        {mediaType === "application" ? (
          <a href={url} target="_blank" className="MEDI_Wploadr_application">
            <img src="./tests/textFileI.svg" style={{ width: "3rem" }} />
          </a>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ImageCard;
