import React, { useEffect, useRef, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BountyCard from "../BountyCard/BountyCard";
import AdCard from "../AdCard/AdCard";
import CharityCard from "../CharityCard/CharityCard";
import SponserCard from "../SponserCard/SponserCard";
import { isArray } from "lodash";
import homeFeedAlgorithm from "../../../../Algorithms/feedAlgorithm";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

const HomeMasonary = ({
  bountyId,
  isLogin = true,
  filter,
  searchQuery,
}: any) => {
  const [homeFeedData, setHomeFeedData] = useState<any>([]);
  let [page, setPage] = useState<any>(1);
  let [hasMore, setHasMore] = useState<any>(true);
  const [adboxHeight, setAdBoxHeight] = useState<any>("20rem");
  const navigate = useNavigate();
  console.log(filter, "filter");
  // useEffect(() => {
  //   // homeFeedAlgorithm({filter, searchQuery })
  //   // .then((res: any) =>
  //   //   setHomeFeedData(res)
  //   // );
  //   fetchData();
  // }, [filter, searchQuery]);

  useEffect(() => {
    fetchData(); // Fetch data initially
  }, [filter, searchQuery]); // Trigger useEffect when filter or searchQuery changes

  const fetchData = () => {
    setPage(1); // Reset page to 1 when filter or searchQuery changes
    homeFeedAlgorithm({ page: 1, filter, searchQuery }).then((res: any) => {
      console.log(res, "fetchMoreData fetchData");
      setHomeFeedData(res);
      if (searchQuery) {
        console.log("searchquery apply");
        setHasMore(res.length > 10);
      } else {
        console.log("searchquery not apply");
        setHasMore(res.length > 0);
      }
    });
  };

  const fetchMoreData = () => {
    homeFeedAlgorithm({ page: page + 1, filter, searchQuery }).then(
      (res: any) => {
        console.log(res, "fetchMoreData");
        if (res.length > 0) {
          setPage(page + 1);
          setHomeFeedData((prevData: any) => [...prevData, ...res]);
        } else {
          setHasMore(false); // No more data to fetch
        }
      }
    );
  };

  const viewBounty = (id: any) => {
    navigate("/bountipreview", { state: id });
  };

  // const calculateModulesData = () => {
  //   const totalItems = homeFeedData.length;
  //   const adPercentage = Math.ceil(0.1 * totalItems); // 10% for advertisement
  //   const bountyPercentage = Math.ceil(0.2 * totalItems); // 20% for bounty

  //   let adCount = 0;
  //   let bountyCount = 0;

  //   return homeFeedData.map((feed: any) => {
  //     if (feed.module === "advertisement" && adCount < adPercentage) {
  //       adCount++;
  //       return (
  //         <AdCard
  //           key={feed.id}
  //           brand={"Adidas"}
  //           brandLogo={"./tests/adidasLogo.svg"}
  //           bottomText={"Say goodbye to wet socks."}
  //           mediaType={feed.type}
  //           src={feed.presignedUrls[0]}
  //           isLogin={isLogin}
  //           adId={feed.adId}
  //         />
  //       );
  //     } else if (feed.module === "Bounti" && bountyCount < bountyPercentage) {
  //       bountyCount++;
  //       return (
  //         <BountyCard
  //           key={feed.id}
  //           isLogin={isLogin}
  //           bountyId={feed._id}
  //           redirectToThisBounty={viewBounty}
  //         />
  //       );
  //     }
  //     return null;
  //   });
  // };
  return (
    <section
      className="homeMasonry__components"
      id="scrollableDiv"
      style={
        isLogin
          ? { width: "100%", height: "80vh",
           overflow: "auto"
           }
          : {
              width: "80%",
              // height: "80vh",
              // overflow: "auto",
            }
      }
    >
      <InfiniteScroll
        dataLength={homeFeedData.length} //This is important field to render the next data
        next={fetchMoreData}
        hasMore={!isLogin ? false : hasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        style={{overflow:'hidden'}}
        
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            450:1,
            750: 2,
            900: 2,
            1366: 2,
            1452: 2,
            1800: 3,
            1920: 3,
          }}
        >
          <Masonry gutter="0.5rem">
            {isArray(homeFeedData) ? (
              homeFeedData?.map((feed: any) => {
                console.log(homeFeedData, "feed_data");
                if (feed.module === "Bounti") {
                  console.log("feed_Bounti", feed._id);
                  return (
                    <div>
                      {/* Bounty Card display on My World Page */}
                      <BountyCard
                        isLogin={isLogin}
                        bountyId={feed?._id}
                        redirectToThisBounty={viewBounty}
                      />
                    </div>
                  );
                }
                if (feed.module === "advertisement") {
                  console.log("Advetising_feed", feed);
                  return (
                    <>
                    {/* Advertise Card display on My World Page */}
                      <AdCard
                        brand={"Adidas"}
                        brandLogo={"./tests/adidasLogo.svg"}
                        bottomText={"Say goodbye to wet socks."}
                        mediaType={feed?.type}
                        src={feed?.presignedUrls[0]}
                        // styles={{ height: adboxHeight }}
                        isLogin={isLogin}
                        adId={feed?.adId}
                      />
                    </>
                  );
                }

                // Charity card display on my world page
                // if (feed.module === "charity") {
                //   console.log("charityFeed", feed?.image);
                //   return (
                //     <>
                //       <CharityCard
                //         charityName={feed?.charityName}
                //         image={feed?.image}
                //         isLogin={isLogin}
                //         charityId={feed?.id}
                //       />
                //     </>
                //   );
                // }

                // sponsor card display on my world page
                // if (feed.module === "sponsor") {
                //   console.log("sponserFeed", feed);
                //   return (
                //     <>
                //       <SponserCard
                //         sponsorName={feed?.sponsorName}
                //         image={feed?.image}
                //         isLogin={isLogin}
                //         sponserId={feed?.id}
                //       />
                //     </>
                //   );
                // }
                return <></>;
              })
            ) : (
              <></>
            )}
          </Masonry>
        </ResponsiveMasonry>
      </InfiniteScroll>
    </section>
  );
};

export default HomeMasonary;
