import axios from "axios";
import React, { useEffect, useState } from "react";
import { userRelatedAllMedia } from "../../apis/getApis";
import ImageCard from "./ImageCard";
import { useDispatch, useSelector } from "react-redux";
import { addStoredData } from "../../apis/postApis";
import { Empty } from "antd";

const UserRelatedMedia = ({ bountyId }: any) => {
  const currentStoredAssetTab = useSelector(
    (state: any) => state.booleanStatus.currentStoredAssetTab
  );
  const dispatch = useDispatch();

  // localStates..
  const [storedVideos, setStoredVideos] = useState<any>([]);
  const [storedImages, setStoredImages] = useState<any>([]);
  const [storedAudios, setStoredAudios] = useState<any>([]);
  const [storedFiles, setStoredFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // fetch videos
    axios
      .get(`${userRelatedAllMedia}?type=video`)
      .then((res: any) => {
        setStoredVideos(res.data.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
    // fetch images
    axios
      .get(`${userRelatedAllMedia}?type=image`)
      .then((res: any) => {
        setStoredImages(res.data.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
    // fetch audios
    axios
      .get(`${userRelatedAllMedia}?type=audio`)
      .then((res: any) => {
        setStoredAudios(res.data.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
    // fetch text
    axios
      .get(`${userRelatedAllMedia}?type=file`)
      .then((res: any) => {
        setStoredFiles(res.data.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div className={"flex"} style={{ flexWrap: "wrap" }}>
       {/* Render different types of media based on the currentStoredAssetTab */}
      {currentStoredAssetTab === "VIDEO" && (
        <>
          {storedVideos.length ? (
            storedVideos.map((video: any) => (
              <ImageCard
                key={video.id}
                bountyId={bountyId}
                url={video.video}
                mediaType={"video"}
                styles={{ marginLeft: "0.5rem", marginTop: "1rem" }}
                type={"SA"}
                id={video.id}
              />
            ))
          ) : (
            <div className="no_data">
              <Empty description="No videos found" />
            </div>
          )}
        </>
      )}

      {currentStoredAssetTab === "IMAGE" && (
        <>
          {storedImages.length ? (
            storedImages.map((image: any) => (
              <ImageCard
                key={image.id}
                bountyId={bountyId}
                url={image.image}
                mediaType={"image"}
                styles={{ marginLeft: "0.5rem", marginTop: "1rem" }}
                type={"SA"}
                id={image.id}
              />
            ))
          ) : (
            <div className="no_data">
              <Empty description="No images found" />
            </div>
          )}
        </>
      )}

      {currentStoredAssetTab === "AUDIO" && (
        <>
          {storedAudios.length ? (
            storedAudios.map((audio: any) => (
              <ImageCard
                key={audio.id}
                bountyId={bountyId}
                url={audio.audio}
                mediaType={"audio"}
                styles={{ marginLeft: "0.5rem", marginTop: "1rem" }}
                type={"SA"}
                id={audio.id}
              />
            ))
          ) : (
            <div className="no_data">
              <Empty description="No audios found" />
            </div>
          )}
        </>
      )}

      {currentStoredAssetTab === "TEXT" && (
        <>
          {storedFiles.length ? (
            storedFiles.map((file: any) => (
              <ImageCard
                key={file.id}
                bountyId={bountyId}
                url={file.video}
                mediaType={"application"}
                styles={{ marginLeft: "0.5rem", marginTop: "1rem" }}
                type={"SA"}
                id={file.id}
              />
            ))
          ) : (
            <div className="no_data">
              <Empty description="No files found" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserRelatedMedia;
