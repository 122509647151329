import Input from "antd/es/input/Input";
import { useDispatch, useSelector } from "react-redux";
import "./loginDialog.css";
import { Button, Spin } from "antd";
import { RootState } from "../../app/store";
import BasicInfo from "../../components/LoginComponent/BasicInfo";
import { ChangeEvent, useState } from "react";
import axios from "axios";
import {
  Forgot_Mail,
  loginRoute,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
} from "../../apis/postApis";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "antd";
import "./loginDialog.css";
import {
  toggleForgotConfirmDialog,
  toggleFormateEmailDialog,
  toggleLoginDialogCreation,
  toggleRegisterDialogCreation,
} from "../../app/slices/singleStates";
import { setForgotEmail } from "../../app/slices/singleStates";
import FloatLabel from "./FloatLable";
import ForgotConfirm from "./ForgotConfirm";

const ForgotScreen = () => {
  const [loading, setLoading] = useState<any>(false);
  const [isAlert, setIsAlert] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [isError, setIsError] = useState<any>(false);
  const [email, setEmail] = useState<any>("");
  const isFormateEmailDialog = useSelector(
    (state: RootState) => state.booleanStatus.isFormateEmailDialog
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isForgotConfirm = useSelector(
    (state: RootState) => state.booleanStatus.isForgotConfirm
  );

  // onEnter submit
  const inputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      varifyEmail();
    }
  };

  // Email send for verification API
  const varifyEmail = async () => {
    await axios
      .post(Forgot_Mail, { email })
      .then((res) => {
        dispatch(toggleFormateEmailDialog(false));
        dispatch(toggleForgotConfirmDialog(true));
        // setIsError(false);
        // setLoading(false);
        // setIsAlert(true);
        setMessage(res.data.message);
        setTimeout(() => {
          setIsAlert(false);
        }, 2500);
      })
      .catch((error: any) => {
        if (error.response.data.message) {
          setMessage(error.response.data.message);
          setIsAlert(true);
          setIsError(true);
          setTimeout(() => {
            setIsAlert(false);
            setIsError(false);
          }, 2000);
        }
        console.log("REsponse--", error.response.data.message);
      });
    dispatch(setForgotEmail(email));
  };

  return (
    <>
      <div
        className="login__dialog__main__wrapper"
        style={{ height: "fit-content" }}
      >
        {/* left side content */}
        <div className="login__dialog__left__side">
          {/* heading goes here*/}
          <h1
            className="login_heading"
            // style={{ position: "relative" }}
          >
            Enter the email associated with your account and we will email you a
            link to reset your password.
            <Alert
              style={isAlert ? { position: "absolute" } : { display: "none" }}
              message={message}
              type={!isError ? "success" : "error"}
              showIcon
            />
          </h1>
          {/* heading end here*/}

          {/* main goes here */}
          <main className="login__component__main">
            {/* login from goes here */}
            <section className="login__main__form">
              {/* username or email goes here */}
              <div className="login__INPUT__dialog">
                <FloatLabel
                  label="Email"
                  id="useremail"
                  type="text"
                  name="email"
                  onChange={(e: any) => {
                    setEmail(e.target.value.toLowerCase());
                  }}
                  onKeyDown={inputKeyDown}
                />
              </div>
              {/* username or email end here */}

              {/* login button goes here */}
              <Button
                className="login_btn__dialog"
                disabled={loading}
                onClick={varifyEmail}
              >
                {loading ? (
                  <Spin
                    className="orange-bg-spinner"
                    style={{ color: "var(--pure)" }}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
              {/* login button end here */}
            </section>
            {/* login from end here */}
          </main>
          {/* main end here */}
          {/* <BasicInfo /> */}
          <div className="account__or__not">
            Back to{" "}
            <span
              onClick={() => {
                dispatch(toggleFormateEmailDialog(false));
                dispatch(toggleLoginDialogCreation(true));
              }}
            >
              Login
            </span>{" "}
          </div>
        </div>

        {/* right side image */}
        <div className="login__dialog__right__side">
          <img src="./tests/logindialog.png" />
        </div>
        <button
          className="login__dialog__close"
          onClick={() => {
            dispatch(toggleFormateEmailDialog(false));
          }}
        >
          X
        </button>
      </div>
    </>
  );
};

export default ForgotScreen;
