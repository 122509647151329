import Checkbox from "antd/es/checkbox/Checkbox";
import "./uploadsponser.css";
import { useEffect, useState } from "react";
import {
  getAllSponserList,
  getSelectedSponser,
} from "../../../../../../apis/getApis";
import axios from "axios";
import { createSponsor } from "../../../../../../apis/postApis";
import { editSponsors } from "../../../../../../apis/putApis";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import {
  addIsCharityUploader,
  addIsSponserUploader,
} from "../../../../../../app/slices/singleStates";
import { setText } from "../../../../../../app/slices/CheckListStatus";

const UploadSponser = ({ bountyId }: any) => {
  // local states
  const [isShow, setIsShow] = useState<any>(false);
  const [sponserList, setSponserList] = useState<any>([]);
  const createdSponserId: any = localStorage.getItem("createdSponserId");
  const [createdSponserIdState, setCreatedSponserId] = useState<any>("");
  const [realSponserResponse, setRealSponserResponse] = useState<any>([]);
  const [isCarouselActive, setIsCarauselActive] = useState<boolean>(false);
  const [sponsorCommon, setSponsorCommon] = useState<any>([]);
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState(false);
  const dispatch = useDispatch();
  const isSponserUploader = useSelector(
    (state: any) => state.booleanStatus.isSponserUploader
  );

  // fetch Sponser List
  const fetchSponserList = async () => {
    await axios
      .get(getAllSponserList)
      .then((res: any) => {
        console.log("sponserGET-List", res);
        setSponserList(res.data.data);
      })
      .catch((err: any) => {});
  };

  function findCommonObjects(array1: any, array2: any) {
    const commonIds: any = [];

    array1.forEach((obj1: any) => {
      const matchingObj = array2.find((obj2: any) => obj1.id === obj2.id);
      if (matchingObj) {
        commonIds.push(obj1.id);
      }
    });

    return commonIds;
  }

  // create sponser to db
  const createSponserToDB = () => {
    if (!createdSponserId) {
      axios
        .post(`${createSponsor}/${bountyId}`)
        .then((res: any) => {
          localStorage.setItem("createdSponserId", res.data.data);
          setCreatedSponserId(res.data.data);
        }).catch((err: any) => {});
    } else {
    }
  };

  // Set a timeout to close the alert after 3000 milliseconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAlertSuccess(false);
      setIsAlertError(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isAlertSuccess, isAlertError]);

  useEffect(() => {
    if (realSponserResponse && sponserList) {
      const commonId: any = findCommonObjects(realSponserResponse, sponserList);
      setSponsorCommon(commonId);
      console.log("communId", commonId);
    }
  }, [realSponserResponse, sponserList]);

  const fetchSelectedSponser = () => {
    if (createdSponserId) {
      axios
        .get(`${getSelectedSponser}/${bountyId}`)
        .then((res) => {
          setRealSponserResponse(res.data.data);
          console.log("sponserGET-useEffect", res);
          if (res.data.data.length > 0) {
            setIsCarauselActive(true);
          }
        }).catch((err: any) => {});
    }
  };

  const checkOrUncheckSponser = (e: any) => {
    if (createdSponserId || createdSponserIdState) {
      axios
        .put(`${editSponsors}/${createdSponserId}/${bountyId}`, {
          sponsorId: e.target.value,
        }).then((res: any) => {
          dispatch(setText([res.data.checkList.Sponser, 12]));
          if (res.status === 201) {
            // If status code is 201, set success message and show the alert
            setIsAlertSuccess(true);
            setSuccessMessage(res.data.message); // Assuming your success message is in res.data.message
          } else {
            setIsAlertError(true);
          }

          // Fetch the updated sponser data
          axios
            .get(`${getSelectedSponser}/${bountyId}`)
            .then((res) => {
              console.log("sponserGET", res);
              setRealSponserResponse(res.data.data);
              if (res.data.data.length > 0) {
                setIsCarauselActive(true);
              }
            })
            .catch((err: any) => {});
        })
        .catch((err: any) => {
          setIsAlertError(true);
        });
    } else {
      // Handle the case when createdSponserId or createdSponserIdState is not available
    }
  };

  console.log("realSponserData", realSponserResponse);

  useEffect(() => {
    createSponserToDB();
    fetchSponserList();
    fetchSelectedSponser();
  }, []);

  return (
    <>
      <div style={{top:"50%"}}>
        {isAlertSuccess && (
          <div className="upload-alert">
            <Alert
              message={isSuccessMessage}
              type="success"
              showIcon
              closable
              onClose={() => setIsAlertSuccess(false)}
            />
          </div>
        )}
      </div>
      <div>
        {isAlertError && (
          <div className="upload-alert">
            <Alert
              message="Something went wrong"
              type="error"
              showIcon
              closable
              onClose={() => setIsAlertError(false)}
            />
          </div>
        )}
      </div>
      <div
        className={`sponser_c1 upload__sponser__wrapper`}
        onMouseOver={() => {
          dispatch(addIsSponserUploader(true));
        }}
        onMouseOut={() => {
          dispatch(addIsSponserUploader(false));
        }}
      >
        <label className="responsive_label upload__sponser__heading">
          Sponsor
        </label>
        <div
          className={`sponserSlider_c1 flex upload__sponser__sliderBody`}
          style={{
            background: "var(--sidebar)",
            justifyContent: "end",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(addIsCharityUploader(false));
            isSponserUploader
              ? dispatch(addIsSponserUploader(false))
              : dispatch(addIsSponserUploader(true));
          }}
        >
          {!isCarouselActive ? (
            <div>
              <img
                src="./assets/edit.svg"
                style={{
                  fontSize: "1rem",
                  marginLeft: "1rem",
                  marginRight: "0.5rem",
                  marginTop: "0.5rem",
                }}
              />
            </div>
          ) : (
            <>
              <Swiper
                slidesPerView={1}
                spaceBetween={1}
                navigation={true}
                modules={[Navigation, Autoplay]}
                loop
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {realSponserResponse?.map((singleSponser: any) => {
                  return (
                    <SwiperSlide key={`${Math.random() * Date.now() ** 10}`}>
                      <div style={{ width: "100%", height: "5em" }}>
                        <img
                          src={singleSponser?.image}
                          style={{
                            borderRadius: "0.4em",
                            maxWidth: "100%",
                            maxHeight: "5em",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          )}
        </div>
        {/* sponser body */}
        <div
          className="upload__sponser__select"
          onMouseOver={() => {
            dispatch(addIsSponserUploader(true));
          }}
          style={isSponserUploader ? { display: "block" } : { display: "none" }}
        >
          {sponserList?.map((sponser: any, ind: any) => {
            return (
              <div
                className="sel__sponser"
                style={ind !== 0 ? { marginTop: "1rem" } : {}}
              >
                <Checkbox
                  className="upload__sponser_checkbox"
                  value={sponser.id}
                  checked={sponsorCommon.includes(sponser.id)}
                  onChange={checkOrUncheckSponser}
                />
                <div className="uploader_sponser__name">
                  {sponser.sponsorName}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default UploadSponser;
