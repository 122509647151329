import axios from "axios";
import "./loginDialog.css";
import SlideCaptcha from "react-slide-captcha";
import "react-slide-captcha/dist/styles.css";
import {
  Register_Mail,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
} from "../../apis/postApis";
import Header from "../../apis/header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  toggleConfirmRegisterCreation,
  toggleLoginDialogCreation,
  toggleRegisterCaptcha,
  toggleRegisterDialogCreation,
} from "../../app/slices/singleStates";
import { Alert, Button, Spin } from "antd";

const CapthaDialog = ({ setIsCap = {} }) => {
  const currentLanguage = useSelector((state) => state.booleanStatus.language);
  const [rerender, setRender] = useState(false);
  const registerAccessToken = useSelector(
    (state) => state.booleanStatus.registerAccessToken
  );
  const vnodeRef = useRef(null);
  const isCaptcha = useSelector((state) => {
    return state.booleanStatus.isCaptcha;
  });
  const location = useLocation();
  console.log("CapthaLocation", location.state);
  const dispatch = useDispatch();
  const [forceUpdate, setForceUpdate] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWrong, setIswrong] = useState(false);

  // captcha varification function goes here
  const captchaVarification = () => {
    axios
      .post(
        `${Register_Mail}/${currentLanguage}`,
        {},
        {
          headers: {
            authorization: `Bearer ${registerAccessToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(toggleRegisterCaptcha(false));
        dispatch(toggleConfirmRegisterCreation(true));
      })
      .catch((err) => { });
  };

  // useEffect(() => {

  // }, [vnodeRef.current]);
  // useEffect(()=>{
  //   setRender(isCaptcha)
  // },[isCaptcha])
  // captcha varification function end here
  return (
    <>
      <div className="login__dialog__main__wrapper">
        {/* left side content */}
        <div
          className="login__dialog__left__side"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* start here */}
          {/* heading goes here*/}
          <h1
            className="register_heading"
          // style={{ position: "relative" }}
          >
            Drag the slider below <br /> to fit the puzzle piece and get
            verified
          </h1>
          {/* heading end here*/}

          {/* main goes here */}
          <main
            className="register__component__main"
            style={{ width: "19rem", marginTop: "2rem" }}
          >
            <section
              className="register__main__form"
              style={{ marginTop: "15rem" }}
            >
              {/* Captcha Slider */}
              <SlideCaptcha
                ref={(ref) => console.log("justTERRERT", ref)}
                puzzleUrl={"../tests/Puzzle2.png"}
                containerClassName={"slideCaptchaContainer"}
                resetButton="inline"
                reset="manual"
                bgUrl={"../tests/Puzzle1.png"}
                onRequest={(value, validateSuccess, fail, resetCaptcha) => {
                  console.log("captchaVarification", value);
                  // captchaVarification 0.8893129770992366 previous images
                  // captchaVarification 0.6106870229007634 current  images
                  if (value <= 0.6245038167938931 && value >= 0.6006870229007634) {
                    captchaVarification();
                  } else {
                    setIswrong(true);
                    setTimeout(() => {
                      setIswrong(false);
                    }, 1000);
                    resetCaptcha();
                  }
                }}
              />
            </section>
          </main>
          {/* main end here */}

          <Alert
            style={isWrong ? { position: "absolute", width: "100%", zIndex: "9999999", bottom: "0" } : { display: "none" }}
            message="wrong captcha!"
            className="wrong__captcha"
            type="error"
            showIcon
          />
        </div>

        {/* right side image */}
        <div className="login__dialog__right__side">
          <img src="./tests/logindialog.png" />
        </div>
      </div>
    </>
  );
};

export default CapthaDialog;
