import React from "react";

const SendIcon = () => {
  return (
    <svg
      className="sendicon"
      xmlns="http://www.w3.org/2000/svg"
      width="17.671"
      height="17.584"
      viewBox="0 0 17.671 17.584"
    >
      <path
        id="Path_33"
        data-name="Path 33"
        d="M17.672,0,0,9.208,4.538,11.7l6.938-5.41L6.371,13.4v4.189l3.054-3.011,3.447,1.963Z"
        transform="translate(0 -0.002)"
        fill="#e98326"
      />
    </svg>
  );
};

export default SendIcon;
