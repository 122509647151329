import { useState } from "react";
import { FluidContainer } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const GuestArea = () => {
  const [tabnumber, setTabnumber] = useState<number>(0);
  const toggleD = useSelector((state: RootState) => {
    return state.booleanStatus.toggleD;
  });
  return (
    <>
      <FluidContainer
        className={"overight__fluid"}
        parentClass={"fluid_parent"}
      >
        <div className="guestArea__main">
          {/* header */}
          <header
            className={
              toggleD
                ? "dashboard__top dashboard__top_nc"
                : "dashboard__top dashboard__top_wc"
            }
            style={{
              margin: "auto",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 6px",
              width: "fit-content",
              borderRadius: "0.5rem",
            }}
          >
            <section
              className={
                toggleD
                  ? "dashboard__top__tab dashboard__top__tab_nc"
                  : "dashboard__top__tab dashboard__top__tab_wc"
              }
              style={{ width: "fit-content" }}
            >
              <button
                className="dash__tab  dash__tab__left guest__header__button guest__header__button__left"
                id={tabnumber === 0 ? "guest__header__Act" : ""}
                onClick={() => {
                  setTabnumber(0);
                }}
              >
                feed
              </button>
              <button
                className="dash__tab guest__header__button"
                id={tabnumber === 1 ? "guest__header__Act" : ""}
                onClick={() => {
                  setTabnumber(1);
                }}
              >
                friends
              </button>
              <button
                className="dash__tab guest__header__button"
                id={tabnumber === 2 ? "guest__header__Act" : ""}
                onClick={() => {
                  setTabnumber(2);
                }}
              >
                communities
              </button>
              <button
                className="dash__tab guest__header__button"
                id={tabnumber === 3 ? "guest__header__Act" : ""}
                onClick={() => {
                  setTabnumber(3);
                }}
              >
                badges
              </button>
              <button
                className="dash__tab dash__tab__right guest__header__button guest__header__button__right"
                id={tabnumber === 4 ? "guest__header__Act" : ""}
                onClick={() => {
                  setTabnumber(4);
                }}
              >
                discover
              </button>
            </section>
          </header>
          <div className="guestArea__inner">
            {/*  */}
            <div>
              <h1 className="guestArea__inner__heading">
                Congratulations, you’re in!
              </h1>
              <p className="guestArea__inner__paragraph">
                There’s a reward waiting for you…
              </p>
            </div>
            {/* guestArea Grid Section goes here */}
            <div className="guestArea__Grid">
              <div>
                <div className="guestArea__GridBox"></div>
                <div
                  className="guestArea__GridBox"
                  style={{ marginTop: "3rem" }}
                ></div>
              </div>
              <div style={{ marginLeft: "3rem" }}>
                <div className="guestArea__GridBox"></div>
                <div
                  className="guestArea__GridBox"
                  style={{ marginTop: "3rem" }}
                ></div>
              </div>
            </div>
            {/* guestArea Grid Section end here */}
            <p className="guest__notes">
              Choose one of our featured Bountl Brands to receive a special
              welcome gift!
            </p>
            <div className="flex justify-center">
              <h1 className="guest__afterSelect__message">
                I knew it! You’ve got great taste.
              </h1>
            </div>
          </div>

          {/* image 1 */}
          {/* <img src="./tests/band-image1.png" className="band_image_1" alt="" />
          <img src="./tests/band-image2.png" className="band_image_2" alt="" /> */}
        </div>
      </FluidContainer>
    </>
  );
};

export default GuestArea;
