import React ,{useState,useEffect}from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";
import { getRewards } from "../../../../../../apis/getApis";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { useDispatch } from "react-redux";
import { addRewardUploaderRef } from "../../../../../../app/slices/singleStates";
import RewardEditCard from "./RewardEditCard";

export default function RewardSetter({ data, setF }: any) {
  const [swiper, setSwiper] = React.useState<any>(null);
  const dispatch = useDispatch<any>();

  const handleSwiper = (swiper: any) => {
    setSwiper(swiper);
    dispatch(addRewardUploaderRef(swiper));
  };
  const fetchAllRewards = async (setRewards: any, selectedId: any) => {
    await axios
      .get(`${getRewards}/${selectedId}`)
      .then((res: any) => {
        console.log("fetchedReward", res);
        setRewards(res.data.rewards);
    })
      .catch((err: any) => {
        console.log("fetchedReward", err);
      });
  };
  useEffect(() => {
    const fetchRewards = async () => {
      if (data && data.length > 0) {
        // Assuming selectedId is available in your data structure
        const selectedId = data[0]?.selectedId; // Adjust this according to your data structure
      
        if (selectedId) {
          try {
            const res = await axios.get(`${getRewards}/${selectedId}`);
            console.log("Fetched rewards:", res);
            // Do something with the fetched rewards, like setting them in state
          } catch (error) {
            console.error("Error fetching rewards:", error);
            // Handle error
          }
        } else {
          console.warn("SelectedId not found in data");
        }
      }
    };
  
    fetchRewards();
  }, [data]);
  

  // const [rewards, setRewardsData] = useState<any>([]);
  // useEffect(() => {
  //   fetchAllRewards(setRewardsData, selectedId);
  // }, []);
  if (!data || !Array.isArray(data)) {
    // Handle the case where data is not an array
    return <div>No data available</div>;
  }

  const changeActiveChange = (e:any) => {
    console.log(e,"0099")
  }

  return (
    <div className="mt-half flex">
      <Swiper
        navigation={true}
        slidesPerView={1}  // Display one slide at a time
        spaceBetween={5}  // Add some space between slides
        modules={[Navigation]}
        onSwiper={handleSwiper}
        className="reward_setter_carousel"
        onActiveIndexChange={changeActiveChange}
        loop
        style={{ height: "3rem" }}
      >
        {data.map((elm: any, index: number) => {
          {console.log(data,"rewardssss data")};
          // Display two consecutive items in each iteration
          if (index % 2 === 0) {
            return (
              <SwiperSlide
                className="carousel_reward_setter_screen"
                key={`reward_${elm._id}_${index}`}
              >
                <div className="flex" style={{ width: "fit-content" }}>
                  <RewardEditCard setF={setF} data={elm} currentSwiperInd={index}/>
                  {/* Check if data[index + 1] exists before rendering */}
                  {data[index + 1] && (
                    <RewardEditCard setF={setF} data={data[index + 1]} currentSwiperInd={index+1}/>
                  )}
                </div>
              </SwiperSlide>
            );
          }

          // Return null for odd-indexed items to skip duplicate entries
          return null;
        })}
      </Swiper>
    </div>
  );
}
