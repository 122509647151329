import axios from "axios";
import { setBountiCategory } from "../../apis/putApis";
import {
  getAllSubCategoryList,
  getSelectedCategoriesList,
} from "../../apis/getApis";
import { useEffect, useState } from "react";
import { addSubCategory } from "../../app/slices/singleStates";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setText } from "../../app/slices/CheckListStatus";

const SelectGlobal = ({ styles, data, bountyId }: any) => {
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`${getSelectedCategoriesList}/${bountyId}`).then((res: any) => {
      setSelectedCategory(res.data.data.category);
    });
  }, []);

  // set current bounti category
  const setCurrentBountiCategory = (e: any) => {
    setSelectedCategory(e.target.value);
    axios
      .put(`${setBountiCategory}/${bountyId}`, {
        category: e.target.value,
      })
      .then((res: any) => {
        dispatch(setText([res.data.checkList.Categories, 6]));
        axios.get(`${getAllSubCategoryList}/${bountyId}`).then((res) => {
          console.log("sub-category", res);

          dispatch(addSubCategory(res.data[0].subValues));
        });
      })
      .catch((err: any) => {});
  };

  return (
    <select
      style={
        styles
          ? styles
          : {
              marginTop: "0.5rem",
            }
      }
      className="global-select"
      onChange={setCurrentBountiCategory}
      value={selectedCategory}
      disabled={isLock}
    >
      {data?.map((elm: any) => {
        return <option value={elm?.value}>{elm?.value}</option>;
      })}
    </select>
  );
};

export default SelectGlobal;
