import React from "react";

const BreadcrumbIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.016"
      height="7.749"
      viewBox="0 0 7.016 7.749"
      style={{ marginLeft: "0.5em", marginRight: "0.5em" }}
    >
      <path
        id="Polygon_6"
        data-name="Polygon 6"
        d="M3.874,0,7.749,7.016H0Z"
        transform="translate(7.016) rotate(90)"
        fill="#e98326"
      />
    </svg>
  );
};

export default BreadcrumbIcon;
