import React from "react";

const BountiManagerIcon = () => {
  return (
    <svg
      style={{ marginRight: "1.4em" }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="7.62"
      height="14.898"
      viewBox="0 0 7.62 14.898"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_94"
            data-name="Rectangle 94"
            width="7.62"
            height="14.898"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_156" data-name="Group 156" transform="translate(0 0)">
        <g
          id="Group_155"
          data-name="Group 155"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_114"
            data-name="Path 114"
            d="M7.121,0H.32C.086,0,0,.173,0,.406v3.7H7.622V.406c0-.233-.267-.406-.5-.406M2.044,3.232.756,3.416C.639,3.434.6,3.35.6,3.234V3.191c0-.118.041-.2.156-.187l1.314.172c.116.015.09.04-.026.056"
            transform="translate(0 -0.001)"
            fill="#fff"
          />
          <path
            id="Path_115"
            data-name="Path 115"
            d="M0,3.364v9.875c0,.233.086.486.32.486H.6v.086h.686v-.086H6.336v.086h.6v-.086h.185a.554.554,0,0,0,.5-.486V3.364Zm2.044.905L.756,4.453C.639,4.471.6,4.387.6,4.271V4.228c0-.118.041-.2.156-.187l1.314.172c.116.015.09.04-.026.056"
            transform="translate(0 1.088)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default BountiManagerIcon;
