import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define the interface for the state
export interface State {
  isEnter: boolean; // Indicates if Enter key is pressed
  isRegisterEnter: boolean; // Indicates if Enter key is pressed during registration
  url: string; // URL data
  type: string; // Type data
  formData: any; // Form data
  messages: any; // Messages data
}

// Initial state for the slice
const initialState: State = {
  isEnter: false,
  isRegisterEnter: false,
  url: "",
  type: "",
  formData: null,
  messages: null,
};

// Create a slice for managing state related to input events
export const isEnterOnInput = createSlice({
  name: "isEnterOnInput",
  initialState,
  reducers: {
    submit: (state, action: PayloadAction<boolean>) => {
      state.isEnter = action.payload;
    },
    registerSubmit: (state, action: PayloadAction<boolean>) => {
      state.isRegisterEnter = action.payload;
    },
    addUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    addAuthType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    addFormData: (state, action: PayloadAction<any>) => {
      state.formData = action.payload;
    },
    addMessages: (state, action: PayloadAction<any>) => {
      state.messages = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  submit,
  addUrl,
  addAuthType,
  addFormData,
  addMessages,
  registerSubmit,
} = isEnterOnInput.actions;

export default isEnterOnInput.reducer;
