import React from "react";
import "./watchLaterAd.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { deleteWatchLater } from "../../../../apis/delete";
import Header from "../../../../apis/header";

// it function return media according media type
const AdMediaWatch = ({ src, adId,setWatchLaterData }: any) => {
  // when video end delete API call
  const handleWatchAd = async (adsId: any) => {
    try {
      await axios.delete(
        `${deleteWatchLater}/${adsId}`,
        Header
      );
      console.log("Watch Later Delete:", adsId);
      // Update state after deletion
      setWatchLaterData((prevData:any) => {
        const updatedAds = prevData.ads.filter((ad: any) => ad._id !== adsId);
        return { ...prevData, ads: updatedAds };
      });
    } catch (error) {
      console.error("Error deleting advertisement from Watch Later:", error);
    }
  };

  return (
    <video
      src={src}
      style={{
        width: "100%",
        borderRadius: "1rem",
        objectFit: "cover",
        height: "100%",
      }}
      muted
      controls
      onEnded={() => handleWatchAd(adId)}
    ></video>
  );
};

const WatchLaterAdCard = ({
  styles,
  src,
  adId,
}: any) => {
  return (
    <>
      <section className="home__advertise__card" style={styles}>
        <AdMediaWatch src={src} adId={adId} />
      </section>
    </>
  );
};

export default WatchLaterAdCard;
