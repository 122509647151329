import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14.47"
      height="14.47"
      viewBox="0 0 14.47 14.47"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_39"
            data-name="Rectangle 39"
            width="14.47"
            height="14.47"
            fill="#e98326"
          />
        </clipPath>
      </defs>
      <g
        id="Group_117"
        data-name="Group 117"
        transform="translate(-286.625 -1050.536)"
      >
        <g
          id="Group_30"
          data-name="Group 30"
          transform="translate(286.625 1050.536)"
        >
          <g
            id="Group_29"
            data-name="Group 29"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_17"
              data-name="Path 17"
              d="M13.919,12.92,10.713,9.715a6.023,6.023,0,1,0-1,1l3.206,3.206a.706.706,0,0,0,1-1M1.413,6A4.591,4.591,0,1,1,6,10.595,4.6,4.6,0,0,1,1.413,6"
              transform="translate(-0.056 -0.025)"
              fill="#e98326"
            />
          </g>
        </g>
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(289 1053)"
          fill="#e98326"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
