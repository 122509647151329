import React, { useState, useEffect } from "react";
import { FluidContainer } from "../../components";
import axios from "axios";
import { D } from "../../apis/main";
import { useParams } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import Header from "../../apis/header";
import { emailChange } from "../../apis/postApis";

const EmailChangeForm = ({ form, onCancel }: any) => {

  // Email Change Function
  const handleEmailChange = async (values: any) => {
    try {
      const response = await axios.post(
        `${emailChange}`,
        {
          newEmail: values.newEmail,
        },
        Header
      );

      message.success(response.data.message);
      onCancel(); // Close the modal
      form.resetFields(); // Reset the form fields
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message || "Unauthorized access.");
      } else {
        message.error("Error changing password. Please try again.");
      }
      console.error("Error changing email:", error);
      message.error("Error changing email. Please try again.");
    }
  };

  return (
    <Form form={form} onFinish={handleEmailChange}>
      <Form.Item
        label="New Email"
        name="newEmail"
        rules={[
          {
            required: true,
            message: "Please input your new email!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default EmailChangeForm;
