import React from "react";
import "./profile.css";

const StatCard = ({ num, label,style }: any) => {
  return (
    <section className="profile__statics__card" style={style}>
      <div style={{ fontSize: "1.5rem" }}>{num}</div>
      <b style={{ fontSize: "1rem", color: "var(--sidebar)" }}>{label}</b>
    </section>
  );
};

export default StatCard;
