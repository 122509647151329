import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { FluidContainer } from "../../components";
import styles from "../bounti-pages/BountyPreview/bountipreview.module.css";


const TestPage = () => {
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );

  return (
    <>
      <FluidContainer breadcrumb={["Home", "Bounti", "Lists"]}>
        <div className="flex justify-between bounti-preview-res bp-container">
          {/* 66% */}
          <div
            className={
              toggleD
                ? `${styles.div_67} div_67_respon`
                : `${styles.div_67} div_67_respon div_67_respon_collapse`
            }
          >
          
          </div>
        </div>
      </FluidContainer>
    </>
  );
};

export default TestPage;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import SubmissionStep1 from "../bounti-pages/BountyPreview/related-components/submission-bounti/SubmissionStep1";
// import SubmissionStep2 from "../bounti-pages/BountyPreview/related-components/submission-bounti/SubmissionStep2";
// import SubmissionStep3 from "../bounti-pages/BountyPreview/related-components/submission-bounti/SubmissionStep3";
// import SubmissionStep4 from "../bounti-pages/BountyPreview/related-components/submission-bounti/SubmissionStep4";
// import { ChatRoom, FileManager } from "../../components";
// import { StoredAssetsTab } from "../../components/FileManager";
// import { useLocation } from "react-router-dom";

// const TestPage = () => {
//   const location = useLocation();
//   const selectedId = location.state && location.state.selectedId;
//   const [userPointsData, setUserPointsData] = useState<any>([]);

//   useEffect(() => {
//     axios
//       .get(
//         "https://bountihunt.com:5000/v1/get/tempBountiPoints/6533a2d113a6a73997834e95",
//         {
//           headers: {
//             authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTNhMDhlOTZmMmVkOThjYjJmMGMzNjMiLCJyb2xlIjoibWVtYmVyIiwiZW1haWwiOiJidGh1a3JhbEBnbWFpbC5jb20iLCJpYXQiOjE3MDEwNjQ3MzQsImV4cCI6MTcwODg0MDczNH0.8XyA7foc7-_f2qK8bVBlalbk5LT0zK6vDou__iX_Nu4`,
//           },
//         }
//       )
//       .then((res: any) => {
//         console.log(res);
//         setUserPointsData(res?.data);
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   }, []);
//   return (
//     <div>
//       {/* user points table */}
//       <div style={{ width: "90%", margin: "2rem auto" }}>
//         {/* <h1>Points Test Table</h1>
//         <table
//           style={{
//             width: "100%",
//             border: "1px solid #dbcece",
//             borderRadius: "0.4rem",
//             padding: "0.5rem",
//             marginTop: "1rem",
//             background: "#dbcece",
//           }}
//         >
//           <thead>
//             <tr>
//               <th style={{ border: "1px solid black" }}>User</th>
//               <th style={{ border: "1px solid black" }}>rpPoints</th>
//               <th style={{ border: "1px solid black" }}>xpPoints</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td
//                 style={{
//                   textAlign: "center",
//                   padding: "0.5rem",
//                   border: "1px solid black",
//                 }}
//               >
//                 <b>{userPointsData?.userId}</b>
//               </td>
//               <td
//                 style={{
//                   textAlign: "center",
//                   padding: "0.5rem",
//                   border: "1px solid black",
//                 }}
//               >
//                 <b>{userPointsData?.rpPoints}</b>
//               </td>
//               <td
//                 style={{
//                   textAlign: "center",
//                   padding: "0.5rem",
//                   border: "1px solid black",
//                 }}
//               >
//                 <b>{userPointsData?.xpPoints}</b>
//               </td>
//             </tr>
//           </tbody>
//         </table>

//         <br />
//         <br />
//         <h1>SubmissionStep1</h1>

//         <ChatRoom mesiboToken={"1fcd4c5e716f1a523f5dd17f53972a09a70124992d3c7a4d0083e444a3117yac4aac97feb"}/> */}
//         <FileManager selectedId={selectedId} />
//         <StoredAssetsTab/>

//         {/* <DraggableList/> */}
//       </div>
//     </div>
//   );
// };

// export default TestPage;
