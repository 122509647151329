import React, { useState, useEffect } from "react";
import { Badge, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EditIcon from "@mui/icons-material/Edit"; // Import the edit icon
import { useDispatch } from "react-redux"; // Import useDispatch
import {
  toggleSubmissionScreen,
  setcurrentSubmission,
} from "../../../../app/slices/singleStates";
import {
  setSubmissionTitle,
  setSubmissionDescription,
  setSubmissionFiles,
} from "../../../../app/slices/bountiSubmissionData";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { Popconfirm } from "antd";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import axios from "axios";
import SubmissionView from "../../../../components/SubmissionModule/SubmissionFeedView";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { likeSubmission,pointTransfer,winnerNotify } from "../../../../apis/postApis";
import { Alert } from "antd";
import {
  getSubmissionlikes,
  getSubmissonCounts,
} from "../../../../apis/getApis";
import Header from "../../../../apis/header";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SubmissionViewEditBounty from "../../../../components/SubmissionModule/SubmissionViewEditBounty";
import { winnerUpdate } from "../../../../apis/putApis";

const SubmissionCardBounty = ({
  submission,
  toggleD,
  styles,
  isViewPhase,
}: any) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  // console.log(submission, "edittttt");
  const submissionScreen = useSelector(
    (state: RootState) => state.booleanStatus.submissionScreen
  );
  const [isError, setIsError] = useState(false);
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }
  const location = useLocation();
  const reduxBountyId = location.state;
  const [viewSubmission, setViewSubmission] = useState<any>(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [likes, setLikes] = useState({
    upvotes: 0,
    downvotes: 0,
  });
  const [alertVisible, setAlertVisible] = useState(false); // State for managing the visibility of the alert
  const [upCounts, setUpCounts] = useState("");
  const [downCounts, setDownCounts] = useState("");
  // Handle click on edit icon
  const handleEditClick = () => {
    dispatch(setcurrentSubmission(submission));
    dispatch(setSubmissionDescription(submission.description));
    dispatch(setSubmissionTitle(submission.description));
    dispatch(setSubmissionFiles(submission.media));
    // Dispatch an action to set submission screen to Step 1
    dispatch(toggleSubmissionScreen(true));
  };

  // Click on Submission View Icon
  const handleViewClick = () => {
    dispatch(setcurrentSubmission(submission));
    // dispatch(setSubmissionDescription(submission.description));
    // dispatch(setSubmissionTitle(submission.description));
    // dispatch(setSubmissionFiles(submission.media));
    // Dispatch an action to set submission screen to Step 1
    // dispatch(toggleSubmissionScreen(true));
    setViewSubmission(true);
  };
  const handleViewClickEditBounty = () => {
    dispatch(setcurrentSubmission(submission));
    dispatch(setSubmissionDescription(submission.description));
    dispatch(setSubmissionTitle(submission.description));
    dispatch(setSubmissionFiles(submission.media));
    // Dispatch an action to set submission screen to Step 1
    // dispatch(toggleSubmissionScreen(true));
    setViewSubmission(true);
  };

  // Submission Popup Close
  const handleSubPopupClose = () => {
    setViewSubmission(false);
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${getSubmissionlikes}/${submission?.submissionId}/${loginData?.userId}`,
        Header
      );
      console.log(response, "getsubmission_likes");

      // Update submission state with the received likes data
      setLikes({
        upvotes: response.data.data.upvotes,
        downvotes: response.data.data.downvotes,
      });
    } catch (error) {
      console.error(error, "getsubmission_likes");
    }
  };

  // get submission like API
  useEffect(() => {
    getData();
  }, []);

  // get submissio count API
  useEffect(() => {
    const getCount = async () => {
      try {
        const response = await axios.get(
          `${getSubmissonCounts}/${submission?.submissionId}`
        );
        console.log(response, "getsubmission_counts");
        setUpCounts(response.data.data.totalUpvotes);

        setDownCounts(response.data.data.totalDownvotes);
      } catch (error) {
        console.error(error, "getsubmission_counts");
      }
    };

    getCount();
  }, [downCounts, upCounts]);

  // like current submission
  const likeCurrentSubmission = async (voteType: string) => {
    try {
      const response = await axios.post(
        `${likeSubmission}/${submission?.submissionId}`,
        { voteType }, // pass the vote type to the API endpoint
        Header
      );
      console.log(response, "response_likesub");
      getData();
    } catch (error:any) {
      console.error(error, "response_likesub");
      if (error.response) {
        // If there's a response from the server
        const responseData = error.response.data;
        if (responseData.error) {
          // If the response contains an error message
         setErrorMessage(responseData.error)
        }
      }
      setIsError(true)
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  // Unlike SUbmission API
  const unlikeCurrentSubmission = async () => {
    try {
      const response = await axios.post(
        `${likeSubmission}/${submission?.submissionId}`,
        {}, // pass the vote type to the API endpoint
        Header
      );
      getData();
      console.log(response, "response_likesub_unlike");
    } catch (error:any) {
      console.error(error, "response_likesub_unlike");
      if (error.response) {
        // If there's a response from the server
        const responseData = error.response.data;
        if (responseData.error) {
          // If the response contains an error message
         setErrorMessage(responseData.error)
        }
      }
      setIsError(true)
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  // click on winner icon
  // Handler for confirming making a winner
  // const handleMakeWinnerConfirm = async () => {
  //   try {
  //     // Log the reduxBountyId
  //     console.log("Redux Bounty ID:", reduxBountyId);

  //     // Call winner_update API
  //     await axios.put(
  //       `https://mingalinks.com:5000/v1/winner_update/${submission.submissionId}`
  //     )
  //     .then(response => {
  //       // Handle successful response
  //       console.log("Winner update API response:", response);
  //       // Call winnerNotification API
  //       return axios.post(
  //         `https://mingalinks.com:5000/v1/winnerNotification/${submission.submissionId}/${reduxBountyId.selectedId}`
  //       );
  //     })
  //     .then(response => {
  //       // Handle successful response
  //       console.log("Winner notification API response:", response);
  //       // Close the popup after the submission is marked as a winner
  //       setAlertVisible(false);
  //     })
  //     .catch(error => {
  //       // Handle error from winnerNotification API
  //       console.error("Error marking submission as a winner (winnerNotification API):", error);
  //       // Handle error if needed
  //     });
  //   } catch (error) {
  //     // Handle error from winner_update API
  //     console.error("Error marking submission as a winner (winner_update API):", error);
  //     // Handle error if needed
  //   }
  // };

  // Make a winner API
  const handleMakeWinnerConfirm = async () => {
    try {
      // Log the reduxBountyId
      console.log("Redux Bounty ID:", reduxBountyId);

      // Call winner_update API
      const winnerUpdateResponse = await axios.put(
        `${winnerUpdate}/${submission.submissionId}`
      );

      // Handle successful response from winner_update API
      console.log("Winner update API response:", winnerUpdateResponse);

      // Close the popup after the submission is marked as a winner
      setAlertVisible(false);

      // Call winnerNotification API after closing the popup
      const winnerNotificationResponse = await axios.post(
        `${winnerNotify}/${submission.submissionId}/${reduxBountyId.selectedId}`
      );

      // Handle successful response from winnerNotification API
      console.log(
        "Winner notification API response:",
        winnerNotificationResponse
      );

      // Call additional API with params: bountiId and header for userId
      const additionalApiResponse = await axios.post(
        `${pointTransfer}/${reduxBountyId.selectedId}`,
        {},
        Header
      );

      // Handle successful response from additional API
      console.log("Additional API response:", additionalApiResponse);
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(
          "Error occurred while setting up the request:",
          error.message
        );
      }
      // Handle error if needed
    }
  };

  // Handler for cancelling making a winner
  const handleMakeWinnerCancel = () => {
    // Close the alert
    setAlertVisible(false);
  };
  return (
    <>
    {/* Error Alert */}
      {isError && (
        <div className="announcement_success">
          <Alert
            message={errorMessage}
            type="error"
            showIcon
            onClose={() => setIsError(false)}
          />
        </div>
      )}

      {/* Submission View Component Render */}
      <div
        className="flex"
        style={
          viewSubmission
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        {/* <button
              className="close_dialog__sub__Creation"
              onClick={() => {
               setViewSubmission(false)
              }}
            >
              X
            </button> */}
        {isViewPhase ? (
          <SubmissionViewEditBounty
            onClose={handleSubPopupClose}
            reduxBountyId={reduxBountyId}
          />
        ) : (
          <SubmissionView
            onClose={handleSubPopupClose}
            reduxBountyId={reduxBountyId}
          />
        )}
        {/* {submissionScreen=== 1 && <Step1View mode="view" />}
            {submissionScreen === 2 && <Step2View mode="view" />}
            {submissionScreen === 3 && <CollabaratorStepView />}
            {submissionScreen === 4 && <Step3View mode="view" reduxBountyId={reduxBountyId}/>}
            {submissionScreen === 5 && <Step4View mode="view" reduxBountyId={reduxBountyId}   onClose={handleSubPopupClose}/>}
       */}
      </div>
      <div
        className={
          toggleD ? "rr_commentCard" : "rr_commentCard rr_commentCard_collapse"
        }
        style={{
          marginTop: "1rem",
          height: "8em", // Added quotes around the value
          width: "100%", // Added quotes around the value
          background: "var(--pure)",
          borderRadius: "1em", // Removed semicolon after "1em"
          display: "flex", // Removed semicolon after "flex"
          ...(submission?.isWinner === true &&
            isViewPhase && {
              // Additional styles if submission.isWinner === true
              background: "#3775b1",
            }),
        }}
      >
        {/* User Image who Create Submission */}
        <img
          src={submission?.userImage}
          className={styles.commentImage_c2}
          alt="Profile"
        />
        <div
          className={
            toggleD
              ? `${styles.comment_card_main} r_comment_card_main rr_comment_card_main`
              : `${styles.comment_card_main} r_comment_card_main rr_comment_card_main_collapse`
          }
        >
          <div className="userDeatailParent">
            <div className="flex items-center rr_submission_user_data">
                 {/* User Name who Create Submission */}
              <p
                style={{ marginLeft: "0.5em" }}
                className="rr_submission_user_name"
              >
                <b style={{ color: "var(--orange)" }}>{submission.userName}</b>{" "}
                submitted …
              </p>
            </div>
            {/* Submission Title */}
            <div className={`${styles.submissionTitle} submission_text`}>
              "{submission.title}"
            </div>
            {/* Submission Description */}
            <div className={`${styles.submissionDesc} submission_text`}>
              {submission.description && submission.description.length > 15
                ? `${submission.description.substring(0, 50)}.....`
                : submission.description}
            </div>
          </div>
          {/* Submission Time Ago */}
          <div className="flex flex-col items-end likeandmore_submission">
            <p>
              <b style={{ color: "var(--orange)" }}>{submission.timeAgo}</b>
            </p>
            {/* Submission Upward ,Downword  */}
            <div>
              {!isViewPhase && (
                // <Badge
                //   badgeContent={""}
                //   className={`${styles.badge} m-half-right`}
                // >
                //   {/* <FavoriteBorderIcon className={styles.icons_c1} /> */}
                //   <ThumbUpOffAltOutlinedIcon
                //     className={styles.icons_c1}
                //     onClick={() => likeCurrentSubmission("upvote")}
                //   />
                // </Badge>
                <Badge
                  badgeContent={upCounts}
                  className={`${styles.badge} m-half-right`}
                >
                  {likes?.upvotes > 0 ? (
                    <>
                      {console.log("upvotessss", likes?.upvotes)}
                      <ThumbUpAltIcon
                        className={styles.icons_c1}
                        onClick={() => unlikeCurrentSubmission()}
                      />
                    </>
                  ) : (
                    <ThumbUpOffAltOutlinedIcon
                      className={styles.icons_c1}
                      onClick={() => likeCurrentSubmission("upvote")}
                    />
                  )}
                </Badge>
              )}
              <Badge badgeContent={downCounts} className={`${styles.badge}`}>
                {isViewPhase ? (
                  <IconButton
                    aria-label="eye"
                    component="span"
                    onClick={handleViewClick}
                  >
                    <VisibilityIcon style={{ color: "#e98326" }} />
                  </IconButton>
                ) : likes?.downvotes > 0 ? (
                  <ThumbDownIcon
                    className={styles.icons_c1}
                    onClick={() => unlikeCurrentSubmission()}
                  />
                ) : (
                  <ThumbDownOutlinedIcon
                    className={styles.icons_c1}
                    onClick={() => likeCurrentSubmission("downvote")}
                  />
                )}
              </Badge>

              {!isViewPhase && (
                <Badge
                  badgeContent={""}
                  className={`${styles.badge} m-half-right`}
                >
                  <IconButton
                    aria-label="eye"
                    component="span"
                    onClick={handleViewClick}
                  >
                    <VisibilityIcon style={{ color: "#e98326" }} />
                  </IconButton>
                </Badge>
              )}
              {/* Edit icon within badge */}

              {/* Submission winner */}
              {isViewPhase &&
                (submission?.isWinner === true ? (
                  <span
                    style={{
                      color: "#e98326",
                      marginRight: "0.5rem",
                      fontWeight: "700",
                    }}
                  >
                    Winner Declared
                  </span>
                ) : (
                  <Popconfirm
                    title="Make a Winner"
                    description="Are you sure you want to make this user a winner?"
                    visible={alertVisible}
                    onConfirm={handleMakeWinnerConfirm}
                    onCancel={handleMakeWinnerCancel}
                    okText="Yes"
                    cancelText="No"
                    icon={
                      <EmojiEventsIcon
                        style={{ color: "#e98326", marginRight: "0.5rem" }}
                      />
                    }
                  >
                    <IconButton
                      aria-label="make a winner"
                      component="span"
                      onClick={() => setAlertVisible(true)}
                    >
                      <EmojiEventsIcon style={{ color: "#e98326" }} />
                    </IconButton>
                  </Popconfirm>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmissionCardBounty;
