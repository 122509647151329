import React, { useState, useEffect } from "react";
import { FluidContainer } from "../../../../components";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import Header from "../../../../apis/header";
import { getWatchLater } from "../../../../apis/getApis";
import { deleteWatchLater } from "../../../../apis/delete";
import WatchLaterAdCard from "./WatchLaterAdCard";

const WatchLater = () => {
  const [form] = Form.useForm();
  const [watchLaterData, setWatchLaterData] = useState<any>({}); // Initialize as empty array
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }

  // Get watch later data API
  useEffect(() => {
    const fetchWatchLaterData = async () => {
      try {
        const response = await axios.get(`${getWatchLater}`, Header);
        setWatchLaterData(response.data.data); // Assuming response.data contains userData object
        console.log(response.data.data, "response.data.user");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchWatchLaterData();
  }, []);

  const handleWatchAd = async (adsId: any) => {
    try {
      await axios.delete(
        `${deleteWatchLater}/${adsId}`, // Remove : from template string
        Header
      );
      console.log("Watch Later Delete:", adsId);
      // If you want to update state after deletion, you need to refetch the data or manually remove the item from state.
    } catch (error) {
      console.error("Error deleting advertisement from Watch Later:", error);
    }
  };

  return (
    <FluidContainer breadcrumb={["Home", "Everything Else", "Watch Later"]}>
      {/* <main className="setting__main flex justify-between"> */}
      <div>
        {watchLaterData.ads &&
          watchLaterData.ads.map((ad: any) => (
            <>
              {/* {console.log(ad, "fhsufyguysdg")} */}
              <WatchLaterAdCard
                key={ad._id}
                src={ad.clickLink}
                adId={ad._id}
                setWatchLaterData={setWatchLaterData}
              />
            </>
          ))}
      </div>
      {/* </main> */}
    </FluidContainer>
  );
};

export default WatchLater;
