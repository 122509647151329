import { Badge } from "@mui/material";
import styles from "./sidebar.module.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  addSidebarToggle,
  addSidebarToggleD,
  setProfileDataRedux,
  setSidebarStatsRedux,
  toggleIsRefresh,
} from "../../app/slices/singleStates";
import { SearchIcon } from "../../svgicons";
import AntDMenu from "./AntDMenu";
import NotificationDropDown from "./NotificationDropDown";
import { useEffect, useState } from "react";
import { getUserProfile } from "../../apis/getApis";
import axios from "axios";
import Header from "../../apis/header";
import { D, U } from "../../apis/main";

const Sidebar = ({ dynamic }: any) => {
  const toggle = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggle
  );
  const isRefresh = useSelector(
    (state: RootState) => state.booleanStatus.isRefresh
  );
  const currentRoutesStage = useSelector(
    (state: RootState) => state.booleanStatus.currentRoutesStage
  );
  const profileDataRedux = useSelector(
    (state: RootState) => state.booleanStatus.profileData
  );
  // setSidebarStatsRedux
  const sidebarStatsRedux = useSelector(
    (state: RootState) => state.booleanStatus.sidebarStats
  );
  const [isLoading, setIsloading] = useState(false);
  const [profileData, setProfileData] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalNotifications, setTotalNotification] = useState(0);
  const [sidebarStats, setSidebarStats] = useState<any>({});
  const logoutFromMinga = () => {
    localStorage.clear();
    navigate("/login");
  };

  // goto myfeed
  const goToMyFeed = (e: any) => {
    navigate("/home");
  };

  // console.log("SidebarDynamic", dynamic);

  const [isNotification, setIsNotification] = useState(false);
  let [isClickOnNotification, setIsClickOnNotification] = useState(0);

  const fetchProfileData = async () => {
    setIsloading(true);
    await axios
      .get(getUserProfile, Header)
      .then((res: any) => {
        // console.log("sidebarProfile", res);
        setProfileData(res?.data);
        dispatch(setProfileDataRedux(res.data));
        setIsloading(false);
        dispatch(toggleIsRefresh(false));
      })
      .catch((err: any) => {
        setIsloading(false);
      });
  };
  // console.log(profileData, "profileData");
  const fetchStats = async () => {
    await axios
      .get(`${D}/get/sidebarData/${U}`)
      .then((sidebarStats: any) => {
        // console.log("sidebarStats", sidebarStats);
        dispatch(setSidebarStatsRedux(sidebarStats.data));
        console.log(sidebarStats.data, "sidebarStats.data");
      })
      .catch((error: any) => {
        // console.log("sidebarStats", error);
      });
  };

  useEffect(() => {
    fetchStats();
  }, []);

  useEffect(() => {
    if (profileDataRedux) {
      fetchProfileData();
    } else {
      fetchProfileData();
    }
  }, []);

  useEffect(() => {
    fetchProfileData();
    console.log("update update update ", isRefresh);
  }, [isRefresh]);

  return (
    <>
      <aside
        className={
          toggle
            ? `${styles.sidebar} main_sidebar_res`
            : `${styles.sidebar} hide_main_sidebar_res`
        }
      >
        <div className={`${styles.inner}`}>
          {/* branding section goes here */}
          <section className={`${styles.branding}`}>
            {/* logo */}
            <div
              className={`${styles.logo}`}
              onClick={goToMyFeed}
              style={{ cursor: "pointer" }}
            >
              <img
                src={dynamic ? "../tests/logo.svg" : "./tests/logo.svg"}
                alt=""
              />
            </div>

            {/* tagline */}
            <p className={`${styles.tagline}`}>
              Where <span className={`${styles.highlight}`}>connections</span>{" "}
              matter
            </p>
          </section>
          {/* branding section end here */}

          {/* profile goes here */}
          <section
            className={`${styles.profile}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/profile");
            }}
          >
            <div className={`${styles.profileImg}`}>
              <img
                src={profileDataRedux?.userImage || profileData?.userImage}
                alt="profile"
              />
              {/* <img
                src={
                  dynamic ? "../tests/deadpool.webp" : "./tests/deadpool.webp"
                }
                alt="profile"
              /> */}
            </div>
            <div className={`${styles.user}`}>
              <h2>{profileDataRedux?.userName || profileData?.userName}</h2>
            </div>
          </section>
          {/* profile end here */}

          {/* notification and message badges */}
          <section className={`${styles.badges} flex justify-center`}>
            <Badge badgeContent={0} className={styles.badge}>
              <MailIcon className={styles.icons} />
              {/* <MessageIcon/> */}
            </Badge>

            <div
              onClick={() => {
                isNotification
                  ? setIsNotification(false)
                  : setIsNotification(true);

                setIsClickOnNotification(isClickOnNotification + 1);
              }}
              style={{ cursor: "pointer" }}
            >
              <Badge badgeContent={totalNotifications} className={styles.badge}>
                <NotificationsIcon
                  className={`${styles.icons} ${styles.notiIcon}`}
                />
              </Badge>
            </div>
          </section>

          {/* diff-area */}
          <section className={`${styles.diff} flex justify-center`}>
            <div className={`${styles.stats} ${styles.firstStats}`}>
              <b className={`${styles.highlight}`}>
                {sidebarStatsRedux?.level}
              </b>
              <b className={`${styles.st_text}`}>Level</b>
            </div>

            <div className={styles.stats}>
              <b className={`${styles.highlight}`}>
                {sidebarStatsRedux?.points}
              </b>
              <b className={`${styles.st_text}`}>Coins</b>
            </div>

            <div className={styles.stats}>
              <b className={`${styles.highlight}`}>{sidebarStatsRedux?.loot}</b>
              <b className={`${styles.st_text}`}>Loot</b>
            </div>

            <div
              className={styles.toggleB}
              onClick={() => {
                dispatch(addSidebarToggleD(false));
                dispatch(addSidebarToggle(false));
              }}
            >
              <ArrowLeftIcon className={styles.toggleBIcon} />
            </div>
          </section>

          {/* sidebar_list */}
          <ul className={`${styles.linkList_P}`}>
            <AntDMenu />
          </ul>
        </div>

        {/* bottom */}
        <section className={`${styles.sidebar_bottom}`}>
          <div className={styles.extraPages}>
            <Link
              to={"/privacyPolicy"}
              className={styles.ex_Li}
              target="_blank"
            >
              Privacy
            </Link>
            <Link to={"/"} className={styles.ex_Li}>
              Help
            </Link>
            <Link to={"/"} className={styles.ex_Li}>
              Legal
            </Link>
            <Link to={"/"} className={styles.ex_Li}>
              Contact
            </Link>
            <button
              className={`${styles.ex_Li} logoutButoon`}
              onClick={logoutFromMinga}
            >
              Logout
            </button>
          </div>
          {/* search */}
          <div className={`flex items-center ${styles.sidebar_bottom_search}`}>
            <input
              className={`${styles.input}`}
              type="text"
              placeholder="search..."
            />
            <SearchIcon />
          </div>
        </section>
      </aside>
      {/* notificationDropDown */}
      <NotificationDropDown
        isNotification={isNotification}
        setIsNotification={setIsNotification}
        setTotalNotification={setTotalNotification}
        isClickOnNotification={isClickOnNotification}
      />
    </>
  );
};

export default Sidebar;
