import { Switch } from "antd";
import React from "react";

const NotificationDropDown = ({ setIsBountyNotiPop }: any) => {
  return (
    <div className="bounty__notification__drop">
      <div className="notificati__close">
        <button
          onClick={() => {
            setIsBountyNotiPop(false);
          }}
        >
          x
        </button>
      </div>
      <div className="email_NotifiConatiner">
        <div className="email_NotifiConatiner__Left">Allow Email</div>
        <div className="email_NotifiConatiner__switch">
          <Switch />
        </div>
      </div>
    </div>
  );
};

export default NotificationDropDown;
