import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Input, Switch } from "antd";
import { setSubmissionScreen } from "../../app/slices/singleStates";
import { useDispatch, useSelector } from "react-redux";
import {
  setPublic,
  setSubmissionDescription,
  setSubmissionTitle,
} from "../../app/slices/bountiSubmissionData";
import { RootState } from "../../app/store";
import { setCurrentSubmission } from "../../app/slices/submissionForm";
const { TextArea } = Input;

const Step1 = () => {
  const dispatch = useDispatch();
  const reduxSubmissionData = useSelector((state: RootState) => {
    return state.bountiSubmissionData;
  });
  const { currentSubmission } = useSelector(
    (state: any) => state.booleanStatus
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publicStatus, setPublicStatus] = useState(false);

  // useEffect(() => {
  //   if (currentSubmission) {
  //     setTitle(currentSubmission.title || "");
  //     setDescription(currentSubmission.description || "");
  //     setPublicStatus(currentSubmission.isPublic || false);

  //     // Update Redux state with current values
  //     dispatch(setSubmissionTitle(currentSubmission.title || ""));
  //     dispatch(setSubmissionDescription(currentSubmission.description || ""));
  //     dispatch(setPublic(currentSubmission.isPublic || false));
  //   }
  // }, [currentSubmission]);

  const handleTitleChange = (e: any) => {
    // setTitle(e.target.value);
    dispatch(setSubmissionTitle(e.target.value));
  };

  const handleDescriptionChange = (
    e: any
  ) => {
    // setDescription(e.target.value);
    dispatch(setSubmissionDescription(e.target.value));
  };
  // const switchChange = (checked: boolean) => setPublicStatus(checked);
  const switchChange = (e: any) => {
    dispatch(setPublic(e));
  };

  const handleNextClick = () => {
    // event.preventDefault();
    // if (mode === "create") {
      // dispatch(setSubmissionTitle(title));
      // dispatch(setSubmissionDescription(description));
      // dispatch(setPublic(publicStatus));
      dispatch(setSubmissionScreen(2));
    // } 
    // else if (mode === "edit" && currentSubmission) {
    //   dispatch(
    //     setCurrentSubmission({
    //       ...currentSubmission,
    //       title: title,
    //       description: description,
    //       isPublic: publicStatus,
    //     })
    //   );
    //   console.log("Current Title dispatch:", title);
    //   console.log("Current Description dispatch:", description);
    //   console.log("Current Public Status dispatch:", publicStatus);
    //   dispatch(setSubmissionTitle(currentSubmission.title || ""));
    //   dispatch(setSubmissionDescription(currentSubmission.description || ""));
    //   dispatch(setPublic(currentSubmission.isPublic || false));
    
    //   console.log("Redux Submission Data after dispatch:", reduxSubmissionData);
    //   dispatch(setSubmissionScreen(2));
    // }
  };

  return (
    <section className="submission__step1">
      <div className="submission__step__inner">
        <div>
          <label
            htmlFor="sTilte"
            className="STILTELAVBEL"
            style={{ color: "var(--orange)" }}
          >
            Submission Title
          </label>
          <Input
            placeholder="Submission Title"
            id="sTilte"
            style={{ marginTop: "0.4rem" }}
            value={reduxSubmissionData?.title}
            onChange={handleTitleChange}
          />
        </div>

        <div style={{ marginTop: "1rem" }}>
          <label
            htmlFor="s1sDesc"
            className="STILTELAVBEL"
            style={{ color: "var(--orange)" }}
          >
            Submission Description
          </label>
          <TextArea
            placeholder="Submission Description"
            id="s1sDesc"
            style={{ marginTop: "0.4rem", height: "10rem", resize: "none" }}
            onChange={handleDescriptionChange}
            value={reduxSubmissionData?.description}
          />
          <div
            className="flex items-center"
            style={{ marginLeft: "1rem", marginTop: "2rem" }}
          >
            <p style={{ marginRight: "0.4rem" }}>private</p>
            <Switch
              size={"small"}
              checked={reduxSubmissionData?.isPublic}
              onChange={switchChange}
            />
            <p style={{ marginLeft: "0.4rem" }}>public</p>
          </div>
        </div>

        <div
          className="step1_sub_buttonR step1__SubmissionButooRow"
          style={{ marginTop: "1rem" }}
        >
          <Button className="submit__button_Submisn" onClick={handleNextClick}>
            {/* {mode === "create" ? "Next" : "Update & Next"} */}Next
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Step1;
