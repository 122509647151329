import React, { useState } from "react";
import axios from "axios";
import "./advertisementForm.css";
import { advertisementUpload } from "../../apis/postApis";

const AdForm = () => {
  const [formData, setFormData] = useState({
    adTitle: "",
    video: null,
    youtubeLink: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        video: file,
      }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const form = new FormData();
      form.append("adTitle", formData.adTitle);
      console.log(formData.adTitle, "formData.adTitle");
      form.append("youtubeLink", formData.youtubeLink);
      console.log(formData.youtubeLink, "formData.youtubeLink");

      if (formData.video) {
        form.append("video", formData.video);
      }
      console.log("FormData:", form);

      const response = await axios.post(advertisementUpload, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Form submitted successfully:", response.data);

      alert(response.data.message);

      setFormData({
        adTitle: "",
        video: null,
        youtubeLink: "",
      });
      
    } catch (error) {
      console.error("Error uploading ad:", error);
      alert("An error occurred while uploading the video. Please try again later.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="ad-form">
      <div>
        <h5>Advertisement Upload Form</h5>
      </div>
      <label>
        Title:
        <input
          type="text"
          name="adTitle"
          value={formData.adTitle}
          onChange={handleInputChange}
        />
      </label>
      <br />

      <label>
        Upload Advertisement:
        <input
          type="file"
          name="video"
          accept="video/*,image/*"
          onChange={handleFileChange}
        />
      </label>
      <br />

      <label>
        YouTube Link (Optional):
        <input
          type="text"
          name="youtubeLink"
          value={formData.youtubeLink}
          onChange={handleInputChange}
        />
      </label>
      <br />

      <button type="submit">Submit</button>
    </form>
  );
};

export default AdForm;