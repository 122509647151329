import styles from "./card.module.css";

const BlueCard = ({ user, userId, userProfle }: any) => {
  return (
    <section className={styles.blueCard} id={userId}>
        {/* Profile section */}
      <section className={styles.profile}>
            {/* Profile image */}
        <div className={styles.profileImage}>
          <img src={`${userProfle}`}  alt="" />
            {/* Displaying user's name */}
          <b>{user.split("@")[0]}</b>
        </div>
      </section>
         {/* Meter section */}
      <section className={styles.meter}>
        <div className={styles.meterDiv}>
             {/* Meter value */}
          <span>90</span>
          <img src="./tests/icons/meterPure.svg" style={{ width: "2rem" }} />
             {/* Meter category */}
          <span>Gaming +2</span>
        </div>
      </section>
      <section className={styles.basicInfo}>
        <div>
          <p
            style={{
              color: "var(--pure)",
              fontSize: "0.8rem",
              fontWeight: "400",
            }}
          >
            1 Mutual Friend
          </p>
          <p
            style={{
              color: "var(--pure)",
              fontSize: "0.8rem",
              fontWeight: "400",
            }}
          >
            1 Mutual Brands
          </p>
        </div>
        <img src="./tests/icons/friend.svg" alt="" />
      </section>
    </section>
  );
};

export default BlueCard;
