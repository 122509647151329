import React from "react";
import "./profile.css";
import { FluidContainer } from "../../components";
import UserCard from "./UserCard";
import ProfileTabs from "./ProfileTabs";
import SubmissionCard from "./SubmissionCard";
import FriendCard from "./FriendCard";
import { useParams } from "react-router-dom";
import axios from "axios";
import { D, U } from "../../apis/main";
import { fetchProfileStats, getUserProfile } from "../../apis/getApis";
import Header from "../../apis/header";

const Profile = () => {
  const [isDynamicRoute, setIsDynamicRoute] = React.useState<boolean>(false);
  const [allSubmission, setAllSubmission] = React.useState<any>([]);
  const [profileStats, setProfileStats] = React.useState<any>({});
  const [friendlist, setFriendList] = React.useState<any>([]);
  // const [profileDetail, setProfileDetail] =  React.useState<any>();

  // fetch friend
  const fetchFriends = async () => {
    await axios
      .get(`${D}/get/friend-requests/${U}`)
      .then((response: any) => {
        console.log("fetchFriendLists", response?.data?.friendsList);
        setFriendList(response?.data?.friendsList);
      })
      .catch((error: any) => {
        console.log("fetchFriendLists", error);
      });
  };
  let { userId } = useParams();
  React.useEffect(() => {
    if (userId) {
      setIsDynamicRoute(true);
    } else {
      setIsDynamicRoute(false);
    }

    // userwise submission
    axios
      .get(`${D}/get/submissionData/${U}`)
      .then((submissionResponse: any) => {
        console.log("allSubmission Data", submissionResponse.data.submissions);
        setAllSubmission(submissionResponse.data.submissions);
      })
      .catch((error: any) => {
        console.log("allSubmission Data", error);
      });

    // fetch stats
    axios
      .get(`${fetchProfileStats}/${U}`)
      .then((response: any) => {
        console.log("profileStats", response);
        setProfileStats(response.data);
      })
      .catch((err: any) => {
        console.log("profileStats", err);
      });

    // fetch profiledata
    // axios
    // .get(getUserProfile, Header)
    // .then((res: any) => {
    //     console.log("sidebarProfile", res);
    //     setProfileDetail(res.data); // Update state with res.data
    //     console.log(res.data, "profileDetail"); // Log the updated state
    // })
    // .catch((err: any) => {
    //     // Handle error
    // });

    fetchFriends();
  }, []);
  return (
    <FluidContainer>
      {/* main goes here */}
      <main className="profile__main flex justify-between">
        {/* main user related here info goes here */}
        <div className="profile__middle">
          <UserCard
            isDynamicRoute={isDynamicRoute}
            profileStats={profileStats}
          />

          <ProfileTabs isDynamicRoute={isDynamicRoute} />
        </div>
        {/* main user related here info goes here */}

        {/* profile main right section goes here */}
        <section className="profile__right">
          <SubmissionCard
            isDynamicRoute={isDynamicRoute}
            allSubmission={allSubmission}
          />
          <FriendCard friends={friendlist} isDynamicRoute={isDynamicRoute} />
        </section>
        {/* profile main right section end here */}
      </main>
      {/* main end here */}
    </FluidContainer>
  );
};

export default Profile;
