import React from "react";
import "./style.css";
import { Button } from "antd";
import axios from "axios";
import { acceptOrDecline } from "../../apis/putApis";
import { fetchNotification } from "../../apis/getApis";
import { useNavigate } from "react-router-dom";

const FriendNotify = ({
  message,
  time,
  profile,
  requestId,
  setNotifications,
  setTotalNotification,
  type,
  setTotalWinnerNotification,
  bountyId,
  senderId,
  notificationId,
}: any) => {
  const navigate = useNavigate();

  // friend request Accept or Decline
  const AcceptOrDecline = async (status: any) => {
    await axios
      .put(`${acceptOrDecline}/${requestId}`, {
        response: status,
      })
      .then((res: any) => {
        axios
          .get(fetchNotification)
          .then((notifications: any) => {
            console.log("AcceptOrDecline1", notifications);
            setNotifications(notifications.data.notifications);
            setTotalNotification(notifications.data.notifications.length);
          })
          .catch((err: any) => {});
        console.log("AcceptOrDecline", res);
      })
      .catch((err: any) => {
        console.log("AcceptOrDecline", err);
      });
  };

  // Bounty redirect
  const redirectBounty = (bountyId: string) => {
    navigate("/bountipreview", { state: bountyId });
    console.log("bountyIdbountyId", bountyId);
  };

  // View user profile
  const viewProfile = () => {
    navigate(`/profile/${senderId}`);
  };

  // Notification close function
  // Notification close function
  const handleNotificationClose = async (notificationId: any) => {
    try {
      await axios.delete(
        `https://mingalinks.com:5000/v1/remove/notifications/${notificationId}`
      );

      // Remove the deleted notification from the state
      setNotifications((prevNotifications: any) =>
        prevNotifications.filter(
          (notification: any) => notification.notificationId !== notificationId
        )
      );
      // Update total notification count
      setTotalNotification((prevCount: any) => prevCount - 1);
    } catch (error) {
      console.error("Error removing notification:", error);
    }
  };

  return (
    <div className="friend__notify flex justify-between">
      {/* profile */}
      <div className="flex">
        <div
          className="friend__notify__profile"
          onClick={viewProfile}
          style={{ cursor: "pointer" }}
        >
          <img src={profile} alt="sender" />
        </div>
        <div className="friend__notify__middle">
          <div className="friend__notify__text">
            {/* <b>Tarun Rajpurohit</b> sent you a friend request. */}
            {message}
          </div>
          <div className="friend__notify__time">{time}</div>

          {/* type engaged */}
          {type === "engaged" && (
            <div style={{ marginTop: "1rem", width: "112%" }}>
              <Button
                className="friend__notify__Accept"
                onClick={() => {
                  AcceptOrDecline("accept");
                }}
              >
                Accept
              </Button>
              <Button
                className="friend__notify__cancel"
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  AcceptOrDecline("decline");
                }}
              >
                Cancel
              </Button>
            </div>
          )}

          {/* type winner */}
          {type === "winner" && (
            <div style={{ marginTop: "1rem" }}>
              <Button
                className="friend__notify__cancel"
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  redirectBounty(bountyId);
                }}
              >
                Visit Bounty
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="friend__read__mark">
        {/* <div className="circle__unmark"></div> */}
        {type === "engaged" ? (
          <div></div>
        ) : (
          <div
            className="close__unmark"
            onClick={() => handleNotificationClose(notificationId)}
          >
            X
          </div>
        )}
      </div>
    </div>
  );
};

export default FriendNotify;
