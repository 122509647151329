import React, { useEffect, useState } from "react";
import Pin_Module from "./Pin_Module";
import { MoreHorizOutlined, MoreVert } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  getAdvertisePins,
  getBountyPins,
  getCharityPins,
  getSponsorPins,
  globalBounti2,
} from "../../../../apis/getApis";
import axios from "axios";
import {
  includeAdvertises,
  includeBounties,
  includeCharities,
  includeSponser,
} from "../../../../app/slices/pinsData";
import { pinBounty, pinAd } from "../../../../apis/postApis";
import Header from "../../../../apis/header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createCharity, createSponsor } from "../../../../apis/postApis";
import { addFeedAdURL } from "../../../../app/slices/singleStates";

const PinSection = ({ setIsAdPopup }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pinsData = useSelector((state: RootState) => {
    return state.pinsData;
  });
  const location = useLocation();
  const reduxBountyId = location.state;
  const [boutiesData, setBountiesData] = useState<any>({});
  const [bountyPin, setBountyPin] = useState<any>({});
  const [adPin, setAdPin] = useState<any>({});
  console.log(pinsData, "pinsData putResponse bountyPin");
  useEffect(() => {
    setBountyPin(pinsData.bounties);
    setAdPin(pinsData.ads);
  }, []);

  // when click on pin Bounty it will be unpin
  const unPinThisBounty = async (bountyId: any) => {
    console.log(bountyId, "bountyId putResponse");
    try {
      const putResponse = await axios.post(`${pinBounty}/${bountyId}`, {
        title: bountyPin?.title,
      });
      const bountyPins = await axios.get(getBountyPins);
      console.log(putResponse, "putResponse putResponse");
      dispatch(includeBounties(bountyPins.data.pinnedBounti));
    } catch (error) {
      console.error("Error pinning bounty putResponse:", error);
    }
  };
  // when click on pin Advertisement it will be unpin
  const unpinAd = async (adId: any) => {
    await axios
      .post(`${pinAd}/${adId}`, {})
      .then((putResponse: any) => {
        axios
          .get(getAdvertisePins)
          .then((pinsAd: any) => {
            dispatch(includeAdvertises(pinsAd.data.pinnedAdvertisements));
          })
          .catch((err) => {});
      })
      .catch((putError: any) => {});
  };
  console.log(pinsData, "pinsDatapinsData putResponse");
  React.useEffect(() => {
    // Pinned advertisements data API
    axios
      .get(getAdvertisePins)
      .then((pinsAd: any) => {
        console.log("totalAdsPins", pinsAd.data.pinnedAdvertisements);
        dispatch(includeAdvertises(pinsAd.data.pinnedAdvertisements));
      })
      .catch((err) => {});
    // Pinned Sponsors data
    axios
      .get(getSponsorPins)
      .then((pinsSonser: any) => {
        console.log("totalSponserPinssxaz", pinsSonser);
        dispatch(includeSponser(pinsSonser.data.pinnedSponsors));
      })
      .catch((err) => {});
    // pinned bounties data
    axios
      .get(getBountyPins)
      .then((pinsBounty: any) => {
        console.log("totalBountyPins", pinsBounty);
        dispatch(includeBounties(pinsBounty.data.pinnedBounti));
      })
      .catch((err) => {});
    // pinned charity data
    axios
      .get(getCharityPins)
      .then((pinsCharity: any) => {
        dispatch(includeCharities(pinsCharity.data.pinCharity));
      })
      .catch((err) => {});
  }, []);

  // redirect clicked bounty
  const viewBounty = (id: any) => {
    navigate("/bountipreview", { state: id });
  };

  return (
    <React.Fragment>
      <div className="P__pinSection">
        <div className="pinsection__header">Pins</div>

        {/* Bounty Pin Section */}
        <Pin_Module no={"1"} label={"Bounties"}>
          {pinsData?.bounties?.map((bountyPin: any, ind: any) => {
            return (
              <div
                className="pin__module flex justify-between items-center pin__childern"
                style={{ background: "var(--light)" }}
              >
                <div className="pin__module__left flex items-center">
                  <div className="pin__module_no">{ind + 1}</div>
                  <div
                    className="pin__module_label"
                    style={{ fontSize: "0.8rem", cursor: "pointer" }}
                    onClick={() => {
                      viewBounty(bountyPin?.bountiId);
                    }}
                  >
                    {bountyPin?.title}
                  </div>
                </div>
                <div className="pin__modul__right flex items-center">
                  {/* <p
                    style={{
                      fontSize: "0.8rem",
                      color: "var(--sidebar_secondory)",
                      marginRight: "0.8rem",
                      fontWeight: "600",
                    }}
                  >
                    Dec 15
                  </p> */}
                  <img
                    src="./tests/icons/pin-fill.svg"
                    style={{ cursor: "pointer", width: "1rem" }}
                    alt=""
                    onClick={() => unPinThisBounty(bountyPin.bountiId)}
                  />
                  <MoreVert style={{ color: "var(--orange)" }} />
                </div>
              </div>
            );
          })}
        </Pin_Module>

        {/* Sponsor Pin Section */}
        {/* <Pin_Module no={"2"} label={"Sponsers"}>
          {pinsData?.sponsers?.map((sponserPin: any, ind: any) => {
            return (
              <div
                className="pin__module flex justify-between items-center pin__childern"
                style={{ background: "var(--light)" }}
              >
                <div className="pin__module__left flex items-center">
                  <div className="pin__module_no">{ind + 1}</div>
                  <div
                    className="pin__module_label"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {sponserPin?.sponsorName}
                  </div>
                </div>
                <div className="pin__modul__right flex items-center">
                  
                  <img
                    src="./tests/icons/pin-fill.svg"
                    style={{ cursor: "pointer", width: "1rem" }}
                    alt=""
                  />
                  <MoreVert style={{ color: "var(--orange)" }} />
                </div>
              </div>
            );
          })}
        </Pin_Module>

        // Charity Pin section
        <Pin_Module no={"3"} label={"Charities"}>
          {pinsData?.charities?.map((charityPin: any, ind: any) => {
            return (
              <div
                className="pin__module flex justify-between items-center pin__childern"
                style={{ background: "var(--light)" }}
              >
                <div className="pin__module__left flex items-center">
                  <div className="pin__module_no">{ind + 1}</div>
                  <div
                    className="pin__module_label"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {charityPin?.charityId}
                  </div>
                </div>
                <div className="pin__modul__right flex items-center">
                  <img
                    src="./tests/icons/pin-fill.svg"
                    style={{ cursor: "pointer", width: "1rem" }}
                    alt=""
                  />
                  <MoreVert style={{ color: "var(--orange)" }} />
                </div>
              </div>
            );
          })}
        </Pin_Module> */}

        {/* Advertisement Pin Section */}
        <Pin_Module no={"2"} label={"View later"}>
          {pinsData?.ads?.map((adPin: any, ind: any) => {
            console.log("pinAdKaData", adPin);
            return (
              <div
                className="pin__module flex justify-between items-center pin__childern"
                style={{ background: "var(--light)", cursor: "pointer" }}
              >
                <div className="pin__module__left flex items-center">
                  <div className="pin__module_no">{ind + 1}</div>
                  <div
                    className="pin__module_label"
                    style={{ fontSize: "0.8rem" }}
                    onClick={() => {
                      setIsAdPopup(true);
                      dispatch(addFeedAdURL(adPin?.adId));
                    }}
                  >
                    {adPin?.adTitle}
                  </div>
                </div>
                <div className="pin__modul__right flex items-center">
                  {/* <p
                    style={{
                      fontSize: "0.8rem",
                      color: "var(--sidebar_secondory)",
                      marginRight: "0.8rem",
                      fontWeight: "600",
                    }}
                  >
                    Dec 15
                  </p> */}
                  <img
                    src="./tests/icons/pin-fill.svg"
                    style={{ cursor: "pointer", width: "1rem" }}
                    alt=""
                    onClick={() => unpinAd(adPin.adId)}
                  />
                  <MoreVert style={{ color: "var(--orange)" }} />
                </div>
              </div>
            );
          })}
        </Pin_Module>
      </div>
    </React.Fragment>
  );
};

export default PinSection;
