import { useNavigate } from "react-router-dom";

const BlueCard1 = ({ isDynamicRoute, friendData }: any) => {

  // localstates
  const navigate = useNavigate();


  return (
    <section
      className={"profile__blueCard"}
      id={"userId"}
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/profile/${friendData?.userId}`);
      }}
    >
      <section className={"profile__profile"}>
        <div className={"profile__profileImage"}>
          <img src={friendData?.userImage} alt="profileImag" />
          <b>{friendData?.userName}</b>
        </div>
      </section>
      <section className={"profile__meter"}>
        <div className={"profile__meterDiv"}>
          <span>90</span>
          <img
            src={
              isDynamicRoute
                ? "../tests/icons/meterPure.svg"
                : "./tests/icons/meterPure.svg"
            }
            style={{ width: "2rem" }}
          />
          <span>Gaming +2</span>
        </div>
      </section>
      <section className={"profile__basicInfo"}>
        <div>
          <p
            style={{
              color: "var(--pure)",
              fontSize: "0.8rem",
              fontWeight: "400",
            }}
          >
            1 Mutual Friend
          </p>
          <p
            style={{
              color: "var(--pure)",
              fontSize: "0.8rem",
              fontWeight: "400",
            }}
          >
            1 Mutual Brands
          </p>
        </div>
        <img
          src={
            isDynamicRoute
              ? "../tests/icons/friend.svg"
              : "./tests/icons/friend.svg"
          }
          alt=""
        />
      </section>
    </section>
  );
};

export default BlueCard1;
