import React, { useState } from "react";
import { SearchIcon } from "../../../../svgicons";

const P_Search = ({ onSearch }: any) => {
  const [searchQuery, setSearchQuery] = useState("");

  // functin call when click on serch icon
  const handleSearch = () => {
    console.log("handleSearch called", handleSearch, onSearch, searchQuery);
    // Trigger search API call
    onSearch(searchQuery);
  };

  // search works on key board enter key
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <React.Fragment>
      <div className="P__search">
        {/* Search Input filed */}
        <input
          type="text"
          placeholder="Search the feed ..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        {/* search Icon */}
        <div className="icons__serach" onClick={handleSearch}>
          <SearchIcon />
        </div>
      </div>
    </React.Fragment>
  );
};

export default P_Search;
