import { D, U, WD } from "./main";

const likeCurrentBounti = `${D}/like`;
const bookMarkCurrentBounti = `${D}/favorite`;
const mediaUpload = `${D}/upload`;
const createSponsor = `${D}/bounti-sponsor`;
const createCharity = `${D}/bounti-charity`;
const addStoredData = `${D}/create/storedAssets`;
const Date_Time_Picker = `${D}/bounti-phase`;
const createReward = `${D}/create/reward`;
const loginRoute = `${D}/login`;
const loginWithGoogle = `${WD}/auth`;
const loginWithFacebook = `http://www.facebook.com/v17.0/dialog/oauth?client_id=702639981711620&redirect_uri=${WD}/auth/facebook/callback&scope=email,public_profile`;
const loginWithTwitter = `${WD}/auth/twitter`;
const create_bounty = `${D}/create`;
let registerURL: string = `${D}/register`;
let Register_Mail: string = `${D}/send-varification-email`;
let commentCApi = `${D}/media_comments`;
const replyOnMediaComment = `${D}/media_reply`;
const mediaCommentLike = `${D}/likeMediaComment`;
const assetBookmark = `${D}/post/mediaFav`;
const assetLike = `${D}/post/mediaLikes`;
let Forgot_Mail: string = `${D}/email-varify`;
const createAnnouncement = `${D}/create/announcement`;
const likeAnouncement = `${D}/post/announcementLikes`;
const uploadAssets = `${D}/announcement/uploadMedia`;
const createSubmissionAsset = `${D}/submission/uploadMedia`;
const pinAd = `${D}/pin/advertisement/${U}`;
const pinSponsor = `${D}/pin/sponsor/${U}`;
const pinBounty = `${D}/pin/bounti/${U}`;
const pinCharity = `${D}/pin/charity/${U}`;
const addFriend = `${D}/send-friend-request/${U}`;
const addSubmissionComment = `${D}/submission_comments`;
const addSubmissionCommentReply = `${D}/submission_reply`;
const likeSubmission = `${D}/post/submissionLikes`;
const advertisementUpload = `${D}/upload/homePageAd`;
const resendForgot = `${D}/resend-link`;
const emailChange = `${D}/email/change/request`;
const winnerNotify = `${D}/winnerNotification`;
const pointTransfer = `${D}/transferPoints`;
export {
  likeCurrentBounti,
  addFriend,
  pinBounty,
  pinCharity,
  pinSponsor,
  pinAd,
  Forgot_Mail,
  likeAnouncement,
  createSubmissionAsset,
  uploadAssets,
  createAnnouncement,
  assetBookmark,
  assetLike,
  commentCApi,
  create_bounty,
  createCharity,
  bookMarkCurrentBounti,
  mediaUpload,
  createSponsor,
  addStoredData,
  Date_Time_Picker,
  createReward,
  loginRoute,
  loginWithGoogle,
  loginWithFacebook,
  loginWithTwitter,
  registerURL,
  Register_Mail,
  replyOnMediaComment,
  mediaCommentLike,
  addSubmissionComment,
  addSubmissionCommentReply,
  likeSubmission,
  advertisementUpload,
  resendForgot,
  emailChange,
  winnerNotify,
  pointTransfer,
};
