import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Input } from "antd";
import { SwiperSlide, Swiper } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { setSubmissionScreen } from "../../app/slices/singleStates";
import { setSubmissionFiles } from "../../app/slices/bountiSubmissionData";
import { RootState } from "../../app/store";
import { isArray } from "lodash";
const { TextArea } = Input;

const Step2 = () => {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = React.useState<any>();
  const reduxSubmissionData: any = useSelector((state: RootState) => {
    return state.bountiSubmissionData;
  });

  const { currentSubmission } = useSelector(
    (state: any) => state.booleanStatus
  );
  const [files, setFiles] = useState<File[]>([]);
  // console.log("reduxSubmissionData", currentSubmission?.media,files);

  // media swiper init
  const mediaSwiperInit = (e: any) => {
    setSwiper(e);
  };
  // useEffect(() => {
  //   if (currentSubmission) {
  //     setFiles(currentSubmission.media || []);

  //     // Update Redux state with current values
  //     dispatch(setSubmissionFiles(currentSubmission.media || ""));
  //   }
  // }, [currentSubmission]);

  // open file manager
  const openFileManager = (e: any) => {
    const submisionFile = document.getElementById("submisionFile");
    if (submisionFile) {
      submisionFile.click();
    }
  };

  // changeFiles
  // const changeFiles = (e: any) => {
  //   dispatch(setSubmissionFiles(e.target.files));
  //   let files: any = [];
  //   const fileLenght = e.target.files.length;
  //   for (let index = 0; index < fileLenght; index++) {
  //     const element = e.target.files[index];
  //     files.push(element);
  //   }
  //   setFiles(e.target.files);
  //   console.log(files,fileLenght,"filessssss")
  // };
  // changeFiles
  const changeFiles = (e: any) => {
    dispatch(setSubmissionFiles(e.target.files));
    let newFiles: File[] = [];
    const fileLenght = e.target.files.length;
    for (let index = 0; index < fileLenght; index++) {
      const element = e.target.files[index];
      newFiles.push(element);
    }
    setFiles(newFiles); // Set files to the array of files you've created
    console.log(newFiles, fileLenght, "filessssss");
  };

  return (
    <section className="submission__step1">
      {/* file:hidden goes here*/}
      <input
        type="file"
        style={{ width: "0.1px", height: "0.1px", visibility: "hidden" }}
        id="submisionFile"
        onChange={changeFiles}
        multiple={true}
        accept="image/*"
      />
      {/* file:hidden goes here*/}

      <div className="submission__step__inner">
        {/* main Submission frame end here */}
        <div className="main__submissionAsset__Frame" onClick={openFileManager}>
          {reduxSubmissionData?.files?.length > 0 ? (
            <img
              src={URL.createObjectURL(reduxSubmissionData?.files[0])}
              className="uploadIcon__submission"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <img
              src="./assets/upload.svg"
              className="uploadIcon__submission"
              alt=""
            />
          )}
        </div>
        {/* main Submission frame end here */}
        <div
          className="ancmnt__main__media__swiper overRight__submissionSwiper flex"
          style={{ marginTop: "1rem" }}
        >
          <img
            src="./assets/backward.svg"
            alt="prev"
            style={{ cursor: "pointer" }}
            onClick={() => {
              swiper?.slidePrev();
            }}
          />

          {files.length > 1 ? (
            <Swiper
              className="annoucement__swiper"
              slidesPerView={3}
              spaceBetween={15}
              onInit={mediaSwiperInit}
              style={{ paddingLeft: "1rem" }}
            >
              {files.slice(1).map((file: File, index: number) => (
                <SwiperSlide
                  className="annoucement-slide"
                  key={index}
                  onClick={openFileManager}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    className="uploadIcon__submission"
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              className="annoucement__swiper"
              slidesPerView={3}
              spaceBetween={15}
              onInit={mediaSwiperInit}
              style={{ paddingLeft: "1rem" }}
            >
              <SwiperSlide
                className="annoucement-slide"
                onClick={openFileManager}
              >
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "2rem", borderRadius: "1rem" }}
                />
              </SwiperSlide>
              <SwiperSlide
                className="annoucement-slide"
                onClick={openFileManager}
              >
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "2rem", borderRadius: "1rem" }}
                />
              </SwiperSlide>
              <SwiperSlide
                className="annoucement-slide"
                onClick={openFileManager}
              >
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "2rem", borderRadius: "1rem" }}
                />
              </SwiperSlide>
            </Swiper>
          )}
          {/* <Swiper
            className="annoucement__swiper"
            slidesPerView={3}
            spaceBetween={15}
            onInit={mediaSwiperInit}
            style={{ paddingLeft: "1rem" }}
          >
            <SwiperSlide
              className="annoucement-slide"
              onClick={openFileManager}
            >
              {reduxSubmissionData?.files?.length > 1 ? (
                <img
                  src={URL.createObjectURL(reduxSubmissionData?.files[1])}
                  className="uploadIcon__submission"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "2rem", borderRadius: "1rem" }}
                />
              )}
            </SwiperSlide>

            <SwiperSlide
              className="annoucement-slide"
              onClick={openFileManager}
            >
              {reduxSubmissionData?.files?.length > 2 ? (
                <img
                src={URL.createObjectURL(reduxSubmissionData?.files[2])}
                  className="uploadIcon__submission"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "2rem", borderRadius: "1rem" }}
                />
              )}
            </SwiperSlide>

            <SwiperSlide
              className="annoucement-slide"
              onClick={openFileManager}
            >
              {reduxSubmissionData?.files?.length  > 3 ? (
                <img
                  src={URL.createObjectURL(reduxSubmissionData?.files[3])}
                  className="uploadIcon__submission"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "2rem", borderRadius: "1rem" }}
                />
              )}
            </SwiperSlide>
          </Swiper> */}
          <img
            src="./assets/forward.svg"
            alt="prev"
            style={{ cursor: "pointer", marginLeft: "1rem" }}
            onClick={() => {
              swiper?.slideNext();
            }}
          />
        </div>

        {/* submit */}
        <div className="step1_sub_buttonR" style={{ marginTop: "1rem" }}>
          <Button
            className="submit__button_Submisn"
            onClick={() => {
              dispatch(setSubmissionScreen(1));
            }}
          >
            Previous
          </Button>
          <Button
            className="submit__button_Submisn"
            style={{ marginLeft: "0.5rem" }}
            onClick={() => {
              dispatch(setSubmissionScreen(3));
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Step2;
