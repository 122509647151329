import { ChatRoom, FluidContainer } from "../../../components";
import {
  BountiCommentWrapper,
  BountyDetailComponent,
  R_BountyDetailComponent,
} from "./related-components";
import styles from "./bountipreview.module.css";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import Reward from "../../../components/Reward/Reward";
import axios from "axios";
import {
  getSubmissionBounty,
  globalBounti2,
  getMesiboToken,
  profileWithUID,
} from "../../../apis/getApis";
import Header from "../../../apis/header";
import { useLocation } from "react-router-dom";
import MediaPreviewDialog from "./related-components/MediaPreview/MediaPreviewDialog";
import Step2 from "../../../components/SubmissionModule/Step2";
import Step1 from "../../../components/SubmissionModule/Step1";
import Step3 from "../../../components/SubmissionModule/Step3";
import Step4 from "../../../components/SubmissionModule/Step4";
import CollabaratorStep from "../../../components/SubmissionModule/CollabaratorStep";
import {
  toggleSubmissionScreen,
  setcurrentSubmission,
} from "../../../app/slices/singleStates";
import { Alert } from "antd";
import { Popconfirm } from "antd";

const BountyPreview = () => {
  const [isToggleChat, setIsToggleChat] = useState<any>(false);
  const [allSubmission, setAllSubmission] = useState<any>([]);
  const [submissionTimeAgo, setSubmissionTimeAgo] = useState<any>([]);
  const [userName, setUserName] = useState<any>("");
  const [bountyData, setBountyData] = useState<any>();
  const [bountyPhase, setBountyPhase] = useState<any>("");
  const [textData, setTextData] = useState<any>("");
  const [profileImage, setUserProfile] = useState<any>("");
  const [idSubmission, setSubmissionId] = useState<any>("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [hasWinnerDialog, setHasWinnerDialog] = useState(false); // State to track if there's a winner
  const [isCreateMode, setIsCreateMode] = useState(false); // Default mode is 'create'
  const [mesiboToken, setMesiboToken] = useState("");
  const [mesiboGid, setMesiboGid] = useState<string>("");
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  // redux state
  const dispatch = useDispatch();
  const { currentSubmission } = useSelector(
    (state: RootState) => state.booleanStatus
  );
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }
  const ToggleChat = () => {
    isToggleChat ? setIsToggleChat(false) : setIsToggleChat(true);
  };
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  const submissionScreen = useSelector(
    (state: RootState) => state.booleanStatus.submissionScreen
  );
  const isMediaViewer = useSelector(
    (state: RootState) => state.booleanStatus.isMediaViewer
  );
  const isSubmissionScreen = useSelector(
    (state: RootState) => state.booleanStatus.isSubmissionScreen
  );
  const isRewardDialog = useSelector(
    (state: RootState) => state.booleanStatus.isRewardDialog
  );
  const submissionRedux = useSelector((state: RootState) => {
    return state.bountiSubmissionData;
  });

  const location = useLocation();
  const reduxBountyId = location.state;

  // useEffect(() => {
  //   // Check if currentSubmission is null
  //   if (!currentSubmission) {
  //     setIsCreateMode(true);
  //     dispatch(setcurrentSubmission(null)); // Clear any previous submission data
  //     dispatch(toggleSubmissionScreen(true)); // Set submission screen to Step1
  //   } else {
  //     setIsCreateMode(false);
  //     dispatch(toggleSubmissionScreen(true)); // Set submission screen to Step1
  //   }
  // }, [currentSubmission, dispatch]);
  const handleBountyDeleteCancel = () => {
    // Close the alert
    setAlertVisible(false);
  };

  // get Submission Bounty wise API fetch
  useEffect(() => {
    window.alert = () => {
      console.log("-");
    };
    setIsLoadingSubmission(true);
    axios
      .get(`${getSubmissionBounty}/${reduxBountyId}`)
      .then((response: any) => {
        console.log(
          "submissionGlobalData",
          reduxBountyId,
          response.data.submissions
        );
        setIsLoadingSubmission(false);
        setAllSubmission(response.data.submissions);
        setSubmissionId(response.data.submissions.submissionId);
      })
      .catch((err: any) => {
        setIsLoadingSubmission(false);
      });
  }, []);
  // useEffect(() => {
  //   const hasWinner = allSubmission.some(
  //     (submission: any) => submission.isWinner
  //   );
  //   setHasWinnerDialog(hasWinner);
  //   console.log("All Submissions Winner:", allSubmission);
  //   console.log("Has Winner:", hasWinner);
  // }, [allSubmission]);

  useEffect(() => {
    if (submissionRedux.isAlert) {
      // get Submission Bounty wise API fetch
      axios
        .get(`${getSubmissionBounty}/${reduxBountyId}`)
        .then((response: any) => {
          console.log("submissionGlobalData", response.data.submissions);
          setIsLoadingSubmission(false);
          setAllSubmission(response.data.submissions);
        })
        .catch((err: any) => {
          setIsLoadingSubmission(false);
        });
    }
  }, [submissionRedux.isAlert]);

  // Bounty all details fetch
  useEffect(() => {
    axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((res: any) => {
        console.log("THISRESponse", res.data);
        // set mesibo gID for bounty
        if (res.data && res.data.text && res.data.text.mesibo_gid) {
          setMesiboGid(res.data.text.mesibo_gid); // Set mesibo_gid from response
        }
        // get mesibo token API
        axios
          .get(`${getMesiboToken}/${res.data.text.mesibo_gid}`, Header)
          .then((res: any) => {
            console.log("getMesiboToken", res.data);
            setMesiboToken(res.data.mesiboToken);
          })
          .catch((err: any) => {
            console.log(err, "getMesiboToken");
          });
      })
      .catch((err: any) => {});
    console.log(mesiboGid, "mesibo_gidmesibo_gid");
    // mesibo token get
  }, []);

  const fetchData: any = async () => {
    // fetch bounty data
    axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((response: any) => {
        setBountyData(response.data);
        if (response.data && response.data.currentPhase) {
          // If currentPhase is present, set it
          setBountyPhase(response.data.currentPhase);
        } else {
          console.error("Current phase not found in response data.");
        }
        setTextData(response.data.text.userID);

        // Fetch user profile after setting textData
        axios
          .get(`${profileWithUID}/${response.data.text.userID}`, Header)
          .then((userProfileResponse: any) => {
            console.log("userImage1", userProfileResponse.data);
            setUserName(userProfileResponse.data.userName);
            setUserProfile(userProfileResponse.data.userImage);
            // Handle user profile response here
          })
          .catch((userProfileErr: any) => {
            console.error("Error fetching user profile:", userProfileErr);
          });
        console.log("goodboy", response.data);
      })
      .catch((err: any) => {
        console.error("Error fetching bounty data:", err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [textData]);

  return (
    <>
      <FluidContainer breadcrumb={["Home", "Bounti", "Preview"]}>
        <div className="flex justify-between bounti-preview-res bp-container">
          {/* 66% */}
          <div
            className={
              toggleD
                ? `${styles.div_67} div_67_respon `
                : `${styles.div_67} div_67_respon div_67_respon_collapse`
            }
            style={{ width: "74%" }}
          >
            <BountyDetailComponent />
            <R_BountyDetailComponent />
            <BountiCommentWrapper
              allSubmission={allSubmission}
              userName={userName}
              profileImage={profileImage}
              isLoading={isLoadingSubmission}
            />
          </div>

          {/* 30% */}
          {/* Chat functionality Bounty Preview right side section */}
          <div
            className={
              isToggleChat
                ? `${styles.div_30}  div_30_responsive-withFloat`
                : `${styles.div_30} div_30_responsive`
            }
          >
            <ChatRoom mesiboToken={mesiboToken} mesiboGid={mesiboGid} />
          </div>
          {/* {hasWinnerDialog && ( // Render the dialog only if there's a winner
            <div
              className="dialog"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="dialog-content">
                <h2>Congratulations!</h2>
                <p>You have a winner submission.</p>
                <button onClick={() => setHasWinnerDialog(false)}>Close</button>
              </div>
            </div>
          )} */}
          {/* submission Dialog goes here */}
          <div
            className="flex"
            style={
              isSubmissionScreen
                ? {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "var(--dialogOverlay)",
                    zIndex: "99999",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : { display: "none" }
            }
          >
            {/* <button
              className="close_dialog__sub__Creation"
              onClick={() => {
                dispatch(toggleSubmissionScreen(false));
              }}
            >
              X
            </button> */}
            {bountyPhase === "submission" ? (
              <>
                {/* {console.log(textData, loginData?.userId, "textData")} */}
                {textData === loginData?.userId ? (
                  // <div className="submission__no">
                  //   <p
                  //     style={{
                  //       fontSize: "1.3rem",
                  //       fontWeight: "800",
                  //     }}
                  //   >
                  //     "You cannot Submit on your own Bounty."
                  //   </p>
                  // </div>
                  <div className="mediaAsset__delete__popup">
                    <div className="close_BTNmediaAsset__delete__popup">
                      <button
                        onClick={() => {
                          dispatch(toggleSubmissionScreen(false));
                        }}
                      >
                        x
                      </button>
                    </div>
                    <p style={{ marginTop: "0.5remrem" }}>
                      You cannot Submit on your own Bounty.
                    </p>
                  </div>
                ) : (
                  <>
                    {
                      submissionScreen === 1 && (
                        // (isCreateMode || !currentSubmission ? (
                        <Step1 />
                      )
                      // ) : (
                      // <Step1 mode="edit" />
                      // ))
                    }
                    {submissionScreen === 2 && <Step2 />}
                    {submissionScreen === 3 && <CollabaratorStep />}
                    {submissionScreen === 4 && (
                      <Step3 reduxBountyId={reduxBountyId} />
                    )}
                    {
                      submissionScreen === 5 && (
                        // (isCreateMode || !currentSubmission ? (
                        <Step4 reduxBountyId={reduxBountyId} />
                      )
                      // ) : (
                      // <Step4 reduxBountyId={reduxBountyId} mode="edit" />
                      // ))
                    }
                    <button
                      className="close_dialog__sub__Creation"
                      onClick={() => {
                        dispatch(toggleSubmissionScreen(false));
                      }}
                    >
                      X
                    </button>
                  </>
                )}
              </>
            ) : (
              <div className="mediaAsset__delete__popup">
                <div className="close_BTNmediaAsset__delete__popup">
                  <button
                    onClick={() => {
                      dispatch(toggleSubmissionScreen(false));
                    }}
                  >
                    x
                  </button>
                </div>
                <p style={{ marginTop: "0.5remrem" }}>
                  This bounty is not in a phase where it's eligible for
                  submissions.
                </p>
              </div>
            )}
          </div>
          {/* submission Dialog end here */}

          {/* reward dialog box goes here */}
          <div
            className="flex"
            style={
              isRewardDialog
                ? {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "var(--dialogOverlay)",
                    zIndex: "99999",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : { display: "none" }
            }
          >
            <Reward isEditable={false} />
          </div>
          {/* reward dialog box end here */}

          {/* media viewer goes here */}
          <div
            className="flex"
            style={
              isMediaViewer
                ? {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "var(--dialogOverlay)",
                    zIndex: "99999",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : { display: "none" }
            }
          >
            <MediaPreviewDialog reduxBountyId={reduxBountyId} />
          </div>
          {/* media viewer goes here */}

          {/* alerts */}
        </div>
      </FluidContainer>
      {isToggleChat ? (
        <CloseIcon onClick={ToggleChat} className="chatroom_float_button" />
      ) : (
        <ChatIcon onClick={ToggleChat} className="chatroom_float_button" />
      )}
    </>
  );
};

export default BountyPreview;
