import React from "react";
import './pointTable.css';

const PointTable = () => {
  return (
    <>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>User Email</th>
              <th>Bounty Title</th>
              <th>Xp Points</th>
              <th>Rp Points</th>
              <th>Rl Points</th>
              <th>Activity</th>
              <th>Date and Time</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>tarunrajputohit12@gmail.com</td>
              <td>Minga.com</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>like, favorite</td>
              <td>05/02/2024</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PointTable;
