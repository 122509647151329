const userData: any = localStorage.getItem("loginData");

// get user access_token from localstorage
const Header: any = {
  headers: {
    authorization: userData
      ? `Bearer ${JSON.parse(userData).access_token}`
      : ``,
  },
};


export default Header;
