import { useDispatch, useSelector } from "react-redux";
import "./loginDialog.css";
import { Button, Spin } from "antd";
import { RootState } from "../../app/store";
import BasicInfo from "../../components/LoginComponent/BasicInfo";
import { ChangeEvent, useState } from "react";
import axios from "axios";
import {
  Register_Mail,
  loginRoute,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
  resendForgot,
} from "../../apis/postApis";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "antd";
import "./registerconfirm.css";
import {
  toggleConfirmRegisterCreation,
  toggleForgotConfirmDialog,
} from "../../app/slices/singleStates";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ForgotConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<any>();
  const currentLanguage = useSelector(
    (state: any) => state.booleanStatus.language
  );
  const registerAccessToken = useSelector(
    (state: any) => state.booleanStatus.registerAccessToken
  );
  const forgotEmail = useSelector(
    (state: RootState) => state.booleanStatus.forgotEmail
  );
  console.log(forgotEmail, "forgotEmail");
  const [loading, setLoading] = useState<any>(false);
  const [isAlert, setIsAlert] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [isError, setIsError] = useState<any>(false);
  console.log("registerConfirm", swiper);

  // resend verification mail
  async function resendEmail() {
    try {
      const response = await axios.post(`${resendForgot}`, {
        email: forgotEmail,
      });
      console.log(response, "responseresponseresponse");
      // Assuming you have a success message in the response, you can handle it accordingly
      setMessage(response.data.message);
      setIsAlert(true);
      setTimeout(() => {
        setIsAlert(false);
      }, 2000);
    } catch (error) {
      // Handle error
      console.error("Error resending email:", error);
      setMessage("Error resending email");
      setIsAlert(true);
      setIsError(true);
      setTimeout(() => {
        setIsAlert(false);
        setIsError(false);
      }, 2000);
    }
  }

  return (
    <>
      <div className="confirm__dialog__main__wrapper">
        {/* left side content */}
        <div className="confirm__dialog__left__side">
          {/* heading goes here*/}
          <h1
            className="confirm_heading"
            // style={{ position: "relative" }}
          >
            {/* You're almost in! Just */}
            {/* <br /> one more step. */}
            Reset Password !
            <Alert
              style={isAlert ? { position: "absolute" } : { display: "none" }}
              message={message}
              type={!isError ? "success" : "error"}
              showIcon
            />
          </h1>
          {/* heading end here*/}

          {/* main goes here */}
          <main className="confirm__component__main">
            {/* login with social media */}
            <div className="confirm_text">
              An email for Reset Password has been sent to your address.
            </div>

            <div className="detail__text">
              Head on over there to confirm the link and reset your password.
            </div>
          </main>

          <div
            className="click__here resend_auth_varification"
            style={{ fontSize: "0.9rem" }}
          >
            Need us to resend the confirmation email?{" "}
            <span
              onClick={resendEmail}
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
            >
              Resend
            </span>
          </div>

          <div className="check__bounties__text">
            Once you're in, check out these bounties:
          </div>

          {/* Slider */}

          <Swiper
            className="register_confirmation_swiper"
            style={{ width: "100%" }}
            onInit={(e: any) => {
              setSwiper(e);
            }}
          >
            {/* slide */}
            <SwiperSlide
              className="register_confirmation_slide"
              style={{ cursor: "pointer", display: "flex !important" }}
            >
              <img
                className="arrows arrows__prev"
                src="../tests/icons/prev.svg"
                onClick={() => {
                  swiper?.slidePrev();
                }}
              />
              <div className="slider__box ">
                <div className="slider__img">
                  <img src="./tests/slide.png" />
                </div>

                <div className="slider__text">
                  <p className="slider__text__1">Project Series Name</p>
                  <p className="slider__text__2">Artwork #design</p>
                  <p className="slider__text__3">Submission Phase</p>
                  <p className="slider__text__4">02D 1H 10M</p>
                </div>
              </div>
              <img
                className="arrows arrows__next"
                src="../tests/icons/next.svg"
                onClick={() => {
                  swiper?.slideNext();
                }}
              />
            </SwiperSlide>
            {/* slide */}
            <SwiperSlide
              className="register_confirmation_slide"
              style={{ cursor: "pointer", display: "flex !important" }}
            >
              <img
                className="arrows arrows__prev"
                src="../tests/icons/prev.svg"
              />
              <div className="slider__box ">
                <div className="slider__img">
                  <img src="./tests/slide.png" />
                </div>

                <div className="slider__text">
                  <p className="slider__text__1">Project Series Name</p>
                  <p className="slider__text__2">Artwork #design</p>
                  <p className="slider__text__3">Submission Phase</p>Once you're
                  in, check out these bounties:
                  <p className="slider__text__4">02D 1H 10M</p>
                </div>
              </div>
              <img
                className="arrows arrows__next"
                src="../tests/icons/next.svg"
              />
            </SwiperSlide>
            {/* slide */}
            <SwiperSlide
              className="register_confirmation_slide"
              style={{ cursor: "pointer", display: "flex !important" }}
            >
              <div className="slider__box ">
                <div className="slider__img">
                  <img src="./tests/slide.png" />
                </div>

                <div className="slider__text">
                  <p className="slider__text__1">Project Series Name</p>
                  <p className="slider__text__2">Artwork #design</p>
                  <p className="slider__text__3">Submission Phase</p>
                  <p className="slider__text__4">02D 1H 10M</p>
                </div>
              </div>
              <img
                className="arrows arrows__next"
                src="../tests/icons/next.svg"
                onClick={() => {
                  swiper?.slideNext();
                }}
              />
            </SwiperSlide>
            {/* slide */}
            <SwiperSlide
              className="register_confirmation_slide"
              style={{ cursor: "pointer", display: "flex !important" }}
            >
              <img
                className="arrows arrows__prev"
                src="../tests/icons/prev.svg"
              />
              <div className="slider__box ">
                <div className="slider__img">
                  <img src="./tests/slide.png" />
                </div>

                <div className="slider__text">
                  <p className="slider__text__1">Project Series Name</p>
                  <p className="slider__text__2">Artwork #design</p>
                  <p className="slider__text__3">Submission Phase</p>
                  <p className="slider__text__4">02D 1H 10M</p>
                </div>
              </div>
              <img
                className="arrows arrows__next"
                src="../tests/icons/next.svg"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div></div>

        {/* right side image */}
        <div className="confirm__dialog__right__side">
          <img src="./tests/confirm.png" />
        </div>
        <button
          className="confirm__dialog__close"
          onClick={() => {
            dispatch(toggleForgotConfirmDialog(false));
          }}
        >
          X
        </button>
      </div>
    </>
  );
};

export default ForgotConfirm;
