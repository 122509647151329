import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import styles from "./given.module.css";
import { InteractionCard } from "../../../../components";

const AvtarGroups = ({ bountyId, globalInteractionData }: any) => {
  // styles for avtars goes here
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  // Get the last two user images from globalInteractionData
  const lastTwoUserImages = globalInteractionData
    .slice(-2) // Get the last two elements
    .map((data: any) => data.userImage); // Extract the userImage
  console.log(lastTwoUserImages, "lastTwoUserImages");

  const othersCount = Math.max(0, globalInteractionData.length - 2); // Calculate count of others
  return (
    <>
      {!globalInteractionData || globalInteractionData.length === 0 ? (
        <p>Take a lead !! Be the first to Interact with this Bounty.</p>
      ) : (
        <Stack direction="row" spacing={2}>
          <div className={`${styles.avtar_grp_c1}`}>
            {lastTwoUserImages[1] && (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="Remy Sharp" src={lastTwoUserImages[1]} />
              </StyledBadge>
            )}
            {lastTwoUserImages[0] && (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Avatar alt="Travis Howard" src={lastTwoUserImages[0]} />
              </Badge>
            )}
            <p
              className={`${styles.numberOfEngage_C1} numberOfEngage_C1_res bountipreview__likesAndList`}
            >
              and{" "}
              <b className="color-headingBlue cursor-pointor">
                {othersCount} other{othersCount > 1 ? "s" : ""}
              </b>{" "}
              have engaged
              <InteractionCard bountyId={bountyId} />
            </p>
          </div>
        </Stack>
      )}
    </>
  );
};

export default AvtarGroups;
