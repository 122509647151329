import Input from "antd/es/input/Input";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin } from "antd";
import { RootState } from "../../app/store";
import BasicInfo from "../../components/LoginComponent/BasicInfo";
import { ChangeEvent, useState } from "react";
import axios from "axios";
import {
  loginRoute,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
  registerURL,
} from "../../apis/postApis";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "antd";
import "./registerDialog.css";
import {
  toggleRegisterCaptcha,
  toggleRegisterDialogCreation,
  toggleLoginDialogCreation,
  addRegisterToken,
} from "../../app/slices/singleStates";
import FloatLabelRegister from "./FloatLabelRegister";
import { WD } from "../../apis/main";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const RegisterDialog = () => {
  const [formData, setFormData] = useState<any>({
    email: "",
    password: "",
    repeat_password: "",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<any>(false);
  const [isAlert, setIsAlert] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [isError, setIsError] = useState<any>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowconfirmPassword(!showconfirmPassword);
  };
  // const navigate = useNavigate();

  //   register function
  // const registerNow = async () => {
  //   setLoading(true);
  //   await axios
  //     .post(registerURL, formData)
  //     .then((res) => {
  //       console.log("registerData", res);
  //       setLoading(false);
  //       setIsError(false);
  //       dispatch(toggleRegisterDialogCreation(false));
  //       dispatch(toggleRegisterCaptcha(true));
  //       dispatch(addRegisterToken(res.data.access_token));
  //       // navigate("/new");
  //       // navigate(-1);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setIsError(true);
  //       setMessage(err.response.data.message);
  //       setIsAlert(true);
  //       setTimeout(() => {
  //         setIsAlert(false);
  //       }, 2500);
  //     });
  // };

  const registerNow = async () => {
    setLoading(true);
    if (!formData.password.trim()) {
      setLoading(false);
      setIsError(true);
      setMessage("Please fill in the Password field.");
      setIsAlert(true);
      return;
    }
    if (!formData.repeat_password.trim()) {
      setLoading(false);
      setIsError(true);
      setMessage("Please fill in the Confirm Password field.");
      setIsAlert(true);
      return;
    }
    if (formData.password !== formData.repeat_password) {
      setLoading(false);
      setIsError(true);
      setMessage("Password and Confirm Password do not match.");
      setIsAlert(true);
      return;
    }

    await axios
      .post(registerURL, formData)
      .then((res) => {
        setLoading(false);
        setIsError(false);
        dispatch(toggleRegisterDialogCreation(false));
        dispatch(toggleRegisterCaptcha(true));
        dispatch(addRegisterToken(res.data.access_token));
      })
      .catch((err) => {
        setLoading(false);
        setIsError(true);
        setMessage(err.response.data.message);
        setIsAlert(true);
        setTimeout(() => {
          setIsAlert(false);
        }, 2500);
      });
  };

  // when user change in input field
  function registerFieldChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "email") {
      let value: any = e.target.value.toLowerCase();
      setFormData({ ...formData, email: value });
    } else if (e.target.name === "password") {
      setFormData({ ...formData, password: e.target.value });
    } else if (e.target.name === "repeat_password") {
      setFormData({ ...formData, repeat_password: e.target.value });
    }
  }

  //   onEnter then register
  const inputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      registerNow();
    }
  };

  return (
    <>
      <div className="register__dialog__main__wrapper">
        {/* left side content */}
        <div className="register__dialog__left__side">
          {/* heading goes here*/}
          <h1
            className="register_heading"
            // style={{ position: "relative" }}
          >
            Where every
            <br />
            interactions counts.
            <Alert
              style={
                isAlert
                  ? { position: "absolute", fontSize: "1.2rem" }
                  : { display: "none" }
              }
              message={message}
              type={!isError ? "success" : "error"}
              showIcon
            />
          </h1>
          {/* heading end here*/}

          {/* main goes here */}
          <main className="register__component__main">
            {/* login with social media */}
            <div className="register__with__social">
              {/* google */}
              <div className="login__social_section first_login_social">
                <Link
                  to={loginWithGoogle}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src="./tests/icons/google-g-icon-9.svg" alt="google" />
                </Link>
              </div>
              {/* twitter */}
              <div className="login__social_section">
                <Link
                  to={loginWithTwitter}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src="./tests/icons/x.svg" alt="twitter" />
                </Link>
              </div>
              {/* facebook */}
              {/* facebook */}
              <div className="login__social_section">
                <Link
                  to={loginWithFacebook}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src="./tests/icons/fb.svg" alt="facebook" />
                </Link>
              </div>
              {/* linkedin */}
              <div className="login__social_section">
                <img src="./tests/icons/ln.svg" alt="linkedin" />
              </div>
            </div>

            {/* regiter from goes here */}
            <section className="register__main__form">
              <div className="register__or">or...</div>

              {/* username or email goes here */}
              <div className="register__INPUT__dialog">
                <FloatLabelRegister
                  label="Email"
                  id="email"
                  type="text"
                  name="email"
                  onChange={registerFieldChange}
                  onKeyDown={inputKeyDown}
                />
              </div>
              {/* username or email end here */}

              {/* password goes here */}
              <div className="register__INPUT__dialog">
                <FloatLabelRegister
                  label="Password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={registerFieldChange}
                  onKeyDown={inputKeyDown}
                />
                {showPassword ? (
                  <EyeInvisibleOutlined
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <EyeOutlined
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              {/*password end here */}

              {/* repeat password goes here */}
              <div className="register__INPUT__dialog">
                <FloatLabelRegister
                  label="Confirm Password"
                  id="repeat_password"
                  type={showconfirmPassword ? "text" : "password"}
                  name="repeat_password"
                  onChange={registerFieldChange}
                  onKeyDown={inputKeyDown}
                />
                {showconfirmPassword ? (
                  <EyeInvisibleOutlined
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <EyeOutlined
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
              {/* repeat password goes end */}

              {/* Checkbox for confirming that the user is not a robot */}
              {/* <div className="register__checkbox__dialog">
                <input type="checkbox" id="isPersonCheckbox" />
                <label htmlFor="isPersonCheckbox">
                  I'm a person, not a robot
                </label>
              </div> */}

              {/* register button goes here */}
              <Button
                className="register_btn__dialog"
                disabled={loading}
                onClick={registerNow}
              >
                {loading ? (
                  <Spin
                    className="orange-bg-spinner"
                    style={{ color: "var(--pure)" }}
                  />
                ) : (
                  "Sign Up"
                )}
              </Button>
              {/* register button end here */}
            </section>
            {/* register from end here */}
          </main>
          {/* main end here */}
          {/* <BasicInfo /> */}
          <div className="account__have">
            Already a member? just{" "}
            <span
              onClick={() => {
                dispatch(toggleRegisterDialogCreation(false));
                dispatch(toggleLoginDialogCreation(true));
              }}
            >
              Login
            </span>
          </div>
        </div>

        {/* right side image */}
        <div className="register__dialog__right__side">
          <img src="./tests/logindialog.png" />
        </div>
        <button
          className="register__dialog__close"
          onClick={() => {
            dispatch(toggleRegisterDialogCreation(false));
          }}
        >
          X
        </button>
      </div>
    </>
  );
};

export default RegisterDialog;
