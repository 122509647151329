import Input from "antd/es/input/Input";
import { useDispatch, useSelector } from "react-redux";
import "./loginDialog.css";
import { Button, Spin } from "antd";
import { RootState } from "../../app/store";
import BasicInfo from "../../components/LoginComponent/BasicInfo";
import { ChangeEvent, useState } from "react";
import axios from "axios";
import {
  loginRoute,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
} from "../../apis/postApis";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "antd";
import "./loginDialog.css";
import {
  toggleFormateEmailDialog,
  toggleLoginDialogCreation,
  toggleRegisterDialogCreation,
} from "../../app/slices/singleStates";
import FloatLabel from "./FloatLable";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const LoginDialog = () => {
  const [loading, setLoading] = useState<any>(false);
  const [isAlert, setIsAlert] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [isError, setIsError] = useState<any>(false);
  const [formData, setFormData] = useState<any>({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isLoginDialog = useSelector(
    (state: RootState) => state.booleanStatus.isLoginDialog
  );
  const dispatch = useDispatch();

  const login = async (e: any) => {
    setLoading(true);
    await axios
      .post(loginRoute, formData)
      .then((response: any) => {
        setLoading(false);
        setIsError(false);
        if (response.data) {
          localStorage.setItem("loginData", JSON.stringify(response.data));
          setMessage(response.data.message);
          setIsAlert(true);
          setTimeout(() => {
            setIsAlert(false);
          }, 2500);
          navigate("/home");
          window.location.reload();
        }
      })
      .catch((error: any) => {
        setLoading(false);
        setIsError(true);
        setIsAlert(true);
        setTimeout(() => {
          setIsAlert(false);
        }, 2500);
        if (error.response && error.response.data.message) {
          setMessage(error.response.data.message);
          return;
        }
        setMessage("Check your internet");
      });
  };

  const forgotPassword = (e: any) => {
    dispatch(toggleLoginDialogCreation(false));
    dispatch(toggleFormateEmailDialog(true));
  };

  // when user change in input field
  function loginFieldChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "email") {
      let value: any = e.target.value.toLowerCase();
      setFormData({ ...formData, email: value });
    } else if (e.target.name === "password") {
      setFormData({ ...formData, password: e.target.value });
    }
  }

  // onEnter submit
  const inputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      login(event);
    }
  };
  return (
    <>
      <div className="login__dialog__main__wrapper">
        {/* left side content */}
        <div className="login__dialog__left__side">
          {/* heading goes here*/}
          <h1
            className="login_heading"
            // style={{ position: "relative" }}
          >
            Linking Lives,
            <br />
            Enriching Experiences
            <Alert
              style={
                isAlert
                  ? { position: "absolute", fontSize: "1.2rem" }
                  : { display: "none" }
              }
              message={message}
              type={!isError ? "success" : "error"}
              showIcon
            />
          </h1>
          {/* heading end here*/}

          {/* main goes here */}
          <main className="login__component__main">
            {/* login with social media */}
            <div className="login__with__social__dialog">
              {/* google */}
              <div className="login__social_section first_login_social">
                <Link
                  to={loginWithGoogle}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src="./tests/icons/google-g-icon-9.svg" alt="google" />
                </Link>
              </div>
              {/* twitter */}
              <div className="login__social_section">
                <Link
                  to={loginWithTwitter}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src="./tests/icons/x.svg" alt="twitter" />
                </Link>
              </div>
              {/* facebook */}
              <div className="login__social_section">
                <Link
                  to={loginWithFacebook}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src="./tests/icons/fb.svg" alt="facebook" />
                </Link>
              </div>
              {/* linkedin */}
              <div className="login__social_section">
                <img src="./tests/icons/ln.svg" alt="linkedin" />
              </div>
            </div>

            {/* login from goes here */}
            <section className="login__main__form">
              <div className="login__or">or...</div>
              {/* username or email goes here */}
              <div className="login__INPUT__dialog">
                {/* <Input
                                    type="text"
                                    id="useremail"
                                    name="email"
                                    onChange={loginFieldChange}
                                    onKeyDown={inputKeyDown}
                                    className="login__INPUT__input__dialog"
                                    placeholder="Email"
                                /> */}
                <FloatLabel
                  label="Email"
                  id="useremail"
                  type="text"
                  name="email"
                  onChange={loginFieldChange}
                  onKeyDown={inputKeyDown}
                />
              </div>
              {/* username or email end here */}

              {/* password goes here */}
              <div className="login__INPUT__dialog">
                <label htmlFor="password">Password</label>
                {/* <Input
                                    id="password"d
                                    type="password"
                                    name="password"
                                    className="login__INPUT__input__dialog"
                                    onChange={loginFieldChange}
                                    onKeyDown={inputKeyDown}
                                /> */}
                <FloatLabel
                  label="Password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={loginFieldChange}
                  onKeyDown={inputKeyDown}
                />
                {showPassword ? (
                  <EyeInvisibleOutlined
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <EyeOutlined
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              {/* password goes end */}

              {/* login button goes here */}
              <Button
                className="login_btn__dialog"
                disabled={loading}
                onClick={login}
              >
                {loading ? (
                  <Spin
                    className="orange-bg-spinner"
                    style={{ color: "var(--pure)" }}
                  />
                ) : (
                  "Login"
                )}
              </Button>
              {/* login button end here */}
            </section>
            {/* login from end here */}
          </main>
          {/* main end here */}
          {/* <BasicInfo /> */}
          <div className="account__or__not">
            Not a member?{" "}
            <span
              onClick={() => {
                dispatch(toggleLoginDialogCreation(false));
                dispatch(toggleRegisterDialogCreation(true));
              }}
            >
              Sign Up
            </span>{" "}
            today! <br />
            <span
              style={{ display: "inline-block", marginTop: "1rem" }}
              onClick={forgotPassword}
            >
              Forgot Password ?
            </span>
          </div>
        </div>

        {/* right side image */}
        <div className="login__dialog__right__side">
          <img src="./tests/logindialog.png" />
        </div>
        <button
          className="login__dialog__close"
          onClick={() => {
            dispatch(toggleLoginDialogCreation(false));
          }}
        >
          X
        </button>
      </div>
    </>
  );
};

export default LoginDialog;
