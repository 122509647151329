import { useState } from "react";
import { FluidContainer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import "./auth.css";
import LoginDialog from "./LoginDialog";
import CapthaDialog from "./CapthaDialog";
import RegisterConfirm from "./RegisterConfirm";
import RegisterDialog from "./RegisterDialog";
import {
  toggleRegisterDialogCreation,
  toggleLoginDialogCreation,
} from "../../app/slices/singleStates";
import { useLocation } from "react-router-dom";

const CaptchaOnPage = () => {
  const [isCap, setIsCap] = useState<any>(true)
  const [currentTab, setCurrentTab] = useState<number>(0);
  const location = useLocation();

  const isLoginDialog = useSelector(
    (state: RootState) => state.booleanStatus.isLoginDialog
  );
  const isCaptcha: any = useSelector((state: RootState) => {
    return state.booleanStatus.isCaptcha;
  });

  const isRegisterDialog = useSelector(
    (state: RootState) => state.booleanStatus.isRegisterDialog
  );

  const isConfirmRegister = useSelector(
    (state: RootState) => state.booleanStatus.isConfirmRegister
  );

  const dispatch = useDispatch();

  return (
    <>
        {/* FluidContainer for UI layout */}
      <FluidContainer
        clearUi={true}
        parentStyles={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="login__inner_container" style={{ width: "97%" }}>
          {/* topbar goes here */}
          <header className="login___header">
            <div className="login___header__left login___left">
              <img src="./tests/Minga Logo.svg" className="login___logo" />
            </div>
            <div className="login___header__right login___right">
              <div className="login__header_tab">
                        {/* Tab buttons */}
                <button
                  onClick={() => {
                    setCurrentTab(0);
                  }}
                  className={
                    currentTab === 0
                      ? "auth__tab auth__act auth__tab__left"
                      : "auth__tab auth__tab__left"
                  }
                >
                  Discover
                </button>
                <button
                  onClick={() => {
                    setCurrentTab(1);
                  }}
                  className={
                    currentTab === 1 ? "auth__tab auth__act " : "auth__tab "
                  }
                >
                  Top Rewards
                </button>
                <button
                  onClick={() => {
                    setCurrentTab(2);
                  }}
                  className={
                    currentTab === 2 ? "auth__tab auth__act " : "auth__tab "
                  }
                >
                  Communities
                </button>
                <button
                  onClick={() => {
                    setCurrentTab(3);
                  }}
                  className={
                    currentTab === 3 ? "auth__tab auth__act auth__tab__right " : "auth__tab auth__tab__right "
                  }
                >
                  Charities
                </button>

                {/* login,singup button */}
                <button
                  className={"auth__login"}
                  onClick={() => {
                    dispatch(toggleLoginDialogCreation(true));
                  }}
                >
                  Login
                </button>
                <button
                  className={"auth__signup"}
                  onClick={() => {
                    dispatch(toggleRegisterDialogCreation(true));
                  }}
                >
                  Sign Up
                </button>
              </div>
              {/* <div className="login__header_sponserOfMonth">
                <span className="login__header_sponserOfMonth__label">
                  Sponser Of the Month
                </span>
                <div className="login__header_sponserOfMonth__image">
                  <img src="./tests/deadpool.webp" alt="sponser image here" />
                </div>
              </div> */}
            </div>
          </header>
          {/* topabar end here */}

          {/* left goes here */}
          {/* <LoginComponent /> */}
          {/* left end here */}

          {/* right goes here */}
          {/* right end here */}
        </div>
      </FluidContainer>

      {/* Login Dialog */}
      {/* <div
        className="flex"
        style={
          true
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        <LoginDialog />
      </div> */}

      {/* captcha dialog goes here*/}
      <div
        className="flex"
        style={
          isCap
            ? {
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "var(--dialogOverlay)",
              zIndex: "99999",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }
            : { display: "none" }
        }
      >
        <CapthaDialog setIsCap={setIsCap} />
      </div>
      {/* captcha dialog end here*/}

      {/* Confirm Registartion */}
      <div
        className="flex"
        style={
          isConfirmRegister
            ? {
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "var(--dialogOverlay)",
              zIndex: "99999",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }
            : { display: "none" }
        }
      >
        <RegisterConfirm />
      </div>

    </>
  );
};

export default CaptchaOnPage;
