import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Register_Link_Verify } from "../../apis/getApis";
import { useSelector } from "react-redux";

const RegisterVarification = () => {
  const params = useParams();
  const nav = useNavigate();
  const registerAccessToken = useSelector(
    (state: any) => state.booleanStatus.registerAccessToken
  );

  //  link expire or not state
  const [isInValid, setIsInValid] = useState<boolean>(false);

  //  when page will load first time then given api will be call
  useEffect(() => {
    axios
      .get(`${Register_Link_Verify}/${params.id}/${params.token}`, {
        headers: {
          Authorization: `Bearer ${registerAccessToken}`,
        },
      })
      .then((res) => {
        if (res.data) {
          nav("/guest-area");
        }
      })
      .catch((err) => {
        setIsInValid(true);
      });
  }, []);

  if (isInValid) {
    return <div>Verifying Link Expired.</div>
  }

  return <div>Verifying Link...</div>;
};

export default RegisterVarification;
