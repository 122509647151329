import React, { useState } from "react";

const Pin_Module = ({ no, label, children }: any) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <React.Fragment>
      <div className="pin__module flex justify-between items-center">
        <div className="pin__module__left flex items-center">
          {/* Pin Section label set */}
          <div className="pin__module_no">{no}</div>
          <div className="pin__module_label">{label}</div>
        </div>
        {/*after label toggle arrow icon set  */}
        <div className="pin__modul__right" onClick={toggleExpanded}>
          <img
            src={
              isExpanded
                ? "./tests/icons/arrowDW.svg"
                : "./tests/icons/arrowDB.svg"
            }
            style={{ cursor: "pointer", width: "1rem" }}
            alt=""
          />
        </div>
      </div>
      {isExpanded && children}
    </React.Fragment>
  );
};

export default Pin_Module;
