import axios from "axios";
import "./style.css";
import { D, U } from "../../../../apis/main";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Popconfirm, Progress } from "antd";
import { getUserProfile, profileWithUID } from "../../../../apis/getApis";
import Header from "../../../../apis/header";
import { globalBounti2 } from "../../../../apis/getApis";
import { setAnnonceData } from "../../../../app/slices/singleStates";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AnnouncementView from "../../../../components/AnnoucementPop/AnnoucementView";
const AnnouncementCard = ({
  data,
  setMessage,
  setIsError,
  setIsAlert,
  bountyId,
  setAnnouncementList,
  setToggle,
  setCurrentSelectAnnouncementId,
  status,
  setTextArea,
}: any) => {
  const dispatch = useDispatch();
  console.log(status, "status");
  const [loading, setLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<any>("");
  const [bountyData, setBountyData] = useState<any>();
  const [textData, setTextData] = useState<any>();
  const [userImage, setUserImage] = useState<any>("");
  const [profileImage, setUserProfile] = useState<any>("");
  const [showAnnouncementView, setShowAnnouncementView] = useState(false);
  const location = useLocation();
  const reduxBountyId = location.state;
  console.log(data, "dataaaaa");

  const confirm = (e: React.MouseEvent<HTMLElement>, id: any) => {
    deleteCurrentAnnouncement(id);
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
  };
  const fetchData: any = async () => {
    // fetch bounty data
    axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((response: any) => {
        console.log("goodboy", response.data);
        setBountyData(response.data);
        setTextData(response.data.text.userID);

        // Fetch user profile after setting textData
        axios
          .get(`${profileWithUID}/${response.data.text.userID}`, Header)
          .then((userProfileResponse: any) => {
            // console.log("userImage1", userProfileResponse.data.userName);
            setUserName(userProfileResponse.data.userName);
            setUserProfile(userProfileResponse.data.userImage);
            // Handle user profile response here
          })
          .catch((userProfileErr: any) => {
            console.error("Error fetching user profile:", userProfileErr);
          });
      })
      .catch((err: any) => {
        console.error("Error fetching bounty data:", err);
      });
  };
  const fetchProfileData = async () => {
    await axios
      .get(getUserProfile, Header)
      .then((res: any) => {
        console.log("PROFILEDATA", res.data);
        setUserImage(res.data.userImage);
      })
      .catch((err: any) => {
        console.log("PROFILEDATA", err);
      });
  };

  // Function to handle click on the View icon
  const handleViewClick = () => {
    setShowAnnouncementView(true);
    // setCurrentSelectAnnouncementId(data?._id);
    dispatch(setAnnonceData(data));
  };
  useEffect(() => {
    fetchData();
    fetchProfileData();
    console.log("profileImage", profileImage);
  }, []);

  const deleteCurrentAnnouncement = async (id: any) => {
    setLoading(true);
    setIsAlert(false);
    await axios
      ?.delete(`${D}/delete/announcement/${id}/${U}`)
      .then(async (res) => {
        await axios
          .get(`${D}/get/announcemetList/${U}/${bountyId}`)
          .then((response: any) => {
            console.log("fetchAnnouncement", response);
            setAnnouncementList(response.data);
          })
          .catch((error: any) => {});
        setIsError(false);
        setLoading(false);
        console.log("isDeleteAnnouncement", res);
        setMessage(res.data.message);
        setIsAlert(true);
        setTimeout(() => {
          setIsAlert(false);
        }, 2000);
        // setAnnouncementList((prevLists:any) =>
        //   prevLists.filter((bounty:any) => bounty.id !== bountyId)
        // );
      })
      .catch((error: any) => {
        setLoading(false);
        setMessage("something went wrong");
        setIsAlert(true);
        setIsError(true);
        setTimeout(() => {
          setIsAlert(false);
        }, 2000);
      });
  };

  return (
    <>
      {/* annoucement View Popup */}
      <div
        className="flex minga__popup"
        style={
          showAnnouncementView
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        <AnnouncementView
          bountyId={bountyId} // Pass bountyId if needed
          setToggle={setShowAnnouncementView}
        />
      </div>

      <div className="annoucement__card__main" style={{ marginTop: "1rem" }}>
        {/* row1 goes here */}
        <div className="annoucement__card__rowOne flex justify-between items-center">
          {/* row1 left goes here*/}
          <div className="annoucement__card__rowOneLeft flex items-center">
            {status === "VIEW" ? (
              <img
                src={data?.userImage}
                alt=""
                style={{ width: "2rem", borderRadius: "0.5rem" }}
              />
            ) : (
              <img
                src={`${userImage}`}
                alt=""
                style={{ width: "2rem", borderRadius: "0.5rem" }}
              />
            )}
            <div className="submitter__meta" style={{ marginLeft: "1rem" }}>
              <b style={{ color: "var(--orange)" }}>
                {status === "VIEW" ? data?.userName : "you"}
              </b>
              &nbsp; posted ...
            </div>
          </div>
          {/* row1 left goes here*/}

          {/* row1 right goes here*/}
          <div className="annoucement__card__rowOneRight">
            <div>
              <b style={{ color: "var(--orange)" }}>{data?.timeAgo}</b>
            </div>
          </div>
          {/* row1 right goes here*/}
        </div>
        {/* row1 end here */}

        {/* row1 goes here */}
        <div className="annoucement__card__rowOne annoucement__card__mainBody flex justify-between items-center">
          <div className="annoucement__card__mainLeftCK">
            {data?.description}
          </div>
          <div className="annoucement__card__mainRightCK">
            {status === "VIEW" ? (
              <></>
            ) : (
              <img
                src="./assets/edit2.svg"
                style={{ marginRight: "1rem", cursor: "pointer" }}
                alt=""
                onClick={() => {
                  setToggle(true);
                  setCurrentSelectAnnouncementId(data?._id);
                  dispatch(setAnnonceData(data));
                }}
              />
            )}
            {/* {status === "VIEW" ? (
              <></>
            ) : (
              <img
                src="./tests/icons/attachment.svg"
                style={{
                  width: "1.3rem",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
                alt=""
              />
            )} */}
            {status === "VIEW" ? (
              <>
                <IconButton
                  aria-label="eye"
                  component="span"
                  onClick={handleViewClick}
                >
                  <VisibilityIcon style={{ color: "#e98326" }} />
                </IconButton>
              </>
            ) : (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={(e: any) => {
                  confirm(e, data?._id);
                }}
                onCancel={(e: any) => {
                  cancel(e);
                }}
                okText="Yes"
                cancelText="No"
              >
                <button
                  disabled={loading}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "none",
                  }}
                >
                  <img
                    src="./tests/icons/remove.svg"
                    alt=""
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   deleteCurrentAnnouncement(data?._id);
                    // }}
                  />
                </button>
              </Popconfirm>
            )}
          </div>
        </div>
        {/* row1 end here */}
      </div>
    </>
  );
};

export default AnnouncementCard;
