import React from "react";

const MingaeverythingIcon = () => {
  return (
    <svg
      style={{ marginRight: "1em" }}
      id="Group_152"
      data-name="Group 152"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="11.324"
      height="14.786"
      viewBox="0 0 11.324 14.786"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_92"
            data-name="Rectangle 92"
            width="11.324"
            height="14.786"
            fill="#f1f1f1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_151"
        data-name="Group 151"
        transform="translate(0 0)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_110"
          data-name="Path 110"
          d="M8.022.154A.606.606,0,0,0,7.65,0H.217A.218.218,0,0,0,0,.217v14.35a.218.218,0,0,0,.217.219H11.105a.218.218,0,0,0,.217-.219V3.664a.606.606,0,0,0-.154-.371ZM4.007,13.1a.219.219,0,0,1-.219.217h-2.2a.218.218,0,0,1-.217-.217V12.085a.218.218,0,0,1,.217-.219h2.2a.22.22,0,0,1,.219.219Zm0-1.612a.219.219,0,0,1-.219.217h-2.2a.218.218,0,0,1-.217-.217V10.474a.218.218,0,0,1,.217-.219h2.2a.22.22,0,0,1,.219.219Zm0-1.612a.219.219,0,0,1-.219.217h-2.2a.218.218,0,0,1-.217-.217V8.862a.218.218,0,0,1,.217-.217h2.2a.219.219,0,0,1,.219.217Zm0-1.612a.22.22,0,0,1-.219.219h-2.2a.218.218,0,0,1-.217-.219V7.25a.218.218,0,0,1,.217-.217h2.2a.219.219,0,0,1,.219.217Zm3.047,4.883a.217.217,0,0,1-.217.219H4.464a.219.219,0,0,1-.219-.219V7.207a.218.218,0,0,1,.219-.217H6.837a.217.217,0,0,1,.217.217Zm2.875-.006a.218.218,0,0,1-.217.219h-2.2a.219.219,0,0,1-.219-.219V12.128a.218.218,0,0,1,.219-.217h2.2a.218.218,0,0,1,.217.217Zm0-1.61a.218.218,0,0,1-.217.217h-2.2a.218.218,0,0,1-.219-.217V10.517a.219.219,0,0,1,.219-.219h2.2a.218.218,0,0,1,.217.219Zm0-1.612a.218.218,0,0,1-.217.217h-2.2a.218.218,0,0,1-.219-.217V8.906a.219.219,0,0,1,.219-.219h2.2a.218.218,0,0,1,.217.219Zm0-1.612a.218.218,0,0,1-.217.217h-2.2a.218.218,0,0,1-.219-.217V7.294a.218.218,0,0,1,.219-.217h2.2a.218.218,0,0,1,.217.217Z"
          transform="translate(0 0)"
          fill="#f1f1f1"
        />
      </g>
    </svg>
  );
};

export default MingaeverythingIcon;
