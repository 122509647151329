import axios from "axios";
import { D } from "../apis/main";

// My world page pagination,filter,serach data get
const homeFeedAlgorithm = async ({ page, filter, searchQuery }: any) => {
  let mainData;
  if (searchQuery) {
    mainData = await fetchSearchData(searchQuery);
  } else {
    mainData = await fetchFilteredData({ page, filter });
  }
  return mainData;
};

// My world page API for bounties,advertisement with pagination & sort order
const fetchFilteredData = async ({ page, filter }: any) => {
  try {
    let sortOrder = "latest"; // Default sort order
    if (filter === "oldest") {
      sortOrder = "oldest";
    }
    const [bounties, advertisements] = await Promise.all([
      axios.get(
        `${D}/getAll/BountiData?page=${page}&limit=5&sortOrder=${sortOrder}`
      ),
      axios.get(
        `${D}/getAll/adminAdvertisement?page=${page}&limit=5&sortOrder=${sortOrder}`
      ),
    ]);

    // data merge for Bounties & Advertise
    const mergedData: any = mergeToArray({
      bounties: bounties.data.filteredData,
      advertises: advertisements.data.ads,
    });
    console.log(mergedData, "mergedData");
    return mergedData;
  } catch (err) {
    return [];
  }
};


// My world Page search API
const fetchSearchData = async (searchQuery: string) => {
  try {
    const searchResults = await axios.get(
      `${D}/searchBar?title=${searchQuery}`
    );
    console.log("Search Results:", searchResults.data);
    return searchResults.data.searchData;
  } catch (err) {
    return [];
  }
};

// Merge API for serch,bounty & advertise filter & pagination
const mergeToArray = ({
  bounties = [],
  advertises = [],
  searchResults = [],
}: any): any => {
  const mergedArray: any = [];
  bounties.forEach((bounty: any) => {
    mergedArray.push(bounty);
  });
  advertises.forEach((ad: any) => {
    mergedArray.push(ad);
  });
  searchResults.forEach((result: any) => {
    mergedArray.push(result);
  });

  return mergedArray;
};

export default homeFeedAlgorithm;
export { fetchFilteredData, fetchSearchData };
