import React from "react";

const SubmissionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="15.866"
      height="15.901"
      viewBox="0 0 15.866 15.901"
      style={{ marginRight: "1em" }}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_89"
            data-name="Rectangle 89"
            width="15.866"
            height="15.901"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_147" data-name="Group 147" transform="translate(0 0)">
        <g
          id="Group_146"
          data-name="Group 146"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_72"
            data-name="Path 72"
            d="M13.62,6.811a2.2,2.2,0,0,0-1.244.314v-3.6H8.1a2.188,2.188,0,0,0,.338-1.279,2.245,2.245,0,1,0-4.491,0,2.2,2.2,0,0,0,.335,1.279H0V7.613a2.188,2.188,0,0,1,1.276-.335,2.246,2.246,0,0,1,0,4.492A2.2,2.2,0,0,1,0,11.435V15.9H4.736a2.191,2.191,0,0,1-.359-1.309,2.246,2.246,0,1,1,4.492,0A2.191,2.191,0,0,1,8.51,15.9h3.867V10.989a2.185,2.185,0,0,0,1.244.314,2.246,2.246,0,0,0,0-4.492"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default SubmissionIcon;
