import { useDispatch, useSelector } from "react-redux";
import { addFilesData } from "../../app/slices/mediaUploaderData";
import { RootState } from "../../app/store";
import { Alert } from "@mui/material";
import { useState } from "react";

const FileDropper = () => {
  // Local state variables for displaying alerts
  const [isAlert1, setIsAlert1] = useState(false);
  const [isAlert2, setIsAlert2] = useState(false);
  // Redux configuration
  const dispatch = useDispatch();
  const fileDataFromRedux: any = useSelector(
    (state: RootState) => state.mediaUploaderData
  );
  const isLock = useSelector((state: RootState) => state.booleanStatus.isLock);

  // Function to open local file manager
  const clickYourLocalFileManager = () => {
    const fileInput: any = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  // Function to handle file upload
  const uploadFiles = (e: any) => {
    if (e.target.files.length > 0) {
      const files: any = e.target.files;
      console.log("files", files);
      let totalSize: number = 0; // Initialize totalSize outside the loop
      // Check if total number of files exceeds the limit
      if (fileDataFromRedux.length + files.length > 25) {
        setIsAlert1(true);
        setTimeout(() => {
          setIsAlert1(false);
        }, 2300);
        return;
      }
      // Loop through each selected file
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        totalSize += element.size; // Accumulate file sizes
        // Dispatch action to add file data to Redux store
        dispatch(
          addFilesData({
            index: fileDataFromRedux.length + index,
            url: URL.createObjectURL(element),
            type: element.type,
            title: "",
            description: "",
            position: { x: 0, y: 0 },
            size: element.size,
          })
        );
      }
    } else {
      setIsAlert2(true);
      setTimeout(() => {
        setIsAlert2(false);
      }, 2300);
    }
  };

  return (
    <>
      {/* File drop area */}
      <div
        className="FM__DRopper__Main"
        style={isLock ? { cursor: "not-allowed" } : {}}
        onClick={clickYourLocalFileManager}
      >
        {/* Alert for exceeding file limit */}
        <Alert
          severity="info"
          style={
            isAlert1 || isAlert2
              ? {
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  fontWeight: "bolder",
                  fontSize: "1.3rem",
                }
              : { display: "none" }
          }
        >
          total length of assets must between 0 to 25
        </Alert>
        {/* File drop area content */}
        <div className="FM__DROpper_image">
          <img src="./tests/uploadImage.svg" alt="file_upload_image" />
          <h1 className="FM__DROPPer__show">Drag your image, or browse.</h1>
          <p className="FM__Dropper__desc">
            Select up to a maximum of 25 assets to upload.
          </p>
          {/* Hidden file input field */}
          <input
            type="file"
            id="fileInput"
            style={{ visibility: "hidden" }}
            onChange={uploadFiles}
            disabled={isLock}
            multiple
          />
        </div>
      </div>
    </>
  );
};

export default FileDropper;
