import React, { useEffect, useState } from "react";
import axios from "axios"; // Make sure axios is installed: npm install axios
import { getUserActivity } from "../../apis/getApis";
import { useNavigate } from "react-router-dom";

// User Activity Card which is at Profile page bottom left side
const TabCardActivity = ({ isDynamicRoute }: any) => {
  const [userActivity, setUserActivity] = useState<any>(null);
  const navigate = useNavigate();

  // Fetch User Activity API
  useEffect(() => {
    // Function to fetch user activity
    const fetchUserActivity = async () => {
      try {
        // Call your API to fetch user activity
        const response = await axios.get(`${getUserActivity}`);
        // Set user activity state with the response data
        setUserActivity(response.data.interactionUsers);
        console.log(response.data.interactionUsers, "userActivity");
      } catch (error) {
        console.error("Error fetching user activity:", error);
      }
    };

    // Call the function to fetch user activity
    fetchUserActivity();
  }, []); // Call the effect only once when component mounts

  const redirectBounty = (bountyId: string) => {
    navigate("/bountipreview", { state: bountyId });
  };

  return (
    <>
    {/* Mapping user Activity Data */}
      {userActivity && userActivity.length > 0 ? (
        userActivity.map((activity: any, index: any) => (
          <div
            key={index}
            className="profile__tabcard"
            onClick={() => redirectBounty(activity?.bountiId)}
          >
            {/* main show image goes here*/}
            <img
              src={isDynamicRoute ? activity?.media[0] : activity?.media[0]}
              alt=""
            />
            {/* main show image end here*/}

            {/* right section goes here */}
            <div
              className="tabcard__right flex justify-between"
              style={{ width: "77%" }}
            >
              <div className="tabcard__right__start">
                <div className="tabcard__user flex">
                  <div>{activity?.username}</div>
                </div>
                <div className="tabcard__comment">{activity?.message}</div>
              </div>
              <div className="tabcard__right__end">
                <div className="tabcard__right__time">
                  <span style={{ color: "var(--orange)" }}>2h</span> ago
                </div>
                {/* likes and comment section goes here */}
                <div className="tabcard__right__time flex items-center">
                  {/* <div className="cursor-pointor">
                    <img
                      src={
                        isDynamicRoute
                          ? "../tests/icons/boost.svg"
                          : "./tests/icons/boost.svg"
                      }
                      alt="boost"
                    />
                  </div> */}
                  <div className="cursor-pointor">
                    <img
                      src={
                        isDynamicRoute
                          ? "../tests/icons/heart.svg"
                          : "./tests/icons/heart.svg"
                      }
                      alt="like"
                    />
                  </div>
                  <div className="cursor-pointor">
                    <img
                      src={
                        isDynamicRoute
                          ? "../tests/icons/comment2.svg"
                          : "./tests/icons/comment2.svg"
                      }
                      alt="comment"
                    />
                  </div>
                </div>
                {/* likes and comment section end here */}
              </div>
            </div>
            {/* right section end here */}
          </div>
        ))
      ) : (
        <h3 style={{marginTop:'1rem'}}>No Activities yet...</h3>
      )}
    </>
  );
};

export default TabCardActivity;
