import "./responsive.css";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { Avatar, Stack } from "@mui/material";
import { RewardCarousel, USTCard } from "../index";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CharityCarousel from "../CharityCarousel";
import SponserCarousel from "../SponserCarousel";
import axios from "axios";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {
  bookMarkCurrentBounti,
  likeCurrentBounti,
} from "../../../../../apis/postApis";
import Empty from "../../../../../apis/Empty";
import Header from "../../../../../apis/header";
import {
  allAsset2,
  bountiCategory2,
  bountiCharity,
  bountiSponsor,
  globalBounti2,
  isCurrentFav,
  isCurrentLike,
  rewardData2,
  totalBountiLikes,
  totalBountiMarks,
} from "../../../../../apis/getApis";
import { useLocation } from "react-router-dom";

const R_BountyDetailComponent = () => {
  const location = useLocation();
  const reduxBountyId = location.state;
  const [bountyData, setBountyData] = useState<any>();
  const [rewardData, setRewardData] = useState<any>();
  const [categoryData, setCategoryData] = useState<any>();
  const [assetData, setAssetData] = useState<any>();
  const [totalBoutiLikess, setTotalBountyLikes] = useState<any>();
  // const [totalBoutiFavs, setTotalBountyFavs] = useState<any>();
  const [sponserData, setSponserData] = useState<any>();
  const [charityData, setCharityData] = useState<any>();
  const [currentFav, setCurrentFav] = useState<any>();
  const [currentLike, setcurrentLike] = useState<any>();

  // fetch function
  const fetchData: any = async () => {
    // fetch bounty data
    axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((response: any) => {
        setBountyData(response.data);
      })
      .catch((err: any) => {});
    // fetch reward data
    axios
      .get(`${rewardData2}/${reduxBountyId}`)
      .then((response: any) => {
        setRewardData(response.data);
      })
      .catch((err: any) => {});
    // categoty fetch
    axios
      .get(`${bountiCategory2}/${reduxBountyId}`)
      .then((response: any) => {
        setCategoryData(response.data);
      })
      .catch((err: any) => {});
    // all assets fetch
    axios
      .get(`${allAsset2}/${reduxBountyId}?type=undefined`)
      .then((response: any) => {
        setAssetData(response.data);
      })
      .catch((err: any) => {});
    // total likes
    axios
      .get(`${totalBountiLikes}/${reduxBountyId}`)
      .then((response: any) => {
        setTotalBountyLikes(response.data);
      })
      .catch((err: any) => {});
    // axios.get(`${totalBountiMarks}/${reduxBountyId}`),
    // fetch sponsers
    axios
      .get(`${bountiSponsor}/${reduxBountyId}`)
      .then((response: any) => {
        setSponserData(response.data);
      })
      .catch((err: any) => {});
    // fetch charity
    axios
      .get(`${bountiCharity}/${reduxBountyId}`)
      .then((response: any) => {
        setCharityData(response.data);
      })
      .catch((err: any) => {});
    // iscurrent fav
    axios
      .get(`${isCurrentFav}/${reduxBountyId}`)
      .then((response: any) => {
        setCurrentFav(response.data);
      })
      .catch((err: any) => {});
    // iscurrent like
    axios
      .get(`${isCurrentLike}/${reduxBountyId}`)
      .then((response: any) => {
        setcurrentLike(response.data);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  // styles for avtars goes here
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  // all states
  const [currentComponent, setcurrentComponent] = useState<any>({
    totalBoutiLikes: "",
    totalBoutiMarks: "",
    isMeLikeBouti: "",
    isMeMarkBouti: "",
    isLiking: false,
    isMarking: false,
  });

  useEffect(() => {
    setcurrentComponent({
      ...currentComponent,
      totalBoutiLikes: totalBoutiLikess?.totalLikes,
      isMeLikeBouti: currentLike?.likeStatus,
      isMeMarkBouti: currentFav?.favoriteStatus,
    });
  }, [totalBoutiLikess, currentLike, currentFav]);

  // like current bounti
  const likeYourBouty = () => {
    setcurrentComponent({ ...currentComponent, isLiking: true });
    axios
      .post(`${likeCurrentBounti}/${reduxBountyId}`, Empty, Header)
      .then((response) => {
        axios
          .get(isCurrentLike)
          .then((res) => {
            console.log("likeStatus", res);
            setcurrentComponent({ ...currentComponent, isLiking: false });
            setcurrentComponent({
              ...currentComponent,
              isMeLikeBouti: res?.data.likeStatus,
              totalBoutiLikes: res?.data.likeStatus
                ? currentComponent?.totalBoutiLikes + 1
                : currentComponent?.totalBoutiLikes - 1,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {
        setcurrentComponent({ ...currentComponent, isLiking: false });
      });
  };

  // add favorites
  function bountiBookMark() {
    setcurrentComponent({ ...currentComponent, isMarking: true });
    axios
      .post(`${bookMarkCurrentBounti}/${reduxBountyId}`, Empty, Header)
      .then((res) => {
        setcurrentComponent({
          ...currentComponent,
          isMeMarkBouti: res?.data.message === "favorite" ? true : false,
          isMarking: false,
        });
        console.log(res?.data.message);
      })
      .catch((err) => {
        setcurrentComponent({ ...currentComponent, isMarking: false });
        console.log(err);
      });
  }

  // if (isLoading) {
  //   return (
  //     <h1 className="r_boutiDetail_wrapper" style={{ textAlign: "center" }}>
  //       Loading...
  //     </h1>
  //   );
  // }

  return (
    <div className="r_boutiDetail_wrapper">
      <div className="flex justify-between r_boutiDetail_wrapper_inner">
        {/* left */}
        <section className="r_boutiDetail_wrapper--left">
          {/* branding */}
          <div className="flex items-center">
            <img src="./tests/deadpool.webp" className={`brand_c1_image`} />
            <h1 className={`r-brandName_c1 color-headingBlue`}>Mr. Deadpool</h1>
          </div>
        </section>

        {/* center */}
        <section className="r_boutiDetail_wrapper--center">
          <div>
            <label className="r_label">Participants</label>
            <Stack direction="row" spacing={2}>
              <div className="flex items-end r_avtar_grp_c1">
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    alt="Remy Sharp"
                    src="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
                  />
                </StyledBadge>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Avatar
                    alt="Travis Howard"
                    src="https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png"
                  />
                </Badge>
                <p className={`r-numberOfEngage_C1`}>
                  and{" "}
                  <b className="color-headingBlue cursor-pointor">13 other’s</b>{" "}
                  have engaged
                </p>
              </div>
            </Stack>
          </div>
        </section>
      </div>

      {/* right */}
      <section className={`sponserWraper_c1-right`}>
        {/* sponser */}
        <div className={`r-sponser_c1`}>
          <label className="r_label">Sponser</label>
          <div className={"r_sponserSlider_c1"}>
            <SponserCarousel sponserData={sponserData?.data} />
          </div>
        </div>

        {/* charity */}
        <div className={`r_charity_c1`}>
          <label className="responsive_label">Charity</label>
          <div className={"r_charitySlider_c1"}>
            <CharityCarousel boutiCharityData={charityData?.data} />
          </div>
        </div>
      </section>

      <div style={{ marginTop: "2em" }}>
        {/* grid */}
        <div className={`r_grid_c1`}>
          <div className={`r-one`}>
            {/* main assets */}
            {assetData?.data[0]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video className={`r_mainImg_c1`} controls>
                  <source src={assetData?.data[0]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                className={`r_mainImg_c1`}
                src={
                  assetData?.data[0]?.imageUrl
                    ? assetData?.data[0]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                alt=""
              />
            )}
          </div>
          <div className={`r-three`}>
            {/* asset 2 goes here*/}
            {assetData?.data[1]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video className={`r_img_c1`} controls>
                  <source src={assetData?.data[0]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                src={
                  assetData?.data[1]?.imageUrl
                    ? assetData?.data[1]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                className={`r_img_c1`}
                alt=""
              />
            )}
            {/* asset 2 end here */}

            {/* asset 3 goes here */}
            {assetData?.data[2]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video className={"r_img_c1"} controls>
                  <source src={assetData?.data[0]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                src={
                  assetData?.data[2]?.imageUrl
                    ? assetData?.data[2]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                alt=""
                className={"r_img_c1"}
              />
            )}
            {/* asset 3 end here */}

            {/* asset 4 goes here */}
            {assetData?.data[3]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video className={"r_img_c1"} controls>
                  <source src={assetData?.data[0]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                src={
                  assetData?.data[3]?.imageUrl
                    ? assetData?.data[3]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                className={"r_img_c1"}
              />
            )}
            {/* asset 4 end here */}
          </div>
        </div>

        <main className={"r_main_c1"}>
          {/* bounti title bar */}
          <div className={`r_bountiTitleBar_c1`}>
            {/* left bounti title */}
            <div className={`r_bountiMeta_c1`}>
              <h1 className={`r_bountititle bountititle_res color-headingBlue`}>
                {bountyData?.text.title}
              </h1>
              <div className={`r_tags_c1`}>
                <p className={`r_hashtag r_hashtagFirst hashtag_res`}>#Art</p>
                <p className={`r_hashtag hashtag_res`}>#Gaming</p>
              </div>
            </div>

            {/* right like and options */}
            <div className="flex items-center r_bountiMetaRight_c1">
              <Badge
                badgeContent={currentComponent?.totalBoutiLikes}
                className={`r_badge m-half-right`}
              >
                {!currentComponent?.isMeLikeBouti ? (
                  <>
                    {!currentComponent?.isLiking ? (
                      <button className="noBtn">
                        <FavoriteBorderIcon
                          onClick={() => {
                            likeYourBouty();
                          }}
                          className={"r_icons_c1"}
                        />
                      </button>
                    ) : (
                      <button className="noBtn" disabled>
                        <FavoriteBorderIcon
                          onClick={() => {
                            likeYourBouty();
                          }}
                          className={"r_icons_c1"}
                        />
                      </button>
                    )}
                  </>
                ) : (
                  <FavoriteIcon
                    onClick={() => {
                      likeYourBouty();
                    }}
                    className={"r_icons_c1"}
                  />
                )}
              </Badge>
              {currentComponent.isMeMarkBouti ? (
                <>
                  {!currentComponent.isMarking ? (
                    <button className="noBtn">
                      <BookmarkIcon
                        onClick={bountiBookMark}
                        className={`r_icons_c1`}
                      />
                    </button>
                  ) : (
                    <button className="noBtn" disabled>
                      <BookmarkIcon
                        onClick={bountiBookMark}
                        className={`r_icons_c1`}
                      />
                    </button>
                  )}
                </>
              ) : (
                <>
                  {!currentComponent.isMarking ? (
                    <button className="noBtn">
                      <BookmarkBorderIcon
                        onClick={bountiBookMark}
                        className={`r_icons_c1`}
                      />
                    </button>
                  ) : (
                    <button className="noBtn" disabled>
                      <BookmarkBorderIcon
                        onClick={bountiBookMark}
                        className={`r_icons_c1`}
                      />
                    </button>
                  )}
                </>
              )}
              <NotificationsOutlinedIcon className={`r_icons_c1`} />
              <MoreVertOutlinedIcon className={`r_icons_c1`} />
            </div>
          </div>

          {/* bounti description section */}
          <div className={`r_bountiDescription_c1`}>
            <div className={`r_desc_c1`}>
              <p className={`r_bText btext_res`}>
                {bountyData?.text.description}
              </p>
              <p className={`r_bText btext_res`} style={{ marginTop: "1em" }}>
                Formats accepted: png, jpg, ai, eps, pdf, psd
              </p>
              <p className={`r_bText btext_res`}>Dimensions: 2000 by 2000 px</p>
            </div>

            <div className={`r_unlocks`}>
              <USTCard>
                <label style={{ color: "var(--pure)" }}>Unlocks</label>
                <p>{bountyData?.text?.unlocks}/100</p>
              </USTCard>
              <USTCard styling={{ marginTop: "0.5em" }}>
                <label style={{ color: "var(--pure)" }}>Submissions</label>
                <p>{bountyData?.text?.totalSubmissions}/100</p>
              </USTCard>
              <USTCard styling={{ marginTop: "0.5em" }}>
                <label style={{ color: "var(--pure)" }}>Time Limit</label>
                <p>{bountyData?.nextPhaseTimeLeft}</p>
              </USTCard>
            </div>
          </div>

          {/* Reward carousel */}
          <div className={"r_rewardWrapper_c1 reqwrdWarppers"}>
            <h1 className={`r_bountititle bountititle_res color-headingBlue`}>
              Reward
            </h1>
            <RewardCarousel rewardData={rewardData?.rewards} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default R_BountyDetailComponent;
