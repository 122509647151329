import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BountyCreation,
  BountyPreview,
  CreatorsBounties,
  Login,
  TestPage,
} from "./pages";
import Dashboard from "./pages/Dashboard/Dashboard";
import ViwerBounties from "./pages/bountylists/ViwerBounties";
import CaptchaOnPage from "./pages/authentication/CaptchaOnPage";
import RegisterVarification from "./pages/authentication/RegisterVarification";
import PointTable from "./pages/tests-pages/pointTable";
import COMINGSOON from "./pages/COMINGSOON";
import ForgotVarification from "./pages/authentication/ForgotVarification";
import GuestArea from "./pages/authentication/GuestArea";
import GuestArea2 from "./pages/authentication/GuestArea2";
import Profile from "./pages/profile/Profile";
import ProfileDynamic from "./pages/profile/ProfileDynamic";
import { DEVLOPMENT_MODE } from "./mode";
import PrivacyPolicyContent from "./pages/tests-pages/privacyPolicy";
import AllSubmission from "./pages/profile/AllSubmission";
import Setting from "./pages/profile/Setting";
import { Label } from "@mui/icons-material";
import WatchLater from "./pages/Dashboard/components/BountyCard/WatchLater";
import AdForm from "./pages/tests-pages/AdForm";
import EmailVarification from "./pages/profile/EmailVarification";
import ResetDialog from "./pages/authentication/ResetDialog";

if (DEVLOPMENT_MODE === "PRODUCTION") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}else{
  
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <COMINGSOON />,
  },
  {
    path: "/viewBounties",
    element: <ViwerBounties />,
  },
  {
    path: "/test",
    element: <TestPage />,
  },
  {
    path: "/bouticreation",
    element: <BountyCreation />,
  },
  {
    path: "/home",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/creator-bouties-list",
    element: <CreatorsBounties />,
  },
  {
    path: "/user/verify/:id/:token",
    element: <RegisterVarification />,
  },
  {
    path: "/forgot/verify/:id/:token",
    element: <ForgotVarification />,
  },
  {
    path: "/email/verify/:id/:token",
    element: <EmailVarification />,
  },
  {
    path: "/bountipreview",
    element: <BountyPreview />,
  },
  {
    path: "/register-captcha",
    element: <CaptchaOnPage />,
  },
  {
    path: "/xp-points",
    element: <PointTable />,
  },
  {
    path: "/guest-area",
    element: <GuestArea />,
  },
  {
    path: "/guest-area-next",
    element: <GuestArea2 />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicyContent />
  },
  {
    path: "/profile/:userId",
    element: <ProfileDynamic />,
  },
  {
    path: "/mySubmisisons",
    element: <AllSubmission />,
  },
  {
    path: "/setting",
    element: <Setting />,
  },
  {
    path: "/watchlater",
    element: <WatchLater />
  },
  {
    path: "/advertisementForm",
    element: <AdForm />
  },
]);
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <section>
    {/* <PrimeReactProvider> */}
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <>
          <RouterProvider router={router} />
        </>
      </Provider>
    </QueryClientProvider>
    {/* </PrimeReactProvider> */}
  </section>
);
