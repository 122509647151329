import Input from "antd/es/input/Input";
import { useDispatch, useSelector } from "react-redux";
import "./loginDialog.css";
import { Button, Spin } from "antd";
import { RootState } from "../../app/store";
import BasicInfo from "../../components/LoginComponent/BasicInfo";
import { ChangeEvent, useState } from "react";
import axios from "axios";
import {
  loginRoute,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
} from "../../apis/postApis";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Alert } from "antd";
import "./loginDialog.css";
import {
  toggleFormateEmailDialog,
  toggleLoginDialogCreation,
  toggleRegisterDialogCreation,
  toggleResetDialog,
} from "../../app/slices/singleStates";
import FloatLabel from "./FloatLable";
import { Reset_Password } from "../../apis/putApis";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const ResetDialog = () => {
  const [loading, setLoading] = useState<any>(false);
  const [isAlert, setIsAlert] = useState<any>(false);
  const [message, setMessage] = useState<any>("");
  const [isError, setIsError] = useState<any>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<any>({
    uid: location.state,
    password: "",
    repeat_password: "",
  });
  const isResetDialog = useSelector(
    (state: RootState) => state.booleanStatus.isResetDialog
  );
  const dispatch = useDispatch();

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };
  const resetPassword = async (e: any) => {
    await axios
      .put(Reset_Password, formData)
      .then((res: any) => {
        console.log("resetPassword", res.data);
        if (res.data) {
          setIsError(false);
          setMessage(res.data.message);
          setIsAlert(true);
          setTimeout(() => {
            setIsAlert(false);
            dispatch(toggleResetDialog(false));
          }, 2000);
        }
      })
      .catch((err: any) => {
        setIsError(true);
        setMessage(err?.response?.data?.message);
        setIsAlert(true);
        setTimeout(() => {
          setIsAlert(false);
        }, 2000);
      });
  };

  // when user change in input field
  function resetFieldChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  // onEnter submit
  const inputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      resetPassword(event);
    }
  };
  return (
    <>
      <div
        className="login__dialog__main__wrapper"
        style={{ height: "fit-content" }}
      >
        {/* left side content */}
        <div className="login__dialog__left__side">
          {/* heading goes here*/}
          <h1
            className="login_heading"
            // style={{ position: "relative" }}
          >
            Linking Lives,
            <br />
            Enriching Experiences
            <Alert
              style={
                isAlert
                  ? { position: "absolute", bottom: "-4rem", width: "18rem" }
                  : { display: "none" }
              }
              message={message}
              type={!isError ? "success" : "error"}
              showIcon
            />
          </h1>
          {/* heading end here*/}

          {/* main goes here */}
          <main className="login__component__main">
            {/* reset from goes here */}
            <section className="login__main__form">
              {/* password */}
              <div className="login__INPUT__dialog">
                <FloatLabel
                  label="Password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={resetFieldChange}
                  onKeyDown={inputKeyDown}
                />
                 {showPassword ? (
                  <EyeInvisibleOutlined
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <EyeOutlined
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              {/* password */}

              {/* password goes here */}
              <div className="login__INPUT__dialog">
                <FloatLabel
                  label="Confirm Password"
                  id="repeat_password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="repeat_password"
                  onChange={resetFieldChange}
                  onKeyDown={inputKeyDown}
                />
                 {showConfirmPassword ? (
                  <EyeInvisibleOutlined
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <EyeOutlined
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
              {/* password goes end */}

              {/* login button goes here */}
              <Button
                className="login_btn__dialog"
                disabled={loading}
                onClick={resetPassword}
              >
                {loading ? (
                  <Spin
                    className="orange-bg-spinner"
                    style={{ color: "var(--pure)" }}
                  />
                ) : (
                  "Reset Password"
                )}
              </Button>
              {/* login button end here */}
            </section>
            {/* reset from end here */}
          </main>
          {/* main end here */}
          {/* <BasicInfo /> */}
          <div className="account__or__not">
            Back to{" "}
            <span
              onClick={() => {
                dispatch(toggleFormateEmailDialog(false));
                dispatch(toggleLoginDialogCreation(true));
              }}
            >
              Login
            </span>{" "}
          </div>
        </div>

        {/* right side image */}
        <div className="login__dialog__right__side">
          <img src="./tests/logindialog.png" />
        </div>
        <button
          className="login__dialog__close"
          onClick={() => {
            dispatch(toggleResetDialog(false));
          }}
        >
          X
        </button>
      </div>
    </>
  );
};

export default ResetDialog;
