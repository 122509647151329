import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define initial state for bountiSubmissionData
const initialState: any = {
  title: "",                // Title of the submission
  description: "",          // Description of the submission
  isPublic: false,          // Flag indicating if the submission is public or not
  files: [],                // Array to store files related to the submission
  isReviewedReward: false,  // Flag indicating if the submission's reward is reviewed
  isTermsAccept: false,     // Flag indicating if terms are accepted for the submission
  alertMessage:"",          // Message for alert
  isAlert:false,            // Flag indicating if alert is shown
};

// Create a slice for managing submission data
export const bountiSubmissionData = createSlice({
  name: "bountiSubmissionData",
  initialState,
  reducers: {
    setSubmissionTitle: (state: any, action: PayloadAction<any>) => {
      state.title = action.payload;
    },
    setAlertMessage: (state: any, action: PayloadAction<any>) => {
      state.alertMessage = action.payload;
    },
    setIsAlert: (state: any, action: PayloadAction<any>) => {
      state.isAlert = action.payload;
    },
    setSubmissionDescription: (state: any, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setSubmissionFiles: (state: any, action: PayloadAction<any>) => {
      state.files = action.payload;
    },
    setPublic: (state: any, action: PayloadAction<any>) => {
      state.isPublic = action.payload;
    },
    setIsTermsAccept: (state: any, action: PayloadAction<any>) => {
      state.isTermsAccept = action.payload;
    },
    setIsReviewedReward: (state: any, action: PayloadAction<any>) => {
      state.isReviewedReward = action.payload;
    },
  },
});

// Export action creators
export const {
  setSubmissionTitle,
  setSubmissionDescription,
  setSubmissionFiles,
  setPublic,
  setIsTermsAccept,
  setIsReviewedReward,
  setAlertMessage,
  setIsAlert
} = bountiSubmissionData.actions;

// Export reducer
export default bountiSubmissionData.reducer;
