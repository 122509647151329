import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { message } from "antd";
import { Menu } from "antd";
import { Tooltip } from "antd";
import {
  BountiManagerIcon,
  BountisIcon,
  BrandBuildIcon,
  CommunitiesIcon,
  MingaeverythingIcon,
  MyworldIcon,
  SubmissionIcon,
} from "../../svgicons";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { create_bounty } from "../../apis/postApis";
import { U } from "../../apis/main";
import axios from "axios";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  tooltip?: string // Add tooltip parameter
): MenuItem {
  return {
    key,
    icon,
    children,
    label: (
      <Tooltip title={tooltip}>
        <span>{label}</span>
      </Tooltip>
    ),
    type,
  } as MenuItem;
}
// Define createBounty function here

const items: MenuProps["items"] = [
  // getItem("My World", "My World", <MyworldIcon />, [
  //   getItem(<Link to={"/home"}>My Feed</Link>, "Central feed", null),
  //   // getItem(
  //   //   <Link to={"/creator-bouties-list"}>Daily Minga</Link>,
  //   //   "DMK",
  //   //   null
  //   // ),
  //   // getItem(<Link to={""}>Friends</Link>, "Friends", null),
  //   // getItem("Brands", "Brands", null),
  //   getItem("Communities", "Communities", null),
  //   getItem(<Link to={"/login"}>Login</Link>, "Communities", null),
  //   // getItem(<Link to="/xp-points">test-xp</Link>, "test-xp", null),
  //   // getItem("Badges & Achievements", "Badges & Achievements", null),
  //   // getItem("Discover", "Discover", null),
  // ]),
  getItem(<Link to={"/home"}>My World</Link>, "My World", <MyworldIcon />),
  // getItem("My Submissions", "My Submissions", <SubmissionIcon />, [
  //   getItem("All Submissions", "Central Submissions feed"),
  //   // getItem("Unlocked", "Unlocked"),
  //   // getItem("Drafts", "Drafts"),
  //   // getItem("Active", "Active"),
  //   // getItem("Past", "Past"),
  // ]),
  // Modify the "Communities" menu item to include a tooltip

  getItem(
    <Link to={"/mySubmisisons"}>My Submissions</Link>,
    "My Submissions",
    <SubmissionIcon />
  ),
  getItem(
    <Link to={"/viewBounties"}>Bounties</Link>,
    "Bounties",
    <BountisIcon />
    // , [
    // getItem(<Link to={"/viewBounties"}>Relevant</Link>, "Relevant"),
    // getItem("New", "New"),
    // getItem("Trending", "Trending"),
    // getItem(<Link to={`#`}>Create a Bounty</Link>, "Create a Bounty"),
    // getItem("Manage a Bounty", "Manage a Bounty"),
    // ]
  ),

  // getItem("Communities", "Communities11", <CommunitiesIcon />
  // // , [
  //   // getItem("Relevant", "Relevant1"),
  //   // getItem("New", "New1"),
  //   // getItem("Trending", "Trending1"),
  // // ]
  // ),
  getItem(
    "Communities",
    "Communities11",
    <CommunitiesIcon />,
    undefined,
    undefined,
    "COMING SOON"
  ),

  getItem("Bounty Manager", "WBMK", <BountiManagerIcon />, [
    getItem(
      "Create a Bounty",
      "Create a Bounty",
      undefined,
      undefined,
      undefined,
      undefined
    ),

    getItem(
      <Link to="/creator-bouties-list">Manage a Bounty</Link>,
      "MABK_WBMK"
    ),
  ]),
  getItem(
    "Brand Builder",
    "Brand Builder",
    <BrandBuildIcon />,
    undefined,
    undefined,
    "COMING SOON"
  ),
  getItem("Everything Else", "Minga Everything", <MingaeverythingIcon />, [
    getItem(<Link to={"/profile"}>Profile</Link>, "Profile"),
    // getItem("Relevancy Rank", "Relevancy Rank"),
    // getItem("Relate Status", "Relate Status"),
    // getItem("Minga Level", "Minga Level"),
    // getItem("Charity Support", "Charity Support"),
    // getItem("Total Boost Count", "Total Boost Count"),
    // getItem("Sponsors", "Sponsors"),
    // getItem("Badges", "Badges"),
    // getItem("Achievements", "Achievements"),
    // getItem("Talents", "Talents"),
    // getItem("Streaks", "Streaks"),
    // getItem("Ad Rewards", "Ads"),
    getItem(<Link to="/setting">Settings</Link>, "Settings"),
    getItem(<Link to="/watchLater">Watch Later</Link>, "Watch Later"),
    // getItem("Privacy & Data", "Privacy & Data"),
  ]),
];
const rootSubmenuKeys = [
  "My World",
  "My Submissions",
  "Bounties",
  "Communities11",
  "Minga Everything",
  "Brand Builder",
  "WBMK",
];

const AntDMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState<any>(
    location.pathname === "/creator-bouties-list"
      ? ["WBMK"]
      : location.pathname === "/test"
      ? ["Bounties"]
      : ["My World"]
    // location.pathname !== "/test" ? ["My World"] : ["Bounties"]
  );
  const [currentPage, setCurrentPage] = useState<any>("");

  const createBounty = async () => {
    try {
      const res = await axios.post(`${create_bounty}/${U}`, {});
      console.log("bhabille", res);
      localStorage.removeItem("createdSponserId");
      localStorage.removeItem("createdCharityId");
      navigate("/bouticreation", {
        state: { selectedId: res.data.bountiSaved._id },
      });
      
    } catch (err) {
      console.error(err);
    }
  };
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  // Handle click event for "Create a Bounty" menu item
  const handleCreateBountyClick = () => {
    // Call the createBounty function
    createBounty();
  };
  return (
    <>
      <Menu
        defaultSelectedKeys={
          location.pathname === "/creator-bouties-list"
            ? ["MABK_WBMK"]
            : location.pathname === ""
            ? ["Friends"]
            : location.pathname === "/test"
            ? ["Relevant"]
            : location.pathname === "/creator-bouties-list"
            ? ["MABK_WBMK"]
            : []
        }
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        className="sideber_menu_ant"
        style={{ width: 256, background: "none" }}
        mode="inline"
        items={items}
        onClick={(e) => {
          if (e.key === "Create a Bounty") {
            handleCreateBountyClick();
            return; // Exit early if "Create a Bounty" is clicked
          }
        }}
      />
    </>
  );
};

export default AntDMenu;
