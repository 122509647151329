import React from "react";

const DateFilter = () => {
  const [isDropdown, setDropDown] = React.useState(false);
  return (
    <React.Fragment>
      <div
        className="p_filter"
        style={{
          height: "3.3rem",
          alignItems: "center",
          width: "10rem",
          borderRadius: "0.5rem",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 6px",
          background: "var(--sidebar)",
        }}
        onClick={() => {
          isDropdown ? setDropDown(false) : setDropDown(true);
        }}
      >
        <img src="./tests/icons/filterIcon.svg" alt="" />
        <div style={{ color: "var(--pure)" }} className="filer__label">
          Date
        </div>
        <img src="./tests/icons/arrowDW.svg" alt="" />
        {/* filer dropdown goes here */}
        <section
          style={isDropdown ? { display: "block",boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", } : { display: "none" }}
          className="p_filter__dropdown"
        >
          <li className="p_filter_ddlist">Latest</li>
          <li className="p_filter_ddlist">Oldest</li>
          <li className="p_filter_ddlist">Relevant</li>
          <li className="p_filter_ddlist">Popular</li>
        </section>
        {/* filer dropdown end here */}
      </div>
    </React.Fragment>
  );
};

export default DateFilter;
