import { useEffect, useState } from "react";
import BlueCard from "./BlueCard";
import styles from "./card.module.css";
import axios from "axios";
import {
  getAllListOfBountyLikes,
  getUserInteractionGlob,
  getFriendInteraction,
} from "../../apis/getApis";
import Globcard from "./GlobCard";
import FriendInteractionCard from "./FriendInteractionCard";

const InteractionCard = ({ bountyId }: any) => {
  const [likesList, setLikesList] = useState<any>([]);
  const [globalInteractionData, setGlobalInteractionData] = useState<any>([]);
  const [friendInteractionData, setFriendInteractionData] = useState<any>([]);
  const [personsData, setPersonsData] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState<any>(1);

  const fetchLikesList = async () => {
    await axios
      .get(`${getAllListOfBountyLikes}/${bountyId}`)
      .then((res) => {
        setLikesList(res.data.likesForBounti);
        // console.log(res.data.likesForBounti, "res.data.likesForBounti");
      })
      .catch((err) => {});
  };

  const fetchGlobInteractionList = async () => {
    await axios
      .get(`${getUserInteractionGlob}/${bountyId}`)
      .then((res) => {
        setGlobalInteractionData(res.data.interactionUsers);
        // console.log(res.data.interactionUsers, "setGlobalInteractionData");
      })
      .catch((err) => {});
  };

  const fetchFriendInteractionList = async () => {
    await axios
      .get(`${getFriendInteraction}&bountiId=${bountyId}`)
      .then((res) => {
        setFriendInteractionData(res.data.interactionUsers);
        // console.log(res, "setFriendInteractionData");
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchLikesList();
    fetchGlobInteractionList();
    fetchFriendInteractionList();
  }, []);

  return (
    <div className={`${styles.InteractionCard} bountypreview__InteractionCard`}>
      <header className={styles.Header}>
        <div className={styles.Heading}>Interactions</div>
        <div className={styles.Tabs}>
          {/* tab 1 */}
          <div
            className={
              currentTab === 1
                ? `${styles.tab} ${styles.tabActive}`
                : `${styles.tab}`
            }
            onClick={() => {
              setCurrentTab(1);
            }}
          >
            <img
              src="./tests/icons/global.svg"
              style={{ width: "2rem", paddingBottom: "0.5rem" }}
              alt=""
            />
            <span className={`${styles.tab_number}`}>
              {globalInteractionData?.length}
            </span>
          </div>

          {/* tab 2 */}
          <div
            className={
              currentTab === 2
                ? `${styles.tab} ${styles.tabActive}`
                : `${styles.tab}`
            }
            onClick={() => {
              setCurrentTab(2);
            }}
          >
            <img
              src="./tests/icons/profile.svg"
              style={{ width: "2rem", paddingBottom: "0.5rem" }}
              alt=""
            />
            <span className={`${styles.tab_number}`}>
              {friendInteractionData?.length}
            </span>
          </div>

          {/* tab 3 */}
          <div
            className={
              currentTab === 3
                ? `${styles.tab} ${styles.tabActive}`
                : `${styles.tab}`
            }
            onClick={() => {
              setCurrentTab(3);
            }}
          >
            <img
              src="./tests/icons/heart.svg"
              style={{ width: "2rem", paddingBottom: "0.5rem" }}
              alt=""
            />
            <span className={`${styles.tab_number}`}>{likesList?.length}</span>
          </div>
        </div>
      </header>
      <main className={styles.Main}>
        {/* globcard */}
        {currentTab === 1 ? (
          globalInteractionData.map((interactionData: any) => {
            console.log(
              interactionData,
              globalInteractionData,
              "interactionData"
            );
            return (
              <Globcard
                user={interactionData?.userName}
                userId={interactionData?._id}
                userProfle={interactionData?.userImage}
              />
            );
          })
        ) : (
          <></>
        )}

        {/* friend interaction card */}
         {currentTab === 2 ? (
          friendInteractionData.map((interactionData: any) => {
            return (
              <FriendInteractionCard
                user={interactionData?.userName}
                userId={interactionData?._id}
                userProfle={interactionData?.userImage}
              />
            );
          })
        ) : (
          <></>
        )}

        {/* like card */}
        {currentTab === 3 ? (
          likesList.map((likeData: any) => {
            console.log(likeData, likesList, "likesList");
            return (
              <BlueCard
                user={likeData?.userName}
                userId={likeData?._id}
                userProfle={likeData?.userImage}
              />
            );
          })
        ) : (
          <></>
        )}
      </main>
    </div>
  );
};

export default InteractionCard;
