import React, { useState, useEffect } from "react";
import "./profile.css";
import { FluidContainer } from "../../components";
import UserCard from "./UserCard";
import ProfileTabs from "./ProfileTabs";
import SubmissionCard from "./SubmissionCard";
import FriendCard from "./FriendCard";
import { useParams } from "react-router-dom";
import UserCardDynamic from "./UserCardDynamic";
import axios from "axios";
import { D, U } from "../../apis/main";
import { fetchProfileStats } from "../../apis/getApis";

const ProfileDynamic = () => {
  const [isDynamicRoute, setIsDynamicRoute] = React.useState<boolean>(false);
  const [isFriend, setIsFriend] = useState<any>("");
  const [profileStats, setProfileStats] = React.useState<any>({});
  const [allSubmission, setAllSubmission] = React.useState<any>([]);
  const [friendlist, setFriendList] = useState<any>([]);
  const params = useParams();
  let { userId } = useParams();
  console.log(userId, "vvvuserIduserId");
  React.useEffect(() => {
    // userwise submission
    axios
      .get(`${D}/get/submissionData/${params.userId}`)
      .then((submissionResponse: any) => {
        console.log("allSubmission Data", submissionResponse.data.submissions);
        setAllSubmission(submissionResponse.data.submissions);
      })
      .catch((error: any) => {
        console.log("allSubmission Data", error);
      });

    // fetch status
    const fetchRequestStatus = async () => {
      await axios
        .get(`${D}/get/friend-status/${U}/${params.userId}`)
        .then((response: any) => {
          // setIsRequested(response.data.friendRequests[0].status);
          console.log("whichStatus", response);
          setIsFriend(response.data.status);
        })
        .catch((error: any) => {
          console.log("whichStatus", error);
        });
    };
    // fetch friend list
    const fetchFriends = async () => {
      await axios
        .get(`${D}/get/friend-requests/${params.userId}`)
        .then((response: any) => {
          console.log("fetchFriendLists", response?.data?.friendsList);
          setFriendList(response?.data?.friendsList);
        })
        .catch((error: any) => {
          console.log("fetchFriendLists", error);
        });
    };

    axios
      .get(`${fetchProfileStats}/${params.userId}`)
      .then((response: any) => {
        console.log("profileStats", response);
        setProfileStats(response.data);
      })
      .catch((err: any) => {
        console.log("profileStats", err);
      });
    // Call fetchRequestStatus when userId changes
    if (userId) {
      setIsDynamicRoute(true);
      fetchFriends();
      fetchRequestStatus();
    }
  }, [params.userId, userId,isFriend]); // Update dependencies here

  // useEffect(() => {
  //   console.log(isDynamicRoute, userId, isFriend, profileStats, "profileStats");
  // }, [isFriend, profileStats, userId, isDynamicRoute]);
  return (
    <FluidContainer dynamic={true}>
      {/* main goes here */}
      <main className="profile__main flex justify-between">
        {/* main user related here info goes here */}
        <div className="profile__middle">
          <UserCardDynamic
            isDynamicRoute={isDynamicRoute}
            isFriend={isFriend}
            profileStats={profileStats}
          />
          {isFriend === true ? (
            <ProfileTabs isDynamicRoute={isDynamicRoute} />
          ) : (
            <div
              className=" profile__submission__card_relative"
              style={{ marginTop: "1rem" }}
            >
              <img
                src="../tests/profile__tabShot.png"
                style={{ objectFit: "cover" }}
              />
              <div
                className="blur-full-div profile-blur-sub"
                style={{ textAlign: "center", flexDirection: "column" }}
              >
                <img
                  src="../tests/icons/noview.svg"
                  style={{ width: "2rem" }}
                  alt=""
                />
                <p>
                  Only User Friend can <br /> See this section
                </p>
              </div>
            </div>
          )}
        </div>
        {/* main user related here info goes here */}

        {/* profile main right section goes here */}
        <section className="profile__right">
          {isFriend === true ? (
            <SubmissionCard
              allSubmission={allSubmission}
              isDynamicRoute={isDynamicRoute}
            />
          ) : (
            <div className="profile__submission__card profile__submission__card_relative">
              <img
                src="../tests/profile_subShot.png"
                style={{ objectFit: "cover" }}
              />
              <div
                className="blur-full-div profile-blur-sub"
                style={{ flexDirection: "column" }}
              >
                <img
                  src="../tests/icons/noview.svg"
                  style={{ width: "2rem" }}
                  alt=""
                />
                <p>
                  Only User Friend can <br /> See given submission
                </p>
              </div>
            </div>
          )}
          {isFriend ? (
            <FriendCard friends={friendlist} isDynamicRoute={isDynamicRoute} />
          ) : (
            <div
              className="profile__submission__card profile__submission__card_relative"
              style={{ marginTop: "2rem", height: "25rem" }}
            >
              <img
                src="../tests/profile_friShot.png"
                style={{ objectFit: "cover" }}
              />
              <div
                className="blur-full-div profile-blur-sub"
                style={{ flexDirection: "column" }}
              >
                <img
                  src="../tests/icons/noview.svg"
                  style={{ width: "2rem" }}
                  alt=""
                />
                <p>
                  Only User Friend can <br /> See given friend list
                </p>
              </div>
            </div>
          )}
        </section>
        {/* profile main right section end here */}
      </main>
      {/* main end here */}
    </FluidContainer>
  );
};

export default ProfileDynamic;
