import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import axios from "axios";
import { D } from "../../../../apis/main";
import { addFeedAdURL } from "../../../../app/slices/singleStates";

// when click on Pin advertisement name Advertise Popup Open(My World Page)
const AdView = ({ setIsAdPopup, selectedAdId }: any) => {
  // local states
  const advertiseId = useSelector((state: RootState) => {
    return state.booleanStatus.activeFeedAdURL;
  });
  const [loading, setLoading] = useState<boolean>(true); // Introduce loading state
  const dispatch = useDispatch();

  const [adURL, setAdUURL] = useState<any>("");
  const [adURLType, setAdUURLType] = useState<any>("");
  const [adYouTubeLink, setAdYouTubeLink] = useState<any>("");

  // get advertisement accrding selected advertisement from pin section
  useEffect(() => {
    setLoading(true); // Set loading to true when selectedAdId changes
    if (selectedAdId) {
      axios
        .get(`${D}/get/ads/${selectedAdId}`)
        .then((response: any) => {
          setAdUURL(response?.data?.ads[0]?.presignedUrls[0]);
          setAdUURLType(response?.data?.ads[0]?.type);
          setAdYouTubeLink(response?.data?.ads[0]?.youtubeLink);
          setLoading(false); // Set loading to false when data is fetched
        })
        .catch((err: any) => {
          console.log("Error fetching ad data:", err);
          setLoading(false); // Make sure to set loading to false even on error
        });
    }
  }, [selectedAdId]);

  return (
    <div className="adView__dashboard">
      {loading ? ( // Display loading indicator if loading
        <div className="adframe">
          <div></div>
        </div>
      ) : (
        // Render ad content once data is fetched
        <div className="adframe">
          {adURLType === "video" ? (
            <video className="adframe__md" controls key={selectedAdId}>
              <source src={adURL} />
            </video>
          ) : adURLType === "image" && adYouTubeLink === "" ? (
            <img src={adURL} className="adframe__md" alt="" />
          ) : adURLType === "image" && adYouTubeLink !== "" ? (
            <iframe
              className="adframe__md"
              src={adYouTubeLink}
              title="YouTube Video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              key={selectedAdId}
              allowFullScreen
            ></iframe>
          ) : (
            // Render a fallback element here if none of the conditions match
            <div>No valid content to display</div>
          )}
          {/* Close button */}
          <div
            className="adView__dashboard__close"
            onClick={() => {
              setIsAdPopup(false);
            }}
          >
            X
          </div>
        </div>
      )}
    </div>
  );
};

export default AdView;
