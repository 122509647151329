import React, { useState } from "react";
import "./loginDialog.css"; // Import your component-specific CSS

const FloatLabel = ({ label, id, type, name, onChange, onKeyDown }) => {
  const [focused, setFocused] = useState(false);

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    setFocused(false);
  };

  return (
    <div className={`login__INPUT__dialog ${focused ? "focused" : ""}`}>
      <label htmlFor={id} className="floating-label">
        {label}
      </label>
      <input
        id={id}
        type={type}
        name={name}
        className="login__INPUT__input__dialog"
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
    </div>
  );
};

export default FloatLabel;
