import React from "react";
import { useNavigate } from "react-router-dom";
import Submission from "./Submission";

// Submission Card shows at User Profiule Top Right Side
const SubmissionCard = ({ isDynamicRoute, allSubmission }: any) => {
  let submissionsToDisplay = allSubmission;
  const navigate = useNavigate();

  // If there are more than four submissions, show only the last four
  if (allSubmission.length > 4) {
    submissionsToDisplay = allSubmission.slice(-4);
  }
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }

  const handleViewAllClick = () => {
    // Redirect to the "My Submissions" page
    navigate("/mySubmisisons");
  };

  return (
    <div className="profile__submission__card">
      <div className="profile__submission__heading">Submissions</div>
      <div style={{ height: "91%" }} className="profile__submission__body">
        {submissionsToDisplay.map((submission: any, ind: any) => {
          return (
            <Submission
              key={ind} // Add a unique key for each submission element
              isDynamicRoute={isDynamicRoute}
              style={
                ind === 0
                  ? { cursor: "pointer" }
                  : { marginTop: "1rem", cursor: "pointer" }
              }
              submission={submission}
            />
          );
        })}
       
      </div>
       {/* Conditionally render the "View All" button */}
        {/* {allSubmission.length > 4 && ( */}
        <button
            className="butttt"
            onClick={handleViewAllClick}
            // style={{background:'#046791',color:'white',cursor:'pointer',borderRadius:'0.5rem',right:'0'}}
          >
            View All
          </button>
        {/* )} */}
    </div>
  );
};

export default SubmissionCard;
