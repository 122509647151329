import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { addIsMediaDeletePopup } from "../../app/slices/singleStates";
import { addCurrentMediaTab } from "../../app/slices/singleStates";
import axios from "axios";
import { deleteAssets } from "../../apis/delete";
import {
  addFilesData,
  clearMediaUploaderData,
  removeMedia,
} from "../../app/slices/mediaUploaderData";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchMediaData } from "../../apis/getApis";
import { addcurrentFocusCardNumber } from "../../app/slices/mediaUploaderSignles";

const DeleteConfirmation = ({ bountyId }: any) => {
  // State variables
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);

  // Redux selectors
  const fileDataFromRedux: any = useSelector((state: RootState) => {
    return state.mediaUploaderData;
  });
  const isMediaDeletePopup: any = useSelector((state: RootState) => {
    return state.booleanStatus.isMediaDeletePopup;
  });
  const mainId: any = useSelector((state: RootState) => {
    return state.booleanStatus.deleteMainId;
  });
  const assetId: any = useSelector((state: RootState) => {
    return state.booleanStatus.deleteAssetId;
  });
  const currentImageCardInd: any = useSelector((state: RootState) => {
    return state.booleanStatus.currentImageCardInd;
  });
  // Redux dispatch
  const dispatch = useDispatch();
  // Effect to clear the confirmation message when isLoading changes
  useEffect(() => {
    if (isLoading) {
      // Clear the confirmation message after starting the delete process
      setIsAlertSuccess(false);
      setIsAlertError(false);
    }
  }, [isLoading]);
  // Effect to auto hide success alert after 3 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAlertSuccess(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isAlertSuccess]);

  // delete media
  const deleteMedia = () => {
    setIsLoading(true);
    axios
      .delete(`${deleteAssets}/${mainId}/${assetId}`)
      .then((res: any) => {
        dispatch(addIsMediaDeletePopup(false));
        dispatch(removeMedia(currentImageCardInd - 1));
        setIsLoading(false);
        setIsAlertSuccess(true);
        // dispatch(clearMediaUploaderData([]));
        // axios
        //   .get(`${fetchMediaData}/${bountyId}?type=undefined`)
        //   .then((res: any) => {
        //     res.data.data.forEach((element: any, index: any) => {
        //       dispatch(
        //         addFilesData({
        //           index: fileDataFromRedux.length + index,
        //           url: element.imageUrl,
        //           type: element.imageUrl.split("/")[5],
        //           title: element.title,
        //           description: element.description,
        //           position: { x: 0, y: 0 },
        //           mediaId: element.mediaId,
        //           id: element.id,
        //         })
        //       );
        //       dispatch(addcurrentFocusCardNumber(0));
        //     });
        //   })
        //   .catch((err: any) => {
        //     console.log(err);
        //   });
      })
      .catch((err: any) => {});
  };

  // Loading icon
  const orangeSpin = (
    <LoadingOutlined style={{ color: "#e98326", fontSize: 30 }} spin />
  );

  return (
    <>
      {/* Success alert */}
      <div>
        {isAlertSuccess && (
          <div className="asset-deleted-alert">
            <Alert
              message="Asset Deleted"
              type="success"
              showIcon
              closable
              onClose={() => setIsAlertSuccess(false)}
            />
          </div>
        )}
      </div>

      {/* Media delete confirmation popup */}
      <div
        className="flex"
        style={
          isMediaDeletePopup
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button
                onClick={() => {
                  dispatch(addIsMediaDeletePopup(false));
                }}
              >
                {isLoading ? null : "x"}
              </button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
              {isLoading ? null : "Are you sure you want to delete this item?"}
            </p>
            <div>
              {isLoading ? (
                <Spin indicator={orangeSpin} size="large">
                  <div className="content" />
                </Spin>
              ) : (
                <>
                  <button
                    className="delete__asset_confirm delete__asset_confirm_delete"
                    onClick={() => {
                      deleteMedia();
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="delete__asset_confirm delete__asset_confirm_cancle"
                    onClick={() => {
                      dispatch(addIsMediaDeletePopup(false));
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirmation;
