import React from "react";
import P_Filter from "./P_Filter";
import P_Search from "./P_Search";
import PinSection from "./PinSection";

// My world page right side section
const PinBar = ({ style, setIsAdPopup, onFilterChange, onSearch ,activeFilter}: any) => {
  console.log("handleSearch pinbar",onSearch)
  return (
    <div style={style}>
      {/* Pin section component rendering */}
      <P_Filter onFilterChange={onFilterChange} activeFilter={activeFilter}/>
      <P_Search onSearch={onSearch} />
      <PinSection setIsAdPopup={setIsAdPopup} />
    </div>
  );
};

export default PinBar;
