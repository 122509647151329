import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserProfile } from "../../apis/getApis";
import Header from "../../apis/header";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const Submission = ({ style, isDynamicRoute, submission }: any) => {
  const [profileDetail, setProfileDetail] = useState<any>([]);
  const navigate = useNavigate();
  const redirectToBounty = (id: any) => {
    navigate("/bountipreview", { state: id });
  };
  console.log(submission, "submissionsubmissionsubmission");
  useEffect(() => {
    // fetch profiledata
    axios
      .get(getUserProfile, Header)
      .then((res: any) => {
        console.log("sidebarProfile_submisison", res);
        setProfileDetail(res.data); // Update state with res.data
        console.log(res.data, "sidebarProfile_submisison"); // Log the updated state
      })
      .catch((err: any) => {
        // Handle error
      });
  }, []);
  return (
    <section
      className="profile__sbmn"
      style={style}
      onClick={() => {
        redirectToBounty(submission?.bountiId);
      }}
    >
      {/* Submission Media 1st Image */}
      <div className="prf__sbmn__left">
        <img
          // src={profileDetail?.userImage}
          src={submission?.media[0]}
          style={{ width: "6rem", padding: "1rem", borderRadius: "0.8rem" }}
          alt="profile"
        />

        {/* likes and comment section goes here */}
        <div className="prf__sbmn__right">
          <div
            className="tabcard__right__time flex items-center"
            style={{ paddingRight: "1rem" }}
          >
            <div className="cursor-pointer">
              {isDynamicRoute ? (
                <ThumbUpAltIcon
                  style={{
                    color: "#e47f24",
                    marginTop: "1rem",
                    marginRight: "0.5rem",
                  }}
                />
              ) : (
                <ThumbUpAltIcon
                  style={{
                    color: "#e47f24",
                    marginTop: "1rem",
                    marginRight: "0.5rem",
                  }}
                />
              )}
            </div>

            <div className="cursor-pointer">
              {isDynamicRoute ? (
                <ThumbDownIcon
                  style={{ color: "#e47f24", marginTop: "1rem" }}
                />
              ) : (
                <ThumbDownIcon
                  style={{ color: "#e47f24", marginTop: "1rem" }}
                />
              )}
            </div>

            {/* <div className="cursor-pointor">
              <img
                src={
                  isDynamicRoute
                    ? "../tests/icons/boost.svg"
                    : "./tests/icons/boost.svg"
                }
                alt="boost"
              />
            </div> */}
            {/* <div className="cursor-pointor">
              <img
                src={
                  isDynamicRoute
                    ? "../tests/icons/heart.svg"
                    : "./tests/icons/heart.svg"
                }
                alt="like"
              />
            </div> */}
            {/* <div className="cursor-pointor">
              <img
                src={
                  isDynamicRoute
                    ? "../tests/icons/comment2.svg"
                    : "./tests/icons/comment2.svg"
                }
                alt="comment"
              />
            </div> */}
          </div>
          {/* Submission Title */}
          <div className="prf__sbmn__project" style={{ paddingRight: "1rem" }}>
            {submission?.title}
          </div>
          {/* <div className="prf__sbmn__tgs" style={{ paddingRight: "1rem" }}>
            Artwork #Design
          </div> */}
          <div className="prf__sbmn__total" style={{ paddingRight: "1rem" }}>
            Total Submissions: 103
          </div>
          {/* <div className="prf__sbmn__status" style={{ paddingRight: "1rem" }}>
            Status: Winner
          </div> */}
        </div>
        {/* likes and comment section end here */}
      </div>
    </section>
  );
};

export default Submission;
