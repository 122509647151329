import React, { useEffect, useState } from "react";
import "./style.css";
import { Empty } from "antd";
import FriendNotify from "./FriendNotify";
import WinnerNotify from "./WinnerNotify";
import {
  favouriteNotification,
  fetchNotification,
  fetchNotificationWinner,
  likeNotification,
  submissionNotification,
} from "../../apis/getApis";
import axios from "axios";
import LikeNotify from "./LikeNotify";

const NotificationDropDown = ({
  isNotification,
  setIsNotification,
  setTotalNotification,
  isClickOnNotification,
  setTotalWinnerNotification,
}: any) => {
  const [notifications, setNotifications] = useState([]);
  const [winnerNotifications, setWinnerNotifications] = useState([]);
  const [likeNotifications, setLikeNotifications] = useState([]);
  const [favouriteNotifications, setFavouriteNotifications] = useState([]);
  const [submissionNotifications, setSubmissionNotifications] = useState([]);
  useEffect(() => {
    // friend notification
    axios
      .get(fetchNotification)
      .then((response: any) => {
        // console.log("notifications", response);
        setNotifications(response.data.notifications);
        setTotalNotification(response.data.notifications.length);
      })
      .catch((err: any) => {});

    // winner notification
    // axios
    //   .get(fetchNotificationWinner)
    //   .then((response: any) => {
    //     console.log("fetchNotificationWinner", response);
    //     setWinnerNotifications(response.data.notifications);
    //     setTotalWinnerNotification(response.data.notifications.length);
    //   })
    //   .catch((err: any) => {});

    // like notification
    // axios
    //   .get(likeNotification)
    //   .then((response: any) => {
    //     console.log("likeNotification", response);
    //     setLikeNotifications(response.data.notifications);
    //     // setTotalWinnerNotification(response.data.notifications.length);
    //   })
    //   .catch((err: any) => {});

    // favourite notifaction
    // axios
    //   .get(favouriteNotification)
    //   .then((response: any) => {
    //     console.log("favouriteNotification likeNotification", response);
    //     setFavouriteNotifications(response.data.notifications);
    //     // setTotalWinnerNotification(response.data.notifications.length);
    //   })
    //   .catch((err: any) => {});

    // submission notification
    // axios
    //   .get(submissionNotification)
    //   .then((response: any) => {
    //     console.log("submissionNotification likeNotification", response);
    //     setSubmissionNotifications(response.data.notifications);
    //     // setTotalWinnerNotification(response.data.notifications.length);
    //   })
    //   .catch((err: any) => {});
  }, [isClickOnNotification]);

  return (
    <div className={isNotification ? "notification__minga" : "hide"}>
      {/* notification header */}
      <div className="notification__minga__header" style={{ height: "2rem" }}>
        <button onClick={() => setIsNotification(false)}>x</button>
      </div>
      {/* notification body */}
      <div
        className="notification__minga__body"
        style={{ height: "calc(100% - 2rem)" }}
      >
        {/* friend Notification */}
        {notifications.length > 0 && (
          <>
            {notifications.map((notification: any) => (
              <FriendNotify
                key={notification.requestId}
                message={notification.message}
                time={notification.timeAgo}
                profile={notification.senderImage}
                requestId={notification.requestId}
                setNotifications={setNotifications}
                setTotalNotification={setTotalNotification}
                bountyId={notification.bountiId}
                type={notification.type}
                senderId={notification.senderId}
                notificationId={notification.notificationId}
              />
            ))}
          </>
        )}

        {/* Winner Notification */}
        {/* {winnerNotifications.length > 0 && (
          <>
            {winnerNotifications.map((notification: any) => (
              <WinnerNotify
                key={notification.requestId}
                message={notification.message}
                time={notification.timeAgo}
                profile={notification.senderImage}
                requestId={notification.requestId}
                setNotifications={setWinnerNotifications}
                setTotalNotification={setTotalWinnerNotification}
                bountyId={notification.bountiId}
              />
            ))}
          </>
        )} */}

        {/* like Notification */}
        {/* {likeNotifications.length > 0 && (
          <>
            {likeNotifications.map((notification: any) => (
              <LikeNotify
                key={notification.requestId}
                message={notification.message}
                time={notification.timeAgo}
                profile={notification.senderImage}
                requestId={notification.requestId}
                setNotifications={setWinnerNotifications}
                setTotalNotification={setTotalWinnerNotification}
                bountyId={notification.bountiId}
                senderId={notification.senderId}
              />
            ))}
          </>
        )} */}
        {/* favourite Notification */}
        {/* {favouriteNotifications.length > 0 && (
          <>
            {favouriteNotifications.map((notification: any) => (
              <LikeNotify
                key={notification.requestId}
                message={notification.message}
                time={notification.timeAgo}
                profile={notification.senderImage}
                requestId={notification.requestId}
                setNotifications={setWinnerNotifications}
                setTotalNotification={setTotalWinnerNotification}
                bountyId={notification.bountiId}
                senderId={notification.senderId}
              />
            ))}
          </>
        )} */}

        {/* Submission Notifications */}
        {/* {submissionNotifications.length > 0 && (
          <>
            {submissionNotifications.map((notification: any) => (
              <LikeNotify
                key={notification.requestId}
                message={notification.message}
                time={notification.timeAgo}
                profile={notification.senderImage}
                requestId={notification.requestId}
                setNotifications={setWinnerNotifications}
                setTotalNotification={setTotalWinnerNotification}
                bountyId={notification.bountiId}
                senderId={notification.senderId}
              />
            ))}
          </>
        )} */}
        {/* Submission Notification */}
        {notifications.length === 0 && winnerNotifications.length === 0 && (
          <Empty description="No notifications yet" />
        )}
      </div>
    </div>
  );
};

export default NotificationDropDown;
