import styles from "./card.module.css";

const FriendInteractionCard = ({ user, userId, userProfle }: any) => {
  return (
    <section className={styles.blueCard} id={userId}>
      <section className={styles.profile}>
        {/* user image */}
        <div className={styles.profileImage}>
          <img src={`${userProfle}`}  alt="" />
          {/* user name */}
          <b>{user.split("@")[0]}</b>
        </div>
      </section>
      <section className={styles.meter}>
        <div className={styles.meterDiv}>
          <span>90</span>
          <img src="./tests/icons/meterPure.svg" style={{ width: "2rem" }} />
          <span>Gaming +2</span>
        </div>
      </section>
      <section className={styles.basicInfo}>
        <div>
          <p
            style={{
              color: "var(--pure)",
              fontSize: "0.8rem",
              fontWeight: "400",
            }}
          >
            1 Mutual Friend
          </p>
          <p
            style={{
              color: "var(--pure)",
              fontSize: "0.8rem",
              fontWeight: "400",
            }}
          >
            1 Mutual Brands
          </p>
        </div>
        <img src="./tests/icons/friend.svg" alt="" />
      </section>
    </section>
  );
};

export default FriendInteractionCard;
