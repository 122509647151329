import React, { useEffect, useState } from "react";
import "./profile.css";
import StatCard from "./StatCard";
import axios from "axios";
import { profileUpdate } from "../../apis/putApis";
import {
  getAllCategoryList,
  getAllSubCategoryList,
  getUserProfile,
  getCategoryNew,
  getSubNew,
  profileSubCategory,
  profileWithUID,
  fetchNotification,
} from "../../apis/getApis";
import Header from "../../apis/header";
import { Skeleton } from "antd";
import Header2 from "../../apis/header-multi";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import { addFriend } from "../../apis/postApis";
import { D, U } from "../../apis/main";

const UserCardDynamic = ({ isDynamicRoute, isFriend, profileStats }: any) => {
  // local states...
  const [username, setUsername] = useState("");
  const [tagline, setTagline] = useState("");
  const [category, setCategory] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [bio, setBio] = useState("");
  const [isCategoryDropdown, setCategoryDropdown] = React.useState(false);
  const [file, setFile] = useState(null);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [isLoading, setIsloading] = useState(false);
  const [completeCategoryList, setCompleteCategoryList] = useState<any>([]);
  const [completeSubCategoryList, setCompleteSubCategoryList] = useState<any>(
    []
  );
  const [isRequested, setIsRequested] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [isSentSuccess, setIsSentSuccess] = useState(false);
  const [receiverId, setReceiverId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [userDataUserId, setUserDataUserId] = useState<any>([]);
  const params = useParams();
  console.log("useParams", params.userId);

  const SubCat = () => {
    let newArray: any = [];
    selectedSubCategory?.forEach((elm: any) => {
      newArray.push(elm?.value);
    });
    return (
      <div
        style={{
          color: "var(--orange)",
          marginTop: "0.5rem",
          fontSize: "0.8rem",
        }}
      >
        {newArray?.join(" , ")}
      </div>
    );
  };

  // fetch profile data
  const fetchProfileData = async () => {
    setIsloading(true);
    await axios
      .get(`${profileWithUID}/${params.userId}`)
      .then((res: any) => {
        setIsloading(false);
        setUserDataUserId(res.data);
        console.log("PROFILEDATA-UserID", res.data);

        // set tagline
        if (res.data.tagline.length === 0) {
          setTagline("Write your tagline here.");
        } else {
          setTagline(res?.data?.tagline);
        }

        // set userBio
        if (res.data.userBio.length === 0) {
          setBio("Write your bio here...");
        } else {
          setBio(res?.data?.userBio);
        }

        // set userName
        if (res.data.userName.length === 0) {
          setUsername("Username");
        } else {
          setUsername(res?.data?.userName);
        }

        // set categories
        // if (res.data.categories.length === 0) {
        //   setCategory("Please select category");
        // } else {
        //   setCategory(res?.data?.categories.categories);
        //   setCategoryId(res?.data?.categories._id);
        // }

        // set subCategories
        // if (res.data.subCategories.length === 0) {
        //   setSelectedSubCategory([]);
        // } else {
        //   let obj: any = [];
        //   res.data.subCategories.forEach((elm: any) => {
        //     obj.push({ value: elm });
        //   });
        //   setSelectedSubCategory(obj);
        // }

        // set userimage
        if (res.data.userImage.length === 0) {
          setProfileImage(() => {
            return !isDynamicRoute
              ? "./tests/profile.png"
              : "../tests/profile.png";
          });
        } else {
          setProfileImage(res?.data?.userImage);
        }
      })
      .catch((err: any) => {
        setTagline("Write your tagline here.");
        setBio("Write your bio here...");
        setUsername("Username");
        // setCategory("Please select category");
        setProfileImage(() => {
          return !isDynamicRoute
            ? "./tests/profile.png"
            : "../tests/profile.png";
        });
        setIsloading(false);
      });
  };

  // fetch categories list
  const fetchCategoriesList = async () => {
    await axios
      .get(`${getCategoryNew}?userId=${params.userId}`)
      .then((res: any) => {
        console.log("completecategory", res.data.categories);
        setCompleteCategoryList(res.data.categories);
        setCategory(res.data.categories);
      })
      .catch((err: any) => {});
  };
  // fetch categories list
  const fetchSubCategoriesList = async () => {
    await axios
      .get(`${getSubNew}?userId=${params.userId}`)
      .then((res: any) => {
        console.log("completecategory", res.data.categories);
        setSubCategories(res.data.subcategories);
      })
      .catch((err: any) => {});
  };

  // fetch status
  // const fetchRequestStatus = async () => {
  //   await axios
  //     ?.get(`${D}/get/friend-status/${U}`)
  //     .then((response: any) => {
  //       // setIsRequested(response.data.friendRequests[0].status);
  //       console.log("whichStatus", response.data);
  //     })
  //     .catch((error: any) => {
  //       console.log("fetchRequestStatus", error);
  //     });
  // };

  useEffect(() => {
    fetchProfileData();
    fetchCategoriesList();
    fetchSubCategoriesList();
    console.log(category, "sdjsfhusdgvudhgv");
    // fetchRequestStatus();
  }, [params]);
  //send friend request
  const sendFriendRequest = async () => {
    try {
      const response = await axios.post(`${addFriend}/${params.userId}`);
      console.log("sendFriendRequest", response.data.notification.receiver);
      setReceiverId(response.data.notification.receiver);
      if (response.data.message) {
        setIsSentSuccess(true);
      }

      // Fetch notifications after sending friend request
      const notifications = await axios.get(
        `${fetchNotification}/${response.data.notification.receiver}`
      );
      console.log("notifications_usercard sendFriendRequest", notifications);
      setNotifications(notifications.data.notifications);
      // setTotalNotification(notifications.data.notifications.length);
    } catch (error) {
      console.log("sendFriendRequest", error);
    }
  };

  return (
    <>
      <div className="profile__usercard">
        {/* hidden input */}
        <input
          type="file"
          style={{ width: "0.1px", height: "0.1px", visibility: "hidden" }}
          id="upload__profile"
          disabled={isDynamicRoute}
        />
        {/* user card left goes here */}
        <div className="profile__usercard__left">
          <div className="profile__userMeta">
            {/* User Name */}
            {!isLoading ? (
              <input
                type="text"
                value={userDataUserId?.userName}
                onChange={(e: any) => {
                  setUsername(e.target.value);
                }}
                className="profile__userName"
                disabled={isDynamicRoute}
              />
            ) : (
              <Skeleton.Input
                className="profile_username_skeleton"
                active={true}
              />
            )}

{/* Tagline */}
            {!isLoading ? (
              <input
                type="text"
                value={userDataUserId?.tagline}
                onChange={(e: any) => {
                  setTagline(e.target.value);
                }}
                className="profile__tagline"
                disabled={isDynamicRoute}
              />
            ) : (
              <Skeleton.Input
                active={true}
                className="profile_tagline_skeleton"
              />
            )}
          </div>
          {/* profile Image */}
          <div
            className="profile__image"
            style={{ cursor: "pointer" }}
            // onClick={openFileManager}
          >
            {!isLoading ? (
              <img
                src={userDataUserId?.userImage}
                style={{
                  width: "90%",
                  height: "20.5rem",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px ",
                }}
                alt=""
              />
            ) : (
              <Skeleton.Image
                className="profile__image__skeleton"
                active={true}
              />
            )}
          </div>
          <div className="profile__progress"></div>
          <div className="profile__levelInfo">
            <b>750xp</b> points to <b>Level 13</b>
          </div>
          <div className="profile__statics">
            {/* Submission Count */}
            <StatCard num={profileStats?.submission} label={"Submissions"} />
            {/* Bounties won count */}
            <StatCard
              num={profileStats?.bountiesWon}
              label={"Bounties Won"}
              style={{ marginLeft: "1rem" }}
            />
            {/* achievements count */}
            <StatCard
              num={profileStats?.achievements}
              label={"Achievements"}
              style={{ marginLeft: "1rem" }}
            />
          </div>
        </div>
        {/* user card left end here */}

        {/* user card right goes here */}
        <div className="profile__usercard__right">
          {/* user bio section goes here */}
          <div className="profile__usercard__bio">
            {/* bio header */}
            <div className="profile__bio__header">
              <div className="profile__bio__left">
                <h1>Bio</h1>
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  {/* selected multiple Category  */}
                  {!isLoading ? (
                    <>
                      <Multiselect
                        key={`category-select`}
                        selectedValues={category}
                        displayValue="categories"
                        placeholder="Interests"
                      />
                    </>
                  ) : (
                    <Skeleton.Input
                      className="profile__category__skeleton"
                      active={true}
                    />
                  )}{" "}
                </div>

                {/* selected multiple subcategory */}
                <Multiselect
                  key={`subcategory-select`}
                  selectedValues={subCategories}
                  displayValue="value"
                  placeholder="Sub-Interests"
                />
                {/* <div
                  style={
                    isDynamicRoute
                      ? {}
                      : {
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }
                  }
                >
                  <div
                    className="profile__category"
                    style={{ position: "relative", cursor: "pointer" }}
                  >
                    <p
                      style={{ cursor: "pointer", marginTop: "0.5rem" }}
                      onClick={() => {
                        !isCategoryDropdown
                          ? setCategoryDropdown(true)
                          : setCategoryDropdown(false);
                      }}
                    >
                      {!isLoading ? (
                        <>
                          {category}
                          <img
                            src={"./tests/icons/arrowDB.svg"}
                            style={
                              isDynamicRoute
                                ? { display: "none" }
                                : { width: "1rem", marginLeft: "1rem" }
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <Skeleton.Input
                          className="profile__category__skeleton"
                          active={true}
                        />
                      )}{" "}
                    </p>
                    <ul
                      className="profile__category__ddl"
                      style={
                        isCategoryDropdown && !isDynamicRoute
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {completeCategoryList?.map((category: any) => {
                        return (
                          <li
                            key={category?._id}
                            // onClick={() => {
                            //   setCategory(category?.value);
                            //   setCategoryId(category?._id);
                            //   // updateCategory(category?.value);
                            //   setCategoryDropdown(false);
                            //   setSelectedSubCategory([]);
                            // }}
                          >
                            "artttt"
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {isDynamicRoute ? (
                    <SubCat></SubCat>
                  ) : !isLoading ? (
                    <Multiselect
                      options={completeSubCategoryList}
                      onSelect={(e: any) => {
                        // saveSelectedSubCategory(e);
                      }}
                      onRemove={(e: any) => {
                        // saveSelectedSubCategory(e);
                      }}
                      selectedValues={selectedSubCategory}
                      displayValue="value"
                      placeholder="Select Sub Categories"
                      className="multiselect__profile"
                    />
                  ) : (
                    <Skeleton.Input active={true} />
                  )}
                </div> */}
              </div>
              {/* accrordng friend status fetch data */}
              <div
                className="profile__bio__right profile__add__friend"
                style={
                  isDynamicRoute ? { display: "block" } : { display: "none" }
                }
                onClick={sendFriendRequest}
              >
                <div
                  className="flex items-center"
                  style={{ cursor: "pointer" }}
                >
                  <b
                    style={{
                      color: "var(--orange)",
                      textAlign: "center",
                      display: "inline-block",
                      marginRight: "1rem",
                    }}
                  >
                    {isSentSuccess ||
                    isFriend === true ||
                    isFriend === "requested" ? (
                      <>
                        <img
                          src="../tests/icons/check.svg"
                          style={{ width: "1.5rem" }}
                          alt=""
                        />
                        <p>{isFriend === "requested" ? "requested" : ""}</p>
                      </>
                    ) : (
                      "+"
                    )}
                  </b>
                  <img src="../tests/person-border.svg" alt="" />
                </div>
              </div>
            </div>
            {/* bio header end here*/}

            {/* bio description goes here */}
            <div className="profile__bio__description">
              {!isLoading ? (
                <textarea
                  className="profile__bio__description__textArea"
                  style={{ width: "100%", height: "7rem", resize: "none" }}
                  value={userDataUserId?.userBio}
                  onChange={(e: any) => {
                    setBio(e.target.value);
                  }}
                  disabled={isDynamicRoute}
                />
              ) : (
                <Skeleton active={true} className="profile__bio__skeleton" />
              )}
            </div>
            {/* bio description end here */}

            {/* tempary coming soon section */}
            <div className={"profile__coming__soon"}>
              <p>Coming Soon</p>
            </div>
          </div>
          {/* user bio section end here */}
        </div>
        {/* user card right end here */}
      </div>
      {/* notificationDropDown */}
    </>
  );
};

export default UserCardDynamic;
