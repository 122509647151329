import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { toggleRewardDeleteDialog } from "../../../../../app/slices/singleStates";
import { deleteRewards } from "../../../../../Functions/MediaUploaderF";

const DeleteConfirmDialog = ({bountyId}:any) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const isRewardDeleteDialog = useSelector(
    (state: any) => state.booleanStatus.isRewardDeleteDialog
  );
  const rewardIdForDelete = useSelector(
    (state: any) => state.booleanStatus.rewardIdForDelete
  );
  const rewardMainIdForDelete = useSelector(
    (state: any) => state.booleanStatus.rewardMainIdForDelete
  );
  const setForRewardDelete = useSelector(
    (state: any) => state.booleanStatus.setForRewardDelete
  );

  return (
    <>
      {/* mediaUploader Dialog Box goes here */}
      <div
        className="flex"
        style={
          isRewardDeleteDialog
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button
                onClick={() => {
                  dispatch(toggleRewardDeleteDialog(false));
                }}
              >
                x
              </button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
              {loading
                ? "deleting..."
                : "Are you sure you want to delete this item?"}
            </p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={() => {
                    deleteRewards(
                      rewardIdForDelete,
                      rewardMainIdForDelete,
                      setForRewardDelete,
                      dispatch,
                      setLoading,
                      bountyId
                    );
                  }}
                  disabled={loading}
                >
                  Delete
                </button>
                <button
                  className="delete__asset_confirm delete__asset_confirm_cancle"
                  onClick={() => {
                    dispatch(toggleRewardDeleteDialog(false));
                  }}
                >
                  Cancel
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirmDialog;
