import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import DateFilter from "./DateFilter";
import { Tooltip } from "antd";
import TabCardActivity from "./TabCard";

const ProfileTabs = ({ isDynamicRoute }: any) => {
  const [tabnumber, setTabnumber] = useState<number>(0);
  const toggleD = useSelector((state: RootState) => {
    return state.booleanStatus.toggleD;
  });
  return (
    <div className="profile__tab__section">
      {/* tab header goes here */}
      <header
        className={
          toggleD
            ? "dashboard__top dashboard__top_nc"
            : "dashboard__top dashboard__top_wc"
        }
        style={{
          width: "100%",
          borderRadius: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <section
          className={
            toggleD
              ? "dashboard__top__tab dashboard__top__tab_nc"
              : "dashboard__top__tab dashboard__top__tab_wc"
          }
          style={{
            width: "fit-content",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 6px",
            borderRadius: "0.5rem",
          }}
        >
          <button
            className="dash__tab  dash__tab__left guest__header__button guest__header__button__left"
            id={tabnumber === 0 ? "guest__header__Act" : ""}
            onClick={() => {
              setTabnumber(0);
            }}
          >
            Activity
          </button>
          <button
            className="dash__tab guest__header__button"
            id={tabnumber === 1 ? "guest__header__Act" : ""}
            onClick={() => {
              setTabnumber(1);
            }}
            disabled={true}
          >
            Engagement
          </button>
          <Tooltip title={"coming soon"}>
            <button
              className="dash__tab guest__header__button"
              id={tabnumber === 2 ? "guest__header__Act" : ""}
              onClick={() => {
                setTabnumber(2);
              }}
              disabled={true}
            >
              Badges
            </button>
          </Tooltip>
          <Tooltip title={"coming soon"}>
            <button
              className="dash__tab guest__header__button"
              id={tabnumber === 3 ? "guest__header__Act" : ""}
              onClick={() => {
                setTabnumber(3);
              }}
              disabled={true}
            >
              Streaks
            </button>
          </Tooltip>
          <Tooltip title={"coming soon"}>
            <button
              className="dash__tab dash__tab__right guest__header__button guest__header__button__right"
              id={tabnumber === 4 ? "guest__header__Act" : ""}
              onClick={() => {
                setTabnumber(4);
              }}
              disabled={true}
            >
              Brands
            </button>
          </Tooltip>
        </section>
        <DateFilter />
      </header>
      {/* tab header end here */}

      {/* tab body goes here */}
      {/* <section className="profile__tab__body"> */}
      {tabnumber === 0 && <TabCardActivity isDynamicRoute={isDynamicRoute} />}
        {/* <TabCard isDynamicRoute={isDynamicRoute} />
        <TabCard isDynamicRoute={isDynamicRoute} />
        <TabCard isDynamicRoute={isDynamicRoute} />
        <TabCard isDynamicRoute={isDynamicRoute} />
        <TabCard isDynamicRoute={isDynamicRoute} /> */}
        {/* <p>Coming Soon</p> */}
      {/* </section> */}
      {/* tab body end here */}
    </div>
  );
};

export default ProfileTabs;
