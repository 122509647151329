import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Initialize states
const initialState: any = {
  ads: [],
  sponsers: [],
  charities: [],
  bounties: [],
};

// Create a slice for managing pins data
export const pinsData = createSlice({
  name: "pinsData",
  initialState,
  reducers: {
    includeAdvertises: (state: any, action: PayloadAction<any>) => {
      state.ads = action.payload;
    },
    includeSponser: (state: any, action: PayloadAction<any>) => {
      state.sponsers = action.payload;
    },
    includeCharities: (state: any, action: PayloadAction<any>) => {
      state.charities = action.payload;
    },
    includeBounties: (state: any, action: PayloadAction<any>) => {
      state.bounties = action.payload;
    },
  },
});

// Export action creators
export const {
  includeAdvertises,
  includeSponser,
  includeCharities,
  includeBounties,
} = pinsData.actions;

// Export reducer
export default pinsData.reducer;
