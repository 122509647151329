import { useDispatch, useSelector } from "react-redux";
import FMForm from "./FMForm";
import FileDropper from "./FileDropper";
import FileProgress from "./FileProgress";
import ImageCard from "./ImageCard";
import "./filemanager.css";
import { RootState } from "../../app/store";
import { addcurrentFocusCardNumber } from "../../app/slices/mediaUploaderSignles";
import {
  addFilesData,
  clearMediaUploaderData,
  resetNewElement,
} from "../../app/slices/mediaUploaderData";
import axios from "axios";
import { mediaUpload } from "../../apis/postApis";
import { useEffect, useState } from "react";
import { fetchMediaData } from "../../apis/getApis";
import MediaWrapper from "../MediaWrapper/MediaWrapper";
import { addCurrentMediaTab } from "../../app/slices/singleStates";
import { deleteAssets } from "../../apis/delete";
import DeleteConfirmation from "./DeleteConfirmation";
import { updateTotalSize } from "../../app/slices/filesProgressData";
import { Alert, CircularProgress } from "@mui/material";
import AlertPopup from "./AlertPopup";
import { editMediaData, updateMediaSequence } from "../../apis/putApis";

const FileManager = ({ selectedId }: any) => {
  // redux config
  const fileDataFromRedux: any = useSelector((state: RootState) => {
    return state.mediaUploaderData;
  });
  console.log("fileDataFromRedux", fileDataFromRedux);
  const totalUploadedFilesFromRedux: any = useSelector((state: RootState) => {
    return state.booleanStatus.totalUploadedFiles;
  });
  console.log("mediaUploaderData-", fileDataFromRedux);
  const isMediaDialog: any = useSelector((state: RootState) => {
    return state.booleanStatus.isMediaDialog;
  });
  const nowTab = useSelector(
    (state: any) => state.booleanStatus.currentMediaTab
  );
  const isCurrentFocusCardNumber: any = useSelector((state: RootState) => {
    return state.mediaUploaderSignles.currentFocusCard;
  });
  const dispatch = useDispatch<any>();
  // local states
  const [filesDone, setFilesDone] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [currentSelect, setCurrentSelect] = useState<any>("./tests/empty.svg");
  const [currentSelectType, setCurrentSelectType] = useState("image");
  const [isDisble, setIsDisable] = useState(false);
  const [isAlert, setisAlert] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [isBlobPost, setIsBlobPost] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [update, setUpdate] = useState<any>([]);
  const currentMediaTab: any = useSelector(
    (state: any) => state.booleanStatus.currentMediaTab
  );
  const mediaUploaderData = useSelector(
    (state: RootState) => state.mediaUploaderData
  );
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });

  // useEffects of given components
  useEffect(() => {
    if (isMediaDialog) {
      dispatch(clearMediaUploaderData([]));
      axios
        .get(`${fetchMediaData}/${selectedId}?type=undefined`)
        .then((res: any) => {
          res.data.data.forEach((element: any, index: any) => {
            dispatch(
              addFilesData({
                index: fileDataFromRedux.length + index,
                url: element.imageUrl,
                type: element.imageUrl.split("/")[5],
                title: element.title,
                description: element.description,
                position: { x: 0, y: 0 },
                mediaId: element.mediaId,
                id: element.id,
              })
            );
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [isMediaDialog]);
  // useEffect(() => {
  //   let imd__: any = document.getElementById("imd__0");
  //   if (imd__) {
  //     imd__.click();
  //   }
  // }, [fileDataFromRedux]);

  useEffect(() => {
    if (fileDataFromRedux.length === 0) {
      setCurrentSelect("./tests/empty.svg");
    }
  }, [fileDataFromRedux]);

  useEffect(() => {
    if (currentMediaTab === 0 && isMediaDialog) {
      dispatch(clearMediaUploaderData([]));
      axios
        .get(`${fetchMediaData}/${selectedId}?type=undefined`)
        .then((res: any) => {
          console.log("_Medias", res);
          res.data.data.forEach((element: any, index: any) => {
            dispatch(
              addFilesData({
                index: fileDataFromRedux.length + index,
                url: element.imageUrl,
                type: element.imageUrl.split("/")[5],
                title: element.title,
                description: element.description,
                position: { x: 0, y: 0 },
                mediaId: element.mediaId,
                id: element.id,
              })
            );
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [currentMediaTab]);

  // when user focus on card
  const focusOnCard = (id: string, dataIndex: number, url: any, type: any) => {
    dispatch(addcurrentFocusCardNumber(dataIndex));
    setCurrentSelect(url);
    setCurrentSelectType(type);
    const card = document.getElementById(id);
    const cards: any = document.querySelectorAll(".img__MEdiaUPloder");
    if (cards) {
      cards.forEach((crd: any) => {
        crd.style.boxShadow = "none";
        crd.style.scale = "1";
      });
    }

    if (card) {
      card.style.scale = "0.9";
      card.style.boxShadow =
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset";
    }
  };

  // swap elements position to each other code goes here
  const handleDragStart = (e: any, index: any) => {
    e.dataTransfer.setData("index", index.toString());
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };
  const handleDrop = (e: any, targetIndex: any) => {
    e.preventDefault();
    console.log(e, targetIndex, "targetIndex");
    const sourceIndex = parseInt(e.dataTransfer.getData("index"));
    console.log(sourceIndex, "sourceIndex targetIndex");
    const updatedElements = [...fileDataFromRedux];
    console.log(updatedElements, "updatedElements targetIndex");

    // Swap positions and IDs of the dragged element and the drop target
    const temp = { ...updatedElements[sourceIndex] };
    console.log(temp, "temp targetIndex");
    updatedElements[sourceIndex] = {
      ...updatedElements[targetIndex],
    };
    updatedElements[targetIndex] = {
      ...temp,
    };
    setUpdate(updatedElements);
    dispatch(resetNewElement(updatedElements));
    console.log(fileDataFromRedux, isModify)
  };




  // upload media files...
  const uploadMediaFiles = async (e: any) => {
    setIsDisable(true);
    setFilesDone(0);
    setUploadProgress(0);
    let counter = 0;
    let totalUploadedSize = 0;

    let totalSize: number = 0;
    fileDataFromRedux.map((file: any) => {
      if (file.url.split(":")[0] === "blob") {
        totalSize = totalSize + parseFloat(file.size);
      }
    });
    dispatch(
      updateTotalSize({
        totalSizeBytes: totalSize.toFixed(2),
      })
    );
    let milestone = 0;
    if (fileDataFromRedux.length > 25) {
      setIsDisable(false);
      return;
    }
    for (let ind = 0; ind < fileDataFromRedux.length; ind++) {
      const elm = fileDataFromRedux[ind];
      const url = elm?.url;
      if (url.split(":")[0] !== "blob") {
        continue;
      }
      setIsBlobPost(true);
      counter++;

      try {
        const response = await fetch(url);

        const blob = await response.blob();

        const reconvertedFile = new File(
          [blob],
          `file${ind}.${elm.type.split("/")[1]}`,
          {
            type: elm.type,
          }
        );

        console.log("reconvertedFile1", reconvertedFile, response, url);

        const formData = new FormData();
        formData.append("media", reconvertedFile);
        formData.append("title", elm.title);
        formData.append("description", elm.description);

        console.log(`FormData${ind}`, formData);

        await axios
          .post(`${mediaUpload}?bountiID=${selectedId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent: any) => {
              console.log("progressEvent", progressEvent);
              const uploadedKb: any = progressEvent.loaded / 1000;
              totalSize = progressEvent.total;
              totalUploadedSize = parseFloat(uploadedKb);
              setUploadedSize((milestone + totalUploadedSize) / 1024);
            },
          })
          .then(() => {
            setAlertMessage("Assets Save Successfully");
          })
          .catch((err: any) => {
            if (err.message.trim() == "Network Error") {
              setAlertMessage("please check your internet connection");
            }
          });
        console.log(
          "file" + ind + "-" + elm.size,
          "    milestone->",
          milestone
        );
        milestone = milestone + elm.size / 1000;

        const percentCompleted =
          (counter /
            fileDataFromRedux.filter((item: any) => item.url.startsWith("blob"))
              .length) *
          100;
        setUploadProgress(percentCompleted);

        formData.forEach((value, key) => {
          formData.delete(key);
        });

        setFilesDone((prevFilesDone) => prevFilesDone + 1);
      } catch (error: any) {
        console.error("Error fetching or uploading file data:", error);
      }
    }

    const sendMediaIds = fileDataFromRedux.map((filess:any) => {
      return filess.id
    })

    await axios
        .put(`${updateMediaSequence}${selectedId}`, {media: sendMediaIds})
        .then(() => {
          setAlertMessage("Sequence Saved Successfully");
        })
        .catch((err: any) => {
          if (err.message.trim() == "Network Error") {
            setAlertMessage("please check your internet connection");
          }
        });

    dispatch(clearMediaUploaderData([]));
    axios
      .get(`${fetchMediaData}/${selectedId}?type=undefined`)
      .then((res: any) => {
        res.data.data.forEach((element: any, index: any) => {
          dispatch(
            addFilesData({
              index: fileDataFromRedux.length + index,
              url: element.imageUrl,
              type: element.imageUrl.split("/")[5],
              title: element.title,
              description: element.description,
              position: { x: 0, y: 0 },
              mediaId: element.mediaId,
              id: element.id,
            })
          );
        });
      })
      .catch((err: any) => {
        console.log(err);
      });

    setisAlert(true);
    setTimeout(() => {
      setisAlert(false);
    }, 2300);

    setIsDisable(false);
  };

  // edit asset
  const editAsset = async () => {
    fileDataFromRedux.map((fileData: any) => {
      if(fileData.edited) {
        const { id, title, description } = fileData;
        axios
            .put(`${editMediaData}/${selectedId}/${id}`, {
              title: title,
              description: description,
            })
            .then((res: any) => {
              setAlertMessage("Assets Save Successfully");
              setisAlert(true);
              setTimeout(() => {
                setisAlert(false);
              }, 2300);
            })
            .catch((err: any) => {});
      }
    })

  };

  return (
    <section
      className="FM__Relative__PArent"
      style={nowTab === 1 ? { display: "none" } : { display: "block" }}
    >
      {/* cofirm delete popover */}
      <DeleteConfirmation bountyId={selectedId} />
      <AlertPopup isAlert={isAlert} message={alertMessage} close={setisAlert} />
      <section className="FM__wrapper bg-pure radius-1">
        <div className="FM__left__main FM__60">
          <FileDropper />
          <FileProgress
            uploadedSize={uploadedSize.toFixed(2)}
            totalFiles={
              fileDataFromRedux.filter((item: any) =>
                item.url.startsWith("blob")
              ).length
            }
            filesDone={filesDone}
            uploadProgress={uploadProgress}
            isDisble={isDisble}
          />
          <div className="FM__IM_CARD_WRPPER">
            {fileDataFromRedux?.map((data: any, ind: any) => {
              return (
                <div
                  onClick={() =>
                    focusOnCard(
                      `imd__${ind}`,
                      ind,
                      data.url,
                      data.type.split("/")[0]
                    )
                  }
                  id={`imd__${ind}`}
                  style={{ height: "fit-content" }}
                  className="img__MEdiaUPloder"
                  draggable
                  onDragStart={(e) => handleDragStart(e, ind)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e, ind)}
                >
                  <ImageCard
                    bountyId={selectedId}
                    setCurrentSelect={setCurrentSelect}
                    assetId={data.id}
                    mainId={data.mediaId}
                    url={data.url}
                    mediaType={data.type.split("/")[0]}
                    ind={ind + 1}
                    text={ind === 0 ? "Cover" : ind <= 3 ? "Preview" : ""}
                  />
                </div>
              );
            })}
          </div>
          <div className="FM__LEFT__info">
            Drag and Drop to rearrange the order. The item in slot 1 will be the
            main cover. The items in slot 2 to 4 will show as previews under the
            cover
          </div>
        </div>

        <div className="FM__right__main FM__36">
          <div className="FM__TAB">
            <button className="FM__act__TAB TAB_BtN TAB_Left">
              Current Bounty
            </button>
            <button
              className="TAB_BtN TAB_Right"
              onClick={() => {
                dispatch(addCurrentMediaTab(1));
              }}
              disabled={isLock}
              style={isLock ? { cursor: "not-allowed" } : {}}
            >
              Stored Assets
            </button>
          </div>
          <div className="FM_SELcted_MEDIA_Div">
            <MediaWrapper
              type={currentSelectType}
              src={currentSelect}
              width={300}
              height={228}
              styles={{ borderRadius: "1rem" }}
            />
          </div>
          <FMForm setIsModify={setIsModify} />
        </div>

        <button
          className="FM__BUTTON"
          onClick={(e: any) => {
            if (isModify) {
              editAsset();
              uploadMediaFiles(e);
            } else {
              uploadMediaFiles(e);
            }
          }}
          style={isDisble || isLock ? { cursor: "not-allowed" } : {}}
          disabled={isDisble || isLock}
        >
          {isDisble ? (
            <CircularProgress size={30} style={{ color: "var(--pure)" }} />
          ) : (
            "Save"
          )}
        </button>
      </section>
    </section>
  );
};

export default FileManager;
