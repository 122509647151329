import React, { useEffect, useState } from "react";
import AvtarGroup from "../AvtarGroup/AvtarGroup";
import "./bountycard.css";
import {
  allAsset2,
  getBountyPins,
  getHashTagsList,
  globalBounti2,
  rewardData2,
} from "../../../../apis/getApis";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../../../../apis/header";
import { pinBounty } from "../../../../apis/postApis";
import { includeBounties } from "../../../../app/slices/pinsData";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvertisements,
  getUserInteractionGlob,
} from "../../../../apis/getApis";
import { U } from "../../../../apis/main";
import { getAdDialog } from "../../../../app/slices/singleStates";
import { RootState } from "../../../../app/store";
import AdDialog from "./adDialog";
import { Popconfirm } from "antd";
import { deleteBounty } from "../../../../apis/delete";
const BountyCard = ({
  bountyId,
  style,
  isLogin,
  redirectToThisBounty,
  bountyCreatePageRedirect,
  sponserId,
  charityId,
  setLists,
}: any) => {
  const [boutiesData, setBountiesData] = useState<any>({});
  const [currentPhase, setCurrentPhase] = useState<string>("");
  const [medias, setMedias] = useState<any[]>([]);
  const [rewards, setRewards] = useState<any[]>([]);
  const [tags, setTags] = useState<any>([]);
  const [adVideos, setAdVideos] = useState<string[]>([]);
  const [isBountyAdPopup, setIsBountyAdPopup] = useState<any>(false);
  const [visible, setVisible] = useState(false);
  const [advertisementData, setAdvertisementData] = useState<any[]>([]); // State to hold advertisement data
  const [currentIndex, setCurrentIndex] = useState(0);
  const [globalInteractionData, setGlobalInteractionData] = useState<any>([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  let { userId } = useParams();

  // useEffect(() => {
  //   if (visible && adVideos.length === 0) {
  //     fetchAdvertisementData();
  //   }
  // }, [visible]);

  // Bounty Delete Accept
  const handleBountyDeleteAccept = async () => {
    console.log("handleBountyDeleteAccept_called");
    try {
      // Make DELETE request to delete the bounty
      await axios.delete(`${deleteBounty}/${bountyId}`);
      setLists((prevLists:any) =>
        prevLists.filter((bounty:any) => bounty._id !== bountyId)
      );
      // Handle any additional logic after deletion if needed
      // For example, redirecting the user or updating the UI
      console.log("Bounty deleted successfully");
      setAlertVisible(false);
    } catch (error) {
      console.error("Error deleting bounty successfully:", error);
    }
  };

  // Bounty Delete Decline
  const handleBountyDeleteCancel = () => {
    // Close the alert
    setAlertVisible(false);
  };

  // Interaction User Data get API
  const fetchGlobInteractionList = async () => {
    await axios
      .get(`${getUserInteractionGlob}/${bountyId}`)
      .then((res) => {
        setGlobalInteractionData(res.data.interactionUsers);
        console.log(res.data.interactionUsers, "setGlobalInteractionData");
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchGlobInteractionList();
  }, []);

  // const handleImageClick = () => {
  //   dispatch(getAdDialog(true)); // Dispatch action to open ad dialog
  //   setVisible(true); // Set visible state to true
  //   if (visible && adVideos.length === 0) {
  //     fetchAdvertisementData();
  //   }
  // };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? adVideos.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === adVideos.length - 1 ? 0 : prevIndex + 1
    );
  };

  // All Publish Bounty Data Fetch API
  const globalBountyData = async () => {
    try {
      const [
        globalBountyResponse,
        hashTagsResponse,
        allAssetResponse,
        rewardDataResponse,
      ] = await Promise.all([
        axios.get(`${globalBounti2}/${bountyId}`, Header),
        axios.get(`${getHashTagsList}/${bountyId}`),
        axios.get(`${allAsset2}/${bountyId}?type=undefined`),
        axios.get(`${rewardData2}/${bountyId}`),
      ]);
      setBountiesData(globalBountyResponse.data.text);
      setCurrentPhase(globalBountyResponse.data.currentPhase);
      setTags(hashTagsResponse.data.hashtag);
      setMedias(allAssetResponse.data.data);
      setRewards(rewardDataResponse.data.rewards);
    } catch (error) {
      console.error("Error fetching global bounty data:", error);
    }
  };

  useEffect(() => {
    globalBountyData();
  }, []);
  const idAdDialog = useSelector(
    (state: RootState) => state.booleanStatus.idAdDialog
  );

  //Function call when click on  My world Page Bounty's Pin Icon
  const pinThis = async () => {
    try {
      const putResponse = await axios.post(`${pinBounty}/${bountyId}`, {
        title: boutiesData?.title,
      });
      const bountyPins = await axios.get(getBountyPins);
      console.log(putResponse, "putResponse");
      dispatch(includeBounties(bountyPins.data.pinnedBounti));
    } catch (error) {
      console.error("Error pinning bounty:", error);
    }
  };
  console.log("sponserId", sponserId);
  return (
    <>
      <section className="home__bountycard" style={style}>
        {/* On bounty Card Button shows Edit Bounty or View Bounty */}
        <button
          className="relavancy_button"
          onClick={() => {
            sponserId
              ? bountyCreatePageRedirect(bountyId, sponserId, charityId)
              : redirectToThisBounty(bountyId);
          }}
          style={{ cursor: "pointer" }}
        >
          {/* Edit Bounty button shows at manage a bounty page */}
          {/* View Bounty button shows at Bounty View and my world page */}
          {sponserId ? "Edit Bounty" : "View Bounty"}{" "}
        </button>

        {/* When Click on Delete Bounty Button confirmation popup */}
        <Popconfirm
          title="Delete a Bounty"
          description="Are you sure you want to delete this bounty?"
          visible={alertVisible} // Set the visibility state
          onConfirm={handleBountyDeleteAccept} // Handle confirmation
          onCancel={handleBountyDeleteCancel} // Handle cancellation
          okText="Yes"
          cancelText="No"
        >
          {sponserId && (
            <button
              className="delete_relavancy_button"
              style={{ cursor: "pointer" }}
              onClick={() => setAlertVisible(true)} // Open Popconfirm on click
            >
              Delete Bounty
            </button>
          )}
        </Popconfirm>

        <section className="home__bounty__header flex justify-between">
          <div className="home__bounty__header_avtars">
            {/* Interacted User shows using Avtar Gorup */}
            <AvtarGroup
              bountyId={bountyId}
              globalInteractionData={globalInteractionData}
            />
          </div>
          <div>
            {/* Pin Icon on Bounty Card top Right side */}
            <button
              className="homePinButton"
              onClick={pinThis}
              style={{ top: "8%", left: "91%" }}
            >
              {isLogin ? <img src={"./tests/icons/pin.svg"} alt="" /> : <></>}
            </button>
          </div>
        </section>
        <section className="home__bounty__main">
          {/* Bounty Title */}
          <h1 className="home__bounty__main_PN">{boutiesData.title}</h1>
          <div className="home__bounty__main__hash">
            {/* Bounty Tags */}
            {tags &&
              tags.slice(0, 2).map((tag: string, index: number) => (
                <span key={index}>
                  {tag}
                  {index < tags.length - 1 && ","}
                </span>
              ))}
            {tags && tags.length > 3 && <span>...</span>}
          </div>
          {/* Bounty current phase */}
          <div style={{ marginTop: "0.5rem", fontWeight: "bold" }}>
            {currentPhase} phase
          </div>
          {/* Bounty Description */}
          <div
            className="home__bounty__main__description"
            style={{ height: "1.8rem", overflow: "auto" }}
          >
            {boutiesData.description?.slice(0, 30)}...
          </div>
          {/* Bounty Uploaded media 1st Image set as a cover Image */}
          <div
            className="home__bounty__main__banner"
            // onClick={() => {
            //   setIsBountyAdPopup(true);
            // }}
          >
            {medias[0]?.imageUrl.split("/")[5] === "video" ? (
              <video controls className="home__bounty__main__banner">
                <source src={medias[0]?.imageUrl}></source>
              </video>
            ) : (
              <img
                className="home__bounty__main__banner"
                src={
                  medias[0]?.imageUrl
                    ? medias[0]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                alt=""
              />
            )}
          </div>
        </section>
        <section className="home__bounty__reward">
          {/* Bounty Reward shows */}
          <h1 className="home__bounty__main_PN">Reward</h1>
          {/* If more than 3 reward then shows 1st 3 rewards */}
          {rewards.slice(0, 3).map((reward, index) => (
            <section key={index} className="homeBountyCard__reward">
              <div className="homeBountyCard__rewardBlue">
                <img
                  src={reward.imagePath}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.3rem",
                  }}
                  alt={`Reward ${index + 1}`}
                />
              </div>
              {/* reward title */}
              <div className="homeBountyCard__rewardName">{reward.title}</div>
            </section>
          ))}
          {rewards.length > 3 && (
            <div className="homeBountyCard__rewardName">
              More rewards inside...
            </div>
          )}
        </section>
      </section>

      {/* <div
        className="flex"
        style={
          isBountyAdPopup
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        <AdDialog
          open={isBountyAdPopup}
          setIsBountyAdPopup={setIsBountyAdPopup}
          bountyId={bountyId}
        />
      </div> */}
    </>
  );
};

export default BountyCard;
