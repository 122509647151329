import styles from "./given.module.css";
import Badge from "@mui/material/Badge";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  USTCard,
  RewardCarousel,
  CharityCarousel,
  SponserCarousel,
} from "./index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  bookmarkCurrentBountiNow,
  likeCurrentBountiNow,
} from "../../../../Functions/bountiPreviewF";
import AvtarGroups from "./AvtarGroups";
import {
  CharityDetailCard,
  ErrorC,
  LoadingC,
  SponserDetailCard,
} from "../../../../components";
import axios from "axios";
import Header from "../../../../apis/header";
import {
  allAsset2,
  bountiCategory2,
  bountiCharity,
  bountiSponsor,
  getHashTagsList,
  globalBounti2,
  isCurrentFav,
  isCurrentLike,
  rewardData2,
  totalBountiLikes,
  getAdditionalData,
  totalBountiMarks,
  getUserProfile,
  allMimeTypeListUrl,
  profileWithUID,
} from "../../../../apis/getApis";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setSubmissionScreen,
  toggleMediaViewer,
  toggleSubmissionScreen,
  setcurrentSubmission,
} from "../../../../app/slices/singleStates";
import { addCurrentMediaView } from "../../../../app/slices/mediaViewerData";
import { Alert } from "antd";
import NotificationDropDown from "./NotificationDropDown";
import { getUserInteractionGlob } from "../../../../apis/getApis";

const BountyDetailPage = () => {
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  const submissionDataFromRedux = useSelector(
    (state: RootState) => state.bountiSubmissionData
  );
  const location = useLocation();
  const reduxBountyId = location.state;
  const [bountyData, setBountyData] = useState<any>();
  const [rewardData, setRewardData] = useState<any>();
  const [categoryData, setCategoryData] = useState<any>();
  const [assetData, setAssetData] = useState<any>();
  const [totalBoutiLikess, setTotalBountyLikes] = useState<any>();
  // const [totalBoutiFavs, setTotalBountyFavs] = useState<any>();
  const [sponserData, setSponserData] = useState<any>();
  const [charityData, setCharityData] = useState<any>();
  const [currentFav, setCurrentFav] = useState<any>();
  const [currentLike, setcurrentLike] = useState<any>();
  const [textData, setTextData] = useState<any>("");
  const [isBountyNotiPop, setIsBountyNotiPop] = useState<any>(false);
  const [userName, setUserName] = useState<any>("");
  const [userImage, setUserImage] = useState<any>("");
  const [tags, setTags] = useState<any>();
  const [mimeType, setMimeType] = useState<any>([]);
  const [globalInteractionData, setGlobalInteractionData] = useState<any>([]);
  const [additionalDetail, setAdditionalDetail] = useState<any>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // fetch function
  const fetchData: any = async () => {
    // fetch bounty data
    axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((response: any) => {
        console.log("goodboy", response.data);
        setBountyData(response.data);
        setTextData(response.data.text.userID);

        // Fetch user profile after setting textData
        axios
          .get(`${profileWithUID}/${response.data.text.userID}`, Header)
          .then((userProfileResponse: any) => {
            // console.log("userImage1", userProfileResponse.data.userName);
            setUserName(userProfileResponse.data.userName);
            setUserImage(userProfileResponse.data.userImage);
            // Handle user profile response here
          })
          .catch((userProfileErr: any) => {
            console.error("Error fetching user profile:", userProfileErr);
          });
      })
      .catch((err: any) => {
        console.error("Error fetching bounty data:", err);
      });

    // fetch reward data
    axios
      .get(`${rewardData2}/${reduxBountyId}`)
      .then((response: any) => {
        setRewardData(response.data);
      })
      .catch((err: any) => {});
    // categoty fetch
    axios
      .get(`${bountiCategory2}/${reduxBountyId}`)
      .then((response: any) => {
        setCategoryData(response.data);
      })
      .catch((err: any) => {});
    // all assets fetch
    axios
      .get(`${allAsset2}/${reduxBountyId}?type=undefined`)
      .then((response: any) => {
        setAssetData(response.data);
      })
      .catch((err: any) => {});
    // total likes
    axios
      .get(`${totalBountiLikes}/${reduxBountyId}`)
      .then((response: any) => {
        setTotalBountyLikes(response.data);
      })
      .catch((err: any) => {});
    // axios.get(`${totalBountiMarks}/${reduxBountyId}`),
    // fetch sponsers
    axios
      .get(`${bountiSponsor}/${reduxBountyId}`)
      .then((response: any) => {
        setSponserData(response.data);
      })
      .catch((err: any) => {});
    // fetch charity
    axios
      .get(`${bountiCharity}/${reduxBountyId}`)
      .then((response: any) => {
        setCharityData(response.data);
      })
      .catch((err: any) => {});
    // iscurrent fav
    axios
      .get(`${isCurrentFav}/${reduxBountyId}`)
      .then((response: any) => {
        setCurrentFav(response.data);
      })
      .catch((err: any) => {});
    // iscurrent like
    axios
      .get(`${isCurrentLike}/${reduxBountyId}`)
      .then((response: any) => {
        setcurrentLike(response.data);
      })
      .catch((err: any) => {});

    // get hashtags list
    axios
      .get(`${getHashTagsList}/${reduxBountyId}`)
      .then((res: any) => {
        setTags(res.data.hashtag.join(" , "));
      })
      .catch((err: any) => {});

    // get additional  data
    axios
      .get(`${getAdditionalData}/${reduxBountyId}`)
      .then((res: any) => {
        setAdditionalDetail(res.data.additionalDetail);
      })
      .catch((err: any) => {});

    // get Mime type
    axios
      .get(`${allMimeTypeListUrl}/${reduxBountyId}`)
      .then((res: any) => {
        console.log(res, "allMimeTypeListUrl");
        setMimeType(res.data.mimeTypes);
      })
      .catch((err: any) => {});
  };

  // fetch interaction glob data
  const fetchGlobInteractionList = async () => {
    await axios
      .get(`${getUserInteractionGlob}/${reduxBountyId}`)
      .then((res) => {
        setGlobalInteractionData(res.data.interactionUsers);
        console.log(res.data.interactionUsers, "setGlobalInteractionData");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchData();
    fetchGlobInteractionList();
  }, []);

  const [sponsorHoveredIndex, setSponsorHoveredIndex] = useState<number | null>(
    null
  );
  const [charityHoveredIndex, setCharityHoveredIndex] = useState<number | null>(
    null
  );
  // Sponsor carousel hover
  const sponsorCarouselHover = (index: number | null) => {
    console.log("sponsorCarouselHover call");
    setSponsorHoveredIndex(index);
    console.log(sponsorHoveredIndex, "sponsorHoveredIndex");
  };

  // Charity carousel hover
  const charityCarouselHover = (index: number | null) => {
    console.log("charityCarouselHover call");
    setCharityHoveredIndex(index);
    console.log(charityHoveredIndex, "charityHoveredIndex");
  };
  // all states
  const [currentComponent, setcurrentComponent] = useState<any>({
    totalBoutiLikes: "",
    totalBoutiMarks: "",
    isMeLikeBouti: "",
    isMeMarkBouti: "",
    isLiking: false,
    isMarking: false,
  });

  useEffect(() => {
    setcurrentComponent({
      ...currentComponent,
      totalBoutiLikes: totalBoutiLikess?.totalLikes,
      isMeLikeBouti: currentLike?.likeStatus,
      isMeMarkBouti: currentFav?.favoriteStatus,
    });
  }, [totalBoutiLikess, currentLike, currentFav]);

  // add current selected media view function
  const addMediaView = (asset: any) => {
    dispatch(
      addCurrentMediaView({
        id: asset?.id,
        mediaId: asset?.mediaId,
        title: asset?.title,
        description: asset?.description,
        imageUrl: asset?.imageUrl,
      })
    );
  };

  // go to profile page
  const checkProfile = () => {
    navigate(`/profile/${bountyData?.text?.userID}`);
  };

  return (
    <div
      className={
        toggleD
          ? `${styles.main_wrapper_c1} main_wrapper_c1_responsive`
          : `${styles.main_wrapper_c1} main_wrapper_c1_responsive when_colapse_main_awrapper`
      }
    >
      {/* left */}
      <section
        className={
          toggleD
            ? `${styles.left_c1} left_c1_res`
            : `${styles.left_c1} left_c1_res left_c1_colapse`
        }
      >
        {/* branding */}
        <div
          className="flex items-center"
          onClick={checkProfile}
          style={{ cursor: "pointer" }}
        >
          <img src={`${userImage}`} className={`${styles.brand_c1}`} />
          <h1
            className={`${styles.brandName_c1} brandName_c1_res color-headingBlue`}
          >
            {userName}
          </h1>
        </div>

        {/* grid */}
        <div
          className={`${styles.grid_c1}`}
          onClick={() => {
            dispatch(toggleMediaViewer(true));
          }}
        >
          {/* main asset */}
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              borderRadius: "1rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor:"var(--sidebar_secondory)",
              height: "20em",
            }}
          >
            {assetData?.data[0]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video
                  className={`${styles.mainImg_c1} mainImg_c1_res`}
                  onClick={() => {
                    addMediaView(assetData?.data[0]);
                  }}
                  controls
                >
                  <source src={assetData?.data[0]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                className={`${styles.mainImg_c1} mainImg_c1_res`}
                onClick={() => {
                  addMediaView(assetData?.data[0]);
                }}
                src={
                  assetData?.data[0]?.imageUrl
                    ? assetData?.data[0]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                alt=""
              />
            )}
          </div>

          <div className={`${styles.three}`} style={{ cursor: "pointer" }}>
            {/* asset 2 goes here*/}
            {assetData?.data[1]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video
                  className={`${styles.img_c1} img_c1_res`}
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                    cursor: "pointer",
                  }}
                  controls
                  onClick={() => {
                    addMediaView(assetData?.data[1]);
                  }}
                >
                  <source src={assetData?.data[1]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                }}
                src={
                  assetData?.data[1]?.imageUrl
                    ? assetData?.data[1]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                onClick={() => {
                  addMediaView(assetData?.data[1]);
                }}
                className={`${styles.img_c1} img_c1_res`}
                alt=""
              />
            )}
            {/* asset 2 end here */}

            {/* asset 3 goes here */}
            {assetData?.data[2]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className={`${styles.img_c1} img_c1_res`}
                  controls
                  onClick={() => {
                    addMediaView(assetData?.data[2]);
                  }}
                >
                  <source src={assetData?.data[2]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                src={
                  assetData?.data[2]?.imageUrl
                    ? assetData?.data[2]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                onClick={() => {
                  addMediaView(assetData?.data[2]);
                }}
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                }}
                alt=""
                className={`${styles.img_c1} img_c1_res`}
              />
            )}
            {/* asset 3 end here */}

            {/* asset 4 goes here */}
            {assetData?.data[3]?.imageUrl.split("/")[5] == "video" ? (
              <>
                <video
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                  }}
                  className={`${styles.img_c1} img_c1_res`}
                  onClick={() => {
                    addMediaView(assetData?.data[3]);
                  }}
                  controls
                >
                  <source src={assetData?.data[3]?.imageUrl}></source>
                </video>
              </>
            ) : (
              <img
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                }}
                src={
                  assetData?.data[3]?.imageUrl
                    ? assetData?.data[3]?.imageUrl
                    : "../A9A9A9.png&text=No+Preview+Media"
                }
                onClick={() => {
                  addMediaView(assetData?.data[3]);
                }}
                className={`${styles.img_c1} img_c1_res`}
              />
            )}
            {/* asset 4 end here */}
          </div>
        </div>
      </section>

      {/* right */}
      <section
        className={
          toggleD
            ? `${styles.right_c1} right_c1_res`
            : `${styles.left_c1} right_c1_res right_c1_colapse`
        }
      >
        {/* right-top */}
        <div className={`${styles.topRight}`}>
          <div>
            <label className="responsive_label">Participants</label>

            <AvtarGroups
              bountyId={reduxBountyId}
              globalInteractionData={globalInteractionData}
            />
          </div>

          <section className={`${styles.sponserWraper_c1}  `}>
            {/* sponser */}
            <div
              className={`${styles.sponser_c1} relative bountypreviewSponser_c1`}
            >
              <label className="responsive_label">Sponsor</label>
              <div
                className={`${styles.sponserSlider_c1} bountypreviewSponserSlider_c1`}
              >
                <SponserCarousel
                  sponserData={sponserData?.data}
                  onHover={sponsorCarouselHover}
                />
              </div>
              <SponserDetailCard
                sponserData={sponserData?.data}
                sponsorHoveredIndex={sponsorHoveredIndex}
              />
            </div>

            {/* charity */}
            <div
              className={`${styles.charity_c1} relative bountypreviewCharity_c1`}
            >
              <label className="responsive_label">Charity</label>
              <div
                className={`${styles.charitySlider_c1} bountypreviewCharitySlider_c1`}
              >
                <CharityCarousel
                  boutiCharityData={charityData?.data}
                  onHover={charityCarouselHover}
                />
              </div>
              <CharityDetailCard
                boutiCharityData={charityData?.data}
                charityHoveredIndex={charityHoveredIndex}
              />
            </div>
          </section>
        </div>

        <main className={styles.main_c1}>
          {/* bounti title bar */}
          <div className={`${styles.bountiTitleBar_c1}`}>
            {/* left bounti title */}
            <div className={`${styles.bountiMeta_c1}`}>
              <h1
                className={`${styles.bountititle} bountititle_res color-headingBlue`}
              >
                {bountyData?.text.title}
              </h1>
              <div className={`${styles.tags_c1}`}>
                <p
                  className={`${styles.hashtag} ${styles.hashtagFirst} hashtag_res`}
                >
                  {tags}
                </p>
              </div>
            </div>

            {/* right like and options */}
            <div className="flex items-center">
              <Badge
                badgeContent={currentComponent?.totalBoutiLikes}
                className={`${styles.badge} m-half-right`}
              >
                {!currentComponent?.isMeLikeBouti ? (
                  <>
                    {!currentComponent.isLiking ? (
                      <button className="noBtn">
                        <FavoriteBorderIcon
                          onClick={() => {
                            likeCurrentBountiNow(
                              currentComponent,
                              setcurrentComponent,
                              reduxBountyId
                            );
                          }}
                          className={styles.icons_c1}
                        />
                      </button>
                    ) : (
                      <button className="noBtn" disabled>
                        <FavoriteBorderIcon
                          onClick={() => {
                            likeCurrentBountiNow(
                              currentComponent,
                              setcurrentComponent,
                              reduxBountyId
                            );
                          }}
                          className={styles.icons_c1}
                        />
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="noBtn"
                    disabled={currentComponent.isLiking}
                  >
                    <FavoriteIcon
                      onClick={() => {
                        likeCurrentBountiNow(
                          currentComponent,
                          setcurrentComponent,
                          reduxBountyId
                        );
                      }}
                      className={styles.icons_c1}
                    />
                  </button>
                )}
              </Badge>
              {currentComponent.isMeMarkBouti ? (
                <>
                  {!currentComponent.isMarking ? (
                    <button className="noBtn">
                      <BookmarkIcon
                        onClick={() => {
                          bookmarkCurrentBountiNow(
                            currentComponent,
                            setcurrentComponent,
                            reduxBountyId
                          );
                        }}
                        className={`${styles.icons_c1} `}
                      />
                    </button>
                  ) : (
                    <button className="noBtn" disabled>
                      <BookmarkIcon
                        onClick={() => {
                          bookmarkCurrentBountiNow(
                            currentComponent,
                            setcurrentComponent,
                            reduxBountyId
                          );
                        }}
                        className={`${styles.icons_c1} `}
                      />
                    </button>
                  )}
                </>
              ) : (
                <>
                  {!currentComponent.isMarking ? (
                    <button className="noBtn">
                      <BookmarkBorderIcon
                        onClick={() => {
                          bookmarkCurrentBountiNow(
                            currentComponent,
                            setcurrentComponent,
                            reduxBountyId
                          );
                        }}
                        className={`${styles.icons_c1} `}
                      />
                    </button>
                  ) : (
                    <button className="noBtn" disabled>
                      <BookmarkBorderIcon
                        onClick={() => {
                          bookmarkCurrentBountiNow(
                            currentComponent,
                            setcurrentComponent,
                            reduxBountyId
                          );
                        }}
                        className={`${styles.icons_c1} `}
                      />
                    </button>
                  )}
                </>
              )}

              <div className="bounti__notification">
                <NotificationsOutlinedIcon
                  onClick={() => {
                    !isBountyNotiPop
                      ? setIsBountyNotiPop(true)
                      : setIsBountyNotiPop(false);
                  }}
                  className={`${styles.icons_c1} `}
                />
                {isBountyNotiPop ? (
                  <NotificationDropDown
                    setIsBountyNotiPop={setIsBountyNotiPop}
                  />
                ) : (
                  <></>
                )}
              </div>
              <MoreVertOutlinedIcon className={`${styles.icons_c1} `} />
            </div>
          </div>

          {/* bounti description section */}
          <div className={`${styles.bountiDescription_c1}`}>
            <div className={`${styles.desc_c1}`}>
              <p className={`${styles.bText} btext_res`}>
                {bountyData?.text?.description}
              </p>
              <p
                className={`${styles.bText} btext_res`}
                style={{ marginTop: "1em" }}
              >
                <b> Additional Detail: </b>
                {additionalDetail}
                <br />
                <b> Formats accepted:</b>{" "}
                {mimeType.map((type: any, index: number) => (
                  <span key={type._id}>
                    {index > 0 && ", "}{" "}
                    {/* Add comma except for the first element */}
                    {type._id}
                  </span>
                ))}
              </p>
              {/* <p className={`${styles.bText} btext_res`}>
                Dimensions: 2000 by 2000 px
              </p> */}
            </div>

            <div className={`${styles.unlocks}`}>
              <USTCard>
                <label style={{ color: "var(--pure)" }}>Unlocks</label>
                <p>{bountyData?.text?.unlocks}/100</p>
              </USTCard>
              <USTCard styling={{ marginTop: "0.5em" }}>
                <label style={{ color: "var(--pure)" }}>Submissions</label>
                <p>{bountyData?.text?.totalSubmissions}/100</p>
              </USTCard>
              <USTCard styling={{ marginTop: "0.5em" }}>
                <label style={{ color: "var(--pure)" }}>
                  {bountyData?.currentPhase}
                </label>
                <p>{bountyData?.nextPhaseTimeLeft}</p>
              </USTCard>
            </div>
          </div>

          {/* Reward carousel */}
          <div className={`${styles.rewardWrapper_c1} reqwrdWarppers`}>
            <h1
              className={`${styles.bountititle} bountititle_res color-headingBlue`}
            >
              Reward
            </h1>
            <RewardCarousel
              bountyId={reduxBountyId}
              rewardData={rewardData?.rewards}
            />
          </div>

          {/* submit button */}
          <button
            className={styles.submit_btn}
            onClick={() => {
              dispatch(toggleSubmissionScreen(true));
              dispatch(setSubmissionScreen(1));
              dispatch(setcurrentSubmission(null));
            }}
          >
            Ready to Submit?
          </button>
        </main>
      </section>

      {/* alerts */}
      <Alert
        message={submissionDataFromRedux?.alertMessage}
        className={
          submissionDataFromRedux?.isAlert
            ? "global__alert_antd"
            : "global__alert_antd__hide"
        }
        type="success"
        showIcon
      />
    </div>
  );
};

export default BountyDetailPage;
