import { useSelector } from "react-redux";
import { Sidebar, Breadcrumb, CollapSidebar } from "..";
import styles from "./fluid.module.css";
import { RootState } from "../../app/store";

const FluidContainer = ({
  children,
  breadcrumb,
  breadcrumbS = 1,
  clearUi = false,
  parentStyles,
  fluiOverStyles = {},
  className,
  parentClass,
  dynamic,
  profileDetail,
}: any) => {
  // Redux state variables
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  const toggle = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggle
  );
  const isBreadcrumb = useSelector(
    (state: RootState) => state.booleanStatus.breadCrumb
  );
  return (
    <div
      className={`${styles.fluid} flex fluid-responsive`}
      style={fluiOverStyles}
    >
      {!clearUi ? (
        // Conditional rendering based on clearUi flag
        <>
          {/* Sidebar for mobile */}
          <div className="mobile-sidebar">
            {toggle ? (
              <Sidebar dynamic={dynamic} />
            ) : (
              <CollapSidebar dynamic={dynamic} />
            )}
          </div>
          {/* Sidebar for desktop */}
          <div className="desktop-sidebar">
            {toggleD ? (
              <Sidebar dynamic={dynamic} />
            ) : (
              <CollapSidebar dynamic={dynamic} />
            )}
          </div>
          {/* Main section */}
          <section
            style={breadcrumbS ? {} : { paddingTop: "0" }}
            className={
              toggleD
                ? `${styles.main} fluit_main_res desktop_noColl_then ${className} ${parentClass}` // Applying CSS classes based on toggleD
                : `${styles.main} fluit_main_res desktop_collapse_then ${className} ${parentClass}`
            }
          >
            <div className={styles.mainInner}>
              {/* Breadcrumb component */}
              {isBreadcrumb === "default" ? (
                <Breadcrumb value={breadcrumb} />
              ) : (
                <img src={breadcrumb ? breadcrumb[0] : ""} />
              )}
              {/* Main content */}
              <main className={styles.mainWrapper}>{children}</main>
            </div>
          </section>
        </>
      ) : (
        <div style={parentStyles}>{children}</div>
      )}
    </div>
  );
};

export default FluidContainer;
