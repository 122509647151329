const MediaWrapper = ({ type, src, width, height, styles, wrapper }: any) => {
  // Define a switch statement to handle different media types
  const renderMedia = () => {
    switch (type) {
      case "image":
        return <img src={src} width={width} height={height} style={styles} />;
      case "video":
        return <video src={src} width={width} height={height} style={styles} controls />;
      case "audio":
        return <audio src={src} style={styles} controls />;
      default:
        return null; // Return null for unsupported media types
    }
  };

  return <div className="media-wrapper" style={wrapper}>{renderMedia()}</div>;
};

export default MediaWrapper;
