import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import React from "react";
// Function to calculate estimated time left for file upload
const calculateTime = (
  totalFilesSize: any,
  totalUploadedSize: any,
  timeSince: any
) => {
  const remainingSize = totalFilesSize - totalUploadedSize;
  const timeTaken = (remainingSize * timeSince) / totalUploadedSize;
  // Convert time to minutes if greater than 60 seconds
  const time =
    timeTaken > 60
      ? `${(timeTaken / 60).toFixed(2)}M`
      : `${timeTaken.toFixed(2)}S`;

  return time;
};

const FileProgress = ({
  filesDone,
  uploadProgress,
  totalFiles,
  uploadedSize,
  timeSince,
}: any) => {
  // Get total size of files from Redux store
  const totalUploadedSize =
    uploadedSize > 1024 ? (uploadedSize / 1024).toFixed(2) : uploadedSize;

  // from redux
  const totalFilesSize: any = useSelector(
    (state: any) => state.totalSizeSlice.totalSize
  );
  // Calculate time left for upload
  const timeLeft = calculateTime(totalFilesSize, totalUploadedSize, timeSince);
  // Calculate percentage of files uploaded
  const percentage = (totalUploadedSize * 100) / totalFilesSize || 0;

  return (
    <div className="FM__FP__wrapper">
      <div className="FM__FP__header">
        <div className="FP__COunts">
          {/* Display files uploaded count */}
          {filesDone >= totalFiles ? "Uploaded" : "Uploading"} {filesDone}{" "}
          {totalFiles !== 0 ? "of " + totalFiles : ""}
        </div>
        {/* More options icon */}
        <MoreVertOutlined className="FP_icon" />
      </div>
      {/* Display uploaded and total file size */}
      <div className="FM__FP__Size">
        {totalUploadedSize} MB of {totalFilesSize} MB
        {/*| {timeLeft} left*/}
        {/*--- {percentage.toFixed(2)}%*/}
      </div>
      {/* File upload progress bar */}
      <div className="FM__FP__progress">
        <div
          className="FM__FP__progress__Animate"
          style={{ width: `${percentage % 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default FileProgress;
