import { viewReward } from "../../apis/getApis";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useEffect, useState } from "react";
import "./reward.css";
import { Radio, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleRewardDialogCreation } from "../../app/slices/singleStates";
import { editTermsAndConditions } from "../../apis/putApis";
import {
  convertImageToFile,
  editReward,
  fetchAllRewards,
  fetchRewardsCategory,
  uploadReward,
} from "../../Functions/MediaUploaderF";
import Alert from "./Alert";
import Header from "../../apis/header";
import { globalBounti2 } from "../../apis/getApis";
import { Input } from "antd";
import { RootState } from "../../app/store";

export default function Reward({ isEditable = true, selectedId }: any) {
  const rewardViewId = useSelector(
    (state: any) => state.booleanStatus.rewardViewId
  );
  const [swiperActiveIndex, setSwiperActiveIndex] = useState<any>(0);
  const [flagLoop, setFlagLoop] = useState<any>(1);

  const isRewardDialog: boolean = useSelector(
    (state: RootState) => state.booleanStatus.isRewardDialog
  );

  console.log("testingData", rewardViewId, swiperActiveIndex);

  const rewardPopupMode = useSelector(
    (state: any) => state.booleanStatus.rewardPopupMode
  );
  const [postData, setPostData] = useState<any>({
    categories: "",
    value: "",
    description: "",
    title: "",
    image: null,
    type: "",
    deliveryMethod: "",
  });
  const [currentRewardId, setCurrentRewardId] = useState<string | null>(null);

  const [swiper, setSwiper] = useState<any>();
  const handleSwiperInit = (swiperInstance: any) => {
    setSwiper(swiperInstance);
    swiperInstance.loopCreate();
  };

  const [rewards, setRewardsData] = useState<any>([]);
  useEffect(() => {
    fetchAllRewards(setRewardsData, selectedId);
  }, []);

  const dispatch = useDispatch();
  const [isDefault, setIsDefault] = useState<boolean>();
  const [imageFile, setImageFile] = useState(null);
  const [termsAndConditons, setTermsAndConditons] = useState<any>({
    Eligibility: "",
    EntryPeriod: "",
    HowToEnter: "",
    Prizes: "",
    SelectionOfWinners: "",
    PrizeRedemption: "",
    RewardSubjectToChange: "",
  });
  const [projectName, setProjectName] = useState<any>("Project Series Name");

  console.log("Terms_and_condition", termsAndConditons);

  const [rewardCategoryList, setRewardCategory] = useState<any>();
  const [singleRewardView, setSingleRewardView] = useState<any>({});

  console.log("rewardViewId", rewardViewId);

  useEffect(() => {
    convertImageToFile(setImageFile, "./tests/defaultReward.png", selectedId);
    if (isDefault) {
      setPostData({ ...postData, image: imageFile });
    } else {
      setPostData({ ...postData, image: null });
    }
  }, [isDefault]);

  useEffect(() => {
    if (!swiper) {
      dispatch(toggleRewardDialogCreation(false));
    }
    if (rewardViewId === 0) {
      setSwiperActiveIndex(rewardViewId);
      return;
    }
    setPostData({
      categories: "",
      value: "",
      description: "",
      title: "",
      image: null,
      type: "",
      deliveryMethod: "",
    });

    setSwiperActiveIndex(rewardViewId);
  }, [isRewardDialog]);

  useEffect(() => {
    if (rewardViewId) {
      swiper?.slideTo(rewardViewId);
    }
  }, [swiper, rewardViewId]);

  useEffect(() => {
    fetchRewardsCategory(setRewardCategory, selectedId);
  }, []);

  // terms and conditions get API
  useEffect(() => {
    axios
      .get(`${globalBounti2}/${selectedId}`, Header)
      .then((res) => {
        console.log(res, "globalBounti2");
        if (res.data.text.termsAndConditions) {
          console.log(
            "Complete Term and Conditional",
            res.data.text.termsAndConditions
          );
          setTermsAndConditons({
            Eligibility: res.data.text.termsAndConditions.Eligibility || "",
            EntryPeriod: res.data.text.termsAndConditions.EntryPeriod || "",
            HowToEnter: res.data.text.termsAndConditions.HowToEnter || "",
            Prizes: res.data.text.termsAndConditions.Prizes || "",
            SelectionOfWinners:
              res.data.text.termsAndConditions.SelectionOfWinners || "",
            PrizeRedemption:
              res.data.text.termsAndConditions.PrizeRedemption || "",
            RewardSubjectToChange:
              res.data.text.termsAndConditions.RewardSubjectToChange || "",
          });
        } else {
          console.log(
            "termsAndConditions property not found in the API response."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  console.log(termsAndConditons, "termsAndConditons2");

  const [loading, setLoading] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const changeCategory = (e: any) => {
    setPostData({ ...postData, categories: e.target.value });
  };

  const changeType = (e: any) => {
    setPostData({ ...postData, type: e.target.value });
  };

  const changeDeliveryMethod = (e: any) => {
    setPostData({ ...postData, deliveryMethod: e.target.value });
  };

  const changeTitle = (e: any) => {
    setPostData({ ...postData, title: e.target.value });
  };

  const changeDescription = (e: any) => {
    setPostData({ ...postData, description: e.target.value });
  };

  const changeValue = (e: any) => {
    setPostData({ ...postData, value: e.target.value });
  };

  const changeRewardFile = (e: any) => {
    setPostData({ ...postData, image: e.target.files[0] });
  };

  // function to save reward
  const saveReward = () => {
    if (rewardPopupMode === "View") {
      editReward(currentRewardId, postData, dispatch, setLoading, selectedId);
      return;
    }
    setLoading(true);
    const bool: any = uploadReward(
      postData,
      setPostData,
      dispatch,
      setLoading,
      selectedId
    );
  };

  const openFile = () => {
    const fileInput: any = document.getElementById("rewardfileInput");

    if (fileInput) {
      fileInput.click();
    }
  };

  // term and conditions changes
  // useEffect(() => {
  //   const getData = setTimeout(async () => {
  //     await axios
  //       .put(
  //         `${editTermsAndConditions}/${selectedId}`,
  //         {
  //           termsAndConditions: termsAndConditons,
  //         },
  //         Header
  //       )
  //       .then((res: any) => {})
  //       .catch((err: any) => {});
  //   }, 2000);
  //   return () => clearTimeout(getData);
  // }, [termsAndConditons]);
  const handleTermsChange = (field: string, value: string) => {
    setTermsAndConditons({
      ...termsAndConditons,
      [field]: value,
    });

    // Call API to update terms and conditions
    axios
      .put(
        `${editTermsAndConditions}/${selectedId}`,
        {
          termsAndConditions: {
            ...termsAndConditons,
            [field]: value,
          },
        },
        Header
      )
      .then((res: any) => {
        console.log(res, "terms_edit");
        // Handle response if needed
      })
      .catch((err: any) => {
        console.log(err, "terms_edit");
        // Handle error if needed
      });
  };

  // handleActiveIndexChange
  const handleActiveIndexChange = (e: any) => {
    setFlagLoop((prev: any) => prev + 1);
    console.log("rewardSlider", e.activeIndex, flagLoop);
    console.log("rewardSlider->", rewards.length, flagLoop);
    setSwiperActiveIndex(e.activeIndex);
  };

  useEffect(() => {
    rewards?.forEach((single: any, index: number) => {
      if (swiperActiveIndex === index) {
        setCurrentRewardId(single?._id);
        setPostData({
          categories: single?.categories,
          value: single?.value,
          description: single?.description,
          title: single?.title,
          image: single?.imagePath,
          type: single?.type,
          deliveryMethod: single?.deliveryMethod,
        });
      }
    });
  }, [swiperActiveIndex]);

  return (
    <>
      <div
        className="close_dialog_wrapper__Reward__Creation"
        style={{ width: "60%" }}
      >
        <button
          className="close_dialog__Reward__Creation"
          onClick={() => {
            rewardPopupMode === "View" ? swiper?.slideTo(rewardViewId) : <></>;
            dispatch(toggleRewardDialogCreation(false));
          }}
        >
          X
        </button>
      </div>
      {rewardPopupMode === "View" ? (
        <div className="Reward--wrapper relative">
          <button
            className="Reward__Save__BUTTON"
            onClick={saveReward}
            disabled={loading}
          >
            {loading ? "uploading..." : "Save"}
          </button>
          <Swiper
            className="rewardSwiper"
            onActiveIndexChange={handleActiveIndexChange}
            onInit={handleSwiperInit}
          >
            {rewards.map((singleReward: any, index: any) => {
              return (
                <SwiperSlide>
                  {/* First Row data */}
                  <div className="Reward--first--row--data">
                    {/* first row left side data */}
                    <div className="Reward--first--row--data--left">
                      <div className="first-sentence">
                        Your Reward Details{" "}
                        <img
                          src="./tests/icons/info.svg"
                          alt=""
                          style={{
                            marginLeft: "0.4rem",
                            cursor: "pointer",
                            width: "1rem",
                          }}
                        />
                      </div>
                      <div className="second-sentence">
                        Fill in the details you wish to promote your Bounty
                      </div>
                    </div>

                    {/* first row right side data */}
                    <div className="Reward--first--row--data--right">
                      <div className="first-sentence">Reward Preview</div>
                      <div className="second-sentence">
                        This is how your reward will appear, by default and
                        larger on hover
                      </div>
                    </div>
                  </div>

                  {/* Second Row Data */}
                  <div className="Reward--second--row--data">
                    {/* second row left side sata */}
                    <div className="Reward--second--row--data--left">
                      <form>
                        <div className="form-field">
                          <div className="row-container">
                            {/* select category */}
                            <div>
                              <label
                                htmlFor="category"
                                className="reawrd_popup_label"
                              >
                                Category:
                              </label>
                              <div
                                style={{
                                  position: "relative",
                                  marginTop: "0.5rem",
                                }}
                              >
                                <select
                                  disabled={!isEditable}
                                  id="category"
                                  name="category"
                                  className="dropdown"
                                  value={postData.categories}
                                  onChange={changeCategory}
                                  style={{
                                    border: "1px solid var(--orange)",
                                    paddingLeft: "0.5rem",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                >
                                  {rewardCategoryList?.map((cat: any) => {
                                    return (
                                      <option value={cat?.value}>
                                        {cat?.value}
                                      </option>
                                    );
                                  })}
                                </select>
                                <img
                                  className="reward_select_downarrow"
                                  src="./tests/icons/downarrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            {/* set default Image or Upload new */}
                            <div className="radio-group flex items-center">
                              <div>
                                <label className="reawrd_popup_label">
                                  Deafult Image:
                                </label>
                                <Radio.Group
                                  defaultValue="b"
                                  buttonStyle="solid"
                                  disabled={!isEditable}
                                  style={{ marginTop: "0.5rem" }}
                                >
                                  <Radio.Button
                                    value="a"
                                    onClick={(e) => {
                                      setIsDefault(true);
                                    }}
                                  >
                                    Yes
                                  </Radio.Button>
                                  <Radio.Button
                                    value="b"
                                    onClick={(e) => {
                                      setIsDefault(false);
                                    }}
                                  >
                                    No
                                  </Radio.Button>
                                </Radio.Group>
                              </div>

                              <img
                                src={"./tests/defaultReward.png"}
                                style={
                                  isDefault
                                    ? {
                                        width: "1.6rem",
                                        height: "1.3rem",
                                        marginLeft: "1rem",
                                      }
                                    : { display: "none" }
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* select reward type */}
                        <div className="form-field">
                          <div className="form-filed2">
                            <div className="category__sub__type">
                              <label
                                htmlFor="category1"
                                className="reawrd_popup_label"
                              >
                                Type
                              </label>
                              <div
                                className="row-container"
                                style={{ position: "relative" }}
                              >
                                <select
                                  id="category1"
                                  name="category1"
                                  disabled={!isEditable}
                                  className="dropdown__2"
                                  onChange={changeType}
                                  value={postData.type}
                                  style={{
                                    border: "1px solid var(--orange)",
                                    paddingLeft: "0.5rem",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    cursor: "pointer",
                                    marginTop: "0.5rem",
                                  }}
                                >
                                  <option value="Digital">Digital</option>
                                  {/* <option value="Physical">Physical</option> */}
                                </select>
                                <img
                                  className="reward_select_downarrow2"
                                  src="./tests/icons/downarrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            {/* selecrt reward Delivery Method */}
                            <div className="delivery__method">
                              <label
                                htmlFor="category2"
                                className="reawrd_popup_label"
                              >
                                Delivery Method
                              </label>
                              <div
                                className="row-container"
                                style={{ position: "relative" }}
                              >
                                <select
                                  id="category2"
                                  name="category2"
                                  className="dropdown__2"
                                  disabled={!isEditable}
                                  onChange={changeDeliveryMethod}
                                  value={postData.deliveryMethod}
                                  style={{
                                    border: "1px solid var(--orange)",
                                    paddingLeft: "0.5rem",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    cursor: "pointer",
                                    marginTop: "0.5rem",
                                  }}
                                >
                                  <option
                                    className="option_reawrd"
                                    value="email"
                                  >
                                    email
                                  </option>
                                  <option
                                    className="option_reawrd"
                                    value="personnel"
                                  >
                                    personnel
                                  </option>
                                </select>
                                <img
                                  className="reward_select_downarrow3"
                                  src="./tests/icons/downarrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* enter reward value */}
                        <div className="form-field">
                          <label htmlFor="value" className="reawrd_popup_label">
                            Value
                          </label>
                          <input
                            type="text"
                            id="value"
                            disabled={!isEditable}
                            name="value"
                            value={postData.value}
                            onChange={changeValue}
                            style={{ color: "var(--paragraphText)" }}
                          />
                        </div>
                        {/* enter Title */}
                        <div className="form-field">
                          <label htmlFor="title" className="reawrd_popup_label">
                            Title:
                          </label>
                          <input
                            type="text"
                            id="title"
                            name="title"
                            disabled={!isEditable}
                            value={postData.title}
                            onChange={changeTitle}
                            style={{ color: "var(--paragraphText)" }}
                          />
                        </div>
                        {/* Enter Description */}
                        <div className="form-field">
                          <label
                            htmlFor="description"
                            className="reawrd_popup_label"
                          >
                            Description:
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            value={postData.description}
                            maxLength={500}
                            disabled={!isEditable}
                            rows={6 as number}
                            style={{ resize: "none" }}
                            onChange={changeDescription}
                          ></textarea>
                        </div>
                      </form>
                    </div>

                    {/* second row right side data */}
                    <div className="Reward--second--row--data--right">
                      <div className="Reward__game__pass">
                        <div className="Reward__game__pass_r1_image">
                          <img
                            src={
                              postData.image &&
                              typeof postData.image != "string"
                                ? URL.createObjectURL(postData.image)
                                : postData.image
                            }
                            style={{ width: "50%", height: "50%" }}
                          />
                        </div>
                        <div className="Reward__game__pass__text">
                          <div className="first-sentence">{postData.title}</div>
                          <div className="second-sentence">
                            {postData.categories}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          color: "var(--grayShade1)",
                          marginTop: "2rem",
                        }}
                      >
                        This is how your reward will appear on hover or tap
                      </div>
                      <div className="reward__right_main__view__Container">
                        <div>
                          <div className="third__row_right__box">
                            <div className="third__row_right__image">
                              <img
                                style={{ width: "50%", height: "50%" }}
                                src={
                                  postData.image &&
                                  typeof postData.image != "string"
                                    ? URL.createObjectURL(postData.image)
                                    : postData.image
                                }
                                alt=""
                              />
                            </div>
                            <div className="third__row_right__text">
                              {/* 1 */}
                              <div className="third__row_right__text1">
                                {postData?.title}
                              </div>

                              {/* 2 */}
                              <div className="third__row_right__text2">
                                {postData?.categories}
                              </div>

                              {/* 3 */}
                              {/* <div className="third__row_right__text3">
                            {postData?.description}
                          </div> */}
                            </div>
                            <div
                              className="reward__hover__card__top__right"
                              style={{ paddingRight: "1rem" }}
                            >
                              <div>
                                <h2
                                  style={{ fontSize: "0.8rem", width: "8rem" }}
                                >
                                  Reward Type:
                                </h2>
                                <b
                                  style={{ fontSize: "0.8rem", width: "8rem" }}
                                >
                                  {postData?.type}
                                </b>
                              </div>
                              <div>
                                <h2
                                  style={{ fontSize: "0.8rem", width: "8rem" }}
                                >
                                  Value:
                                </h2>
                                <b
                                  style={{ fontSize: "0.8rem", width: "8rem" }}
                                >
                                  {postData?.value}
                                </b>
                              </div>
                              <div>
                                <h2
                                  style={{ fontSize: "0.8rem", width: "8rem" }}
                                >
                                  Delivery Method:
                                </h2>
                                <b
                                  style={{ fontSize: "0.8rem", width: "8rem" }}
                                >
                                  {postData?.deliveryMethod}
                                </b>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ marginTop: "1rem" }}
                            className="postDataView__description__reawrd"
                          >
                            {postData?.description}
                          </div>
                        </div>

                        {/* <div className="terms__condition__box">
                          <div className="terms_box_head">Terms</div>
                          <ul className="reward__termsACond">
                         
                            <li>
                              <label
                                htmlFor="Eligibility"
                                className="terms__labels"
                              >
                                Eligibility:
                              </label>
                              <div>
                                <textarea
                                  id="Eligibility"
                                  style={{ resize: "none", width: "100%" }}
                                  rows={6}
                                  value={termsAndConditons?.Eligibility}
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     Eligibility: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("Eligibility", e.target.value)}
                                  name="Eligibility"
                                  className="termsTextAreas"
                                  disabled={!isEditable}
                                />
                              </div>
                            </li>
                        
                            <li>
                              <label
                                htmlFor="EntryPeriod"
                                className="terms__labels"
                              >
                                Entry Period:
                              </label>
                              <div>
                                <textarea
                                  id="EntryPeriod"
                                  style={{ resize: "none", width: "100%" }}
                                  value={termsAndConditons?.EntryPeriod}
                                  rows={6}
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     EntryPeriod: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("EntryPeriod", e.target.value)}
                                  name="EntryPeriod"
                                  className="termsTextAreas"
                                  disabled={!isEditable}
                                />
                              </div>
                            </li>
                         
                            <li>
                              <label
                                htmlFor="HowToEnter"
                                className="terms__labels"
                              >
                                How To Enter:
                              </label>
                              <div>
                                <textarea
                                  id="HowToEnter"
                                  style={{ resize: "none", width: "100%" }}
                                  rows={6}
                                  value={termsAndConditons?.HowToEnter}
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     HowToEnter: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("HowToEnter", e.target.value)}
                                  name="HowToEnter"
                                  className="termsTextAreas"
                                  disabled={!isEditable}
                                />
                              </div>
                            </li>
                       
                            <li>
                              <label htmlFor="Prizes" className="terms__labels">
                                Prizes:
                              </label>
                              <div>
                                <textarea
                                  id="Prizes"
                                  style={{ resize: "none", width: "100%" }}
                                  rows={6}
                                  value={termsAndConditons?.Prizes}
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     Prizes: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("Prizes", e.target.value)}
                                  name="Prizes"
                                  className="termsTextAreas"
                                  disabled={!isEditable}
                                />
                              </div>
                            </li>
                           
                            <li>
                              <label htmlFor="Prizes" className="terms__labels">
                                Selection Of Winners:
                              </label>
                              <div>
                                <textarea
                                  id="SelectionOfWinners"
                                  style={{ resize: "none", width: "100%" }}
                                  rows={6}
                                  value={termsAndConditons?.SelectionOfWinners}
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     SelectionOfWinners: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("SelectionOfWinners", e.target.value)}
                                  name="SelectionOfWinners"
                                  className="termsTextAreas"
                                  disabled={!isEditable}
                                />
                              </div>
                            </li>
                       
                            <li>
                              <label htmlFor="Prizes" className="terms__labels">
                                Prize Redemption:
                              </label>
                              <div>
                                <textarea
                                  id="PrizeRedemption"
                                  style={{ resize: "none", width: "100%" }}
                                  rows={6}
                                  value={termsAndConditons?.PrizeRedemption}
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     PrizeRedemption: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("PrizeRedemption", e.target.value)}
                                  name="PrizeRedemption"
                                  className="termsTextAreas"
                                />
                              </div>
                            </li>
                        
                            <li>
                              <label
                                htmlFor="RewardSubjectToChange"
                                className="terms__labels"
                              >
                                Reward Subject To Change:
                              </label>
                              <div>
                                <textarea
                                  id="RewardSubjectToChange"
                                  style={{ resize: "none", width: "100%" }}
                                  rows={6}
                                  value={
                                    termsAndConditons?.RewardSubjectToChange
                                  }
                                  // onChange={(e: any) => {
                                  //   setTermsAndConditons({
                                  //     ...termsAndConditons,
                                  //     RewardSubjectToChange: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => handleTermsChange("RewardSubjectToChange", e.target.value)}
                                  name="RewardSubjectToChange"
                                  className="termsTextAreas"
                                  disabled={!isEditable}
                                />
                              </div>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* Third Row Data */}
                  <div className="Reward--third--row--data">
                    {/* third row left side data */}
                    <div
                      className="Reward--third--row--data--left"
                      onClick={openFile}
                    >
                      <div className="Reward__DROpper_image">
                        {postData.image ? (
                          <img
                            src={
                              postData.image &&
                              typeof postData.image != "string"
                                ? URL.createObjectURL(postData.image)
                                : postData.image
                            }
                            alt="file_upload_image"
                            style={{
                              width: "4rem",
                              height: "4rem",
                              borderRadius: "1rem",
                            }}
                          />
                        ) : (
                          <img
                            src="./tests/uploadImage.svg"
                            style={{ width: "4rem", height: "4rem" }}
                            alt="file_upload_image"
                          />
                        )}
                        <h1
                          className="reward__File__Upload"
                          style={isEditable ? {} : { display: "none" }}
                        >
                          Drag your image, or browse.
                        </h1>
                        <p
                          className="reward__File__Upload__desc"
                          style={isEditable ? {} : { display: "none" }}
                        >
                          For a best results choose a png image without a
                          background
                        </p>
                        <input
                          type="file"
                          id="rewardfileInput"
                          disabled={!isEditable}
                          onChange={changeRewardFile}
                          style={{
                            visibility: "hidden",
                            width: "0.1px",
                            height: "0.1px",
                          }}
                          accept="image/*"
                        />
                      </div>
                    </div>

                    {/* arrow button of reward carousel */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "60%",
                        marginTop: "8rem",
                      }}
                    >
                      <button
                        disabled={swiperActiveIndex === 0}
                        style={
                          swiperActiveIndex === 0 ? { opacity: "0.5" } : {}
                        }
                        onClick={() => {
                          swiper?.slidePrev();
                        }}
                        className="reward__navigation reward__prev"
                      >
                        <img
                          src="./tests/icons/prev.svg"
                          style={{ width: "1.5rem" }}
                          alt=""
                        />
                      </button>
                      <button
                        disabled={swiperActiveIndex === rewards?.length - 1}
                        style={
                          swiperActiveIndex === rewards?.length - 1
                            ? { opacity: "0.5" }
                            : {}
                        }
                        onClick={() => {
                          swiper?.slideNext();
                        }}
                        className="reward__navigation reward__next"
                      >
                        <img
                          src="./tests/icons/next.svg"
                          style={{ width: "1.5rem" }}
                          alt=""
                        />
                      </button>
                    </div>
                    {/* arrow button of reward carousel */}

                    {/* save button */}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div className="Reward--wrapper">
          {/* First Row data */}
          <div className="Reward--first--row--data">
            {/* first row left side data */}
            <div className="Reward--first--row--data--left">
              <div className="first-sentence">
                Your Reward Details{" "}
                <img
                  src="./tests/icons/info.svg"
                  alt=""
                  style={{
                    marginLeft: "0.4rem",
                    cursor: "pointer",
                    width: "1rem",
                  }}
                />
              </div>
              <div className="second-sentence">
                Fill in the details you wish to promote your Bounty
              </div>
            </div>

            {/* first row right side data */}
            <div className="Reward--first--row--data--right">
              <div className="first-sentence">Reward Preview</div>
              <div className="second-sentence">
                This is how your reward will appear, by default and larger on
                hover
              </div>
            </div>
          </div>

          {/* Second Row Data */}
          <div className="Reward--second--row--data">
            {/* second row left side sata */}
            <div className="Reward--second--row--data--left">
              <form>
                <div className="form-field">
                  <label htmlFor="category" className="reawrd_popup_label">
                    Category:
                  </label>
                  <div className="row-container">
                    <div style={{ position: "relative" }}>
                      <select
                        disabled={!isEditable}
                        id="category"
                        name="category"
                        className="dropdown"
                        value={postData.categories}
                        onChange={changeCategory}
                        style={{
                          border: "1px solid var(--orange)",
                          paddingLeft: "0.5rem",
                          fontSize: "1rem",
                          fontWeight: "400",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        {rewardCategoryList?.map((cat: any) => {
                          return (
                            <option value={cat?.value}>{cat?.value}</option>
                          );
                        })}
                      </select>
                      <img
                        className="reward_select_downarrow"
                        src="./tests/icons/downarrow.svg"
                        alt=""
                      />
                    </div>

                    <div className="radio-group flex items-center">
                      <Radio.Group
                        defaultValue="b"
                        buttonStyle="solid"
                        disabled={!isEditable}
                      >
                        <Radio.Button
                          value="a"
                          onClick={(e) => {
                            setIsDefault(true);
                          }}
                        >
                          Yes
                        </Radio.Button>
                        <Radio.Button
                          value="b"
                          onClick={(e) => {
                            setIsDefault(false);
                          }}
                        >
                          No
                        </Radio.Button>
                      </Radio.Group>

                      <img
                        src={"./tests/defaultReward.png"}
                        style={
                          isDefault
                            ? {
                                width: "1.6rem",
                                height: "1.3rem",
                                marginLeft: "1rem",
                              }
                            : { display: "none" }
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-field">
                  <div className="form-filed2">
                    <div className="category__sub__type">
                      <label htmlFor="category1" className="reawrd_popup_label">
                        Type
                      </label>
                      <div
                        className="row-container"
                        style={{ position: "relative" }}
                      >
                        <select
                          id="category1"
                          name="category1"
                          disabled={!isEditable}
                          className="dropdown__2"
                          onChange={changeType}
                          value={postData.type}
                          style={{
                            border: "1px solid var(--orange)",
                            paddingLeft: "0.5rem",
                            fontSize: "1rem",
                            fontWeight: "400",
                            cursor: "pointer",
                            marginTop: "0.5rem",
                          }}
                        >
                          <option value="Digital">Digital</option>
                          {/* <option value="Physical">Physical</option> */}
                        </select>
                        <img
                          className="reward_select_downarrow2"
                          src="./tests/icons/downarrow.svg"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="delivery__method">
                      <label htmlFor="category2" className="reawrd_popup_label">
                        Delivery Method
                      </label>
                      <div
                        className="row-container"
                        style={{ position: "relative" }}
                      >
                        <select
                          id="category2"
                          name="category2"
                          className="dropdown__2"
                          disabled={!isEditable}
                          onChange={changeDeliveryMethod}
                          value={postData.deliveryMethod}
                          style={{
                            border: "1px solid var(--orange)",
                            paddingLeft: "0.5rem",
                            fontSize: "1rem",
                            fontWeight: "400",
                            cursor: "pointer",
                            marginTop: "0.5rem",
                          }}
                        >
                          <option className="option_reawrd" value="email">
                            email
                          </option>
                          <option className="option_reawrd" value="personnel">
                            personnel
                          </option>
                        </select>
                        <img
                          className="reward_select_downarrow3"
                          src="./tests/icons/downarrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-field">
                  <label htmlFor="value" className="reawrd_popup_label">
                    Value
                  </label>
                  <input
                    type="text"
                    id="value"
                    disabled={!isEditable}
                    name="value"
                    value={postData.value}
                    onChange={changeValue}
                    style={{ color: "var(--paragraphText)" }}
                  />
                </div>

                <div className="form-field">
                  <label htmlFor="title" className="reawrd_popup_label">
                    Title:
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    disabled={!isEditable}
                    value={postData.title}
                    onChange={changeTitle}
                    style={{ color: "var(--paragraphText)" }}
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="description" className="reawrd_popup_label">
                    Description:
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={postData.description}
                    maxLength={500}
                    disabled={!isEditable}
                    rows={6 as number}
                    style={{ resize: "none" }}
                    onChange={changeDescription}
                  ></textarea>
                </div>
              </form>
            </div>

            {/* second row right side data */}
            <div className="Reward--second--row--data--right">
              <div className="Reward__game__pass">
                <div className="Reward__game__pass_r1_image">
                  <img
                    src={
                      postData.image && typeof postData.image != "string"
                        ? URL.createObjectURL(postData.image)
                        : postData.image
                    }
                    style={{ width: "50%", height: "50%" }}
                  />
                </div>
                <div className="Reward__game__pass__text">
                  <div className="first-sentence">{postData.title}</div>
                  <div className="second-sentence">{postData.categories}</div>
                </div>
              </div>

              <div className="third__row_right__box">
                <div className="third__row_right__image">
                  <img
                    style={{ width: "50%", height: "50%" }}
                    src={
                      postData.image && typeof postData.image != "string"
                        ? URL.createObjectURL(postData.image)
                        : postData.image
                    }
                    alt=""
                  />
                </div>
                <div className="third__row_right__text">
                  {/* 1 */}
                  <div className="third__row_right__text1">
                    {postData?.title}
                  </div>

                  {/* 2 */}
                  <div className="third__row_right__text2">
                    {postData?.categories}
                  </div>

                  {/* 3 */}
                  <div className="third__row_right__text3">
                    {postData?.description}
                  </div>
                </div>
              </div>
              <div className="terms__condition__box">
                <div className="terms_box_head">Terms</div>
                <ul className="reward__termsACond">
                  {/* eligibility */}
                  <li>
                    <label htmlFor="Eligibility" className="terms__labels">
                      Eligibility:
                    </label>
                    <div>
                      <textarea
                        id="Eligibility"
                        style={{ resize: "none", width: "100%" }}
                        rows={6}
                        value={termsAndConditons?.Eligibility}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            Eligibility: e.target.value,
                          });
                        }}
                        name="Eligibility"
                        className="termsTextAreas"
                        disabled={!isEditable}
                      />
                    </div>
                  </li>
                  {/* entry pariod */}
                  <li>
                    <label htmlFor="EntryPeriod" className="terms__labels">
                      Entry Period:
                    </label>
                    <div>
                      <textarea
                        id="EntryPeriod"
                        style={{ resize: "none", width: "100%" }}
                        value={termsAndConditons?.EntryPeriod}
                        rows={6}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            EntryPeriod: e.target.value,
                          });
                        }}
                        name="EntryPeriod"
                        className="termsTextAreas"
                        disabled={!isEditable}
                      />
                    </div>
                  </li>
                  {/* entry pariod */}
                  <li>
                    <label htmlFor="HowToEnter" className="terms__labels">
                      How To Enter:
                    </label>
                    <div>
                      <textarea
                        id="HowToEnter"
                        style={{ resize: "none", width: "100%" }}
                        rows={6}
                        value={termsAndConditons?.HowToEnter}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            HowToEnter: e.target.value,
                          });
                        }}
                        name="HowToEnter"
                        className="termsTextAreas"
                        disabled={!isEditable}
                      />
                    </div>
                  </li>
                  {/* Prizes */}
                  <li>
                    <label htmlFor="Prizes" className="terms__labels">
                      Prizes:
                    </label>
                    <div>
                      <textarea
                        id="Prizes"
                        style={{ resize: "none", width: "100%" }}
                        rows={6}
                        value={termsAndConditons?.Prizes}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            Prizes: e.target.value,
                          });
                        }}
                        name="Prizes"
                        className="termsTextAreas"
                        disabled={!isEditable}
                      />
                    </div>
                  </li>
                  {/* SelectionOfWinners */}
                  <li>
                    <label htmlFor="Prizes" className="terms__labels">
                      Selection Of Winners:
                    </label>
                    <div>
                      <textarea
                        id="SelectionOfWinners"
                        style={{ resize: "none", width: "100%" }}
                        rows={6}
                        value={termsAndConditons?.SelectionOfWinners}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            SelectionOfWinners: e.target.value,
                          });
                        }}
                        name="SelectionOfWinners"
                        className="termsTextAreas"
                        disabled={!isEditable}
                      />
                    </div>
                  </li>
                  {/* PrizeRedemption */}
                  <li>
                    <label htmlFor="Prizes" className="terms__labels">
                      Prize Redemption:
                    </label>
                    <div>
                      <textarea
                        id="PrizeRedemption"
                        style={{ resize: "none", width: "100%" }}
                        rows={6}
                        value={termsAndConditons?.PrizeRedemption}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            PrizeRedemption: e.target.value,
                          });
                        }}
                        name="PrizeRedemption"
                        className="termsTextAreas"
                      />
                    </div>
                  </li>
                  {/* RewardSubjectToChange */}
                  <li>
                    <label
                      htmlFor="RewardSubjectToChange"
                      className="terms__labels"
                    >
                      Reward Subject To Change:
                    </label>
                    <div>
                      <textarea
                        id="RewardSubjectToChange"
                        style={{ resize: "none", width: "100%" }}
                        rows={6}
                        value={termsAndConditons?.RewardSubjectToChange}
                        onChange={(e: any) => {
                          setTermsAndConditons({
                            ...termsAndConditons,
                            RewardSubjectToChange: e.target.value,
                          });
                        }}
                        name="RewardSubjectToChange"
                        className="termsTextAreas"
                        disabled={!isEditable}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Third Row Data */}
          <div className="Reward--third--row--data">
            {/* third row left side data */}
            <div className="Reward--third--row--data--left" onClick={openFile}>
              <div className="Reward__DROpper_image">
                {postData.image ? (
                  <img
                    src={
                      postData.image && typeof postData.image != "string"
                        ? URL.createObjectURL(postData.image)
                        : postData.image
                    }
                    alt="file_upload_image"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "1rem",
                    }}
                  />
                ) : (
                  <img
                    src="./tests/uploadImage.svg"
                    style={{ width: "4rem", height: "4rem" }}
                    alt="file_upload_image"
                  />
                )}
                <h1
                  className="reward__File__Upload"
                  style={isEditable ? {} : { display: "none" }}
                >
                  Drag your image, or browse.
                </h1>
                <p
                  className="reward__File__Upload__desc"
                  style={isEditable ? {} : { display: "none" }}
                >
                  For a best results choose a png image without a background
                </p>
                <input
                  type="file"
                  id="rewardfileInput"
                  disabled={!isEditable}
                  onChange={changeRewardFile}
                  style={{
                    visibility: "hidden",
                    width: "0.1px",
                    height: "0.1px",
                  }}
                  accept="image/*"
                />
              </div>
            </div>

            {/* arrow button of reward carousel */}
            {/* arrow button of reward carousel */}

            {/* save button */}
            <button
              className="Reward__Save__BUTTON"
              onClick={saveReward}
              disabled={loading}
            >
              {loading ? "uploading..." : "Save"}
            </button>
          </div>
        </div>
      )}

      <Alert></Alert>
    </>
  );
}
