import React from "react";
import "./ad.css";
import { Progress } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { changePinCall } from "../../../../app/slices/singleStates";
import { pinAd } from "../../../../apis/postApis";
import { getAdvertisePins } from "../../../../apis/getApis";
import { includeAdvertises } from "../../../../app/slices/pinsData";

//   it function return media according media type
const AdMedia = ({ mediaType, src }: any) => {
  console.log(mediaType,src,"mediaTypemediaType")
  if (mediaType === "image") {
    return (
      <img
        src={src}
        alt={src}
        style={{
          width: "100%",
          borderRadius: "1rem",
          objectFit: "cover",
          height: "100%",
        }}
      />
    );
  }
  if (mediaType === "video") {
    return (
      <video
        src={src}
        style={{
          width: "100%",
          borderRadius: "1rem",
          objectFit: "cover",
          height: "100%",
        }}
        muted
        autoPlay
        controls
      ></video>
    );
  }
  return <></>;
};

// Advertise Card shows at Myworld page,login home page
const AdCard = ({
  styles,
  mediaType,
  src,
  brand,
  brandLogo,
  bottomText,
  isLogin,
  adId,
}: any) => {
  const isPinCall = useSelector((state: RootState) => {
    return state.booleanStatus.isPinCall;
  });

  const dispatch = useDispatch();
  const isPinCallValue = isPinCall * 2;

  // Function call when click on Advertise card pn Icon
  const pinThis = async (e: any) => {
    await axios
      .post(`${pinAd}/${adId}`, {})
      .then((putResponse: any) => {
        axios
          .get(getAdvertisePins)
          .then((pinsAd: any) => {
            dispatch(includeAdvertises(pinsAd.data.pinnedAdvertisements));
          })
          .catch((err) => {});
      })
      .catch((putError: any) => {});
  };
  return (
    <>
      <section className="home__advertise__card" style={styles}>
        <AdMedia mediaType={mediaType} src={src} />
        <button className="homePinButton homePinButton__ad" onClick={pinThis}>
          {isLogin ? <img src={"./tests/icons/pin.svg"} alt="" /> : <></>}
        </button>
        {/* <div className="home__advertise__content">
          <section className="home__ad__top flex justify-between items-center">
            <div className="flex items-center">
              <div className="home__ad__brandLogo">
                <img src={brandLogo} alt="" />
              </div>
              <h1 className="home__ad__brand">{brand}</h1>
            </div>
            <button className="homePinButton" onClick={pinThis}>
              {isLogin ? <img src={"./tests/icons/pin.svg"} alt="" /> : <></>}
            </button>
          </section>

          <section className="home__ad__bottom flex justify-between items-center">
            <div className="home__ad__bottomText">{bottomText}</div>
            
          </section>
        </div> */}
      </section>
    </>
  );
};

export default AdCard;
