import RelatedProjects from "./RelatedProjects";
import styles from "./detailCard.module.css";

const CharityDetailCard = ({ boutiCharityData, charityHoveredIndex }: any) => {
  // Get the hovered charity data based on the index
  const hoveredCharity = boutiCharityData?.[charityHoveredIndex];
  // console.log(hoveredCharity, "charityHoveredIndex")
  return (
    <div className={`${styles.detailCard__wrapper} detailcard_c1_wrapper`}>
      <section className={`${styles.detailCard__wrapper_inner}`}>
        <section className={`${styles.detailCard__top}`}>
          {/* Charity Image and Name section */}
          <div
            className={`${styles.detailCard_top__row} flex justify-between items-center`}
          >
            <div className={`${styles.detailCard_lg}`}>
              {/* Display charity image */}
              <img src={hoveredCharity?.image} alt="Charity Image" />
            </div>
            {/* Display charity name */}
            <div className={`${styles.detailCard_bn}`}>
              {hoveredCharity?.charityName}
            </div>
            {/* Favorite icon section */}
            <div className={`${styles.detailCard_lkw} cursor-pointor`}>
              <img src="./tests/icons/heart.svg" alt="" />
            </div>
          </div>
          {/* Charity description section */}
          <div className={`${styles.detailCard_top__row_description}`}>
            {hoveredCharity?.description}
          </div>
        </section>

        <main className={`${styles.detailCard__main}`}>
          <div className={`${styles.detailCard__main_heading}`}>
            Bounties Supporting Charity
          </div>
          {/* Coins section */}
          <div className={`${styles.detailCard__main_coins}`}>
            {/* Gold points section */}
            <div className={styles.detailCard__coin_section}>
              <img src="./tests/icons/goldcoin.svg" alt="gold points" />
              <span className={`${styles.detailCard__main_points}`}>4</span>
            </div>
            {/* Silver points section */}
            <div className={styles.detailCard__coin_section}>
              <img
                src="./tests/icons/silvercoin.svg"
                className={`${styles.detailCard__coin}`}
                alt="silver points"
              />
              <span className={`${styles.detailCard__main_points}`}>0</span>
            </div>
            {/* Bounti points section */}
            <div className={styles.detailCard__coin_section}>
              <img
                src="./tests/icons/bouticoin.svg"
                className={`${styles.detailCard__coin}`}
                alt="bounti points"
              />
              <span className={`${styles.detailCard__main_points}`}>2+</span>
            </div>
          </div>
          {/* RelatedProjects component */}
          <div className={`${styles.detailCard__carousel__wrapper}`}>
            <RelatedProjects />
          </div>
          {/* Green Card section */}
          <div className={`${styles.detailCard__greenCard_wrapper}`}>
            <div className={`${styles.detailCard__greenCard}`}>
              {/* Information about charity fundraising */}
              <p>
                You’ve personally helped raise{" "}
                <span style={{ fontWeight: "bold" }}>$1,200.00</span> for this
                charity. <span style={{ fontWeight: "bold" }}>$300.00 </span>
                more to go to beat your friend <b>Alex.123</b>.{" "}
                <p>
                  <b>Thanks for helping us make the world a better place!</b>
                </p>
              </p>
            </div>
          </div>
        </main>

        {/* button type */}
        <button className={`${styles.buttonType}`}>89% Relevancy</button>
      </section>
    </div>
  );
};

export default CharityDetailCard;
