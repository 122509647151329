import React, { useState, useEffect } from "react";
import "./style.css";
import { Alert, Button, Checkbox, Input } from "antd";
import { SwiperSlide, Swiper } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubmissionScreen,
  toggleSubmissionScreen,
} from "../../app/slices/singleStates";
import {
  setSubmissionTitle,
  setAlertMessage,
  setIsAlert,
  setIsTermsAccept,
  setSubmissionDescription,
  setSubmissionFiles,
  setPublic,
  setIsReviewedReward,
} from "../../app/slices/bountiSubmissionData";
// import bountiSubmissionData from "../../app/slices/bountiSubmissionData";
import { RootState } from "../../app/store";
import axios from "axios";
import { editPostSubmission } from "../../apis/putApis";
import { createSubmissionAsset } from "../../apis/postApis";
import { globalBounti2 } from "../../apis/getApis";
import Header from "../../apis/header";
import { U } from "../../apis/main";
import { setcurrentSubmission } from "../../app/slices/singleStates";
const { TextArea } = Input;

const Step4 = ({ reduxBountyId }: any) => {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = React.useState<any>();
  const dataRedux = useSelector((state: RootState) => {
    return state.bountiSubmissionData;
  });
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [termsAndConditonsData, setTermsAndConditons] = useState<any>({
    Eligibility: "",
    EntryPeriod: "",
    HowToEnter: "",
    Prizes: "",
    SelectionOfWinners: "",
    PrizeRedemption: "",
    RewardSubjectToChange: "",
  });
  const { currentSubmission } = useSelector(
    (state: any) => state.booleanStatus
  );

  const [termsStatus, setTermsStatus] = useState(false);
  // useEffect(() => {
  //   if (currentSubmission) {
  //     setTermsStatus(currentSubmission.termsAndCondition || "");
  //   }
  // }, [currentSubmission]);
  // media swiper init
  const mediaSwiperInit = (e: any) => {
    setSwiper(e);
  };
  // changeCheckbox
  // const changeCheckbox = (e: any) => setTermsStatus(e.target.checked);
  // {
  // dispatch(setIsTermsAccept(e.target.checked));
  // };
  const changeCheckbox = (e: any) => {
    const checked = e.target.checked;
    setTermsStatus(checked);
    dispatch(setIsTermsAccept(checked));
  };
  // submitCurrentSubmission
  const submitCurrentSubmission = async () => {
    setIsLoading(true);
    await axios
      .put(`${editPostSubmission}/${reduxBountyId}`, {
        title: dataRedux?.title,
        description: dataRedux?.description,
        isPublic: dataRedux?.isPublic,
        rewardReview: dataRedux?.isReviewedReward,
        termsAndCondition: dataRedux?.isTermsAccept,
      })
      .then((response: any) => {
        setIsLoading(false);
        console.log("responseupdate", response);
        dispatch(setAlertMessage(response.data.message));
        dispatch(setIsAlert(true));
        setTimeout(() => {
          dispatch(setIsAlert(false));
          dispatch(setAlertMessage(""));
        }, 3000);
        dispatch(toggleSubmissionScreen(false));

        const formData = new FormData();
        for (let index = 0; index < dataRedux?.files?.length; index++) {
          const element = dataRedux?.files[index];
          formData.append("media", dataRedux?.files[index]);
        }
        const submissionIds = response.data.data.submissions.map(
          (submission4: { _id: string }) => submission4._id
        );
        let lastIndexData = submissionIds[submissionIds.length - 1];
        console.log(lastIndexData, "Last index data");
        axios
          .post(
            `${createSubmissionAsset}/${reduxBountyId}/${U}/${lastIndexData}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response: any) => {
            console.log("response-TRY-Media", response);
          })
          .catch((error: any) => {
            console.log("response-TRY-Media", error);
          });
        // dispatch(setcurrentSubmission(null));
        // Reset bountiSubmissionData slice to initial state
        dispatch(setSubmissionTitle(null));
        dispatch(setSubmissionDescription(""));
        dispatch(setSubmissionFiles(null));
        dispatch(setPublic(null));
        dispatch(setIsTermsAccept(null));
        dispatch(setIsReviewedReward(null));
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log("response-TRY", err);
        setIsError(true);
        if (err.response) {
          // If there's a response from the server
          const responseData = err.response.data;
          if (responseData.error) {
            // If the response contains an error message
           setErrorMessage(responseData.error)
          }
        }
 
        setTimeout(() => {
          setIsError(false);
          setErrorMessage("");
        }, 3000);
      });
  };

  // get terms and conditions
  const fetchTerms = async () => {
    await axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((res) => {
        console.log(res, "globalBounti2");
        if (res.data.text.termsAndConditions) {
          setTermsAndConditons({
            Eligibility: res.data.text.termsAndConditions.Eligibility || "",
            EntryPeriod: res.data.text.termsAndConditions.EntryPeriod || "",
            HowToEnter: res.data.text.termsAndConditions.HowToEnter || "",
            Prizes: res.data.text.termsAndConditions.Prizes || "",
            SelectionOfWinners:
              res.data.text.termsAndConditions.SelectionOfWinners || "",
            PrizeRedemption:
              res.data.text.termsAndConditions.PrizeRedemption || "",
            RewardSubjectToChange:
              res.data.text.termsAndConditions.RewardSubjectToChange || "",
          });
        } else {
          console.log(
            "termsAndConditions property not found in the API response."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // terms and conditions get API
  useEffect(() => {
    fetchTerms();
  }, []);
  return (
    <section className="submission__step1">
      {/* alerts */}
      <Alert
        message={errorMessage}
        className={isError ? "global__alert_antd" : "global__alert_antd__hide"}
        type="error"
        showIcon
      />
      <div className="submission__step__inner">
        <div className="Submission__POPUpHeading2">Terms and Conditions</div>

        <div style={{ marginTop: "2rem" }} className="submi__termsACon">
          {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
          harum dolores cupiditate, quas quos labore ut aperiam deserunt iste
          porro quaerat autem? Ad optio maxime ab porro molestiae quam dolores
          ullam, at id! Nemo minima sequi eveniet ut similique, quam praesentium
          eius rem tempore culpa sapiente officia dolorem, soluta in earum
          quaerat ducimus voluptas qui. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Accusamus harum dolores cupiditate, quas quos labore
          ut aperiam deserunt iste porro quaerat autem? Ad optio maxime ab porro
          molestiae quam dolores ullam, at id! Nemo minima sequi eveniet ut
          similique, quam praesentium eius rem tempore culpa sapiente officia
          dolorem, soluta in earum quaerat ducimus voluptas qui. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Accusamus harum dolores
          cupiditate, quas quos labore ut aperiam deserunt iste porro quaerat
          autem? Ad optio maxime ab porro molestiae quam dolores ullam, at id!
          Nemo minima sequi eveniet ut similique, quam praesentium eius rem
          tempore culpa sapiente officia dolorem, soluta in earum quaerat
          ducimus voluptas qui. */}

          {Object.entries(termsAndConditonsData).map(([key, value]: any) => (
            <div key={key} style={{ marginTop: "1rem" }}>
              <h4>{key}:</h4>
              <p>{value}</p>
            </div>
          ))}
        </div>

        {/* isReview reward */}
        <div className="isReviewed__Terms flex">
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            onChange={changeCheckbox}
            checked={dataRedux?.isTermsAccept}
          />
          <p style={{ color: "var(--dark_2)", marginLeft: "1rem" }}>
            I have reviewed the{" "}
            <b style={{ color: "var(--orange)" }}>Terms and Conditions</b> for
            this bounty
          </p>
        </div>

        {/* submit */}
        <div style={{ marginTop: "1rem" }} className="step1_sub_buttonR">
          <Button
            className="submit__button_Submisn"
            onClick={() => {
              dispatch(setSubmissionScreen(4));
            }}
          >
            Previous
          </Button>
          <Button
            className="submit__button_Submisn"
            style={{ marginLeft: "0.5rem" }}
            onClick={() => {
              submitCurrentSubmission();
            }}
            disabled={isLoading || !dataRedux?.isTermsAccept}
          >
            {/* {mode === "create" ? "Submit" : "Update"} */}
            Submit
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Step4;
