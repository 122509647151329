import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { FluidContainer } from "../../components";
import styles from "../bounti-pages/BountyPreview/bountipreview.module.css";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  allAsset2,
  bountiCategory2,
  getAllGlobalBounties,
  unlocksAdvertisement,
} from "../../apis/getApis";
import { useNavigate } from "react-router-dom";
import BountyCard from "../Dashboard/components/BountyCard/BountyCard";
import AdDialog from "../Dashboard/components/BountyCard/adDialog";
import { useLocation } from "react-router-dom";
import Header from "../../apis/header";
const ViwerBounties = () => {
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  // const location = useLocation();
  // const reduxBountyId = location.state;

  const dispatch = useDispatch();
  const [globalBountiesData, setGlobalBountiesData] = useState<any>([]);
  const [allCompleteGlobalData, setCompleteGlobalData] = useState<any>([]);
  const [realLength, setRealLength] = useState<any>();
  const [isBountyAdPopup, setIsBountyAdPopup] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [visible, setVisible] = useState(false); // State for dialog visibility
  const [unlock, setunlock] = useState<any>([]);
  const [reduxBountyId, setReduxBountyId] = useState<any>("");
  const [advertisements, setAdvertisements] = useState<any[]>([]);
  // Function to open the dialog
  const userDataString = localStorage.getItem("accessData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const userId = userData ? userData.userId : null;
  const navigate = useNavigate();

  const openDialog = (bountyId: string) => {
    localStorage.setItem("bountyIdFromList", bountyId);
    if (userId && bountyId) {
      let adatta = localStorage.getItem("accessData") || "";
      const storedToken = JSON.parse(adatta);
      console.log("token", storedToken);

      if (storedToken) {
        const config = {
          headers: {
            Authorization: `Bearer ${storedToken.access_token}`,
          },
        };

        axios
          .get(`${unlocksAdvertisement}/${bountyId}`, config)
          .then((response: any) => {
            console.log("ressss", response.data);

            if (response.data.unlock === true) {
              navigate("/bouties/bounty-priviews", { state: bountyId });
            } else {
              setData(response.data);
              console.log("ads", response.data);
              if (response.data.length < 1) {
                navigate("/bouties/bounty-priviews", { state: bountyId });
                return;
              } else {
                setVisible(true);
              }
            }
          })
          .catch((error) => {
            setData([]);
            setVisible(true);
          });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(getAllGlobalBounties);
        const updatedGlobalData = await Promise.all(
          res.data.map(async (bounty: any) => {
            let updatedBounty = { ...bounty };

            try {
              const imageResponse = await axios.get(
                `${allAsset2}/${bounty._id}?type=undefined`
              );
              updatedBounty.first = imageResponse.data.data[0]?.imageUrl || "";
            } catch (imageError) {
              console.error("Error in imageResponse:", imageError);
            }

            try {
              const subCategoryResponse = await axios.get(
                `${bountiCategory2}/${bounty._id}`
              );
              updatedBounty.category =
                subCategoryResponse.data.data.category || "";
            } catch (subCategoryError) {
              console.error("Error in subCategoryResponse:", subCategoryError);
            }

            return updatedBounty;
          })
        );

        localStorage.setItem(
          "globalBountiesList",
          JSON.stringify(updatedGlobalData)
        );
        setGlobalBountiesData(updatedGlobalData.reverse());
      } catch (err) {
        console.error("global bounties data", err);
      }
    };
    let cacheData: any = localStorage.getItem("globalBountiesList");
    if (cacheData) {
      setGlobalBountiesData(JSON.parse(cacheData).reverse());
    }
    fetchData();
  }, []);

  // const clickedOnGlobleBounti = async (bountyId: any) => {
  //   // Set the reduxBountyId when a global bounty is clicked
  //   // setReduxBountyId(bountyId);
  //   setIsBountyAdPopup(true);
  //   navigate("/bountipreview", { state: bountyId });
  // };
  const clickedOnGlobleBounti = async (bountyId: any) => {
    setReduxBountyId(bountyId);
    try {
      console.log("Fetching advertisement data...");
      const response = await axios.get(
        `${unlocksAdvertisement}/${bountyId}`,
        Header
      );
      console.log("Advertisement data received:", response.data);
      setAdvertisements(response.data);
      if (response.data.length > 0) {
        console.log(response.data.length,"response.data.length if")
        setIsBountyAdPopup(true);
      } else {
        console.log(response.data.length,"response.data.length else")
        // setIsBountyAdPopup(false);
        navigate("/bountipreview", { state: bountyId });
      }
    } catch (error) {
      console.error("Error fetching advertisement:", error);
    }
  };
  
  return (
    <>
      <FluidContainer breadcrumb={["Home", "Bounties", "Relavant"]}>
        <div className="flex justify-between bounti-preview-res bp-container">
          {/* 66% */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            className={
              toggleD
                ? `${styles.div_67} div_67_respon`
                : `${styles.div_67} div_67_respon div_67_respon_collapse`
            }
          >
            {globalBountiesData.map((bounty: any) => {
              return (
                <div
                  // onClick={() => {
                  //   clickedOnGlobleBounti(bounty._id);
                  // }}
                  // style={{ width: "30%" }}
                  className="syhsgxa__bounty"
                >
                  <BountyCard
                    redirectToThisBounty={clickedOnGlobleBounti}
                    bountyId={bounty?._id}
                  />
                </div>
              );
            })}
          </div>

          {/* 30% */}
          {/* <div className={`${styles.div_30} div_30_responsive`}> */}
          {/* <ChatRoom /> */}
          {/* </div> */}
        </div>
        <div
          className="flex"
          style={
            isBountyAdPopup
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <AdDialog
            open={isBountyAdPopup}
            setIsBountyAdPopup={setIsBountyAdPopup}
            bountyId={reduxBountyId}
          />
        </div>
      </FluidContainer>
    </>
  );
};

export default ViwerBounties;

// {globalBountiesData.map((bounty: any) => (
//   <div
//     key={bounty._id}
//     style={{
//       display: "flex",
//       width: "20rem",
//       marginTop: "1rem",
//     }}
//     // onClick={() => openDialog(bounty._id)} when we add ad dialog box then
//     onClick={() => {
//       clickedOnGlobleBounti(bounty._id);
//     }}
//   >
//     <section
//       className="flex justify-between"
//       style={{
//         background: "var(--sidebar)",
//         borderRadius: "1rem",
//         marginRight: "1rem",
//         cursor: "pointer",
//         alignItems: "center",
//         width: "100%",
//         boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
//       }}
//     >
//       {/* left */}
//       <div
//         style={{
//           width: "50%",
//           height: "12rem",
//           background: "var(--pure)",
//           borderTopLeftRadius: "1rem",
//           borderEndStartRadius: "1rem",
//         }}
//       >
//         {bounty.first ? (
//           bounty.first.split("/")[5] === "video" ? (
//             <>
//               <video
//                 controls
//                 style={{
//                   width: "100%",
//                   height: "100%",
//                   objectFit: "cover",
//                   borderTopLeftRadius: "1rem",
//                   borderBottomLeftRadius: "1rem",
//                 }}
//               >
//                 <source src={bounty.first ? bounty.first : ""} />
//               </video>
//             </>
//           ) : (
//             <>
//               <img
//                 src={bounty.first ? bounty.first : "../demo1.png"}
//                 alt="No Image Found"
//                 style={{
//                   width: "100%",
//                   height: "100%",
//                   objectFit: "cover",
//                   borderTopLeftRadius: "1rem",
//                   borderBottomLeftRadius: "1rem",
//                 }}
//               />
//             </>
//           )
//         ) : (
//           <>
//             <div>No media Found</div>
//           </>
//         )}
//       </div>
//       {/* right */}
//       <div style={{ width: "48%" }}>
//         <h4 style={{ color: "var(--pure)" }}>
//           {bounty.title.length === 0
//             ? "Untitled"
//             : bounty.title.slice(0, 13)}
//           ...
//         </h4>
//         <p
//           style={{
//             marginTop: "0.2rem",
//             color: "var(--pure)",
//             opacity: "50%",
//             fontSize: "0.8rem",
//             paddingRight: "0.2rem",
//           }}
//         >
//           {bounty.title.length === 0
//             ? "empty description"
//             : bounty.description.slice(0, 40)}
//           ...
//         </p>
//         <div style={{ marginTop: "0.4rem" }}>
//           {bounty?.currentPhase
//             ? bounty?.currentPhase
//             : "No Phase Found"}
//         </div>
//         <p style={{ color: "var(--pure)", marginTop: "0.7rem" }}>
//           {bounty?.nextPhaseIn ? bounty?.nextPhaseIn : ""}
//           {/* <NextPhase
//       data={bounty?.nextPhaseIn ? bounty?.nextPhaseIn : ""}
//     /> */}
//         </p>
//         <p style={{ color: "var(--pure)", marginTop: "0.7rem" }}>
//           <div style={{ marginTop: "0.4rem" }}>
//             {bounty.category ? bounty.category : "null"}
//           </div>
//         </p>
//       </div>
//     </section>
//   </div>
// ))}
