import { FluidContainer } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import "./dashboard.css";
import { useState , useEffect} from "react";
import BountyCard from "./components/BountyCard/BountyCard";
import AdCard from "./components/AdCard/AdCard";
import Masonry from "react-responsive-masonry";
import HomeMasonary from "./components/HomeMasonary/HomeMasonary";
import PinBar from "./components/PinBar/PinBar";
import { Tooltip } from "antd";
import AdView from "./components/AdView/AdView";

const Dashboard = () => {
  // localstates
  const [tabnumber, setTabnumber] = useState<any>(0);
  const [isAdPopup, setIsAdPopup] = useState<any>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("latest");
  const [activeFilter, setActiveFilter] = useState("latest"); // Set initial active filter

  // Redux state
  const advertiseId = useSelector((state: RootState) => {
    return state.booleanStatus.activeFeedAdURL;
  });
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );

  // When filter Change
  const handleFilterChange = (filter: string) => {
    console.log("onFilterChange handleFilterChange called")
    setSelectedFilter(filter);
    setActiveFilter(filter); 
  };

  // When serach on myworld page
  const handleSearch = (query:any) => {
    setSearchQuery(query);
  };
  console.log("handleSearch dashboard",searchQuery)

  // useEffect(() => {
  //   if (searchQuery) {
  //     // Call the search API with the search query
  //     fetch(`https:/mingalinks/.com:5000/v1/searchBar?title=${searchQuery}`)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         // Update state with search results
  //         // For example, setHomeFeedData(data) in your case
  //         console.log("handleSearch Search results:", data);
  //       })
  //       .catch((error) => {
  //         console.error("handleSearch Error fetching search results:", error);
  //       });
  //   }
  // }, [searchQuery]);

  return (
    <>
      <FluidContainer breadcrumbS={0}>
        <div className="flex justify-between" style={{ height: "93vh" }}>
          <div style={{ width: "76%" }}>
            {/* top section of home page */}
            <header
              className={
                toggleD
                  ? "dashboard__top dashboard__top_nc"
                  : "dashboard__top dashboard__top_wc"
              }
            >
              <section
                className={
                  toggleD
                    ? "dashboard__top__tab dashboard__top__tab_nc"
                    : "dashboard__top__tab dashboard__top__tab_wc"
                }
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 6px",
                  borderRadius: "1rem",
                }}
              >
                <button
                  className="dash__tab  dash__tab__left"
                  id={tabnumber === 0 ? "dash__tab__act" : ""}
                  onClick={() => {
                    setTabnumber(0);
                  }}
                >
                  feed
                </button>
                <Tooltip title={"coming soon"}>
                  <button
                    className="dash__tab "
                    id={tabnumber === 1 ? "dash__tab__act" : ""}
                    onClick={() => {
                      setTabnumber(1);
                    }}
                    disabled={true}
                  >
                    friends
                  </button>
                </Tooltip>
                <Tooltip title={"coming soon"}>
                  <button
                    className="dash__tab "
                    id={tabnumber === 2 ? "dash__tab__act" : ""}
                    onClick={() => {
                      setTabnumber(2);
                    }}
                    disabled={true}
                  >
                    communities
                  </button>
                </Tooltip>
                <Tooltip title={"coming soon"}>
                  <button
                    className="dash__tab "
                    id={tabnumber === 3 ? "dash__tab__act" : ""}
                    onClick={() => {
                      setTabnumber(3);
                    }}
                    disabled={true}
                  >
                    badges
                  </button>
                </Tooltip>
                <Tooltip title={"coming soon"}>
                  <button
                    className="dash__tab dash__tab__right"
                    id={tabnumber === 4 ? "dash__tab__act" : ""}
                    onClick={() => {
                      setTabnumber(4);
                    }}
                    disabled={true}
                  >
                    discover
                  </button>
                </Tooltip>
              </section>
            </header>
            {/* top section of end page */}

            {/* main section of home page goes here*/}
            <main
              className={
                toggleD
                  ? "dashboard__main dashboard__main_nc"
                  : "dashboard__main dashboard__main_wc"
              }
            >
              {/* My world Page left side Section */}
              <HomeMasonary filter={selectedFilter}  searchQuery={searchQuery}/>
            </main>
          {/* main section of home page end here*/}
          </div>
          {/* My World page right side section - PIN section */}
          <PinBar setIsAdPopup={setIsAdPopup}  onFilterChange={handleFilterChange}  onSearch={handleSearch} activeFilter={activeFilter} style={{ width: "20%" }} />
        </div>

        {/* ad dialog on dashboard goes here */}
        <div
          className="flex"
          style={
            isAdPopup
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <AdView setIsAdPopup={setIsAdPopup} selectedAdId={advertiseId}/>
        </div>
        {/* submission Dialog end here */}
      </FluidContainer>
    </>
  );
};

export default Dashboard;
