import styles from "./detailCard.module.css";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Tooltip } from "antd";

const RelatedProjects = () => {
  return (
    <section className={`${styles.relatedProjects_main}`}>
      {/* arrow previous */}
      <img src="./tests/icons/prev.svg" alt="previous icon" />
      {/* main carousel */}
      <main className={`${styles.relatedProject__mainCarousel}`}>
        <img src="./tests/comingsoon.png" style={{ width: "100%" }} />
        {/* <div className={`${styles.relatedProject__left}`}>
          <img src="./tests/deadpool.webp" />
          <Avatar.Group className={`${styles.avtar__group}`}>
            <a href="https://ant.design">
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  width: "1.6rem",
                  height: "1.6rem",
                }}
              >
                K
              </Avatar>
            </a>
            <Tooltip title="Users" placement="top">
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                  width: "1.6rem",
                  height: "1.6rem",
                }}
                icon={<UserOutlined />}
              />
            </Tooltip>
            <Avatar
              style={{
                backgroundColor: "#1677ff",
                width: "1.6rem",
                height: "1.6rem",
              }}
              icon={<AntDesignOutlined />}
            />
          </Avatar.Group>
        </div>
        <div className={`${styles.relatedProject__right}`}>
          <div>
            <div className={`${styles.project_name}`}>Project Series Name</div>
            <div className={`${styles.hashtags}`}>Artwork #Design</div>
          </div>
          <div>
            <div className={`${styles.phase_name}`}>Submission Phase</div>
            <div className={`${styles.phase_time}`}>02D 1H 10M</div>
          </div>
        </div> */}
      </main>
      {/* arrow next */}
      <img
        src="./tests/icons/next.svg"
        className="cursor-pointor"
        alt="next icon"
      />
    </section>
  );
};

export default RelatedProjects;
