import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

export function COMINGSOON(props) {
    const [click, setClick] = useState(0)
    const navigate = useNavigate();
    return (
        <div className='App' style={{display: "flex",justifyContent: "center" }}>
            <img src="https://images.pexels.com/photos/4439444/pexels-photo-4439444.jpeg?auto=compress&cs=tinysrgb&w=1200&h=800&dpr=1" usemap="#image-map"/>

            <map name="image-map">
                <area onClick={(e) => {e.preventDefault();click === 1 ? setClick(2) : console.log()}} style={{cursor: "auto"}} target="" alt="" title="" href="" coords="477,474,556,610" shape="rect"/>
                <area onClick={(e) => {e.preventDefault();click === 2 ? setClick(3) : console.log()}} style={{cursor: "auto"}} target="" alt="" title="" href="" coords="558,456,645,586" shape="rect"/>
                <area onClick={(e) => {e.preventDefault();click === 3 ? navigate("/login") : console.log()}} style={{cursor: "auto"}} target="" alt="" title="" href="" coords="643,433,727,569" shape="rect"/>
                <area onClick={(e) => {e.preventDefault();click === 0 ? setClick(1) : console.log()}} style={{cursor: "auto"}} target="" alt="" title="" href="" coords="726,412,825,545" shape="rect"/>
            </map>
        </div>
    );
}

export default COMINGSOON;
