import React, { useState, useEffect } from "react";
import { FluidContainer } from "../../components";
import { getSubmissionDataUser } from "../../apis/getApis";
import axios from "axios";
import Submission from "./Submission";

const AllSubmission = ({ isDynamicRoute }: any) => {
  const [allSubmission, setAllSubmission] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true); // Added loading state

  // get all submission data user wise
  useEffect(() => {
    // userwise submission
    axios
      .get(`${getSubmissionDataUser}`)
      .then((submissionResponse: any) => {
        console.log(
          "allSubmission_home_Data",
          submissionResponse.data.submissions
        );
        setAllSubmission(submissionResponse.data.submissions);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error: any) => {
        console.log("allSubmission_home_Data", error);
        setLoading(false); // Set loading to false if there's an error
      });
  }, []);

  return (
    <FluidContainer breadcrumb={["Home", "My Submissions"]}>
      <div className="flex justify-between bounti-preview-res bp-container">
        <div className="all__submission__card">
          {loading ? (
            <p>Loading...</p>
          ) : allSubmission.length > 0 ? (
            allSubmission.map((submission: any, ind: any) => (
              <Submission
                key={ind}
                isDynamicRoute={isDynamicRoute}
                style={
                  ind === 0
                    ? { cursor: "pointer" }
                    : { marginTop: "1rem", cursor: "pointer" }
                }
                submission={submission}
              />
            ))
          ) : (
            <p>No submissions yet...!</p>
          )}
        </div>
      </div>
    </FluidContainer>
  );
};

export default AllSubmission;
