import React from "react";

const CommunitiesIcon = () => {
  return (
    <svg
      style={{ marginRight: "0.7em" }}
      xmlns="http://www.w3.org/2000/svg"
      width="19.443"
      height="10.368"
      viewBox="0 0 19.443 10.368"
    >
      <g
        id="Group_145"
        data-name="Group 145"
        transform="translate(-2.651 -4.206)"
      >
        <g
          id="Group_148"
          data-name="Group 148"
          transform="translate(2.651 4.206)"
        >
          <path
            id="Path_62"
            data-name="Path 62"
            d="M12.106,5.185A1.882,1.882,0,1,1,10.223,3.3a1.883,1.883,0,0,1,1.882,1.882"
            transform="translate(4.46 -2.246)"
            fill="#f1f1f1"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M6.989,5.236A1.882,1.882,0,1,1,5.106,3.354,1.883,1.883,0,0,1,6.989,5.236"
            transform="translate(-0.343 -2.199)"
            fill="#f1f1f1"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M10.3,5.143A2.385,2.385,0,1,1,7.915,2.758,2.385,2.385,0,0,1,10.3,5.143"
            transform="translate(1.822 -2.758)"
            fill="#f1f1f1"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M10.479,5.6h0a5.323,5.323,0,0,0-2.414.527,5.746,5.746,0,0,1,.7.324A5.392,5.392,0,0,1,10.6,8.1a6.258,6.258,0,0,1,.677,1.254c1.359,0,3.969,0,3.969,0A4.557,4.557,0,0,0,10.479,5.6"
            transform="translate(4.201 -0.088)"
            fill="#f1f1f1"
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M6.4,8.05A5.368,5.368,0,0,1,8.231,6.4,5.543,5.543,0,0,1,8.8,6.131a5.335,5.335,0,0,0-2.311-.477A4.543,4.543,0,0,0,1.738,9.409S4.322,9.4,5.689,9.4A6.209,6.209,0,0,1,6.4,8.05"
            transform="translate(-1.738 -0.039)"
            fill="#f1f1f1"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M9.662,5.692H9.642A5.736,5.736,0,0,0,4.65,8.059a6.053,6.053,0,0,0-.981,2.313c.357,0,2.815-.008,4.463-.008.888,0,1.4,0,1.518.006h.021c.12,0,.63-.006,1.518-.006,1.646,0,4.1.006,4.459.008a6.114,6.114,0,0,0-.989-2.315,5.76,5.76,0,0,0-5-2.365"
            transform="translate(0.075 -0.004)"
            fill="#f1f1f1"
          />
        </g>
      </g>
    </svg>
  );
};

export default CommunitiesIcon;
