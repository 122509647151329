import React from "react";

const BrandBuildIcon = () => {
  return (
    <svg
      style={{ marginRight: "1em" }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12.813"
      height="15.569"
      viewBox="0 0 12.813 15.569"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_93"
            data-name="Rectangle 93"
            width="12.813"
            height="15.569"
            fill="#f1f1f1"
          />
        </clipPath>
      </defs>
      <g id="Group_154" data-name="Group 154" transform="translate(0 0)">
        <g
          id="Group_153"
          data-name="Group 153"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_112"
            data-name="Path 112"
            d="M10.65,7.518a2.206,2.206,0,0,1-1.315.432,2.249,2.249,0,0,1-.51-.059.107.107,0,1,1,.049-.209,2.032,2.032,0,0,0,.461.053,1.994,1.994,0,0,0,1.237-.428l0,0A2,2,0,0,0,9.335,3.728a1.979,1.979,0,0,0-.785.16.108.108,0,1,1-.085-.2,2.2,2.2,0,0,1,.87-.178h.05A1.674,1.674,0,0,0,7.914,1.041a1.639,1.639,0,0,0-.487.073,1.3,1.3,0,0,1-1.295,1.27.108.108,0,0,1,0-.217,1.079,1.079,0,1,0,0-2.159A.964.964,0,0,0,5.2,1.087V14.156a1.412,1.412,0,0,0,2.825,0,1.558,1.558,0,0,0-.01-.172,1.976,1.976,0,0,1-.583-.252,2,2,0,0,1-.724-.811.107.107,0,1,1,.193-.094,1.775,1.775,0,0,0,1.228.963h.009a1.783,1.783,0,1,0,.367-3.529.108.108,0,0,1,0-.215,2,2,0,0,1,1.508.686,1.739,1.739,0,0,0,.635-3.215m-2.067,3.89a.108.108,0,0,1,.02.152.107.107,0,0,1-.15.02,1.6,1.6,0,0,0-1.958-.059,1.462,1.462,0,0,0-.511,1.062,1.748,1.748,0,0,0,.5,1.317.108.108,0,0,1-.078.183.108.108,0,0,1-.076-.033,1.961,1.961,0,0,1-.562-1.48,1.683,1.683,0,0,1,.59-1.217A1.745,1.745,0,0,1,5.8,9.647,1.623,1.623,0,0,1,6.386,8.8a1.869,1.869,0,0,1-.274-1.028,2,2,0,0,1,.647-1.342.106.106,0,0,1,.152.005.107.107,0,0,1-.005.153,1.782,1.782,0,0,0-.578,1.192,1.9,1.9,0,0,0,1.3,1.777.107.107,0,1,1-.078.2,2.357,2.357,0,0,1-1.048-.778,1.406,1.406,0,0,0-.5.727,1.537,1.537,0,0,0,.528,1.525,1.856,1.856,0,0,1,2.043.182m.2-2.18a.108.108,0,0,1-.105.084l-.024,0a.109.109,0,0,1-.081-.129.68.68,0,0,0-.875-.8,1.83,1.83,0,0,1-.685.182H7a.108.108,0,0,1-.006-.215,1.585,1.585,0,0,0,.487-.109A.97.97,0,0,1,7.6,8.186a1.554,1.554,0,0,0,.319-2.611l-.069-.041A2.2,2.2,0,0,1,7.672,5.4l-.005,0a.106.106,0,0,1-.031-.026A1.752,1.752,0,0,1,7.16,3.3a2.01,2.01,0,0,1,.775-.9.106.106,0,0,1,.148.033.107.107,0,0,1-.033.149,1.714,1.714,0,0,0-.79,1.8,1.573,1.573,0,0,0,.548.851,1.958,1.958,0,0,1,.22.152,1.827,1.827,0,0,0,.92.25,1.734,1.734,0,0,0,.289-.024.108.108,0,0,1,.035.213,1.982,1.982,0,0,1-.333.028,2.021,2.021,0,0,1-.548-.076,1.757,1.757,0,0,1-.28,2.333c-.016.015-.034.029-.05.043a.894.894,0,0,1,.717,1.081"
            transform="translate(1.312 0.002)"
            fill="#f1f1f1"
          />
          <path
            id="Path_113"
            data-name="Path 113"
            d="M5.368,0a1.079,1.079,0,1,0,0,2.159.108.108,0,0,1,0,.215A1.3,1.3,0,0,1,4.073,1.1a1.675,1.675,0,0,0-1.957,2.4h.049a2.2,2.2,0,0,1,.87.178.107.107,0,1,1-.084.2A2,2,0,0,0,.924,7.295l0,0a1.994,1.994,0,0,0,1.237.428,1.964,1.964,0,0,0,.461-.054.11.11,0,0,1,.13.081.108.108,0,0,1-.081.129,2.233,2.233,0,0,1-.51.059A2.206,2.206,0,0,1,.85,7.51a1.739,1.739,0,0,0,.636,3.215,1.993,1.993,0,0,1,1.506-.687.108.108,0,1,1,0,.215,1.784,1.784,0,1,0,.367,3.53h.009A1.771,1.771,0,0,0,4.6,12.818a.108.108,0,0,1,.194.1,2.015,2.015,0,0,1-.725.811,2,2,0,0,1-.583.252,1.558,1.558,0,0,0-.01.172,1.413,1.413,0,0,0,2.826,0V1.079A.965.965,0,0,0,5.368,0M2.931,9.173A.107.107,0,0,1,2.85,9.3l-.024,0a.109.109,0,0,1-.105-.084.9.9,0,0,1,.333-.913.887.887,0,0,1,.384-.168L3.39,8.1a1.759,1.759,0,0,1-.282-2.333,2.02,2.02,0,0,1-.548.075,2.144,2.144,0,0,1-.333-.026A.108.108,0,0,1,2.261,5.6a1.745,1.745,0,0,0,.289.024,1.827,1.827,0,0,0,.92-.25,1.958,1.958,0,0,1,.22-.152A1.558,1.558,0,0,0,4.24,4.37a1.712,1.712,0,0,0-.789-1.794.109.109,0,0,1-.034-.149.107.107,0,0,1,.149-.033,2,2,0,0,1,.774.9,1.748,1.748,0,0,1-.476,2.071.092.092,0,0,1-.031.026l-.005,0a1.958,1.958,0,0,1-.183.134l-.069.041A1.554,1.554,0,0,0,3.9,8.178a.9.9,0,0,1,.123.055,1.622,1.622,0,0,0,.488.109.108.108,0,0,1-.008.215H4.491a1.836,1.836,0,0,1-.685-.182.68.68,0,0,0-.875.8m2.211,2.172a1.683,1.683,0,0,1,.59,1.217,1.961,1.961,0,0,1-.562,1.48.108.108,0,0,1-.076.033.1.1,0,0,1-.075-.031.107.107,0,0,1,0-.152,1.756,1.756,0,0,0,.5-1.317,1.473,1.473,0,0,0-.512-1.063,1.6,1.6,0,0,0-1.957.06.108.108,0,1,1-.131-.172,1.856,1.856,0,0,1,2.043-.182,1.537,1.537,0,0,0,.528-1.525,1.393,1.393,0,0,0-.5-.727,2.357,2.357,0,0,1-1.048.778.1.1,0,0,1-.039.008.108.108,0,0,1-.039-.209,1.9,1.9,0,0,0,1.3-1.776,1.789,1.789,0,0,0-.578-1.193.106.106,0,0,1-.005-.152.107.107,0,0,1,.153-.005,2,2,0,0,1,.646,1.342,1.85,1.85,0,0,1-.273,1.027,1.629,1.629,0,0,1,.583.853A1.745,1.745,0,0,1,5.143,11.346Z"
            transform="translate(0 0)"
            fill="#f1f1f1"
          />
        </g>
      </g>
    </svg>
  );
};

export default BrandBuildIcon;
