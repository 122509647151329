import { configureStore } from "@reduxjs/toolkit";
import {
  isEnterOnInput,
  booleanStatus,
  totalSizeSlice,
  mediaViewerData,
  bountiSubmissionData,
  CheckListStatus,
  bountiCreatedTabs,
  mediaUploaderData,
  mediaUploaderSignles,
  pinsData
} from "./slices";

// redux store which is set in slices folder
export const store = configureStore({
  reducer: {
    isEnterOnInput,
    pinsData,
    booleanStatus,
    mediaViewerData,
    bountiSubmissionData,
    totalSizeSlice,
    CheckListStatus,
    bountiCreatedTabs,
    mediaUploaderData,
    mediaUploaderSignles,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
