import React, { useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import styles from "./given.module.css";

export default function SponserCarousel({ sponserData, onHover }: any) {
  const [sponsorHoveredIndex, setSponsorHoveredIndex] = useState<number | null>(
    null
  );

  const handleSlideHover = (index: number) => {
    setSponsorHoveredIndex(index);
    onHover(index); // Pass the hovered index to the parent component
  };
  return (
    <div className="flex">
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className={`${styles.reward_carousel}`}
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {sponserData?.map((singleSponser: any, index: number) => {
          console.log(singleSponser, "sponserDatasponserData");
          return (
            <SwiperSlide
              // key={`${Math.random() * Date.now() ** 2}`}
              key={index}
              onMouseEnter={() => handleSlideHover(index)}
            >
              <div
                style={{
                  width: "100%",
                  height: "5em",
                }}
              >
                <img
                  src={singleSponser?.image}
                  style={{
                    borderRadius: "0.4em",
                    maxWidth: "100%",
                    maxHeight: "5em",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
