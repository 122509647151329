const BountisIcon = () => {
  return (
    <svg
      style={{ marginRight: "1em" }}
      xmlns="http://www.w3.org/2000/svg"
      width="15.225"
      height="15.071"
      viewBox="0 0 15.225 15.071"
    >
      <path
        id="Path_111"
        data-name="Path 111"
        d="M5.44,0V3.774L2.171,1.887,0,5.648,3.269,7.535,0,9.422l2.172,3.761L5.44,11.3v3.774H9.783V11.3l3.267,1.887,2.173-3.761L11.955,7.535l3.269-1.887L13.051,1.887,9.783,3.774V0Z"
        transform="translate(0 0)"
        fill="#f1f1f1"
      />
    </svg>
  );
};

export default BountisIcon;
