import { useDispatch, useSelector } from "react-redux";
import "./filemanager.css";
import {
  addCurrentMediaTab,
  addCurrentStoredAssetTab,
} from "../../app/slices/singleStates";
import UserRelatedMedia from "./UserRelatedMedia";
import { addStoredData } from "../../apis/postApis";

const StoredAssetsTab = ({ selectedId }: any) => {
    // Redux state variables
  const nowTab = useSelector(
    (state: any) => state.booleanStatus.currentMediaTab
  );
  const currentStoredAssetTab = useSelector(
    (state: any) => state.booleanStatus.currentStoredAssetTab
  );

  // Redux dispatch function
  const dispatch = useDispatch();
  return (
    <section
      className="FM__Relative__PArent"
      style={nowTab === 0 ? { display: "none" } : { display: "block" }}// Conditionally hide or show based on tab
    >
      <section className="FM__wrapper--child">
        <div className="FM__wrapper heightOver___">
          <div className="FM__left__main FM__60">{/* Left section */}
            <div>
              <img
                src="./tests/deadpool.webp"
                alt=""
                className="FM__T2__CapacityMedia"
              />
            </div>
          </div>

          <div className="FM__right__main FM__36">{/* Right section */}
            <div className="FM__TAB_"> {/* Tab navigation */}
              <button
                className=" TAB_BtN TAB_Left"// Current bounty tab
                onClick={() => {
                  dispatch(addCurrentMediaTab(0)); // Set current tab to 0
                }}
              >
                Current Bounty
              </button>
              {/* Stored assets tab */}
              <button className="TAB_BtN TAB_Right FM__act__TAB">
                Stored Assests
              </button>
            </div>
          </div>
        </div>
        {/* Heading and description */}
        <div className="FM__T2__HeadingWithText">
          <h1 className="FM__DROPPer__show">
            These are assets from previous uploads.
          </h1>
          <p className="FM__Dropper__desc FM__Center">
            Select files you want to add to your current bounty
          </p>
        </div>
        {/* Stored assets tab navigation */}
        <div className="FM__Stored_Tab ">
          <div className="flex">
            <button
              className={
                currentStoredAssetTab === "IMAGE"
                  ? "FM__act__TAB_ TAB_BtN_ TAB_Left"
                  : "TAB_BtN_ TAB_Left"
              }
              onClick={() => {
                dispatch(addCurrentStoredAssetTab("IMAGE"));// Set current tab to IMAGE
              }}
            >
              Image
            </button>
            <button
              className={
                currentStoredAssetTab === "AUDIO"
                  ? "TAB_BtN_ FM__act__TAB_"
                  : "TAB_BtN_"
              }
              onClick={() => {
                dispatch(addCurrentStoredAssetTab("AUDIO"));// Set current tab to AUDIO
              }}
            >
              Audio
            </button>
            <button
              className={
                currentStoredAssetTab === "TEXT"
                  ? "TAB_BtN_ FM__act__TAB_"
                  : "TAB_BtN_"
              }
              onClick={() => {
                dispatch(addCurrentStoredAssetTab("TEXT"));// Set current tab to TEXT
              }}
            >
              Text
            </button>
            <button
              className={
                currentStoredAssetTab === "VIDEO"
                  ? "TAB_BtN_ TAB_Right FM__act__TAB_"
                  : "TAB_BtN_ TAB_Right"
              }
              onClick={() => {
                dispatch(addCurrentStoredAssetTab("VIDEO"));// Set current tab to VIDEO
              }}
            >
              Videos
            </button>
          </div>
        </div>
        <div className="FM__stored_grid">
          {/* User related media */}
          <UserRelatedMedia bountyId={selectedId} />
          {/* <ImageCard styles={{ marginLeft: "0.5rem",marginTop:"1rem" }} type={"SA"} />
          <ImageCard styles={{ marginLeft: "0.5rem",marginTop:"1rem" }} type={"SA"} />
          <ImageCard styles={{ marginLeft: "0.5rem",marginTop:"1rem" }} type={"SA"} />
          <ImageCard styles={{ marginLeft: "0.5rem",marginTop:"1rem" }} type={"SA"} />
          <ImageCard styles={{ marginLeft: "0.5rem",marginTop:"1rem" }} type={"SA"} /> */}
        </div>
        {/* <button className="FM__BUTTON">Add to Bounty</button> */}
        
      </section>
    </section>
  );
};

export default StoredAssetsTab;
