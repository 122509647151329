import { viewReward } from "../../apis/getApis";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useEffect, useState } from "react";
import "./reward.css";
import { Radio, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRewardDialogCreation,
  toggleRewardPopopOnlyCreate,
} from "../../app/slices/singleStates";
import { editTermsAndConditions } from "../../apis/putApis";
import {
  convertImageToFile,
  editReward,
  fetchAllRewards,
  fetchRewardsCategory,
  uploadReward,
} from "../../Functions/MediaUploaderF";
import Alert from "./Alert";
import Header from "../../apis/header";
import { globalBounti2, getDefaultTerms } from "../../apis/getApis";
import { Input } from "antd";
import React, { useRef } from "react";

export default function OnlyCreateReward({ isEditable = true, bountyId }: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [rewardCategoryList, setRewardCategory] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [postData, setPostData] = useState<any>({
    categories: "",
    value: "",
    description: "",
    title: "",
    image: null,
    type: "",
    deliveryMethod: "",
  });
  const dispatch = useDispatch();
  const [isDefault, setIsDefault] = useState<boolean>();
  const [imageFile, setImageFile] = useState(null);
  const [termsAndConditons, setTermsAndConditons] = useState<any>({
    Eligibility: "",
    EntryPeriod: "",
    HowToEnter: "",
    Prizes: "",
    SelectionOfWinners: "",
    PrizeRedemption: "",
    RewardSubjectToChange: "",
  });
  const [projectName, setProjectName] = useState<any>("Project Series Name");

  // console.log("Terms_and_condition", termsAndConditons);
  useEffect(() => {
    convertImageToFile(setImageFile, "./tests/defaultReward.png", bountyId);
    if (isDefault) {
      setPostData({ ...postData, image: imageFile });
    } else {
      setPostData({ ...postData, image: null });
    }
  }, [isDefault]);

  useEffect(() => {
    fetchRewardsCategory(setRewardCategory, bountyId);
  }, []);

  // terms and conditions get API
  // useEffect(() => {
  //   axios
  //     .get(`${getDefaultTerms}`)
  //     .then((res) => {
  //       console.log(res, "globalBounti2");
  //       if (res.data.text.termsAndConditions) {
  //         setTermsAndConditons({
  //           Eligibility: res.data.text.termsAndConditions.Eligibility || "",
  //           EntryPeriod: res.data.text.termsAndConditions.EntryPeriod || "",
  //           HowToEnter: res.data.text.termsAndConditions.HowToEnter || "",
  //           Prizes: res.data.text.termsAndConditions.Prizes || "",
  //           SelectionOfWinners:
  //             res.data.text.termsAndConditions.SelectionOfWinners || "",
  //           PrizeRedemption:
  //             res.data.text.termsAndConditions.PrizeRedemption || "",
  //           RewardSubjectToChange:
  //             res.data.text.termsAndConditions.RewardSubjectToChange || "",
  //         });
  //       } else {
  //         console.log(
  //           "termsAndConditions property not found in the API response."
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  // get default Terms & Condition API
  useEffect(() => {
    axios
      .get(`${getDefaultTerms}`)
      .then((res) => {
        console.log(res, "getDefaultTerms");
        if (res.data.length > 0) {
          // Access the first element of the array
          const defaultTerms = res.data[0].defaultTermsAndConditions;
          console.log(defaultTerms, "defaultTerms getDefaultTerms");
          setTermsAndConditons({
            Eligibility: defaultTerms.Eligibility || "",
            EntryPeriod: defaultTerms.EntryPeriod || "",
            HowToEnter: defaultTerms.HowToEnter || "",
            Prizes: defaultTerms.Prizes || "",
            SelectionOfWinners: defaultTerms.SelectionOfWinners || "",
            PrizeRedemption: defaultTerms.PrizeRedemption || "",
            RewardSubjectToChange: defaultTerms.RewardSubjectToChange || "",
          });
        } else {
          console.log("No default terms found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching getDefaultTerms data:", error);
      });
  }, []);

  const handleDefaultTerms = () => {
    setShowModal(true);
    // if (window.confirm("Do you want to use the default T&C?")) {
    // fetchDefaultTerms();
    // }
  };

  // Function to fetch Default Terms & Condition
  const fetchDefaultTerms = () => {
    axios
      .get(`${getDefaultTerms}`, Header)
      .then((res) => {
        console.log(res, "getDefaultTerms default");
        // Check if the response array has at least one element
        if (res.data.length > 0) {
          // Access the first element of the array
          const defaultTerms = res.data[0].defaultTermsAndConditions;
          console.log(defaultTerms, "defaultTerms getDefaultTerms");
          setTermsAndConditons({
            Eligibility: defaultTerms.Eligibility || "",
            EntryPeriod: defaultTerms.EntryPeriod || "",
            HowToEnter: defaultTerms.HowToEnter || "",
            Prizes: defaultTerms.Prizes || "",
            SelectionOfWinners: defaultTerms.SelectionOfWinners || "",
            PrizeRedemption: defaultTerms.PrizeRedemption || "",
            RewardSubjectToChange: defaultTerms.RewardSubjectToChange || "",
          });
          axios
            .put(
              `${editTermsAndConditions}/${bountyId}`,
              {
                termsAndConditions: {
                  ...defaultTerms, // Use default terms instead of state
                },
              },
              Header
            )
            .then((res) => {
              console.log(res, "terms_edit getDefaultTerms");
              // Handle response if needed
            })
            .catch((err) => {
              console.log(err, "terms_edit getDefaultTerms");
              // Handle error if needed
            });
        } else {
          console.log("No default terms found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching getDefaultTerms terms:", error);
      });
    setShowModal(false);
  };

  // Cancle to fetch Default Terms & Condition
  const handleCancel = () => {
    console.log("handleCancel callleddd");
    setShowModal(false);
  };

  const handleTermsChange = (field: string, value: string) => {
    setTermsAndConditons({
      ...termsAndConditons,
      [field]: value,
    });

    // Call API to update terms and conditions
    axios
      .put(
        `${editTermsAndConditions}/${bountyId}`,
        {
          termsAndConditions: {
            ...termsAndConditons,
            [field]: value,
          },
        },
        Header
      )
      .then((res: any) => {
        console.log(res, "terms_edit_only");
        // Handle response if needed
      })
      .catch((err: any) => {
        console.log(err, "terms_edit_only");
        // Handle error if needed
      });
  };

  // category Change function
  const changeCategory = (e: any) => {
    setPostData({ ...postData, categories: e.target.value });
  };

  // type change function
  const changeType = (e: any) => {
    setPostData({ ...postData, type: e.target.value });
  };

  // chnage delivery method
  const changeDeliveryMethod = (e: any) => {
    setPostData({ ...postData, deliveryMethod: e.target.value });
  };

  // change Title
  const changeTitle = (e: any) => {
    setPostData({ ...postData, title: e.target.value });
  };

  // change Description
  const changeDescription = (e: any) => {
    setPostData({ ...postData, description: e.target.value });
  };

  const changeValue = (e: any) => {
    setPostData({ ...postData, value: e.target.value });
  };

  //   const changeRewardFile = (e: any) => {
  //     console.log(changeRewardFile, "changeRewardFile called");
  // console.log("krinall bug 1",e)
  //    setPostData({ ...postData, image: e.target.files[0]});
  //     // console.log(postData, "postdataa");
  //   };
  const changeRewardFile = () => {
    const file = fileInputRef.current?.files?.[0];
    if (file) {
      setPostData({ ...postData, image: file });
    }
  };

  // Function for Save Reward
  const saveReward = () => {
    setLoading(true);
    const bool: any = uploadReward(
      postData,
      setPostData,
      dispatch,
      setLoading,
      bountyId
    );
  };

  // Function to upload file from local
  const openFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.log("File input not found");
    }
  };

  // term and conditions changes
  // useEffect(() => {
  //   const getData = setTimeout(async () => {
  //     await axios
  //       .put(
  //         `${editTermsAndConditions}/${bountyId}`,
  //         {
  //           termsAndConditions: termsAndConditons,
  //         },
  //         Header
  //       )
  //       .then((res: any) => {})
  //       .catch((err: any) => {});
  //   }, 2000);
  //   return () => clearTimeout(getData);
  // }, [termsAndConditons]);

  return (
    <>
      {/* Set default Terms & Condition confirmation popup */}
      <div
        className="flex"
        style={
          showModal
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={handleCancel}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
              {loading ? "loading..." : "Do you want to use the default T&C?"}
            </p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={fetchDefaultTerms}
                  disabled={loading}
                >
                  Yes
                </button>
                <button
                  className="delete__asset_confirm delete__asset_confirm_cancle"
                  onClick={handleCancel}
                >
                  No
                </button>
              </>
            </div>
          </div>
        </div>
      </div>

      {/*Reward ceation popup close button  */}
      <div
        className="close_dialog_wrapper__Reward__Creation"
        style={{ width: "60%" }}
      >
        <button
          className="close_dialog__Reward__Creation"
          onClick={() => {
            dispatch(toggleRewardPopopOnlyCreate(false));
          }}
        >
          X
        </button>
      </div>

      {/* reward ppup Save button */}
      <div className="Reward--wrapper relative">
        <button
          className="Reward__Save__BUTTON"
          onClick={saveReward}
          disabled={loading}
        >
          {loading ? "uploading..." : "Save"}
        </button>

        <div>
          {/* First Row data */}
          <div className="Reward--first--row--data">
            {/* first row left side data */}
            <div className="Reward--first--row--data--left">
              <div className="first-sentence">
                Your Reward Details{" "}
                <img
                  src="./tests/icons/info.svg"
                  alt=""
                  style={{
                    marginLeft: "0.4rem",
                    cursor: "pointer",
                    width: "1rem",
                  }}
                />
              </div>
              <div className="second-sentence">
                Fill in the details you wish to promote your Bounty
              </div>
            </div>

            {/* first row right side data */}
            <div className="Reward--first--row--data--right">
              <div className="first-sentence">Reward Preview</div>
              <div className="second-sentence">
                This is how your reward will appear, by default and larger on
                hover
              </div>
            </div>
          </div>

          {/* Second Row Data */}
          <div className="Reward--second--row--data">
            {/* second row left side sata */}
            <div className="Reward--second--row--data--left">
              <form>
                <div className="form-field">
                  <div className="row-container">
                    {/* select category */}
                    <div>
                      <label htmlFor="category" className="reawrd_popup_label">
                        Category:
                      </label>
                      <div
                        style={{ position: "relative", marginTop: "0.5rem" }}
                      >
                        <select
                          disabled={!isEditable}
                          id="category"
                          name="category"
                          className="dropdown"
                          value={postData.categories}
                          onChange={changeCategory}
                          style={{
                            border: "1px solid var(--orange)",
                            paddingLeft: "0.5rem",
                            fontSize: "1rem",
                            fontWeight: "400",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ display: "none" }}
                          >
                            Select Category
                          </option>
                          {rewardCategoryList?.map((cat: any) => {
                            return (
                              <option value={cat?.value}>{cat?.value}</option>
                            );
                          })}
                        </select>
                        <img
                          className="reward_select_downarrow"
                          src="./tests/icons/downarrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* select default image or upload new */}
                    <div className="radio-group flex items-center">
                      <div>
                        <label className="reawrd_popup_label">
                          Default Image:
                        </label>
                        <Radio.Group
                          defaultValue="b"
                          buttonStyle="solid"
                          disabled={!isEditable}
                          style={{ marginTop: "0.5rem" }}
                        >
                          <Radio.Button
                            value="a"
                            onClick={(e) => {
                              setIsDefault(true);
                            }}
                          >
                            Yes
                          </Radio.Button>
                          <Radio.Button
                            value="b"
                            onClick={(e) => {
                              setIsDefault(false);
                            }}
                          >
                            No
                          </Radio.Button>
                        </Radio.Group>
                      </div>

                      <img
                        src={"./tests/defaultReward.png"}
                        style={
                          isDefault
                            ? {
                                width: "1.6rem",
                                height: "1.3rem",
                                marginLeft: "1rem",
                              }
                            : { display: "none" }
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-field">
                  <div className="form-filed2">
                    <div className="category__sub__type">
                      {/* select Type */}
                      <label htmlFor="category1" className="reawrd_popup_label">
                        Type
                      </label>
                      <div
                        className="row-container"
                        style={{ position: "relative" }}
                      >
                        <select
                          id="category1"
                          name="category1"
                          disabled={!isEditable}
                          className="dropdown__2"
                          onChange={changeType}
                          value={postData.type}
                          style={{
                            border: "1px solid var(--orange)",
                            paddingLeft: "0.5rem",
                            fontSize: "1rem",
                            fontWeight: "400",
                            cursor: "pointer",
                            marginTop: "0.5rem",
                          }}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ display: "none" }}
                          >
                            Select Type
                          </option>
                          <option value="Digital">Digital</option>
                          {/* <option value="Physical">Physical</option> */}
                        </select>
                        <img
                          className="reward_select_downarrow2"
                          src="./tests/icons/downarrow.svg"
                          alt=""
                        />
                      </div>
                    </div>

                    {/* select Delivery Method */}
                    <div className="delivery__method">
                      <label htmlFor="category2" className="reawrd_popup_label">
                        Delivery Method
                      </label>
                      <div
                        className="row-container"
                        style={{ position: "relative" }}
                      >
                        <select
                          id="category2"
                          name="category2"
                          className="dropdown__2"
                          disabled={!isEditable}
                          onChange={changeDeliveryMethod}
                          value={postData.deliveryMethod}
                          style={{
                            border: "1px solid var(--orange)",
                            paddingLeft: "0.5rem",
                            fontSize: "1rem",
                            fontWeight: "400",
                            cursor: "pointer",
                            marginTop: "0.5rem",
                          }}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ display: "none" }}
                          >
                            Select Method
                          </option>
                          <option className="option_reawrd" value="email">
                            email
                          </option>
                          <option className="option_reawrd" value="personnel">
                            personnel
                          </option>
                        </select>
                        <img
                          className="reward_select_downarrow3"
                          src="./tests/icons/downarrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Enter Reward Value */}
                <div className="form-field">
                  <label htmlFor="value" className="reawrd_popup_label">
                    Value
                  </label>
                  <input
                    type="text"
                    id="value"
                    disabled={!isEditable}
                    name="value"
                    value={postData.value}
                    onChange={changeValue}
                    style={{
                      color: "var(--paragraphText)",
                      paddingLeft: "1.5rem",
                    }}
                  />
                </div>

                {/* Enter Reward Title */}
                <div className="form-field">
                  <label htmlFor="title" className="reawrd_popup_label">
                    Title:
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    disabled={!isEditable}
                    value={postData.title}
                    onChange={changeTitle}
                    style={{ color: "var(--paragraphText)" }}
                  />
                </div>
                {/* Enter Reward Description */}
                <div className="form-field">
                  <label htmlFor="description" className="reawrd_popup_label">
                    Description:
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    maxLength={500}
                    value={postData.description}
                    disabled={!isEditable}
                    rows={6 as number}
                    style={{ resize: "none" }}
                    onChange={changeDescription}
                  ></textarea>
                </div>
              </form>
            </div>

            {/* second row right side data */}
            <div className="Reward--second--row--data--right">
              <div className="Reward__game__pass">
                <div className="Reward__game__pass_r1_image">
                  <img
                    src={
                      postData.image && typeof postData.image != "string"
                        ? URL.createObjectURL(postData.image)
                        : postData.image
                    }
                    style={{ width: "50%", height: "50%" }}
                  />
                </div>
                <div className="Reward__game__pass__text">
                  <div className="first-sentence">{postData.title}</div>
                  <div className="second-sentence">{postData.categories}</div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "var(--grayShade1)",
                  marginTop: "2rem",
                }}
              >
                This is how your reward will appear on hover or tap
              </div>
              <div className="reward__right_main__view__Container">
                <div>
                  <div className="third__row_right__box">
                    <div className="third__row_right__image">
                      <img
                        style={{ width: "50%", height: "50%" }}
                        src={
                          postData.image && typeof postData.image != "string"
                            ? URL.createObjectURL(postData.image)
                            : postData.image
                        }
                        alt=""
                      />
                    </div>
                    <div className="third__row_right__text">
                      {/* 1 */}
                      <div className="third__row_right__text1">
                        {postData?.title}
                      </div>

                      {/* 2 */}
                      <div className="third__row_right__text2">
                        {postData?.categories}
                      </div>

                      {/* 3 */}
                      {/* <div className="third__row_right__text3">
                            {postData?.description}
                          </div> */}
                    </div>
                    <div
                      className="reward__hover__card__top__right"
                      style={{ paddingRight: "1rem" }}
                    >
                      <div>
                        <h2 style={{ fontSize: "0.8rem", width: "8rem" }}>
                          Reward Type:
                        </h2>
                        <b style={{ fontSize: "0.8rem", width: "8rem" }}>
                          {postData?.type}
                        </b>
                      </div>
                      <div>
                        <h2 style={{ fontSize: "0.8rem", width: "8rem" }}>
                          Value:
                        </h2>
                        <b style={{ fontSize: "0.8rem", width: "8rem" }}>
                          {postData?.value}
                        </b>
                      </div>
                      <div>
                        <h2 style={{ fontSize: "0.8rem", width: "8rem" }}>
                          Delivery Method:
                        </h2>
                        <b style={{ fontSize: "0.8rem", width: "8rem" }}>
                          {postData?.deliveryMethod}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "1rem" }}
                    className="postDataView__description__reawrd"
                  >
                    {postData?.description}
                  </div>
                </div>

                {/* <div className="terms__condition__box">
                  <div
                    onClick={handleDefaultTerms}
                    style={{
                      backgroundColor: "var(--pure)",
                      width: "10rem",
                      marginTop: "0.5rem",
                      borderRadius: "0.5rem",
                      color: "var(--sidebar_secondory)",
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                  >
                    Set Default T&C
                  </div>
                  <div className="terms_box_head">Terms</div>
                  <ul className="reward__termsACond">
              
                    <li>
                      <label htmlFor="Eligibility" className="terms__labels">
                        Eligibility:
                      </label>
                      <div>
                        <textarea
                          id="Eligibility"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.Eligibility}
                          onChange={(e) =>
                            handleTermsChange("Eligibility", e.target.value)
                          }
                          name="Eligibility"
                          className="termsTextAreas"
                          disabled={!isEditable}
                        />
                      </div>
                    </li>
            
                    <li>
                      <label htmlFor="EntryPeriod" className="terms__labels">
                        Entry Period:
                      </label>
                      <div>
                        <textarea
                          id="EntryPeriod"
                          style={{ resize: "none", width: "100%" }}
                          value={termsAndConditons?.EntryPeriod}
                          rows={6}
                          onChange={(e) =>
                            handleTermsChange("EntryPeriod", e.target.value)
                          }
                          name="EntryPeriod"
                          className="termsTextAreas"
                          disabled={!isEditable}
                        />
                      </div>
                    </li>
                
                    <li>
                      <label htmlFor="HowToEnter" className="terms__labels">
                        How To Enter:
                      </label>
                      <div>
                        <textarea
                          id="HowToEnter"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.HowToEnter}
                          onChange={(e) =>
                            handleTermsChange("HowToEnter", e.target.value)
                          }
                          name="HowToEnter"
                          className="termsTextAreas"
                          disabled={!isEditable}
                        />
                      </div>
                    </li>
               
                    <li>
                      <label htmlFor="Prizes" className="terms__labels">
                        Prizes:
                      </label>
                      <div>
                        <textarea
                          id="Prizes"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.Prizes}
                          onChange={(e) =>
                            handleTermsChange("Prizes", e.target.value)
                          }
                          name="Prizes"
                          className="termsTextAreas"
                          disabled={!isEditable}
                        />
                      </div>
                    </li>
        
                    <li>
                      <label htmlFor="Prizes" className="terms__labels">
                        Selection Of Winners:
                      </label>
                      <div>
                        <textarea
                          id="SelectionOfWinners"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.SelectionOfWinners}
                          onChange={(e) =>
                            handleTermsChange(
                              "SelectionOfWinners",
                              e.target.value
                            )
                          }
                          name="SelectionOfWinners"
                          className="termsTextAreas"
                          disabled={!isEditable}
                        />
                      </div>
                    </li>
              
                    <li>
                      <label htmlFor="Prizes" className="terms__labels">
                        Prize Redemption:
                      </label>
                      <div>
                        <textarea
                          id="PrizeRedemption"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.PrizeRedemption}
                          onChange={(e) =>
                            handleTermsChange("PrizeRedemption", e.target.value)
                          }
                          name="PrizeRedemption"
                          className="termsTextAreas"
                        />
                      </div>
                    </li>
        
                    <li>
                      <label
                        htmlFor="RewardSubjectToChange"
                        className="terms__labels"
                      >
                        Reward Subject To Change:
                      </label>
                      <div>
                        <textarea
                          id="RewardSubjectToChange"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.RewardSubjectToChange}
                          onChange={(e) =>
                            handleTermsChange(
                              "RewardSubjectToChange",
                              e.target.value
                            )
                          }
                          name="RewardSubjectToChange"
                          className="termsTextAreas"
                          disabled={!isEditable}
                        />
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          {/* Third Row Data */}
          <div className="Reward--third--row--data">
            {/* third row left side data */}

            <div className="Reward--third--row--data--left" onClick={openFile}>
              <div className="Reward__DROpper_image">
                {postData.image ? (
                  <img
                    src={
                      postData.image && typeof postData.image != "string"
                        ? URL.createObjectURL(postData.image)
                        : postData.image
                    }
                    alt="file_upload_image"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "1rem",
                    }}
                  />
                ) : (
                  <img
                    src="./tests/uploadImage.svg"
                    style={{ width: "4rem", height: "4rem" }}
                    alt="file_upload_image"
                  />
                )}

                <h1
                  className="reward__File__Upload"
                  style={isEditable ? {} : { display: "none" }}
                >
                  Drag your image, or browse.
                </h1>
                <p
                  className="reward__File__Upload__desc"
                  style={isEditable ? {} : { display: "none" }}
                >
                  For a best results choose a png image without a background
                </p>
                <input
                  type="file"
                  id="rewardfileInput"
                  ref={fileInputRef}
                  onChange={changeRewardFile}
                  style={{
                    visibility: "hidden",
                    width: "0.1px",
                    height: "0.1px",
                  }}
                  accept="image/*"
                />
              </div>
            </div>

            {/* arrow button of reward carousel */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "60%",
                marginTop: "3rem",
              }}
            ></div>
            {/* arrow button of reward carousel */}

            {/* save button */}
          </div>
        </div>
      </div>
      <Alert></Alert>
    </>
  );
}
