import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Initialize states
const initialState: any = {
  currentFocusCard: 0, // Default value for currentFocusCard
};

// Create a slice for managing single values related to media uploader
export const mediaUploaderSignles = createSlice({
  name: "mediaUploaderSignles",
  initialState,
  reducers: {
    addcurrentFocusCardNumber: (state: any, action: PayloadAction<any>) => {
      state.currentFocusCard = action.payload;
    },
  },
});

// Export action creators
export const { addcurrentFocusCardNumber } = mediaUploaderSignles.actions;

// Export reducer
export default mediaUploaderSignles.reducer;
