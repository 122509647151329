import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Checkbox, Input } from "antd";
import { SwiperSlide, Swiper } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { setSubmissionScreen } from "../../app/slices/singleStates";
import { setIsReviewedReward } from "../../app/slices/bountiSubmissionData";
import { RootState } from "../../app/store";
import axios from "axios";
import { getRewards } from "../../apis/getApis";
const { TextArea } = Input;

const Step3 = ({ reduxBountyId }: any) => {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = React.useState<any>();
  const [rewards, setRewardsData] = useState<any>([]);
  useEffect(() => {
    fetchAllRewards(setRewardsData, reduxBountyId);
  }, []);
  const reduxSubmissionData = useSelector((state: RootState) => {
    return state.bountiSubmissionData;
  });
  console.log(reduxSubmissionData,"reduxSubmissionData")
  const { currentSubmission } = useSelector(
    (state: any) => state.booleanStatus
  );
  // console.log(currentSubmission,"currentSubmission_reward")
  const [rewardStatus, setRewardStatus] = useState(
     false
  );

  // useEffect(() => {
  //   if (currentSubmission) {
  //     setRewardStatus(currentSubmission.rewardReview || "");
  //   }
  // }, [currentSubmission]);

  // media swiper init
  const mediaSwiperInit = (e: any) => {
    setSwiper(e);
  };
  // changeCheckbox
  const changeCheckbox = (e: any) => {
    // const checked = e.target.checked;
    // setRewardStatus(checked);
    dispatch(setIsReviewedReward(e.target.checked));
  };

  const fetchAllRewards = async (setRewards: any, reduxBountyId: any) => {
    await axios
      .get(`${getRewards}/${reduxBountyId}`)
      .then((res: any) => {
        console.log("fetchedReward_submissionfeed", res.data.rewards);
        setRewards(res.data.rewards);
      })
      .catch((err: any) => {
        console.log("fetchedReward_submissionfeed", err);
      });
  };

  return (
    <section className="submission__step1">
      <div className="submission__step__inner">
        <div className="Submission__POPUpHeading">Rewards</div>

        <div
          className="ancmnt__main__media__swiper overRight__submissionSwiper flex"
          style={{ marginTop: "1rem" }}
        >
          <img
            src="./assets/backward.svg"
            alt="prev"
            style={{ cursor: "pointer" }}
            onClick={() => {
              swiper?.slidePrev();
            }}
          />
          <Swiper
            className="annoucement__swiper"
            slidesPerView={3}
            spaceBetween={15}
            onInit={mediaSwiperInit}
            style={{ paddingLeft: "1rem" }}
          >
            {rewards.map((reward: any, index: number) => (
              <>
                {console.log(reward, "1234567")}
                <SwiperSlide key={index} className="sub_RE_slide">
                  <img
                    src={reward.imagePath}
                    style={{ width: "100%", height: "6rem" }}
                  />
                  <p className="reward__name__submision">{reward.title}</p>
                  <p className="reward__name__submision">{reward.value}</p>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
          <img
            src="./assets/forward.svg"
            alt="prev"
            style={{ cursor: "pointer", marginLeft: "1rem" }}
            onClick={() => {
              swiper?.slideNext();
            }}
          />
        </div>

        {/* isReview reward */}
        <div className="isReviewed__REawrd">
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            onChange={changeCheckbox}
            checked={reduxSubmissionData?.isReviewedReward}
          />
          <p style={{ color: "var(--dark_2)" }}>
            I have reviewed the rewards for this bounty
          </p>
        </div>

        {/* submit */}
        <div style={{ marginTop: "1rem" }} className="step1_sub_buttonR">
          <Button
            className="submit__button_Submisn submit__button_SubmisnReawrd"
            onClick={() => {
              dispatch(setSubmissionScreen(3));
            }}
            style={{ marginLeft: "0.5rem" }}
          >
            Previous
          </Button>
          <Button
            className="submit__button_Submisn submit__button_SubmisnReawrd"
            onClick={() => {
              dispatch(setSubmissionScreen(5));
            }}
            style={{ marginLeft: "0.5rem" }}
            disabled={!reduxSubmissionData?.isReviewedReward}
          >
            Next
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Step3;
