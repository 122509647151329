import React, { useEffect, useState } from "react";
import "./style.css";
import { SwiperSlide, Swiper } from "swiper/react";
import axios from "axios";
import { updateAnnouncementDesc } from "../../apis/putApis";
import { likeAnouncement } from "../../apis/postApis";
import Header from "../../apis/header";
import { getAnnouncementDescription } from "../../apis/getApis";
import { D, U } from "../../apis/main";
import { useDispatch, useSelector } from "react-redux";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { getannounceMedia } from "../../apis/getApis";
import { setAnnonceData } from "../../app/slices/singleStates";

const AnnouncementView = ({
  setToggle,
  currentSelectAnnouncementId,
  innerAnnounceId,
  bountyId,
}: any) => {
  // localstates
  const dispatch = useDispatch();
  const { announcement } = useSelector((state: any) => state.booleanStatus);
  console.log(announcement, "annoucement_view");
  const [swiper, setSwiper] = React.useState<any>();
  const [slideNumber, setSlideNumber] = useState<any>(0);
  const [textArea, setTextArea] = React.useState<any>(
    announcement?.description || ""
  );
  const [selectedMedia, setSelectedMedia] = useState<any>(null); // State to hold selected media
  const [mediaAnnounce, setMediaAnnounce] = React.useState<any>([]);
  const [mainLikeStatus, setMainLikeStatus] = useState<boolean>(false);
  const [mainLikes, setMainLikes] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);

  // get announcement data from redux
  useEffect(() => {
    if (announcement) {
      setTextArea(announcement.description || "");
      setFiles(announcement.mediaData || []);
      // get media
      const getData = async () => {
        try {
          const response = await axios.get(
            `${getannounceMedia}/${announcement?._id}`
          );
          setMediaAnnounce(response.data.media);
          console.log(response, "announcement_media currentSelectMedia");
        } catch (error) {
          console.error(error, "announcement_media");
        }
      };
      getData();
    }
  }, [announcement,files]);

  // set view current media
  const saveCurrentView = (currentSelectMedia: any, ind: any) => {
    console.log(currentSelectMedia, "currentSelectMedia");
    setSelectedMedia(currentSelectMedia); // Update selected media state
    setSlideNumber(ind);

  };

  // media swiper init
  const mediaSwiperInit = (e: any) => {
    setSwiper(e);
  };

  // change annoucement description function
  const changeAnnouncement = async (e: any) => {};

  // like current Annoucement
  const likeCurrentAnnouncement = async (e: any) => {
    await axios
      .post(`${likeAnouncement}/${innerAnnounceId}`, {}, Header)
      .then((response: any) => {
        setMainLikeStatus(response.data.data.userLiked);
        setMainLikes(response.data.data.likes);
      })
      .catch((error: any) => {});
  };

  return (
    <div className="annoucemnet__popup">
      {/* annoucement header goes here */}
      <div className="annoucement__popup__header flex justify-between">
        <div className="annoucement__popup__header__left flex">
          <img
            src={announcement?.userImage}
            alt="user image"
            style={{ width: "4rem", borderRadius: "0.4rem" }}
          />
          <div style={{ marginLeft: "0.5rem" }}>
            <b style={{ color: "var(--orange)" }}>{announcement?.userName}</b>{" "}
            posted ...
          </div>
        </div>
        <div className="annoucement__popup__header__right">
          <div className="annoucement__popup__header__time">
            <b style={{ color: "var(--orange)" }}>{announcement?.timeAgo}</b>
          </div>
          <div className="annoucement__popup__header__engage">
            {/* boost */}
            {/* <div className="boostHere social__cursor flex">
              <img src="./tests/icons/boost.svg" alt="boost_icon" />
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                  marginLeft: "-0.5rem",
                }}
              >
                11
              </span>
            </div> */}
            {/* like */}
            {/* <div className="likehere social__cursor flex">
              <img
                src={
                  !mainLikeStatus
                    ? "./tests/icons/heart.svg"
                    : "./assets/fillHeart.svg"
                }
                alt="heart.svg"
                onClick={likeCurrentAnnouncement}
              />
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                }}
              >
                {mainLikes}
              </span>
            </div> */}
            {/* comment */}
            {/* <div className="commenthere social__cursor flex">
              <img src="./tests/icons/comment2.svg" alt="heart.svg" />
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                }}
              >
                8
              </span>
            </div> */}
          </div>
        </div>
      </div>
      {/* annoucement header end here */}

      {/* annoucement body goes here */}
      <div className="annoucement__popup__body flex justify-between">

        {/* annoucement title */}
        <div className="annoucement__popup__body__left">
          <textarea
            className="anc__subbmission__desc"
            spellCheck={false}
            style={{ resize: "none" }}
            value={textArea}
            // onChange={(e) => {
            //   setTextArea(e.target.value);
            // }}
            readOnly // Add readOnly attribute to disable editing
          />
        </div>

        {/* annoucement media */}
        <div className="annoucement__popup__body__right annoucement__media__view">
          {/* main asset */}
          <div
            className="ancmnt__main__media__frame"
            // onClick={openFile}
          >
            {selectedMedia ? ( // Render selected media if available
              <img
                src={selectedMedia.url}
                alt=""
                style={{
                  width: "25rem",
                  height: "25rem",
                  borderRadius: "1rem",
                }}
              />
            ) : (
              mediaAnnounce.length > 0 && ( // Render media at 0 index if available
                <img
                  src={mediaAnnounce[0].url}
                  alt=""
                  style={{
                    width: "25rem",
                    height: "25rem",
                    borderRadius: "1rem",
                  }}
                />
              )
            )}
            {/* <img
              src={files[0]}
              alt=""
              style={{ width: "25rem", height: "25rem", borderRadius: "1rem" }}
            /> */}
          </div>

          {/* media slider */}
          <div
            className="ancmnt__main__media__swiper flex"
            style={{ marginTop: "1rem" }}
          >
            <img
              src="./assets/backward.svg"
              alt="prev"
              style={{ cursor: "pointer" }}
              onClick={() => {
                swiper?.slidePrev();
              }}
            />
            <Swiper
              className="annoucement__swiper"
              slidesPerView={3}
              spaceBetween={15}
              onInit={mediaSwiperInit}
              style={{ paddingLeft: "1rem" }}
            >
              {mediaAnnounce.map((file: any, index: number) => (
                <SwiperSlide
                  className="annoucement-slide"
                  key={index}
                  onClick={() => {
                    saveCurrentView(file, index);
                  }}
                >
                  <img
                    src={file.url}
                    alt=""
                    style={{
                      width: "6rem",
                      height: "5rem",
                      borderRadius: "1rem",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <img
              src="./assets/forward.svg"
              alt="prev"
              style={{ cursor: "pointer", marginLeft: "1rem" }}
              onClick={() => {
                swiper?.slideNext();
              }}
            />
          </div>
        </div>
      </div>
      {/* annoucement body end here */}

      {/* close button goes here */}
      <div
        className="close__anoucement_btn"
        onClick={() => {
          setToggle(false);
          dispatch(setAnnonceData(null));
        }}
      >
        X
      </div>
      {/* close button end here */}

      <input
        type="file"
        style={{ width: "0.1px", height: "0.1px", visibility: "hidden" }}
        id="file__annoucemnt"
        multiple={true}
        // onChange={uploadAnnoucementAsset}
      />
    </div>
  );
};

export default AnnouncementView;
