import React, { useEffect, useState } from "react";
import "./adView.css";
import axios from "axios";
import { unlocksAdvertisement } from "../../../../apis/getApis";
import { useNavigate } from "react-router-dom";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import Header from "../../../../apis/header";
import { adminWatchLater, putAdUnlock } from "../../../../apis/putApis";

// Unlock advertisements - advertise Dialog open when click on view Bounty Button
const AdDialog = ({ open, setIsBountyAdPopup, bountyId }: any) => {
  const [advertisements, setAdvertisements] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [messageError, setErrorMessage] = useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // get advertise data
  useEffect(() => {
    const fetchAdvertisementData = async () => {
      try {
        console.log("Fetching advertisement data...");
        const response = await axios.get(
          `${unlocksAdvertisement}/${bountyId}`,
          Header
        );
        console.log("Advertisement data received:", response.data);
        setAdvertisements(response.data);
      } catch (error) {
        console.error("Error fetching advertisement:", error);
      }
    };

    if (open && currentIndex !== advertisements.length - 1) {
      fetchAdvertisementData();
    }
  }, [open, bountyId]);
  useEffect(() => {
    console.log(messageError, "messageError");
  }, [messageError]);

  // Wen User show whole Video API call and Video remove from unlock advertisements
  useEffect(() => {
    const handleVideoEnded = async () => {
      console.log("Video ended");
      console.log(
        currentIndex === advertisements.length - 1,
        currentIndex,
        advertisements.length - 1,
        "currentIndex === advertisements.length - 1"
      );
      try {
        const currentAdId = advertisements[currentIndex]?._id;
        await axios.put(`${putAdUnlock}/${bountyId}`, {
          AdId: currentAdId,
        });

        if (currentIndex === advertisements.length - 1) {
          console.log("Last advertisement ended");
          navigate("/bountipreview", { state: bountyId });
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      } catch (error) {
        console.error("Error adding advertisement to Watch Later put:", error);
      }
    };

    // Video end
    const videoElement = document.getElementById(
      "advertisement-video"
    ) as HTMLVideoElement;
    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnded);
      videoElement.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }

    // If Image shows 12 second then remove
    if (advertisements[currentIndex]?.type === "image") {
      const timeoutId = setTimeout(() => {
        handleVideoEnded();
      }, 12000); // 12 seconds timeout
      return () => clearTimeout(timeoutId);
    }
    // else if (advertisements[currentIndex]?.type === "video") {
    //   // For videos, add a timeout to handle the case where the video doesn't trigger the ended event.
    //   const timeoutId = setTimeout(() => {
    //     if (!videoElement.ended) {
    //       handleVideoEnded();
    //     }
    //   }, videoElement.duration * 1000); // Timeout duration is set to the video duration
    //   return () => clearTimeout(timeoutId);
    // }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, [currentIndex, advertisements, bountyId, navigate]);

  // When click on watch later Icon API call
  const handleWatchLaterClick = async () => {
    try {
      await axios.put(
        `${adminWatchLater}/${bountyId}`,
        {
          ads: [
            {
              clickLink: advertisements[currentIndex].Ad,
            },
          ],
          adId: advertisements[currentIndex]?._id,
        },
        Header
      );
      console.log("Watch Later:", advertisements[currentIndex]?._id);

      if (currentIndex === advertisements.length - 1) {
        navigate("/bountipreview", { state: bountyId });
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
        setShowModal(true); // Set showModal state to true to show the modal
      } else {
        console.error("Error adding advertisement to Watch Later:", error);
      }
      console.error("Error adding advertisement to Watch Later:", error);
    }
  };
  const handleCancel = () => {
    console.log("handleCancel callleddd");
    setShowModal(false);
    setIsBountyAdPopup(false);
  };
  return (
    <>
      {/* Error shows when click on watch laterIcon if error got */}
      <div
        className="flex"
        style={
          showModal
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={handleCancel}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>{messageError}</p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={handleCancel}
                >
                  OK
                </button>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* Advertise View Popup */}
      <div className="adView__dashboard">
        {open && (
          <div className="adframe" style={{ position: "relative" }}>
            {/* Watch Later Icon */}
            <div className="watch-later-icon" onClick={handleWatchLaterClick}>
              <WatchLaterIcon style={{ color: "#e98326", fontSize: "2rem" }} />
            </div>

            {advertisements.length > 0 && (
              <div>
                {/* If advertise Type Image */}
                {advertisements[currentIndex].type === "image" && (
                  <img
                    src={advertisements[currentIndex].Ad}
                    alt="Advertisement"
                    className="adframe__md"
                  />
                )}
                {/* If advertise Type Video */}
                {advertisements[currentIndex].type === "video" && (
                  <video
                    id="advertisement-video"
                    className="adframe__md"
                    controls
                    autoPlay
                    key={advertisements[currentIndex].Ad}
                  >
                    <source src={advertisements[currentIndex].Ad} />
                  </video>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AdDialog;
