import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Email_Change__Verify } from "../../apis/getApis";
import { useDispatch } from "react-redux";

const EmailVarification = () => {
  const { id, token } = useParams<{ id: string; token: string }>();
  const nav = useNavigate();
  const [isInValid, setIsInValid] = React.useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // Email verification get API
  useEffect(() => {
    axios
      .get(`${Email_Change__Verify}/${id}/${token}`)
      .then((res) => {
        console.log(res, "resonseeeeee");
        if (res.data) {
          setShowPopup(true);
          nav("/setting", { state: id });
        }
      })
      .catch((err) => {
        setIsInValid(true);
      });
  }, []);
  const handleCancel = () => {
    console.log("handleCancel callleddd");
    setShowPopup(false);
  };
  if (isInValid) {
    return <>404 page not found</>;
  }
  return (
    <>
      <div>Verifying Link..</div>
      <div
        className="flex"
        style={
          showPopup
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={handleCancel}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
              Your email has been successfully changed!!
            </p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={handleCancel}
                >
                  OK
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVarification;
