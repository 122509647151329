import styles from "./breadcrumb.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { addSidebarToggle } from "../../app/slices/singleStates";
import { BreadcrumbIcon } from "../../svgicons";

const Breadcrumb = ({ value }: any) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook to dispatch actions

  // Render Breadcrumb component
  return (
    <div className="flex items-center breadcrumb-res"> {/* Container for breadcrumb */}
      {/* Hamburger menu icon */}
      <MenuIcon
        id="hamburger"
        onClick={() => {
          dispatch(addSidebarToggle(true)); // Dispatch action to toggle sidebar when hamburger icon is clicked
        }}
      />
      {/* Map through each breadcrumb value */}
      {value?.map((elm: any, ind: any) => {
        return (
          <div key={ind + Date.now()} className="flex items-center"> {/* Container for each breadcrumb item */}
            {/* Breadcrumb text */}
            <p
              className={styles.brd_text} // Apply CSS class for breadcrumb text
              // Conditionally apply style for last breadcrumb item to change text color to orange
              style={ind == value?.length - 1 ? { color: "var(--orange)" } : {}}
            >
              {elm} {/* Breadcrumb text value */}
            </p>
            {/* Render BreadcrumbIcon component except for the last breadcrumb item */}
            {ind == value?.length - 1 ? <></> : <BreadcrumbIcon />}
          </div>
        );
      })}
    </div>
  );
};
export default Breadcrumb;
