import React, { useState, useEffect } from "react";
import RelatedProjects from "./RelatedProjects";
import styles from "./detailCard.module.css";

const SponserDetailCard = ({ sponserData, sponsorHoveredIndex }: any) => {
  const hoveredSponsor = sponserData?.[sponsorHoveredIndex];
  console.log(hoveredSponsor?.sponsorName, "hoveredSponsorDetail");

  const [sliderValue, setSliderValue] = useState(50);

  useEffect(() => {
    setSliderValue(hoveredSponsor?.range || 50);
    console.log(sliderValue, "sliderValue");
  }, [hoveredSponsor]);

  // Sposnor card slider Change function
  const handleSliderChange = (event: any) => {
    setSliderValue(parseInt(event.target.value, 10));
  };


  const getSliderBackground = () => {
    const leftColor = "#0b5281";
    const rightColor = "#44aeda";

    // Calculate the percentage of the slider value
    const percentage = ((sliderValue - 1) / (100 - 1)) * 100;

    // Use a linear gradient to set background color
    return `linear-gradient(to right, ${leftColor} 0%, ${leftColor} ${percentage}%, ${rightColor} ${percentage}%, ${rightColor} 100%)`;
  };
  return (
    <div
      className={`${styles.detailCard__wrapper} detailcard_c1_wrapper_sponser`}
    >
      <section className={`${styles.detailCard__wrapper_inner}`}>
        <section className={`${styles.detailCard__top}`}>
          <div
            className={`${styles.detailCard_top__row} flex justify-between items-center`}
          >
            {/* Sponsor Image */}
            <div className={`${styles.detailCard_lg}`}>
              {/* <img src="./tests/demoSponser.png" /> */}
              <img src={hoveredSponsor?.image} alt="Sponsor Image" />
            </div>
            {/* Sponsor Name */}
            <div className={`${styles.detailCard_bn}`}>
              {hoveredSponsor?.sponsorName}
            </div>
            {/* Sponsor Like Icon */}
            <div className={`${styles.detailCard_lkw} cursor-pointor`}>
              <img src="./tests/icons/heart.svg" alt="" />
            </div>
          </div>
          {/* Spnsor Description */}
          <div className={`${styles.detailCard_top__row_description}`}>
            {hoveredSponsor?.description}
          </div>
        </section>

        <main className={`${styles.detailCard__main}`}>
          <div className={`${styles.detailCard__main_heading}`}>
            Sponsored Bounties
          </div>
          <div className={`${styles.detailCard__main_coins}`}>
            {/* sposnor gold coin Icon */}
            <div className={styles.detailCard__coin_section}>
              <img src="./tests/icons/goldcoin.svg" alt="gold points" />
              <span className={`${styles.detailCard__main_points}`}>4</span>
            </div>
            {/* sponsor silver coin Icon */}
            <div className={styles.detailCard__coin_section}>
              <img
                src="./tests/icons/silvercoin.svg"
                className={`${styles.detailCard__coin}`}
                alt="silver points"
              />
              <span className={`${styles.detailCard__main_points}`}>0</span>
            </div>
            {/* sponsor bounti coin Icon */}
            <div className={styles.detailCard__coin_section}>
              <img
                src="./tests/icons/bouticoin.svg"
                className={`${styles.detailCard__coin}`}
                alt="bounti points"
              />
              <span className={`${styles.detailCard__main_points}`}>2+</span>
            </div>
          </div>

          <div className={`${styles.detailCard__carousel__wrapper}`}>
            <RelatedProjects />
          </div>

          <div className={`${styles.detailCard__greenCard_wrapper}`}>
            <div className={`${styles.detailCard__greenCard}`}>
              <p>
                Your relate status with this sponsor is higher than{" "}
                <b>Alex.123</b> and 5 of your friends. Hit relationship level 7
                next!
              </p>
              <div className={styles.slidecontainer}>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={sliderValue}
                  onChange={handleSliderChange}
                  className={styles.slider}
                  id="myRange"
                  style={{ background: getSliderBackground() }}
                />
                <div className={styles.sliderValue}>{sliderValue}</div>
              </div>
            </div>
          </div>
        </main>

        {/* button type */}
        <button className={`${styles.buttonType}`}>89% Relevancy</button>
      </section>
    </div>
  );
};

export default SponserDetailCard;
