import { useEffect, useState } from "react";
import styles from "./given.module.css";
import { Badge } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { Skeleton } from "antd";
import axios from "axios";
import { D } from "../../../../apis/main";
import AnnouncementCard from "../../BountyCreation/related-components/AnnoucementCard";
import { useLocation } from "react-router-dom";
import { getAnnouncementBounty, globalBounti2 } from "../../../../apis/getApis";
import Header from "../../../../apis/header";
import SubmissionCardBounty from "../../BountyCreation/related-components/SubmissionCardBounty";

const BountiCommentWrapper = ({
  allSubmission,
  isLoading,
  userName,
  profileImage,
}: any) => {
  const [headerType, setHeaderType] = useState<number>(0);
  const [globalAnnouncementData, setAnnouncementGlobal] = useState<any>([]);
  const location = useLocation();
  const reduxBountyId = location.state;
  console.log(reduxBountyId, "reduxBountyId");
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );

  // fetch here announcement data according bounti
  const fetchAnnouncement = async () => {
    axios
      .get(`${globalBounti2}/${reduxBountyId}`, Header)
      .then((response: any) => {
        console.log("goodboy", response.data);
        // Fetch user profile after setting textData
        axios
          .get(
            `${getAnnouncementBounty}/${response.data.text.userID}/${reduxBountyId}`,
            Header
          )
          .then((userProfileResponse: any) => {
            setAnnouncementGlobal(userProfileResponse.data);
          })
          .catch((userProfileErr: any) => {
            console.error("Error fetching user profile:", userProfileErr);
          });
      })
      .catch((err: any) => {
        console.error("Error fetching bounty data:", err);
      });
  };

  useEffect(() => {
    fetchAnnouncement();
  }, []);
  return (
    <section
      className={
        toggleD
          ? `${styles.wrapper_c2} r_wrapper_c2`
          : `${styles.wrapper_c2} r_wrapper_c2 r_wrapper_c2_collapse`
      }
    >
      {/* header */}
      <section
        className={`${styles.bounty_commet_header_c2} r_bounty_commet_header_c2`}
      >
        <button
          onClick={() => {
            setHeaderType(0);
          }}
          className={
            headerType === 0
              ? `${styles.header_btns} ${styles.header_btn1} ${styles.header_active}`
              : `${styles.header_btns} ${styles.header_btn1}`
          }
        >
          Submission Feed
        </button>
        <button
          onClick={() => {
            setHeaderType(1);
          }}
          className={
            headerType === 1
              ? `${styles.header_btns} ${styles.header_btn2} ${styles.header_active}`
              : `${styles.header_btns} ${styles.header_btn2}`
          }
        >
          Announcements
        </button>
      </section>

      {/* body */}
      <section
        className="comment_submission_parent"
        style={{ height: "24rem", overflowY: "scroll" }}
      >
        {isLoading ? (
          <>
            <Skeleton.Input
              active={true}
              style={{ width: "71.3rem", height: "8rem" }}
            />
            <Skeleton.Input
              active={true}
              style={{ width: "71.3rem", height: "8rem", marginTop: "1rem" }}
            />
          </>
        ) : (
          <>
            {headerType === 1
              ? globalAnnouncementData?.map((data: any, index: number) => (
                  <AnnouncementCard key={index} data={data} status={"VIEW"} />
                ))
              : allSubmission?.map((submission: any, index: number) => (
                  <>
                    {console.log(submission, "submissionsubmission")}
                    <SubmissionCardBounty
                      key={index}
                      submission={submission}
                      toggleD={toggleD}
                      styles={styles}
                    />
                  </>
                ))}
          </>
        )}
      </section>
    </section>
  );
};

export default BountiCommentWrapper;
