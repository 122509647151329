import { useDispatch, useSelector } from "react-redux";
import "./mainEditCSSNew.css";
import { RootState } from "../../../../../app/store";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  additionalDetail,
  editBountiBrand,
  editCurrentBounti,
  editTags,
  multiSubmissionEdit,
  publishBounty,
  submissionUpdate,
  unlockUpdate,
  uploadBrandImage,
} from "../../../../../apis/putApis";
import {
  allMimeTypeListUrl,
  fetchMediaData,
  fetchTodoStatus,
  getAdditionalData,
  getAllCategoryList,
  getAllSubCategoryList,
  getBrandName,
  getHashTagsList,
  getSelectedCategoriesList,
  getUserProfile,
  globalBounti2,
} from "../../../../../apis/getApis";
import Header from "../../../../../apis/header";
import SelectorWithHeading from "../other/SelectorWithHeading";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { USTCard } from "../../../BountyPreview/related-components";
import RewardSetter from "../other/RewardSetter/RewardSetter";
import UploadSponser from "../other/UploadSponser/UploadSponser";
import CharityUploader from "../other/ChariryUploader/CharityUploader";
import { MediaWrapper } from "../../../../../components";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import {
  addIsCharityUploader,
  addIsSponserUploader,
  addRewardPopupMode,
  addRewardViewData,
  addSubCategory,
  toggleMediaUploder,
  togglePhasesDialog,
  toggleRewardDialogCreation,
  toggleRewardPopopOnlyCreate,
} from "../../../../../app/slices/singleStates";
import { getDefaultTerms } from "../../../../../apis/getApis";
import { editTermsAndConditions } from "../../../../../apis/putApis";
import { setText } from "../../../../../app/slices/CheckListStatus";
import { clearMediaUploaderData } from "../../../../../app/slices/mediaUploaderData";
import { fetchAllRewards } from "../../../../../Functions/MediaUploaderF";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { useNavigate } from "react-router-dom";
import { truncateSync } from "fs";

const MainEditArea = ({ selectedId }: any) => {
  // redux
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  // const checkListData = useSelector(
  //   (state: RootState) => state.CheckListStatus
  // );
  // console.log(checkListData,"checkListDatacheckListData")

  const [edited, setEdited] = useState({ bountititle: false });
  const [editedDes, setEditedDes] = useState({ bountiDescription: false });
  const [editedAdd, setEditedAdd] = useState({ bountiAdd: false });
  const [termsAndConditons, setTermsAndConditons] = useState<any>({
    Eligibility: "",
    EntryPeriod: "",
    HowToEnter: "",
    Prizes: "",
    SelectionOfWinners: "",
    PrizeRedemption: "",
    RewardSubjectToChange: "",
  });

  // Function When Terms & Condition Change
  const handleTermsChange = (field: string, value: string) => {
    setTermsAndConditons({
      ...termsAndConditons,
      [field]: value,
    });

    // Call API to update terms and conditions
    axios
      .put(
        `${editTermsAndConditions}/${selectedId}`,
        {
          termsAndConditions: {
            ...termsAndConditons,
            [field]: value,
          },
        },
        Header
      )
      .then((res: any) => {
        console.log(res, "terms_edit_only");
        // Handle response if needed
      })
      .catch((err: any) => {
        console.log(err, "terms_edit_only");
        // Handle error if needed
      });
  };
  const isRewardDialog: boolean = useSelector(
    (state: RootState) => state.booleanStatus.isRewardDialog
  );
  console.log("isRewardDialog maineditarea", isRewardDialog);

  // const isRewardDialog=false;
  const subCategory = useSelector(
    (state: RootState) => state.booleanStatus.subCategory
  );
  const isMediaDialog = useSelector(
    (state: RootState) => state.booleanStatus.isMediaDialog
  );
  const isUploadRewardOnlyCreate = useSelector(
    (state: RootState) => state.booleanStatus.isUploadRewardOnlyCreate
  );

  // const isUploadRewardOnlyCreate=false;

  const swiper = useSelector(
    (state: RootState) => state.booleanStatus.rewardUploaderRef
  );
  const dispatch = useDispatch();

  //   local state
  const [completeCategoryList, setCompleteCategoryList] = useState<any>([]);
  const [completeSelectedSubCategoryList, setCompleteSelectedSubCategoryList] =
    useState<any>([]);
  const [completeSelectedMime, setCompleteSelectedMime] = useState<any>([]);
  const [completeMimeList, setSubCompleteMimeList] = useState<any>([]);
  const [brandName, setBrandName] = useState<any>("");
  const [brandLogo, setBrandLogo] = useState<any>("");
  const [projectName, setProjectName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [additionalD, setAdditionalD] = useState<any>("");
  const [multipleSubmissionValue, setMultipleSubmissionValue] =
    useState<any>("");
  const [brandImage, setBrandImage] = useState<any>(null);
  const [getBountyMedias, setBountyMedies] = useState<any>([]);
  const [unlockData, setUnlockData] = useState<any>();
  const [submissionData, setSubmissionData] = useState<any>();
  const [tags, setTags] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState(false); // State to manage T&C visibility
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });

  const [rewards, setRewards] = useState<any>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleTermsClick = () => {
    console.log("callasjkdghedf");
    setShowTerms(!showTerms); // Toggle T&C visibility on click
  };

  const handleCloseClick = () => {
    setShowTerms(false); // Close T&C section
  };
  const handleDefaultTerms = () => {
    setShowModalTerms(true);
    // if (window.confirm("Do you want to use the default T&C?")) {
    // fetchDefaultTerms();
    // }
  };
  // fetch profile data
  const fetchProfileData = async () => {
    await axios
      .get(getUserProfile, Header)
      .then((res: any) => {
        console.log("PROFILEDATA", res.data);
        setBrandName(res.data.userName);
        setBrandLogo(res.data.userImage);
      })
      .catch((err: any) => {
        console.log("PROFILEDATA", err);
      });
  };

  // API for set Terms & Condition
  useEffect(() => {
    axios
      .get(`${globalBounti2}/${selectedId}`, Header)
      .then((res) => {
        console.log(res, "globalBounti2");
        if (res.data.text.termsAndConditions) {
          console.log(
            "Complete Term and Conditional",
            res.data.text.termsAndConditions
          );
          setTermsAndConditons({
            Eligibility: res.data.text.termsAndConditions.Eligibility || "",
            EntryPeriod: res.data.text.termsAndConditions.EntryPeriod || "",
            HowToEnter: res.data.text.termsAndConditions.HowToEnter || "",
            Prizes: res.data.text.termsAndConditions.Prizes || "",
            SelectionOfWinners:
              res.data.text.termsAndConditions.SelectionOfWinners || "",
            PrizeRedemption:
              res.data.text.termsAndConditions.PrizeRedemption || "",
            RewardSubjectToChange:
              res.data.text.termsAndConditions.RewardSubjectToChange || "",
          });
        } else {
          console.log(
            "termsAndConditions property not found in the API response."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    dispatch(addRewardViewData(null));
  }, [isRewardDialog]);

  //   useEffect first time mount
  useEffect(() => {
    // get category list
    axios
      .get(getAllCategoryList)
      .then((res: any) => {
        setCompleteCategoryList(res.data);
      })
      .catch((err: any) => {});
    // get hash list
    axios
      .get(`${getHashTagsList}/${selectedId}`)
      .then((res: any) => {
        setTags(res.data.hashtag.join(","));
      })
      .catch((err: any) => {});
    // get all Subvategory list
    axios
      .get(`${getAllSubCategoryList}/${selectedId}`)
      .then((res) => {
        console.log("sub-category", res);
        dispatch(addSubCategory(res.data[0].subValues));
      })
      .catch((err: any) => {});
    // get bounty title,description,submission,unlocks
    axios
      .get(`${globalBounti2}/${selectedId}`, Header)
      .then((res: any) => {
        console.log("current Bounty Data", res);
        setProjectName(res.data.text.title);
        setDescription(res.data.text.description);
        setMultipleSubmissionValue(res.data.text.multipleSubmissions);
        setUnlockData(res.data.text.unlocks);
        setSubmissionData(res.data.text.totalSubmissions);
      })
      .catch((err: any) => {});
    // get selected category list
    axios
      .get(`${getSelectedCategoriesList}/${selectedId}`)
      .then((res: any) => {
        setCompleteSelectedSubCategoryList(res.data.data.subcategories);
      })
      .catch((err: any) => {});
    // get all Mime Type for selected
    axios
      .get(`${allMimeTypeListUrl}/${selectedId}`)
      .then((res: any) => {
        setCompleteSelectedMime(res.data.mimeTypes);
      })
      .catch((err: any) => {});
    // get all mime type list
    axios
      .get(allMimeTypeListUrl)
      .then((res: any) => {
        setSubCompleteMimeList(res.data);
      })
      .catch((err: any) => {});
    // get additional details
    axios
      .get(`${getAdditionalData}/${selectedId}`)
      .then((res: any) => {
        console.log("res12345", res);
        setAdditionalD(res.data.additionalDetail);
      })
      .catch((err: any) => {});
    // get bounty name & Image
    axios
      .get(`${getBrandName}/${selectedId}`)
      .then((res: any) => {
        console.log("Basmdsd", res);
        setBrandName(res.data.brandName);
        setBrandImage(res.data.presignedUrl);
      })
      .catch((err: any) => {
        console.log("getBrandName", err);
      });
    // get uploaded media
    axios
      .get(`${fetchMediaData}/${selectedId}?type=undefined`)
      .then((res: any) => {
        console.log("_Medias", res.data.data);
        setBountyMedies(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
    fetchAllRewards(setRewards, selectedId);
    fetchProfileData();
  }, []);

  // useEffect(() => {
  //   fetchAllRewards(setRewards, selectedId);
  // }, [isRewardDialog]);

  useEffect(() => {
    fetchAllRewards(setRewards, selectedId);
  }, [isRewardDialog, setRewards, selectedId]);

  useEffect(() => {
    fetchAllRewards(setRewards, selectedId);
  }, [isUploadRewardOnlyCreate]);

  useEffect(() => {
    axios
      .get(`${fetchMediaData}/${selectedId}?type=undefined`)
      .then((res: any) => {
        axios
          .get(`${fetchTodoStatus}/${selectedId}`)
          .then((res: any) => {
            console.log("RESPONSE", res.data);
            dispatch(setText([res.data.checkList.BountiImages, 3]));
            dispatch(setText([res.data.checkList.Reward, 4]));
          })
          .catch((err: any) => {
            console.log("CheckData", err);
          });
        setBountyMedies(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [isMediaDialog]);

  //  edit Brand Name Function
  const editBrandName = async (e: any) => {
    setBrandName(e.target.value);
    await axios
      .put(`${editBountiBrand}/${selectedId}`, {
        brandName: e.target.value,
      })
      .then((res) => {})
      .catch((error) => {});
  };

  // project name change in DB
  useEffect(() => {
    if (!edited.bountititle) {
      return;
    }
    const getData = setTimeout(async () => {
      await axios
        .put(`${editCurrentBounti}/${selectedId}`, {
          title: projectName,
          media: [],
        })
        .then((res) => {
          setEdited((prevState) => ({
            ...prevState,
            bountititle: false,
          }));
          dispatch(setText([res.data.checkList.Title, 1]));
        })
        .catch((error) => {});
    }, 2000);
    return () => clearTimeout(getData);
  }, [projectName]);

  //   description set in DB
  useEffect(() => {
    if (!editedDes.bountiDescription) {
      return;
    }
    const getData = setTimeout(async () => {
      await axios
        .put(`${editCurrentBounti}/${selectedId}`, {
          description: description,
          media: [],
        })
        .then((res) => {
          setEditedDes((prevState) => ({
            ...prevState,
            bountiDescription: false,
          }));
          dispatch(setText([res.data.checkList.Description, 2]));
        })
        .catch((error) => {});
    }, 2000);
    return () => clearTimeout(getData);
  }, [description]);

  // bounty Additional Details
  useEffect(() => {
    if (!editedAdd.bountiAdd) {
      return;
    }
    const getData = setTimeout(async () => {
      axios
        .put(`${additionalDetail}/${selectedId}`, {
          additionalDetail: additionalD,
        })
        .then((res: any) => {
          setEditedAdd((prevState) => ({
            ...prevState,
            bountiAdd: false,
          }));
          dispatch(setText([res.data.checkList.AdditionalDetail, 16]));
        })
        .catch((err: any) => {});
    }, 2000);
    return () => clearTimeout(getData);
  }, [additionalD]);

  //
  const multipleSubmissionSetting = (e: any) => {
    if (e.target.value <= 3) {
      setMultipleSubmissionValue(e.target.value);
    }

    axios
      .put(`${multiSubmissionEdit}/${selectedId}`, {
        multipleSubmissions: e.target.value,
      })
      .then((res: any) => {})
      .catch((err: any) => {});
  };

  // upload your brand media
  const uploadYourBrand = () => {
    let brandUpload: any = document.getElementById("brandUpload");
    if (brandUpload) {
      brandUpload.click();
    }
  };

  const callApiOfBrandndUpload = (e: any) => {
    console.log("e.target", e.target.files[0]);
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    axios
      .put(`${uploadBrandImage}/${selectedId}`, formData)
      .then((res) => {
        window.alert("uploaded");
      })
      .catch((err: any) => {});
  };

  function unlocksChanges(e: any) {
    setUnlockData(e.target.value);
    axios
      .put(`${unlockUpdate}/${selectedId}`, {
        unlocks: e.target.value,
      })
      .then((res) => {
        dispatch(setText([res.data.checkList.Unlocks, 8]));
      })
      .catch((err) => {});
  }

  // when submission input function will be change
  function submissionChanges(e: any) {
    const Id: any = localStorage.getItem("bountyCreatedID");
    setSubmissionData(e.target.value);

    axios
      .put(`${submissionUpdate}/${selectedId}`, {
        totalSubmissions: e.target.value,
      })
      .then((res) => {
        dispatch(setText([res.data.checkList.Submissions, 9]));
      })
      .catch((err) => {});
  }

  const handleArrowClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrevArrowClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  //   description set in DB
  useEffect(() => {
    const getData = setTimeout(async () => {
      let commaSeparated = tags.split(",");
      let postArray: any = [];
      commaSeparated.forEach((element: any) => {
        console.log(element);
        if (element[0] === "") {
          postArray.push(element);
        } else {
          postArray.push(`${element}`);
        }
      });
      await axios
        .put(`${editTags}/${selectedId}`, {
          hashtag: postArray,
        })
        .then((res: any) => {
          dispatch(setText([res.data.checkList.Tags, 14]));
        })
        .catch((err: any) => {});
    }, 2000);
    return () => clearTimeout(getData);
  }, [tags]);
  useEffect(() => {
    const getData = setTimeout(async () => {
      // Check if tags are empty or null
      if (!tags) return;

      let commaSeparated = tags.split(",");
      let postArray: any = [];
      commaSeparated.forEach((element: any) => {
        console.log(element);
        // Check if the element starts with "#"
        if (element.trim()) {
          postArray.push(element.trim());
        } else {
          postArray.push(`${element.trim()}`);
        }
      });
      await axios
        .put(`${editTags}/${selectedId}`, {
          hashtag: postArray,
        })
        .then((res: any) => {
          dispatch(setText([res.data.checkList.Tags, 14]));
        })
        .catch((err: any) => {});
    }, 2000);

    return () => clearTimeout(getData);
  }, [tags]);

  const handleConfirm = () => {
    setLoading(true);
    console.log("handleConfirm called");
    axios
      .put(`${publishBounty}/${selectedId}`)
      .then((res) => {
        console.log(res, "publishBounty_res");
        navigate("/viewBounties");
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Bad request, check if error data contains missing or incomplete fields
          const { data } = error.response;
          console.log(data, "dhsaufyuyf");
          if (data && data.error) {
            //  missing or incomplete fields error
            setErrorMessage(data.error);
            setShowModalError(true);
          } else {
            console.error("An error occurred:", error.message);
          }
        } else {
          // Handle other types of errors
          console.error("An error occurred:", error.message);
        }
      })
      .finally(() => {
        setLoading(false);
        setShowModal(false);
      });
  };

  const handleCancel = () => {
    console.log("handleCancel callleddd");
    setShowModal(false);
  };
  const handleCancelError = () => {
    console.log("handleCancel callleddd");
    setShowModalError(false);
  };

  // When click on Post / Update Icon get confirmation popup
  const publishBountyfunction = () => {
    console.log("publishBountyfunction callleddd");
    setShowModal(true);
  };

  // Fetch default Terms & Condition function
  const fetchDefaultTerms = () => {
    axios
      .get(`${getDefaultTerms}`, Header)
      .then((res) => {
        console.log(res, "getDefaultTerms default");
        // Check if the response array has at least one element
        if (res.data.length > 0) {
          // Access the first element of the array
          const defaultTerms = res.data[0].defaultTermsAndConditions;
          console.log(defaultTerms, "defaultTerms getDefaultTerms");
          setTermsAndConditons({
            Eligibility: defaultTerms.Eligibility || "",
            EntryPeriod: defaultTerms.EntryPeriod || "",
            HowToEnter: defaultTerms.HowToEnter || "",
            Prizes: defaultTerms.Prizes || "",
            SelectionOfWinners: defaultTerms.SelectionOfWinners || "",
            PrizeRedemption: defaultTerms.PrizeRedemption || "",
            RewardSubjectToChange: defaultTerms.RewardSubjectToChange || "",
          });
          axios
            .put(
              `${editTermsAndConditions}/${selectedId}`,
              {
                termsAndConditions: {
                  ...defaultTerms, // Use default terms instead of state
                },
              },
              Header
            )
            .then((res) => {
              console.log(res, "terms_edit getDefaultTerms");
              // Handle response if needed
            })
            .catch((err) => {
              console.log(err, "terms_edit getDefaultTerms");
              // Handle error if needed
            });
        } else {
          console.log("No default terms found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching getDefaultTerms terms:", error);
      });
    setShowModalTerms(false);
  };

  // when user decline to set default Terms & Condition
  const handleCancelTerms = () => {
    console.log("handleCancel callleddd");
    setShowModalTerms(false);
  };
  // const publishBountyfunction = () => {
  //   const confirmPublish = window.confirm(
  //     "Are you sure you want to publish the bounty?"
  //   );

  //   if (confirmPublish) {
  //     axios
  //       .put(`${publishBounty}/${selectedId}`)
  //       .then((res) => {
  //         console.log(res, "publishBounty_res");
  //         navigate("/viewBounties");
  //       })
  //       .catch((error) => {
  //         // Handle error
  //       });
  //   } else {
  //     // Alert closed
  //   }
  // };

  return (
    <main
      className={
        toggleD
          ? "mainEDITArea__PArent mainEDITArea__PArent_noCollapse"
          : "mainEDITArea__PArent mainEDITArea__PArent_Collapse"
      }
    >
      <DeleteConfirmDialog bountyId={selectedId} />
      {/* publish bounty confirm popup */}
      <div
        className="flex"
        style={
          showModal
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={handleCancel}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
              {loading
                ? "posting..."
                : "Are you sure you want to publish the bounty?"}
            </p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={handleConfirm}
                  disabled={loading}
                >
                  Yes
                </button>
                <button
                  className="delete__asset_confirm delete__asset_confirm_cancle"
                  onClick={handleCancel}
                >
                  No
                </button>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* error msg on publish bounty */}
      <div
        className="flex"
        style={
          showModalError
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={handleCancelError}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>{errorMessage}</p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={handleCancelError}
                >
                  OK
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
      {/* header of mainarea goes here */}

      {/* default terms confirm */}
      <div
        className="flex"
        style={
          showModalTerms
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={handleCancelTerms}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
              {loading ? "loading..." : "Do you want to use the default T&C?"}
            </p>
            <div>
              <>
                <button
                  className="delete__asset_confirm delete__asset_confirm_delete"
                  onClick={fetchDefaultTerms}
                  disabled={loading}
                >
                  Yes
                </button>
                <button
                  className="delete__asset_confirm delete__asset_confirm_cancle"
                  onClick={handleCancelTerms}
                >
                  No
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          toggleD
            ? "mainEDITArea__header mainEDITArea__header_noCollapse"
            : "mainEDITArea__header mainEDITArea__header_noCollapse"
        }
      >
        {/* branding block goes here */}
        <div
          onClick={() => {
            dispatch(addIsCharityUploader(false));
            dispatch(addIsSponserUploader(false));
          }}
          className={
            toggleD
              ? "mainEDITArea__Branding mainEDITArea__Branding_noCollapse  mainDirArea_L_WIdth "
              : "mainEDITArea__Branding mainEDITArea__Branding_noCollapse  mainDirArea_L_WIdth "
          }
        >
          {/* brand upload area goes here */}
          <div
            className={
              toggleD
                ? "mainEDITArea__BrandUpload_wrapper mainEDITArea__BrandUpload_wrapper__noCollapse"
                : "mainEDITArea__BrandUpload_wrapper mainEDITArea__BrandUpload_wrapper__collapse"
            }
          >
            {/* brand image upload block goes here*/}
            <div
              className={
                toggleD
                  ? "mainEDITArea__BrandUpload mainEDITArea__BrandUpload__noCollapse"
                  : "mainEDITArea__BrandUpload mainEDITArea__BrandUpload__collapse"
              }
              onClick={uploadYourBrand}
            >
              <>
                <img
                  src={`${brandLogo}`}
                  alt=""
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "0.5rem",
                  }}
                />
              </>
              {/* <input
                type="file"
                id="brandUpload"
                style={{ visibility: "hidden", width: "0.1px" }}
                onChange={callApiOfBrandndUpload}
              /> */}
            </div>
            {/* brand image upload block end here*/}

            {/* brand name upload block goes here*/}
            <div
              className={
                toggleD
                  ? "mainEDITArea__BrandName mainEDITArea__BrandName__noCollapse"
                  : "mainEDITArea__BrandName mainEDITArea__BrandName__collapse"
              }
            >
              <input
                type="text"
                value={brandName}
                disabled={true}
                className={
                  toggleD
                    ? "mainEDITArea__BrandUploadInput mainEDITArea__BrandUploadInput_noCollapse"
                    : "mainEDITArea__BrandUploadInput mainEDITArea__BrandUploadInput_collapse"
                }
                style={{ display: "flex", flexWrap: "wrap" }}
                // onChange={(e) => {
                //   editBrandName(e);
                // }}
              />
            </div>
            {/* brand name upload block end here*/}

            {/* brand edit icon goes here */}
            {/* <img
              src="../icons/edit.svg"
              style={{
                fontSize: "1em",
                marginTop: "-1.4rem",
              }}
            /> */}
            {/* brand edit icon end here */}
          </div>
          {/* brand upload area end here */}
        </div>
        {/* branding block end here */}

        {/* right block of header block goes here */}
        <div
          className={
            toggleD
              ? "mainEDITArea__Header_right mainEDITArea__Header_right_noCollapse  mainDirArea_R_WIdth"
              : "mainEDITArea__Header_right mainEDITArea__Header_right_noCollapse  mainDirArea_R_WIdth"
          }
        >
          {/* project name block goes here */}
          <div
            onClick={() => {
              dispatch(addIsCharityUploader(false));
              dispatch(addIsSponserUploader(false));
            }}
          >
            <img
              src="./assets/edit.svg"
              style={{
                fontSize: "1em",
                marginRight: "1rem",
                marginTop: "-1.4rem",
              }}
            />
            <input
              value={projectName}
              maxLength={30}
              className={
                toggleD
                  ? "mainEDITArea__titleUploadInput mainEDITArea__titleUploadInput_noCollapse"
                  : "mainEDITArea__titleUploadInput mainEDITArea__titleUploadInput_collapse"
              }
              disabled={isLock}
              placeholder="Bounty Name Here"
              onChange={(e: any) => {
                setEdited((prevState) => ({
                  ...prevState,
                  bountititle: true,
                }));
                setProjectName(e.target.value);
              }}
            />
          </div>
          {/* project name block end here */}

          {/* sponser and charity block goes here */}
          <div className="mainEDITArea__SPonserBLockCharity">
            {/* sponser */}
            <UploadSponser bountyId={selectedId} />

            {/* charity */}
            <CharityUploader bountyId={selectedId} />
          </div>
          {/* sponser and charity block end here */}
        </div>
        {/* right block of header block end here */}
      </div>
      {/* header of main area end here */}

      {/* middel part goes here of main area */}
      <div
        onClick={() => {
          dispatch(addIsCharityUploader(false));
          dispatch(addIsSponserUploader(false));
        }}
        className={
          toggleD
            ? "mainEDITArea__middle mainEDITArea__middle_noCollapse"
            : "mainEDITArea__middle mainEDITArea__middle_noCollapse"
        }
      >
        <div
          style={{ width: "30%" }}
          onClick={() => {
            dispatch(toggleMediaUploder(true));
            dispatch(clearMediaUploaderData([]));
          }}
        >
          {/* main asset upload section goes here */}
          <div
            className={
              toggleD
                ? "mainEDITArea_middle_Asset mainEDITArea_middle_Asset__noCollapse mainDirArea_L_WIdth"
                : "mainEDITArea_middle_Asset mainEDITArea_middle__Asset_collpase mainDirArea_L_WIdth"
            }
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                borderRadius: "1rem",
              }}
            >
              {getBountyMedias.length > 0 ? (
                <>
                  <MediaWrapper
                    styles={{ width: "100%", height: "100%" }}
                    src={getBountyMedias[0].imageUrl}
                    type={getBountyMedias[0].imageUrl.split("/")[5]}
                  />
                </>
              ) : (
                <img src="./assets/upload.svg" style={{ width: "4.75rem" }} />
              )}
            </div>
          </div>
          {/* main asset upload section end here */}
          <div
            className={
              toggleD
                ? "mainEditArea__bottom_L mainDirArea_L_WIdth"
                : " mainEditArea__bottom_L mainDirArea_L_WIdth"
            }
          >
            <div
              className={
                toggleD
                  ? "maiEditArea_assetBox maiEditArea_assetBox__noColl"
                  : "maiEditArea_assetBox maiEditArea_assetBox__Coll"
              }
            >
              {getBountyMedias.length > 1 ? (
                <>
                  <div
                    className="flex justify-center items-center"
                    style={{
                      height: "100%",
                      boxShadow:
                        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                      borderRadius: "1rem",
                    }}
                  >
                    <MediaWrapper
                      styles={{ width: "100%", height: "98%" }}
                      src={getBountyMedias[1].imageUrl}
                      type={getBountyMedias[1].imageUrl.split("/")[5]}
                      wrapper={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={
                toggleD
                  ? "maiEditArea_assetBox maiEditArea_assetBox__noColl"
                  : "maiEditArea_assetBox maiEditArea_assetBox__Coll"
              }
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                borderRadius: "1rem",
              }}
            >
              {getBountyMedias.length > 2 ? (
                <>
                  <div
                    className="flex justify-center items-center"
                    style={{ height: "100%" }}
                  >
                    <MediaWrapper
                      styles={{ width: "100%", height: "98%" }}
                      src={getBountyMedias[2].imageUrl}
                      type={getBountyMedias[2].imageUrl.split("/")[5]}
                      wrapper={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={
                toggleD
                  ? "maiEditArea_assetBox maiEditArea_assetBox__noColl"
                  : "maiEditArea_assetBox maiEditArea_assetBox__Coll"
              }
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                borderRadius: "1rem",
              }}
            >
              {getBountyMedias.length > 3 ? (
                <>
                  <div
                    className="flex justify-center items-center"
                    style={{ height: "100%" }}
                  >
                    <MediaWrapper
                      styles={{ width: "100%", height: "98%" }}
                      src={getBountyMedias[3].imageUrl}
                      type={getBountyMedias[3].imageUrl.split("/")[5]}
                      wrapper={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </>
              ) : (
                <p
                  className="flex items-center justify-center"
                  style={{
                    color: "var(--pure)",
                    fontSize: "2rem",
                    height: "100%",
                  }}
                >
                  +
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Middle right goes here */}
        <div
          className={
            toggleD
              ? "mainEDITArea_middle_right mainEDITArea_middle_right__noCollapse mainDirArea_R_WIdth"
              : "mainEDITArea_middle_right mainEDITArea_middle__right_collpase mainDirArea_R_WIdth"
          }
        >
          {/* left of middle right goes here */}
          <div
            className={
              toggleD
                ? "mainEditAreaMiddleRight__Lft mainEditAreaMiddleRight__LEFT_nocollapse"
                : "mainEditAreaMiddleRight__Lft mainEditAreaMiddleRight__Lft__Collapse"
            }
          >
            {/* row 1 */}
            <div
              className={
                toggleD
                  ? "mainEditAreaMiddleRight__Lft__r1 mainEditAreaMiddleRight__Lft__r1__nOcollapse"
                  : "mainEditAreaMiddleRight__Lft__r1 mainEditAreaMiddleRight__Lft__r1__collapse"
              }
            >
              <SelectorWithHeading
                bountyId={selectedId}
                heading={"Category"}
                dataOne={completeCategoryList}
                loopCount={1}
              />
              <div style={{ marginLeft: "1rem", width: "79.5%" }}>
                <SelectorWithHeading
                  bountyId={selectedId}
                  keyValue={"value"}
                  heading={"Sub Categories"}
                  label={"Sub Categories"}
                  loopCount={2}
                  type={2}
                  data={subCategory}
                  selectedValues={completeSelectedSubCategoryList}
                />
              </div>
            </div>

            {/* row 2 */}

            {/* row 3 */}
            <div
              className={
                toggleD
                  ? "mainEditAreaMiddleRight__Lft__r3 mainEditAreaMiddleRight__Lft__r3__nOcollapse"
                  : "mainEditAreaMiddleRight__Lft__r3 mainEditAreaMiddleRight__Lft__r3__collapse"
              }
            >
              <TextArea
                className="ant-dINPUT minEditAreaBounti__DESC_TExt"
                placeholder="Type your description of the project here."
                maxLength={200}
                autoSize={{ minRows: 8, maxRows: 8 }}
                onChange={(e: any) => {
                  setEditedDes((prevState) => ({
                    ...prevState,
                    bountiDescription: true,
                  }));
                  setDescription(e.target.value);
                }}
                value={description}
                disabled={isLock}
              />
            </div>

            {/* <div
              className={
                toggleD
                  ? "mainEditArea__additionalBTN mainEditArea__additionalBTN_noCollapse"
                  : "mainEditArea__additionalBTN mainEditArea__additionalBTN_collapse"
              }
            >
              <button>Additional Details</button>
            </div> */}

            {/* row 4 */}
            <div
              className={
                toggleD
                  ? "mainEditAreaMiddleRight__Lft__r4 mainEditAreaMiddleRight__Lft__r4__nOcollapse"
                  : "mainEditAreaMiddleRight__Lft__r4 mainEditAreaMiddleRight__Lft__r4__collapse"
              }
            >
              <SelectorWithHeading
                bountyId={selectedId}
                keyValue={"_id"}
                heading={"Formats Accepted"}
                label={"Select Types"}
                loopCount={2}
                type={2}
                data={completeMimeList}
                selectedValues={completeSelectedMime}
              />
            </div>

            {/* row 5 */}

            <div
              className={
                toggleD
                  ? "mainEditAreaMiddleRight__Lft__r5 mainEditAreaMiddleRight__Lft__r5__nOcollapse"
                  : "mainEditAreaMiddleRight__Lft__r5 mainEditAreaMiddleRight__Lft__r5__collapse"
              }
            >
              <div>
                <label htmlFor="additionDedtao" className="label__MAinAreaEdit">
                  <p style={{ textAlign: "center" }}> Additional Detail</p>
                </label>
              </div>
              <TextArea
                className="ant-dINPUT minEditAreaBounti__DESC_TExt"
                id="additionDedtao"
                placeholder="Addtional Detail"
                autoSize={{ minRows: 2, maxRows: 2 }}
                onChange={(e: any) => {
                  setEditedAdd((prevState) => ({
                    ...prevState,
                    bountiAdd: true,
                  }));
                  setAdditionalD(e.target.value);
                }}
                value={additionalD}
                disabled={isLock}
              />
            </div>

            {/* row 6 */}
            <div className="flex" style={{ width: "97%" }}>
              <div
                className={
                  toggleD
                    ? "mainEditAreaMiddleRight__Lft__r2 mainEditAreaMiddleRight__Lft__r2__nOcollapse"
                    : "mainEditAreaMiddleRight__Lft__r2 mainEditAreaMiddleRight__Lft__r2__collapse"
                }
              >
                <div>
                  <label htmlFor="tags" className="label__MAinAreaEdit">
                    Tags:{" "}
                    <b style={{ fontSize: "0.8rem", color: "var(--orange)" }}>
                      Please separate tags with a comma(,)
                    </b>
                  </label>
                </div>
                <Input
                  id="tags"
                  className="ant-dINPUT"
                  placeholder="e.g- Game,Music,Play"
                  onChange={(e: any) => {
                    setTags(e.target.value);
                  }}
                  value={tags}
                  disabled={isLock}
                />
              </div>
              <div
                className={
                  toggleD
                    ? "mainEditAreaMiddleRight__Lft__r6 mainEditAreaMiddleRight__Lft__r6__nOcollapse"
                    : "mainEditAreaMiddleRight__Lft__r6 mainEditAreaMiddleRight__Lft__r6__collapse"
                }
              >
                <div>
                  <label htmlFor="msafou" className="label__MAinAreaEdit">
                    Max Submissions/User
                  </label>
                </div>
                <Input
                  className="ant-dINPUT"
                  id="msafou"
                  value={multipleSubmissionValue}
                  onChange={multipleSubmissionSetting}
                  disabled={isLock}
                />
              </div>
            </div>
          </div>
          {/* left of middle right end here */}

          {/* right of middle right goes here */}
          <div
            className={
              toggleD
                ? "mainEditAreaMiddleRight__Rgt mainEditAreaMiddleRight__RIGHT_nocollapse"
                : "mainEditAreaMiddleRight__Rgt mainEditAreaMiddleRight__Rgt__Collapse"
            }
          >
            <div className={`unlocks`}>
              <USTCard>
                <label style={{ color: "var(--pure)" }}>Unlocks</label>
                <div className="flex">
                  <input
                    type="text"
                    placeholder="... / ..."
                    value={unlockData}
                    onChange={unlocksChanges}
                    className="ustInput"
                    disabled={isLock}
                  />
                  <img
                    src="./assets/edit.svg"
                    style={{
                      width: "1em",
                    }}
                  />
                </div>
              </USTCard>
              <USTCard styling={{ marginTop: "0.5em" }}>
                <label style={{ color: "var(--pure)" }}>Submissions</label>
                <div className="flex">
                  <input
                    type="text"
                    value={submissionData}
                    onChange={submissionChanges}
                    placeholder="... / ..."
                    className="ustInput"
                    disabled={isLock}
                  />
                  <img
                    src="./assets/edit.svg"
                    style={{
                      width: "1em",
                    }}
                  />
                </div>
              </USTCard>
              <USTCard styling={{ marginTop: "0.5em" }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(togglePhasesDialog(true));
                  }}
                >
                  <label style={{ color: "var(--pure)" }}>Time Limit</label>
                  <div className="flex">
                    <p
                      style={{ fontSize: "1rem", color: "var(--sidebar_fade)" }}
                    >
                      ...D ...H ...M
                    </p>
                    <img
                      src="./assets/edit.svg"
                      style={{
                        width: "1em",
                        marginLeft: "1rem",
                      }}
                    />
                  </div>
                </div>
              </USTCard>
            </div>
          </div>
          {/* right of middle right end here */}
        </div>
        {/* Middle right end here */}
      </div>
      {/* middel part end here of main area */}

      {/* bottom part goes here of maineditarea */}
      <div
        className="mainEditArea__bottomWarpper"
        onClick={() => {
          dispatch(addIsCharityUploader(false));
          dispatch(addIsSponserUploader(false));
        }}
      >
        <div></div>
        <div
          className={
            toggleD
              ? "mainEditArea__bottom_R mainDirArea_R_WIdth"
              : " mainEditArea__bottom_R mainDirArea_R_WIdth"
          }
        >
          <div style={{ width: "44rem" }}>
            <div className="reward_uploader__">
              <h1
                className={`bountititle bountititle_res color-headingBlue ml-0`}
              >
                Rewards {`(${rewards?.length})`}
              </h1>
              <div className="flex items-center reward_uploader__controller">
                <ArrowLeft
                  className="highlight-orange cursor-pointor"
                  style={{ fontSize: "3rem" }}
                  onClick={handlePrevArrowClick}
                />
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    background: "var(--orange)",
                    color: "var(--pure)",
                    fontWeight: "bold",
                    borderRadius: "0.5rem",
                    padding: "0.3rem",
                    fontSize: "1.2rem",
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                  disabled={isLock}
                  onClick={() => {
                    dispatch(toggleRewardPopopOnlyCreate(true));
                    //  dispatch(toggleRewardDialogCreation(true));
                    // dispatch(addRewardPopupMode('Create'));
                  }}
                >
                  +
                </button>
                <ArrowRightOutlinedIcon
                  style={{ fontSize: "3rem" }}
                  className="highlight-orange cursor-pointor"
                  onClick={handleArrowClick}
                />
              </div>
            </div>
            <RewardSetter data={rewards} setF={setRewards} />
          </div>

          {/* >T & C for Rewards start */}
          <div className="terms_for_rewards" onClick={handleTermsClick}>
            <p>T & C for Rewards</p>
          </div>
          <div
            className="flex"
            style={
              showTerms
                ? {
                    position: "absolute",
                    top: "7%",
                    left: "16%",
                    width: "69%",
                    height: "93%",
                    zIndex: "999999",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : { display: "none" }
            }
          >
            <div className="reward_edit__card__bottom">
              <div className="terms_edit_close" onClick={handleCloseClick}>
                <p> X</p>
              </div>
              <div className="reward__hover__card__terms_and_condition_edit">
                <div
                  onClick={handleDefaultTerms}
                  style={{
                    backgroundColor: "var(--sidebar_secondory)",
                    width: "10rem",
                    marginTop: "0.5rem",
                    borderRadius: "0.5rem",
                    color: "var(--pure)",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                >
                  Set Default T&C
                </div>
                <h1
                  className="reward__hover__card__terms_main_heading_edit"
                  style={{ color: "var(--dark_2)" }}
                >
                  Terms
                </h1>

                <form>
                  <ul className="reward__termsACond">
                    {/* eligibility */}
                    <li>
                      <label htmlFor="Eligibility" className="terms__labels">
                        Eligibility:
                      </label>
                      <div>
                        <textarea
                          id="Eligibility"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.Eligibility}
                          onChange={(e) =>
                            handleTermsChange("Eligibility", e.target.value)
                          }
                          name="Eligibility"
                          className="termsTextAreas"
                          // disabled={!isEditable}
                        />
                      </div>
                    </li>
                    {/* entry pariod */}
                    <li>
                      <label htmlFor="EntryPeriod" className="terms__labels">
                        Entry Period:
                      </label>
                      <div>
                        <textarea
                          id="EntryPeriod"
                          style={{ resize: "none", width: "100%" }}
                          value={termsAndConditons?.EntryPeriod}
                          rows={6}
                          onChange={(e) =>
                            handleTermsChange("EntryPeriod", e.target.value)
                          }
                          name="EntryPeriod"
                          className="termsTextAreas"
                          // disabled={!isEditable}
                        />
                      </div>
                    </li>
                    {/* entry pariod */}
                    <li>
                      <label htmlFor="HowToEnter" className="terms__labels">
                        How To Enter:
                      </label>
                      <div>
                        <textarea
                          id="HowToEnter"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.HowToEnter}
                          onChange={(e) =>
                            handleTermsChange("HowToEnter", e.target.value)
                          }
                          name="HowToEnter"
                          className="termsTextAreas"
                          // disabled={!isEditable}
                        />
                      </div>
                    </li>
                    {/* Prizes */}
                    <li>
                      <label htmlFor="Prizes" className="terms__labels">
                        Prizes:
                      </label>
                      <div>
                        <textarea
                          id="Prizes"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.Prizes}
                          onChange={(e) =>
                            handleTermsChange("Prizes", e.target.value)
                          }
                          name="Prizes"
                          className="termsTextAreas"
                          // disabled={!isEditable}
                        />
                      </div>
                    </li>
                    {/* SelectionOfWinners */}
                    <li>
                      <label htmlFor="Prizes" className="terms__labels">
                        Selection Of Winners:
                      </label>
                      <div>
                        <textarea
                          id="SelectionOfWinners"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.SelectionOfWinners}
                          onChange={(e) =>
                            handleTermsChange(
                              "SelectionOfWinners",
                              e.target.value
                            )
                          }
                          name="SelectionOfWinners"
                          className="termsTextAreas"
                          // disabled={!isEditable}
                        />
                      </div>
                    </li>
                    {/* PrizeRedemption */}
                    <li>
                      <label htmlFor="Prizes" className="terms__labels">
                        Prize Redemption:
                      </label>
                      <div>
                        <textarea
                          id="PrizeRedemption"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.PrizeRedemption}
                          onChange={(e) =>
                            handleTermsChange("PrizeRedemption", e.target.value)
                          }
                          name="PrizeRedemption"
                          className="termsTextAreas"
                        />
                      </div>
                    </li>
                    {/* RewardSubjectToChange */}
                    <li>
                      <label
                        htmlFor="RewardSubjectToChange"
                        className="terms__labels"
                      >
                        Reward Subject To Change:
                      </label>
                      <div>
                        <textarea
                          id="RewardSubjectToChange"
                          style={{ resize: "none", width: "100%" }}
                          rows={6}
                          value={termsAndConditons?.RewardSubjectToChange}
                          onChange={(e) =>
                            handleTermsChange(
                              "RewardSubjectToChange",
                              e.target.value
                            )
                          }
                          name="RewardSubjectToChange"
                          className="termsTextAreas"
                          // disabled={!isEditable}
                        />
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
          {/* >T & C for Rewards end */}
        </div>
      </div>
      {/* bottom part end here of maineditarea */}

      <button
        className="mainEDitArea__SaveBTN"
        onClick={publishBountyfunction}
        style={{ cursor: "pointer" }}
      >
        Post / Update
      </button>
    </main>
  );
};

export default MainEditArea;
