import React from "react";
import "./style.css";
import { Button, Checkbox, Input } from "antd";
import { SwiperSlide, Swiper } from "swiper/react";
import { useDispatch } from "react-redux";
import { setSubmissionScreen } from "../../app/slices/singleStates";
const { TextArea } = Input;

const CollabaratorStep = () => {
  const dispatch = useDispatch();

  return (
    <section className="submission__step1">
      <div className="submission__step__inner">
        {/* <div className="Submission__POPUpHeading">Collabarator</div> */}
        <img src="./tests/collscreendemo.png" alt="" />

        {/* submit */}
        <div
          style={{ marginTop: "1rem", justifyContent: "space-between" }}
          className="step1_sub_buttonR flex justify-between"
        >
          <div
            className="submissionSkipNow"
            onClick={() => {
              dispatch(setSubmissionScreen(4));
            }}
            style={{ marginLeft: "1rem" }}
          >
            skip
          </div>
          <div>
            <Button
              className="submit__button_Submisn submit__button_SubmisnReawrd"
              onClick={() => {
                dispatch(setSubmissionScreen(2));
              }}
            >
              Previous
            </Button>
            <Button
              className="submit__button_Submisn submit__button_SubmisnReawrd"
              onClick={() => {
                dispatch(setSubmissionScreen(4));
              }}
              style={{ marginLeft: "1rem" }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollabaratorStep;
