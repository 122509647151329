import React from "react";
import BlueCard1 from "./BlueCard1";

const FriendCard = ({ isDynamicRoute, isFriend, friends }: any) => {
  return (
    <div
      className="profile__submission__card"
      style={{ marginTop: "2rem", height: "25rem" }}
    >
      <div className="profile__submission__heading">Friends</div>
      <div className="profile__submission__body" style={{ height: "85%" }}>
        {friends?.map((friend:any)=>{
          return <BlueCard1 isDynamicRoute={isDynamicRoute} friendData={friend}/>
        })}
      </div>
    </div>
  );
};

export default FriendCard;
