import { Checkbox } from "antd";
import { useEffect, useState } from "react";
import "./charityUploader.css";
import axios from "axios";
import {
  getAllCharityList,
  getSelectedCharity,
} from "../../../../../../apis/getApis";
import { editCharity } from "../../../../../../apis/putApis";
import { createCharity } from "../../../../../../apis/postApis";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Alert } from "antd";
import { Navigation, Autoplay } from "swiper/modules";
import {
  addIsCharityUploader,
  addIsSponserUploader,
} from "../../../../../../app/slices/singleStates";
import { useDispatch, useSelector } from "react-redux";
import { setText } from "../../../../../../app/slices/CheckListStatus";

const CharityUploader = ({ bountyId }: any) => {
  // local states
  const [isShow, setIsShow] = useState<any>(false);
  const [charityList, setCharityList] = useState<any>([]);
  const createdCharityId: any = localStorage.getItem("createdCharityId");
  const [createdCharityIdState, setCreatedCharityId] = useState<any>("");
  const [realCharityResponse, setRealCharityResponse] = useState<any>([]);
  const [isCarouselActive, setIsCarauselActive] = useState<boolean>(false);
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [issuccessMessage, setSuccessMessage] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);
  const [charityCommon, setCharityCommon] = useState<any>([]);
  const dispatch = useDispatch();
  const isCharityUploader = useSelector(
    (state: any) => state.booleanStatus.isCharityUploader
  );

  // fetch Sponser List
  const fetchCharityList = async () => {
    await axios
      .get(getAllCharityList)
      .then((res: any) => {
        setCharityList(res.data.data);
      })
      .catch((err: any) => {});
  };

  const fetchSelectedCharity = () => {
    if (createdCharityId) {
      axios
        .get(`${getSelectedCharity}/${bountyId}`)
        .then((res) => {
          setRealCharityResponse(res.data.data);
          console.log("charityGET-useEffect", res);
          if (res.data.data.length > 0) {
            setIsCarauselActive(true);
          }
        }).catch((err: any) => {});
    }
  };

  // create charity to db
  const createCharityToDB = () => {
    if (!createdCharityId) {
      axios
        .post(`${createCharity}/${bountyId}`)
        .then((res: any) => {
          localStorage.setItem("createdCharityId", res.data.data);
          setCreatedCharityId(res.data.data);
        })
        .catch((err: any) => {
          console.log("createdCharityId", err);
        });
    } else {
    }
  };

  function findCommonObjects(array1: any, array2: any) {
    const commonIds: any = [];

    array1.forEach((obj1: any) => {
      const matchingObj = array2.find((obj2: any) => obj1.id === obj2.id);
      if (matchingObj) {
        commonIds.push(obj1.id);
      }
    });

    return commonIds;
  }

  // Set a timeout to close the alert after 3000 milliseconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAlertSuccess(false);
      setIsAlertError(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isAlertSuccess, isAlertError]);

  useEffect(() => {
    if (realCharityResponse && charityList) {
      const commonId: any = findCommonObjects(realCharityResponse, charityList);
      setCharityCommon(commonId);
    }
  }, [realCharityResponse, charityList]);

  const checkOrUncheckCharity = (e: any) => {
    if (createdCharityId || createdCharityIdState) {
      axios
        .put(`${editCharity}/${createdCharityId}/${bountyId}`, {
          charityId: e.target.value,
        })
        .then((res: any) => {
          dispatch(setText([res.data.checkList.Charity, 13]));
          if (res.status === 201) {
            // If status code is 201, set success message and show the alert
            setIsAlertSuccess(true);
            setSuccessMessage(res.data.message); // Assuming your success message is in res.data.message
          } else {
            setIsAlertError(true);
          }
          axios
            .get(`${getSelectedCharity}/${bountyId}`)
            .then((res) => {
              console.log("charityGET", res);
              setRealCharityResponse(res.data.data);
              if (res.data.data.length > 0) {
                setIsCarauselActive(true);
              }
            })
            .catch((err: any) => {});
        })
        .catch((err: any) => {
          setIsAlertError(true);
        });
    } else {
    }
  };

  useEffect(() => {
    fetchCharityList();
    createCharityToDB();
    fetchSelectedCharity();
  }, []);

  return (
    <>
      <div>
        {isAlertSuccess && (
          <div className="upload-alert">
            <Alert
              message={issuccessMessage}
              type="success"
              showIcon
              closable
              onClose={() => setIsAlertSuccess(false)}
            />
          </div>
        )}
      </div>
      <div>
        {isAlertError && (
          <div className="upload-alert">
            <Alert
              message="Something went wrong"
              type="error"
              showIcon
              closable
              onClose={() => setIsAlertError(false)}
            />
          </div>
        )}
      </div>
      <div
        className={`charity_c1`}
        onMouseOver={() => {
          dispatch(addIsCharityUploader(true));
        }}
        onMouseOut={() => {
          dispatch(addIsCharityUploader(false));
        }}
        style={{ marginRight: "1rem", position: "relative", cursor: "pointer" }}
      >
        <label className="responsive_label">Charity</label>
        <div
          className={`charitySlider_c1 flex`}
          style={{
            background: "var(--sidebar)",
            justifyContent: "end",
          }}
          onClick={() => {
            dispatch(addIsSponserUploader(false));
            isCharityUploader
              ? dispatch(addIsCharityUploader(false))
              : dispatch(addIsCharityUploader(true));
          }}
        >
          {!isCarouselActive ? (
            <div>
              <img
                src="./assets/edit.svg"
                style={{
                  fontSize: "1rem",
                  marginLeft: "1rem",
                  marginRight: "0.5rem",
                  marginTop: "0.5rem",
                }}
              />
            </div>
          ) : (
            <>
              <Swiper
                slidesPerView={1}
                spaceBetween={1}
                navigation={true}
                modules={[Navigation, Autoplay]}
                // className={`${styles.reward_carousel}`}
                loop
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {realCharityResponse?.map((charity: any) => {
                  return (
                    <SwiperSlide key={`${Math.random() * Date.now() ** 10}`}>
                      <div style={{ width: "100%", height: "5em" }}>
                        <img
                          src={charity?.image}
                          style={{
                            borderRadius: "0.4em",
                            width: "100%",
                            height: "5em",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          )}
        </div>
        {/* charity body */}
        <div
          onMouseOver={() => {
            dispatch(addIsCharityUploader(true));
          }}
          className="upload__sponser__select charity__body"
          style={isCharityUploader ? { display: "block" } : { display: "none" }}
        >
          {charityList?.map((charity: any, ind: any) => {
            return (
              <div
                className="sel__sponser"
                style={ind !== 0 ? { marginTop: "1rem" } : {}}
              >
                <Checkbox
                  className="upload__sponser_checkbox"
                  value={charity.id}
                  checked={charityCommon.includes(charity.id)}
                  onChange={checkOrUncheckCharity}
                />
                <div className="uploader_sponser__name">
                  {charity.charityName}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CharityUploader;
