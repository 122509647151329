import { useState, useEffect } from "react";
import "./rewardcard.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { globalBounti2, viewReward } from "../../apis/getApis";
import Header from "../../apis/header";

const RewardHoverCard = ({ bountyId, isHoverCard, setHoverCard }: any) => {
  const [rewardData, setRewardData] = useState<any>();
  const [termsAndConditons, setTermsAndConditons] = useState<any>({
    Eligibility: "",
    EntryPeriod: "",
    HowToEnter: "",
    Prizes: "",
    SelectionOfWinners: "",
    PrizeRedemption: "",
    RewardSubjectToChange: "",
  });

  const clikedRewardId = useSelector(
    (state: any) => state.booleanStatus.clikedRewardId
  );

  //   fetch single reward using id
  const fetchSingleReward = async (rewrdId: any) => {
    if (rewrdId) {
      await axios
        .get(`${viewReward}/${bountyId}/${rewrdId}`)
        .then((reward: any) => {
          console.log("Single Reward Data", reward.data.reward);
          setRewardData(reward.data.reward);
        })
        .catch((err: any) => {});
    }
  };

  //   get term and condition
  const fetchTerms = async () => {
    await axios
      .get(`${globalBounti2}/${bountyId}`, Header)
      .then((res) => {
        console.log(res, "globalBounti2");
        if (res.data.text.termsAndConditions) {
          setTermsAndConditons({
            Eligibility: res.data.text.termsAndConditions.Eligibility || "",
            EntryPeriod: res.data.text.termsAndConditions.EntryPeriod || "",
            HowToEnter: res.data.text.termsAndConditions.HowToEnter || "",
            Prizes: res.data.text.termsAndConditions.Prizes || "",
            SelectionOfWinners:
              res.data.text.termsAndConditions.SelectionOfWinners || "",
            PrizeRedemption:
              res.data.text.termsAndConditions.PrizeRedemption || "",
            RewardSubjectToChange:
              res.data.text.termsAndConditions.RewardSubjectToChange || "",
          });
        } else {
          console.log(
            "termsAndConditions property not found in the API response."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // terms and conditions get API
  useEffect(() => {
    fetchTerms();
  }, []);

  useEffect(() => {
    if (clikedRewardId) {
      fetchSingleReward(clikedRewardId);
    }
  }, [clikedRewardId]);

  return (
    <>
      {/* reward card goes here */}
      <section
        className="reward__hover__card"
        style={isHoverCard ? { display: "block" } : { display: "none" }}
        // onMouseLeave={() => {
        //   setHoverCard(false);
        // }}
      >
        {/* top part of it goes here*/}
        <div className="reward__hover__card__top">
          <div className="flex justify-between">
            {/* left of top goes here */}
            <div className="reward__hover__card__top__left">
              <div className="reward_hover_card__image">
                {/* Reward Image */}
                <img
                  src={rewardData?.imagePath}
                  style={{
                    width: "5rem",
                    height:"5rem",
                    borderRadius: "1rem",
                  }}
                  alt=""
                />
              </div>
              <div className="reward_hover_card__meta">
                {/* Reward Title */}
                <div className="reward_hover_card__meta_name">
                  {rewardData?.title}
                </div>
                {/* Reward Catgory */}
                <div className="reward_hover_card__meta_category">
                  {rewardData?.categories}
                </div>
              </div>
            </div>
            {/* left of top end here */}

            {/* right of top goes here */}
            <div className="reward__hover__card__top__right">
              <div>
                <h2>Reward Type:</h2>
                <b>{rewardData?.type}</b>
              </div>
              <div>
                <h2>Value:</h2>
                <b>${rewardData?.value}</b>
              </div>
              <div>
                <h2>Delivery Method:</h2>
                <b>{rewardData?.deliveryMethod}</b>
              </div>
            </div>
            {/* right of top end here */}
          </div>
          {/* Reward Description */}
          <div className="reward__hover__card__description">
            {rewardData?.description}
          </div>
        </div>
        {/* top part of it end here*/}

        {/* bottom part of it goes here*/}
        {/* <div className="reward__hover__card__bottom">
          <div className="reward__hover__card__terms_and_condition">
            <h1 className="reward__hover__card__terms_main_heading">Terms</h1>

            {Object.entries(termsAndConditons).map(([key, value]: any) => (
              <div key={key} style={{ marginTop: "1rem" }}>
                <h2>{key}:</h2>
                <p>{value}</p>
              </div>
            ))}
          </div>
        </div> */}
        {/* bottom part of it end here*/}
      </section>

      {/* reward card end here */}
    </>
  );
};

export default RewardHoverCard;
