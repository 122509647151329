import LanguageIcon from "@mui/icons-material/Language";
import "./footer.css";
import { useState } from "react";
import { Radio } from "antd";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const [language, setLanguage] = useState<any>("English (Us)");
  const [toggle, setToggle] = useState<any>(false);
  return (
    <footer className="global__footer flex justify-between">
      {/* language selector goes*/}
      <div
        className="language__selector flex items-center justify-between"
        onClick={() => {
          toggle ? setToggle(false) : setToggle(true);
        }}
      >
        {/* icon */}
        <LanguageIcon />
        <b id="language">{language}</b>
        <img src="./tests/icons/downarrow_black.svg" />
      </div>

      {/* footer middle goes here */}
      <div className="footer__middle">
        © 2023 All Rights Reserved Minga® <br /> Privacy policy | Terms
      </div>
      {/* footer middle end here */}

      {/* footer social section goes here */}
      <div className="footer__social">
        <FacebookIcon />
        <TwitterIcon/>
        <LinkedInIcon/>
        <InstagramIcon/>
      </div>
      {/* footer social section end here */}

      <div
        className="language__selector language__selector__dropdown"
        style={toggle ? { display: "block" } : { display: "none" }}
      >
        <div
          className="lan_footer"
          onClick={() => {
            setLanguage("English (Us)");
            setToggle(false);
          }}
        >
          English (Us) <input type="radio" name="lg" />
        </div>
        <div
          style={{ marginTop: "0.5rem" }}
          className="lan_footer"
          onClick={() => {
            setLanguage("French");
            setToggle(false);
          }}
        >
          French <input type="radio" name="lg" />
        </div>
        <div
          style={{ marginTop: "0.5rem" }}
          className="lan_footer"
          onClick={() => {
            setLanguage("Hindi");
            setToggle(false);
          }}
        >
          Hindi <input type="radio" name="lg" />
        </div>
      </div>
      {/* language selector end*/}
    </footer>
  );
};

export default Footer;
