import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { editDescription, editTitle } from "../../app/slices/mediaUploaderData";
import { RootState } from "../../app/store";

const FMForm = ({ setIsModify }: any) => {
  const dispatch = useDispatch();
  // Get current focus card number from Redux store
  const isCurrentFocusCardNumber: any = useSelector((state: RootState) => {
    return state.mediaUploaderSignles.currentFocusCard;
  });

  // Get file data from Redux store
  const fileDataFromRedux: any = useSelector((state: RootState) => {
    return state.mediaUploaderData;
  });

  // Get lock status from Redux store
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });

  // Function to check if title or description is being edited
  const isEditing = () => {
    fileDataFromRedux.forEach((elm: any, ind: any) => {
      if (isCurrentFocusCardNumber === ind) {
        if (elm.hasOwnProperty("id")) {
          setIsModify(true);
        }
      }
    });
  };
  // Function to handle title change
  const titleChange = (value: any) => {
    dispatch(editTitle([isCurrentFocusCardNumber, value]));
    isEditing();
  };
  // Function to handle description change
  const descriptionChange = (value: any) => {
    dispatch(editDescription([isCurrentFocusCardNumber, value]));
    isEditing();
  };

  return (
    <div className="FM__Form">
      {/* Title input field */}
      <div className="FM__F_TitleSEc">
        <label htmlFor="title" className="FM__F_LAbel">
          Title
        </label>
        <Input
          id="title"
          className="FM__Input"
          value={fileDataFromRedux[isCurrentFocusCardNumber]?.title}
          placeholder="Type the title of your upload here"
          onChange={(e: any) => {
            titleChange(e.target.value);
          }}
          disabled={isLock}
        />
      </div>
      {/* Description input field */}
      <div className="FM__F_TitleSEc FM__DESC">
        <label htmlFor="description" className="FM__F_LAbel">
          Description
        </label>
        <TextArea
          id="description"
          className="FM__Input"
          placeholder="Type the title of your upload here"
          rows={11}
          value={fileDataFromRedux[isCurrentFocusCardNumber]?.description}
          onChange={(e: any) => {
            descriptionChange(e.target.value);
          }}
          style={{ resize: "none" }}
          disabled={isLock}
        />
      </div>
    </div>
  );
};

export default FMForm;
