import { ChatBubble } from "./index";
import "./chatRoom.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { SendIcon } from "../../svgicons";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import { useState, useEffect, useRef, useTransition } from "react";
import axios from "axios";
import Header from "../../apis/header";

const ChatRoom = ({ mesiboToken, mesiboGid }) => {
  // localstate
  const [selectedEmoji, setSelectedEmoji] = useState("1f60a");
  const [media, setMediaUpload] = useState("");
  const fileInputRef = useRef(null);
  //   mesibo goes here
  const [userToken, setUserToken] = useState("");
  const [message, setMessage] = useState("");
  const [sendTo, setSendTO] = useState("");
  const [api, setApi] = useState();
  const [profile, setProfile] = useState();
  const [connection, setConnection] = useState(true);
  const [newMessage, setNewMessage] = useState("listening to changes...");
  const [isOnline, setIsOnline] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  const [currentMediaType, setCurrentMediaType] = useState();
  const [userData, setUserData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [dateTime, setDateTime] = useState("");
  const [dateTimeSet, setDateTimeSet] = useState(false);
  const [sentMessage, setSentMessage] = useState([
    // { d: true, m: "one", s: "me", t: "time" },
    // { d: false, m: "two", s: "anurag", t: "time" },
  ]);
  const [showNavbar, setShowNavbar] = useState(false);
  const [currentStates, setCurrentStates] = useState({
    isEmojiSection: false,
  });
  const [msgDate, setMsgDate] = useState("");

  // loginData detail
  let loginData = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }
  // console.log(mesiboToken, mesiboGid,"mesiboTokenmesiboToken");
  useEffect(() => {}, [sentMessage]);
  function MesiboListener() {}

  useEffect(() => {
    console.log(profile, "profileprofile");
  }, [profile]);

  // on scroll show message date
  useEffect(() => {
    const handleScroll = () => {
      const chatMainBody = document.querySelector(".chatMainBody");
      if (!chatMainBody) return;
      const scrollTop = chatMainBody.scrollTop;

      // Find the current message date based on the scroll position
      let currentDate = "";
      const messages = document.querySelectorAll(".date_separator");
      messages.forEach((message) => {
        const rect = message.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          currentDate = message.textContent;
        }
      });

      // Update msgDate only if currentDate is not empty
      if (currentDate !== "") {
        setMsgDate(currentDate);
      }

      // If scrolling up and no message date is found, update msgDate based on the last message
      if (scrollTop < 10 && !currentDate) {
        const lastMessage = sentMessage[sentMessage.length - 1];
        if (lastMessage) {
          setMsgDate(lastMessage.date);
        }
      }

      if (scrollTop > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    const chatMainBody = document.querySelector(".chatMainBody");
    if (chatMainBody) {
      chatMainBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatMainBody) {
        chatMainBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [msgDate]);

  // user information get and chat data get
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://mingalinks.com:5000/v1/users/${loginData?.userId}`
        );
        setUserData(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    console.log(dateTime, "2134");
  }, [dateTime]);

  // get msg API
  const msgGet = async () => {
    try {
      // Define the request body with or without time
      const withoutTime = {
        gID: mesiboGid,
      };
      const withTime = {
        gID: mesiboGid,
        timeBefore: dateTime,
      };
      // Make an HTTP POST request to fetch messages
      const response = await axios.post(
        `https://mingalinks.com:5000/v1/mesages/FromGroup`,
        dateTimeSet ? withTime : withoutTime,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      console.info("statusNow fetchh", response);
      // Process the fetched messages
      const sentMessage = response.data.map((item) => {
        const date = new Date(item.timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        const time = `${formattedHours}:${formattedMinutes}`;
        const formattedDate = date.toISOString(); // Get ISO string format of date

        return {
          d: item.sender === userData?.email,
          m: item.content,
          s: item.sender,
          t: time,
          date: formattedDate, // Add formatted date to the message object
        };
      });

      console.info("fetchh statuaws ", sentMessage);
      // Update state with processed messages
      setSentMessage(sentMessage);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Function to group messages by date
  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
    messages.forEach((message) => {
      const messageDate =
        //  new Date(message.date).toLocaleDateString();
        new Date(message.date).toLocaleDateString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }
      groupedMessages[messageDate].push(message);
    });
    return groupedMessages;
  };

  // Fetch messages and update state when mesiboGid or dateTime changes
  useEffect(() => {
    if (mesiboGid) {
      msgGet();
    }
  }, [mesiboGid, dateTime]);

  // Fetch messages and update state when mesiboGid or dateTime changes
  useEffect(() => {
    const fetchMessages = async () => {
      const withoutTime = {
        gID: mesiboGid,
      };
      const withTime = {
        gID: mesiboGid,
        timeBefore: dateTime,
      };
      try {
        // Fetch messages from API
        const response = await axios.post(
          `https://mingalinks.com:5000/v1/mesages/FromGroup`,
          dateTimeSet ? withTime : withoutTime,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );

        // Process messages
        const processedMessages = response.data.map((item) => {
          // Process each message and return the required format
          const date = new Date(item.timestamp);
          const hours = date.getHours();
          const minutes = date.getMinutes();

          const formattedHours = hours < 10 ? `0${hours}` : hours;
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

          const time = `${formattedHours}:${formattedMinutes}`;
          const formattedDate = date.toISOString(); // Get ISO string format of date

          return {
            d: item.sender === userData?.email,
            m: item.content,
            s: item.sender,
            t: time,
            date: formattedDate, // Add formatted date to the message object
          };
        });

        console.info("fetchh statuaws ", sentMessage);

        // Update state with processed messages
        setSentMessage(processedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [mesiboGid, dateTime]);

  useEffect(() => {
    // console.log("chatData changed:", chatData);
  }, [chatData]); // Include chatData in the dependency array

  // upload media with addIcon
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // toggleEmojiSection function goes here
  const toggleEmojiSection = () => {
    if (currentStates.isEmojiSection) {
      setCurrentStates((...prev) => {
        return { ...prev, isEmojiSection: false };
      });
    } else {
      setCurrentStates((...prev) => {
        return { ...prev, isEmojiSection: true };
      });
    }
  };
  // toggleEmojiSection function end here

  // send message function goes here
  const sendMessage = async (url) => {
    setStatusLoader(true);
    setMessage("");
    if (api) {
      let date = new Date();
      let msg = profile.newMessage();
      msg.title = message;
      msg.message = message;
      msg.setContent(media ? media : "");
      msg.setContentType(window.MESIBO_FILETYPE_IMAGE);
      msg.send();

      setSentMessage((sentMessage) => [
        ...sentMessage,
        {
          d: true,
          m: message,
          s: "me",
          t: date.getHours() + ":" + date.getMinutes(),
          date: date.toISOString(),
          mid: msg.mid,
          media: currentMedia,
          mediaType: currentMediaType,
        },
      ]);
    }
    setMediaUpload("");
    setCurrentMediaType("");
    setCurrentMedia("");
  };
  // send message function end here

  // send message on Enter goes here
  const sendMessageOnEnter = (e) => {
    if (e.keyCode === 13) {
      sendMessage();
    }
  };
  // send message on Enter end here

  // set emoji to message goes here
  const setEmojiWithMessage = (emojiData) => {
    setMessage(
      (message) =>
        message + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
    );
    setSelectedEmoji(emojiData.unified);
  };
  // ser emoji to message function end here

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://api.mesibo.com/mesibo-v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // eslint-disable-next-line no-undef
      setApi(new Mesibo());
    };
  }, []);

  useEffect(() => {
    console.log(mesiboToken, "apiiii");
    if (api && mesiboToken) {
      console.log(mesiboToken, "after apiiii");

      connect();
    }
  }, [mesiboToken, api, mesiboGid]);

  const connect = () => {
    console.info(api, "we are connect");
    let demo_app_id = "minga.com";
    api.setAppName(demo_app_id);
    api.setAccessToken(mesiboToken);
    api.setListener(new MesiboListener());
    api.setDatabase("mesibo");
    api.start();
    setProfile(api.getProfile(null, mesiboGid));

    // console.log(
    //   "bht status",
    //   api.getProfile(null, "bthukral@gmail.com").isOnline()
    // );

    // const getActiveUsersOnlineStatus = async () => {
    //   try {
    //     const activeUsers = api.getProfile(null, true); // Get active users
    //     console.log("Active Users Data:", activeUsers);

    //     if (activeUsers && activeUsers.i && activeUsers.i.vb) {
    //       const vbData = activeUsers.i.vb; // Accessing the "vb" data
    //       console.log(vbData,"Active Users ghghgf")
    //       // Iterate through active users and check online status
    //       for (const userId in vbData) {
    //         // Use async/await to ensure that api.getProfile completes before moving to the next iteration
    //         const userProfile = await api.getProfile(null, userId); // Get profile of each user
    //         console.log(userProfile.isOnline(), userId, "Active Users");
    //         if (userProfile && userProfile.isOnline()) {
    //           console.log(`${userId} is online.`);
    //           // Handle online user here
    //         }
    //       }
    //     } else {
    //       console.log("No active users found.");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching active users:", error);
    //   }
    // };

    // getActiveUsersOnlineStatus();
    // Function to get active user emails and set in state
    MesiboListener.prototype.Mesibo_onConnectionStatus = function (
      status,
      value
    ) {
      console.info(status, "statusNowstatusNow");
      // if (status === 1) {
      //   setIsOnline(true);
      // }
      console.log("statusNow", mesiboGid, mesiboToken, status);
      setConnection(false);
    };

    MesiboListener.prototype.Mesibo_onMessageStatus = function (m) {
      console.log(
        "Mesibo_onMessageStatus: from " + m.peer + " status: " + m.status
      );
      if (m.status === 1) {
        setStatusLoader(false);
      }
    };

    MesiboListener.prototype.Mesibo_onMessage = async function (msg) {
      console.log("onMessage", msg);
      if (msg.profile) {
        // console.log("isonline|", msg.profile.isOnline())
      }
      /* Messaging documentation https://mesibo.com/documentation/api/messaging/ */
      if (msg.isIncoming()) {
        /* Profile documentation https://mesibo.com/documentation/api/users-and-profiles/ */
        let sender = msg.profile;

        // check if this message belongs to a group
        /* Group Management - https://mesibo.com/documentation/api/group-management/ */
        if (msg.isGroupMessage()) {
          let group = msg.groupProfile;
        }

        // check if this message is realtime or read from the database
        if (msg.isRealtimeMessage()) {
          console.log("media-msg", msg);
          // setDateTime(msg.date.date+" "+msg.date.time);
          setNewMessage(sender.getNameOrAddress("") + ": " + msg.message);
          if (!dateTimeSet) {
            setDateTimeSet(true); // Set dateTimeSet to true on the first call
            setDateTime(msg.date.date + " " + msg.date.time);
            console.log(dateTime, "dateTimeeee media-msg");
          }
          setSentMessage((sentMessage) => [
            ...sentMessage,
            {
              d: false,
              m: msg.message,
              s: sender.getNameOrAddress(""),
              t: msg.date.time,
              date: msg.date.date,
              media: msg.file.url,
              type: msg.file.type,
              mid: msg.mid,
            },
          ]);

          console.log(
            "Mesibo_onMessage: from " +
              sender.getNameOrAddress("") +
              " msg: " +
              msg.message
          );
        }
      } else if (msg.isOutgoing()) {
        /* messages you sent */
        const requestData = {
          sender: userData?.email,
          gID: mesiboGid,
          content: msg.file.url || msg.message,
        };

        try {
          //  API call
          const response = await fetch(
            "https://mingalinks.com:5000/v1/message",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${loginData.access_token}`,
              },
              body: JSON.stringify(requestData),
            }
          );
          console.log(response, "statusNow response");
          if (response.ok) {
            console.log("Message sent successfully");
          } else {
            console.error("Failed to send message:", response.status);
          }
        } catch (error) {
          console.error("Error sending message:", error);
        }
        console.log("Mesibo_onMessage: sent a message with id: " + msg.mid);
      } else if (msg.isMissedCall()) {
      }
    };
  };

  useEffect(() => {
    if (profile) {
      let rs = profile.createReadSession(MesiboListener);
      console.log("one123,", rs);
      rs.enableReadReceipt(true);
      rs.read(100);
    }
  }, [profile]);

  return (
    <div style={{ height: "100%" }}>
      <section className="chat_wrapper">
        <div
          style={
            currentStates.isEmojiSection
              ? { position: "absolute", bottom: "0", left: "-22rem" }
              : { display: "none" }
          }
        >
          <EmojiPicker
            onEmojiClick={setEmojiWithMessage}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
          />
        </div>
        <header className="chat_header">
          <div className="chat_header_top flex justify-between items-center">
            <h1 className="chat_header_heading">Bounty Chat</h1>
            {/* <div className="chat_header_sortByDate flex items-center">
              <div className="chat_header_sortByDate_label">Sort by:</div>

              <div className="chat_header_dateSelector flex items-center">
                <span>Date</span>{" "}
                <ArrowDropDownIcon style={{ color: "var(--pure)" }} />
              </div>
            </div> */}
          </div>
          {/* <div className="chat_header_bottom flex justify-between items-center">
            <div className="chat_header_sortByDate_label">Chat Members:</div>
            <div className="chat_header_sortByDate flex ">
              <div className="chat_header_dateSelector">Date</div>
            </div>
          </div> */}
        </header>
        <div className="ewqr">
          {showNavbar && (
            <nav className="chat_header2">
              <div className="nav_text">{msgDate}</div>
            </nav>
          )}
        </div>
        {/* chatroom main middle part goes here */}
        <main className="chatMainBody">
          {Object.entries(groupMessagesByDate(sentMessage)).map(
            ([messageDate, messages]) => {
              console.log("sentMessage", sentMessage);
              // const messageDate = message.date;
              return (
                <div key={messageDate}>
                  <div className="date_separator">{messageDate}</div>
                  <div key={`${Math.random() + Date.now()}${0}`}>
                    {messages.map((message) => (
                      <div key={message.mid}>
                        <div
                          className={message.d ? "send_media" : "receive_media"}
                        >
                          {message.mediaType == "image" ? (
                            <img
                              src={message.media}
                              className="very_dark_blue_bg"
                              style={{
                                width: "16rem",
                                height: "16rem",
                                objectFit: "cover",
                                marginTop: "1.5rem",
                              }}
                            />
                          ) : message.mediaType == "video" ? (
                            <video
                              className="very_dark_blue_bg"
                              style={{
                                width: "16rem",
                                height: "16rem",
                                objectFit: "cover",
                                marginTop: "1.5rem",
                              }}
                              controls
                            >
                              <source src={message.media} />
                            </video>
                          ) : message.mediaType == "audio" ? (
                            <>
                              <audio
                                className="very_dark_blue_bg"
                                controls
                                src={message.media}
                                style={{ marginTop: "1.5rem" }}
                              ></audio>
                            </>
                          ) : message.mediaType == "application" ? (
                            <>
                              <a
                                target="_blank"
                                className="very_dark_blue_bg chat_file"
                                href={message.media}
                              >
                                View File
                              </a>
                            </>
                          ) : (
                            ""
                          )}

                          {/* recieve media */}
                          {message.type ? (
                            message.type == 1 ? (
                              <img
                                src={message.media ? message.media : ""}
                                className="very_dark_blue_bg"
                                style={{
                                  width: "16rem",
                                  height: "16rem",
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            ) : message.type == 2 ? (
                              <video
                                className="very_dark_blue_bg"
                                style={{
                                  width: "16rem",
                                  height: "16rem",
                                  objectFit: "cover",
                                }}
                                controls
                              >
                                <source
                                  src={message.media ? message.media : ""}
                                />
                              </video>
                            ) : message.type == 3 ? (
                              <>
                                <audio
                                  controls
                                  src={message.media ? message.media : ""}
                                ></audio>
                              </>
                            ) : (
                              <>
                                <a
                                  target="_blank"
                                  href={message.media ? message.media : ""}
                                >
                                  file_{message.t}
                                </a>
                              </>
                            )
                          ) : (
                            <>
                              {/* "searchng for the type" {message.type} { message.media} */}
                            </>
                          )}
                        </div>
                        <ChatBubble
                          time={message?.t}
                          message={message?.m}
                          user={message?.s}
                          classes={message.d ? "send_chat" : "receive_chat"}
                          // isOnline={isOnline}
                          userEmail={userData?.email}
                          userImage={userData?.userImage}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            }
          )}
        </main>
        {/* chatroom main middle part end here */}

        {/* footer */}
        <footer className="chat_footer">
          <div onClick={handleButtonClick}>
            <AddIcon className="chat_add_icon" />
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              let chat_field = document.getElementById("chat_field");
              chat_field.focus();
              setMediaUpload(e.target.files[0]);
              setCurrentMediaType(e.target.files[0].type.split("/")[0]);
              setCurrentMedia(URL.createObjectURL(e.target.files[0]));
            }}
          />
          <div className="chat_text_area flex justify-between items-center">
            <input
              type="text"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="chat_field"
              id="chat_field"
              onKeyDown={sendMessageOnEnter}
            />
            <div className="footer_chat_icons">
              <EmojiEmotionsIcon
                className="emoji_chat_icon"
                onClick={toggleEmojiSection}
              />
              <div onClick={sendMessage}>
                <SendIcon className="send_chat_icon" />
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
};

export default ChatRoom;
