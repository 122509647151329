import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import axios from "axios";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import styles from "./given.module.css";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addClickedRewardId,
  addRewardPopupMode,
  addRewardViewData,
  toggleRewardDialogCreation,
} from "../../../../app/slices/singleStates";
import { RewardHoverCard } from "../../../../components";
import { globalBounti2 } from "../../../../apis/getApis";
import Header from "../../../../apis/header";
import ArrowLeft from "@mui/icons-material/ArrowLeft";

export default function RewardCarousel({ rewardData, bountyId }: any) {
  const [swiper, setSwiper] = useState<any>(null);
  const [isHoverCard, setHoverCard] = useState<any>(false);
  const [termsAndConditons, setTermsAndConditons] = useState<any>({
    Eligibility: "",
    EntryPeriod: "",
    HowToEnter: "",
    Prizes: "",
    SelectionOfWinners: "",
    PrizeRedemption: "",
    RewardSubjectToChange: "",
  });
  const [showTerms, setShowTerms] = useState(false); // State to manage T&C visibility
  const dispatch = useDispatch();

  const handleSwiper = (swiper: any) => {
    setSwiper(swiper);
  };

  // const handleArrowClick = () => {
  //   if (swiper) {
  //     swiper.slideNext();
  //   }
  // };
  const handleArrowClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrevArrowClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleTermsClick = () => {
    console.log("callasjkdghedf");
    setShowTerms(!showTerms); // Toggle T&C visibility on click
  };

  const handleCloseClick = () => {
    setShowTerms(false); // Close T&C section
  };

  const handleTermsHover = () => {
    setShowTerms(true); // Show T&C on hover
  };

  const handleTermsLeave = () => {
    setShowTerms(false); // Hide T&C when hover leaves
  };

  //   get term and condition
  const fetchTerms = async () => {
    await axios
      .get(`${globalBounti2}/${bountyId}`, Header)
      .then((res) => {
        console.log(res, "globalBounti2fgdth");
        if (res.data.text.termsAndConditions) {
          setTermsAndConditons({
            Eligibility: res.data.text.termsAndConditions.Eligibility || "",
            EntryPeriod: res.data.text.termsAndConditions.EntryPeriod || "",
            HowToEnter: res.data.text.termsAndConditions.HowToEnter || "",
            Prizes: res.data.text.termsAndConditions.Prizes || "",
            SelectionOfWinners:
              res.data.text.termsAndConditions.SelectionOfWinners || "",
            PrizeRedemption:
              res.data.text.termsAndConditions.PrizeRedemption || "",
            RewardSubjectToChange:
              res.data.text.termsAndConditions.RewardSubjectToChange || "",
          });
        } else {
          console.log(
            "termsAndConditions property not found in the API response."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // terms and conditions get API
  useEffect(() => {
    fetchTerms();
  }, []);

  return (
    <>
      <div
        className="flex mt-half relative carousel_reward_view_carousel"
        style={{ width: "fit-content" }}
      >
        {/* <ArrowLeft
          onClick={handlePrevArrowClick}
          className={styles.arrowReward}
        /> */}
        <Swiper
          navigation={true}
          slidesPerView={3}
          modules={[Navigation]}
          className={`${styles.reward_carousel}`}
          onSwiper={handleSwiper}
          style={{ cursor: "pointer" }}
          loop
        >
          {rewardData?.map((singleReward: any, ind: any) => {
            console.log("singleWalaReward", singleReward);
            return (
              <SwiperSlide
                onMouseOver={() => {
                  setHoverCard(true);
                  dispatch(addClickedRewardId(singleReward._id));
                }}
                onMouseLeave={() => {
                  setHoverCard(false);
                }}
                className="carousel_reward_slide"
                key={`${Date.now()}-${ind + Math.random()}`}
              >
                <div className={styles.rewardSld}>
                  <img
                    src={`${singleReward?.imagePath}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "0.3em",
                    }}
                    alt=""
                  />
                </div>
                <label className={`${styles.rewardName} rewardName_res`}>
                  {singleReward?.categories}
                </label>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <ArrowRightOutlinedIcon
          onClick={handleArrowClick}
          className={styles.arrowReward}
        />

        <RewardHoverCard
          setHoverCard={setHoverCard}
          isHoverCard={isHoverCard}
          bountyId={bountyId}
        />
      </div>

      {/* T&C section */}
      <div
        className="terms_for_rewards"
        // onMouseOver={handleTermsHover}
        // onMouseLeave={handleTermsLeave}
        onClick={handleTermsClick} // Add click event handler
      >
        <p>T & C for Rewards</p>
      </div>
      {/* <div
        className="flex"
        style={
          showTerms
            ? {
                position: "absolute",
                top: "43%",
                left: "21%",
                width: "50%",
                height: "50%",
                // background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // overflow:"auto",
              }
            : { display: "none" }
        }
      >
        <div className="reward__hover__card__bottom">
          <div className="terms_close" onClick={handleCloseClick}>
            <p> X</p>
          </div>
          <div className="reward__hover__card__terms_and_condition">
            <h1 className="reward__hover__card__terms_main_heading">Terms</h1>

            {Object.entries(termsAndConditons).map(([key, value]: any) => (
              <>
                {console.log(termsAndConditons, "termsAndConditons2")}
                <div key={key} style={{ marginTop: "1rem" }}>
                  <h2>{key}:</h2>
                  <p>{value}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div> */}
      <div
        className="flex"
        style={
          showTerms
            ? {
                position: "absolute",
                top: "3%",
                left: "16%",
                width: "69%",
                height: "93%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        <div className="reward_edit__card__bottom">
          <div className="terms_edit_close" onClick={handleCloseClick}>
            <p> X</p>
          </div>
          <div className="reward__hover__card__terms_and_condition_edit">
            <h1
              className="reward__hover__card__terms_main_heading_edit"
              style={{ color: "var(--dark_2)" }}
            >
              Terms
            </h1>
            {Object.entries(termsAndConditons).map(([key, value]: any) => (
              <>
                {console.log(termsAndConditons, "termsAndConditons2")}
                <div key={key} style={{ marginTop: "1rem" }}>
                  <p className="terms__labels">{key}:</p>
                  <p className="termsTextAreas">{value}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      {/* {showTerms && (
          <>
          
          </>
        )} */}

      {/* Display T&C text if showTerms is true */}
    </>
  );
}
