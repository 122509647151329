import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

// Avtar Groups shows at bounty card & bounty view page to show intercated user icon with green online Icon
export default function AvtarGroup({ bountyId, globalInteractionData }: any) {
  const lastTwoUserImages = globalInteractionData
    .slice(-2) // Get the last two elements
    .map((data: any) => data.userImage); // Extract the userImage
  console.log(lastTwoUserImages, "lastTwoUserImages");

  const lastTwoUsername = globalInteractionData
    .slice(-2) // Get the last two elements
    .map((data: any) => data.userName); // Extract the userImage
  console.log(lastTwoUsername, "lastTwoUsername");

  const othersCount = Math.max(0, globalInteractionData.length - 2);
  return (
    <>
      {!globalInteractionData || globalInteractionData.length === 0 ? (
        <p>Take a lead !! Be the first to Interact with this Bounty.</p>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            {lastTwoUserImages[1] && (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="Remy Sharp" src={lastTwoUserImages[1]} />
              </StyledBadge>
            )}

            {lastTwoUserImages[0] && (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="Remy Sharp" src={lastTwoUserImages[0]} />
              </StyledBadge>
            )}

            {/* <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </StyledBadge> */}
          </Stack>
          <p className="home__bounty__header_avtars_text">
            {lastTwoUsername[1] && `${lastTwoUsername[1]}, `}
            {lastTwoUsername[0] && `${lastTwoUsername[0]} `}
            {lastTwoUsername.length > 0 && "and "}
            <b className="home_bounty_avt_tx_high">
              {othersCount} {othersCount > 1 ? "others have" : "other has"}{" "}
              engaged
            </b>
          </p>
        </>
      )}
    </>
  );
}
