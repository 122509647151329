import { FileManager, FluidContainer, PhasesDialog } from "../../../components";
import styles from "../BountyPreview/bountipreview.module.css";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { CheckList, MainEditArea } from "./related-components";
import {
  addCurrentRouteStage,
  toggleBreadCrumb,
  toggleMediaUploder,
  toggleRewardDialogCreation,
} from "../../../app/slices/singleStates";
import { StoredAssetsTab } from "../../../components/FileManager";
import Reward from "../../../components/Reward/Reward";
import OnlyCreateReward from "../../../components/Reward/OnlyCreateReward";
import { useLocation } from "react-router-dom";
import AnnouncementPop from "../../../components/AnnoucementPop/AnnoucementPop";
import axios from "axios";
import { createAnnouncement } from "../../../apis/postApis";
import Header from "../../../apis/header";
import AnnouncementCard from "./related-components/AnnoucementCard";
import { D, U } from "../../../apis/main";
import { Alert } from "antd";
import { getSubmissionBounty } from "../../../apis/getApis";
import SubmissionCardBounty from "./related-components/SubmissionCardBounty";
const BountyCreation = () => {
  const location = useLocation();
  let bountyId = location.state && location.state.selectedId;
  console.log(bountyId, "Id_Current");
  const { announcement } = useSelector((state: any) => state.booleanStatus);
  const [isToggleChat, setIsToggleChat] = useState<any>(false);
  const [allSubmission, setAllSubmission] = useState<any>([]);
  const [headerType, setHeaderType] = useState<number>(1);
  const ToggleChat = () => {
    isToggleChat ? setIsToggleChat(false) : setIsToggleChat(true);
  };
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  const isMediaDialog = useSelector(
    (state: RootState) => state.booleanStatus.isMediaDialog
  );
  const isUploadRewardOnlyCreate = useSelector(
    (state: RootState) => state.booleanStatus.isUploadRewardOnlyCreate
  );
  // const isUploadRewardOnlyCreate = true;

  const isPhasesDialog = useSelector(
    (state: RootState) => state.booleanStatus.isPhasesDialog
  );
  const isRewardDialog: boolean = useSelector(
    (state: RootState) => state.booleanStatus.isRewardDialog
  );
  //  const isRewardDialog=false;
  const [announcementPop, setAnnouncementPop] = useState(false);
  const [currentSelectAnnouncementId, setCurrentSelectAnnouncementId] =
    useState<any>("");
  const [innerAnnounceId, setInnerAnnounceId] = useState<any>("");
  useState<any>("");
  const [allAnnouncementList, setAnnouncementList] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const dispatch = useDispatch();
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });
  useEffect(() => {
    dispatch(addCurrentRouteStage(1));
    dispatch(toggleBreadCrumb("icon"));
    dispatch(toggleRewardDialogCreation(false));
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(`${getSubmissionBounty}/${bountyId}`)
      .then((response: any) => {
        console.log("submissionGlobalData", bountyId, response);
        setAllSubmission(response.data.submissions);
        console.log(allSubmission, "allSubmission");
      })
      .catch((err: any) => {
        // setIsLoadingSubmission(false);
      });
  }, []);

  // fetch list of announcement
  const fetchAnnouncementData = async () => {
    await axios
      .get(`${D}/get/announcemetList/${U}/${bountyId}`)
      .then((response: any) => {
        console.log("fetchAnnoucement", response);
        setAnnouncementList(response.data);
      })
      .catch((error: any) => {});
  };
  useEffect(() => {
    fetchAnnouncementData();
  }, []);
  // }, [allAnnouncementList]);

  // add announcement functionality
  const openAnnouncementPopup = async () => {
    setAnnouncementPop(true);
  };

  return (
    <>
      <FluidContainer breadcrumb={["./assets/unlock.svg"]}>
        <div className="flex justify-between bounti-preview-res bp-container">
          {/* 66% */}
          <div
            className={
              toggleD
                ? `${styles.div_67} div_67_respon bounticreation__67 bounticreation__67__nocollapse`
                : `${styles.div_67} div_67_respon div_67_respon_collapse bounticreation__67 bounticreation__67__collapse`
            }
          >
            <div
              style={
                isLock
                  ? {
                      color: "red",
                      marginBottom: "0.5rem",
                    }
                  : { display: "none" }
              }
            >
              <h3>
                This bounty already has a submission and therefore cannot be
                edited any longer.
              </h3>
            </div>
            <MainEditArea selectedId={bountyId} />

            {/* announcement section goes here */}
            <section className="announcementTab__creation">
              {/* announcement header goes here */}
              <div className="flex items-center">
                <button
                  onClick={() => {
                    setHeaderType(0);
                  }}
                  className={
                    headerType === 0
                      ? `${styles.header_btns} ${styles.header_btn1} ${styles.header_active}`
                      : `${styles.header_btns} ${styles.header_btn1}`
                  }
                >
                  Submission Feed
                </button>
                <button
                  onClick={() => {
                    setHeaderType(1);
                  }}
                  className={
                    headerType === 1
                      ? `${styles.header_btns} ${styles.header_btn2} ${styles.header_active}`
                      : `${styles.header_btns} ${styles.header_btn2}`
                  }
                >
                  Announcements
                </button>
                <div className="annoucPlusBtn" onClick={openAnnouncementPopup}>
                  +
                </div>
                <Alert
                  type={isError ? "error" : "success"}
                  message={message}
                  showIcon
                  style={
                    isAlert
                      ? {
                          display: "block",
                          marginLeft: "1rem",
                          marginTop: "0.5rem",
                        }
                      : { display: "none" }
                  }
                />
                {/* announcement create alert */}
                {isAlertSuccess && (
                  <div className="announcement_success">
                    <Alert
                      message="Announcement Saved Successfully..!"
                      type="success"
                      showIcon
                      
                      onClose={() => setIsAlertSuccess(false)}
                    />
                  </div>
                )}
                {isAlertError && (
                  <div className="announcement_success">
                    <Alert
                      message="Announcement Not Saved Successfully..!"
                      type="error"
                      showIcon
                      // closable
                      onClose={() => setIsAlertError(false)}
                    />
                  </div>
                )}
              </div>
              {/* announcement header end here */}

              {/* announcement body goes here */}
              <div className="annoucementBody">
                {headerType === 1
                  ? // If headerType is 1, render AnnoucementCard components
                    allAnnouncementList.map(
                      (announcement: any, index: number) => (
                        <AnnouncementCard
                          key={index}
                          data={announcement}
                          setMessage={setMessage}
                          setIsError={setIsError}
                          setIsAlert={setIsAlert}
                          bountyId={bountyId}
                          setAnnouncementList={allAnnouncementList}
                          setToggle={setAnnouncementPop}
                          setCurrentSelectAnnouncementId={
                            setCurrentSelectAnnouncementId
                          }
                          profileImage={announcement.profileImage}
                        />
                      )
                    )
                  : // If headerType is not 1, render SubmissionCardBounty components
                    allSubmission?.map((submission: any, index: number) => (
                      <SubmissionCardBounty
                        key={index}
                        submission={submission}
                        toggleD={toggleD}
                        styles={styles}
                        isViewPhase={true}
                      />
                    ))}
              </div>

              {/* announcement body end here */}
            </section>
            {/* announcement section end here */}
          </div>

          {/* 30% */}
          <div
            className={
              isToggleChat
                ? `${styles.div_30}  div_30_responsive-withFloat checklistWarraper checklistWarraper_noColl`
                : `${styles.div_30} div_30_responsive checklistWarraper checklistWarraper_Coll`
            }
            id={
              !toggleD ? "checklistWarraper_noColl" : "checklistWarraper_Coll"
            }
          >
            <CheckList selectedId={bountyId} />
          </div>
        </div>

        {/* mediaUploader Dialog Box goes here */}
        <div
          className="flex minga__popup"
          style={
            isMediaDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <div className="close_dialog_wrapper" style={{ width: "80%" }}>
            <button
              className="close_dialog"
              onClick={() => {
                dispatch(toggleMediaUploder(false));
              }}
            >
              X
            </button>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <FileManager selectedId={bountyId} />
            <StoredAssetsTab selectedId={bountyId} />
          </div>
        </div>
        {/* mediaUploader Dialog Box end here */}

        {/* phases Dialog Box goes here */}
        <div
          className="flex minga__popup"
          style={
            isPhasesDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <PhasesDialog selectedId={bountyId} />
        </div>
        {/* phases Dialog Box end here */}

        {/* reward dialog box goes here */}
        <div
          className="flex minga__popup"
          style={
            isRewardDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  // height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <Reward selectedId={bountyId} />
        </div>
        {/* reward dialog box end here */}

        {/* reward onlyCreate dialog box goes here */}
        <div
          className="flex minga__popup"
          style={
            isUploadRewardOnlyCreate
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  // height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <OnlyCreateReward bountyId={bountyId} />
        </div>
        {/* reward dialog box end here */}

        {/* Annoucement popup goes here */}
        <div
          className="flex minga__popup"
          style={
            announcementPop
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          {isCreateMode || !announcement ? (
            <AnnouncementPop
              setToggle={setAnnouncementPop}
              isToggle={announcementPop}
              setIsAlertSuccess={setIsAlertSuccess}
              setIsAlertError={setIsAlertError}
              currentSelectAnnouncementId={currentSelectAnnouncementId}
              innerAnnounceId={innerAnnounceId}
              bountyId={bountyId}
              setAnnouncementList={setAnnouncementList}
              mode="create"
            />
          ) : (
            <AnnouncementPop
              setToggle={setAnnouncementPop}
              isToggle={announcementPop}
              setIsAlertSuccess={setIsAlertSuccess}
              setIsAlertError={setIsAlertError}
              currentSelectAnnouncementId={currentSelectAnnouncementId}
              innerAnnounceId={innerAnnounceId}
              bountyId={bountyId}
              setAnnouncementList={setAnnouncementList}
              mode="edit"
            />
          )}
          
        </div>
        {/* Annoucement popup end here */}
      </FluidContainer>
      {isToggleChat ? (
        <CloseIcon onClick={ToggleChat} className="chatroom_float_button" />
      ) : (
        <ChatIcon onClick={ToggleChat} className="chatroom_float_button" />
      )}
    </>
  );
};

export default BountyCreation;
