import { D, U } from "./main";

const globalBounti2: string = `${D}/content`;
const rewardData2: string = `${D}/count/getData/reward`;
const bountiCategory2: string = `${D}/get/category_data`;
const allAsset2: string = `${D}/image`;
const totalBountiLikes: string = `${D}/like/count`;
const totalBountiMarks: string = `${D}/favorite/count`;
const bountiSponsor: string = `${D}/bounti_sponsor`;
const bountiCharity: string = `${D}/bounti_charity`;
const isCurrentFav: string = `${D}/favorite/${U}`;
const isCurrentLike: any = `${D}/like/${U}`;
const getAllCategoryList: any = `${D}/get/bountiType`;
const getAllSubCategoryList: any = `${D}/get/subCategories`;
const getSelectedCategoriesList: any = `${D}/get/category_data`;
const allMimeTypeListUrl: any = `${D}/get/mimeType`;
const getBrandName: any = `${D}/get/brandDetail`;
const getAdditionalData: any = `${D}/get/additionalDetail`;
const fetchTodoStatus: any = `${D}/get/checklist`;
const fetchMediaData: any = `${D}/image`;
const getAllSponserList = `${D}/sponsor`;
const getAllCharityList = `${D}/charity`;
const getSelectedSponser = `${D}/bounti_sponsor`;
const getSelectedCharity = `${D}/bounti_charity`;
const userRelatedAllMedia = `${D}/image_alltime/${U}`;
const getHashTagsList = `${D}/get/tagsDetail`;
const getPhasesDates = `${D}/get/bounti-phase`;
const fetchRewardCategory = `${D}/get/bountiType`;
const getRewards = `${D}/count/getData/reward`;
const viewReward = `${D}/reward`;
const getAllListOfBountyLikes = `${D}/like`;
const getBounties = `${D}/bounti/${U}`;
const getAllGlobalBounties = `${D}/get/allBountiData`;
const unlocksAdvertisement = `${D}/get/bountyUnlocks`;
let Register_Link_Verify: string = `${D}/verify`;
const getAllMediaComments = `${D}/media_aggregations`;
let Forgot_Link_Verify: string = `${D}/forgot/verify`;
let Email_Change__Verify: string = `${D}/email/verify`;
const getUserProfile = `${D}/get/userProfileData`;
const profileSubCategory = `${D}/get/SubValues`;
const getAnnouncementDescription = `${D}/get/announcement/description`;
const profileWithUID = `${D}/get/userProfile`;
const getGlobalList = `${D}/get/globalData`;
const getSponsorPins = `${D}/get/pinsponsor/${U}`;
const getCharityPins = `${D}/get/pincharity/${U}`;
const getAdvertisePins = `${D}/get/pinAdvertisement/${U}`;
const getBountyPins = `${D}/get/pinbounti/${U}`;
const getLoginStats = `${D}/get/mingaState`;
// const fetchNotification = `${D}/getNotifications/${U}?type=addFriend`;
const fetchNotification = `${D}/getNotifications/${U}`;
const fetchProfileStats = `${D}/get/userProfileBox`;
const getAdvertisements = `${D}/get/adminAdvertisement/${U}`;
const getAnnouncementBounty = `${D}/get/announcemetList`;
const getSubmissionBounty = `${D}/get/submissions`;
const getUserInteractionGlob = `${D}/get/userInteractions`;
const getSubmissionComment = `${D}/submission/comments`;
const getReplySubmissionComment = `${D}/submission/reply`;
const getSubmissionCommentReply = `${D}/agreegate_submissionReply`;
const getSubmissionlikes = `${D}/get/submission/likes`;
const getSubmissonCounts = `${D}/submission_count`;
const fetchNotificationWinner = `${D}/getNotifications/${U}?type=winner`;
const likeNotification = `${D}/getNotifications/${U}?type=like`;
const favouriteNotification = `${D}/getNotifications/${U}?type=favorite`;
const submissionNotification = `${D}/getNotifications/${U}?type=submission`;
const getCategoryList = `${D}/get/subCategories`
const getSubmissionDataUser = `${D}/get/submissionData/${U}`
const getCategoryNew = `${D}/user/categories`
const getUserActivity = `${D}/get/userActivity/${U}`
const getSubNew = `${D}/get/user/subCategories`
const getFriendInteraction = `${D}/get/frinedInteraction?loggedInUserId=${U}`
const getXpPoints = `${D}/get/xpPoint/${U}`
const getannounceMedia = `${D}/get/announcement/media`
const getDefaultTerms = `${D}/get/termsandcondition`
const getMesiboToken = `${D}/get/mesiboAccssToken`
const getUsers = `${D}/users`
const getWatchLater = `${D}/get/adminWatchLater`
export {
  allAsset2,
  fetchNotification,
  fetchProfileStats,
  getBountyPins,
  getCharityPins,
  getLoginStats,
  profileWithUID,
  profileSubCategory,
  getGlobalList,
  getAdvertisePins,
  viewReward,
  getUserProfile,
  rewardData2,
  getPhasesDates,
  getRewards,
  getSelectedCharity,
  Forgot_Link_Verify,
  fetchRewardCategory,
  getAllCharityList,
  getBrandName,
  getHashTagsList,
  userRelatedAllMedia,
  getSelectedSponser,
  getAllSponserList,
  fetchMediaData,
  fetchTodoStatus,
  getAdditionalData,
  getSelectedCategoriesList,
  globalBounti2,
  bountiCategory2,
  totalBountiLikes,
  totalBountiMarks,
  bountiSponsor,
  bountiCharity,
  isCurrentFav,
  isCurrentLike,
  getAllCategoryList,
  getAllSubCategoryList,
  allMimeTypeListUrl,
  getAllListOfBountyLikes,
  getBounties,
  getAllGlobalBounties,
  unlocksAdvertisement,
  Register_Link_Verify,
  getAllMediaComments,
  getAnnouncementDescription,
  getSponsorPins,
  getAdvertisements,
  getAnnouncementBounty,
  getSubmissionBounty,
  getUserInteractionGlob,
  getSubmissionComment,
  getReplySubmissionComment,
  getSubmissionCommentReply,
  getSubmissionlikes,
  getSubmissonCounts,
  fetchNotificationWinner,
  getCategoryList,
  getSubmissionDataUser,
  getCategoryNew,
  getUserActivity,
  getSubNew,
  getFriendInteraction,
  getXpPoints,
  getannounceMedia,
  getDefaultTerms,
  Email_Change__Verify,
  getMesiboToken,
  getUsers,
  getWatchLater,
  likeNotification,
  favouriteNotification,
  submissionNotification,
};
