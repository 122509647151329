import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import styles from "./given.module.css";
import { useState } from "react";

export default function CharityCarousel({ boutiCharityData, onHover }: any) {
  const [swiper, setSwiper] = useState<any>(null);
  const [charityHoveredIndex, setCharityHoveredIndex] = useState<number | null>(null);

  const handleSlideHover = (index: number) => {
    setCharityHoveredIndex(index);
    onHover(index); // Pass the hovered index to the parent component
  };

  const handleSwiper = (swiper: any) => {
    setSwiper(swiper);
  };

  return (
    <div className="flex">
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className={`${styles.reward_carousel}`}
        onSwiper={handleSwiper}
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {boutiCharityData?.map((singleCharity: any, index: number) => {
          return (
            <SwiperSlide
              // key={`${(Math.random() * Date.now() ** ind) / 3 + 1 * 0.5}`}
              key={index} onMouseEnter={() => handleSlideHover(index)}
            >
              <div style={{ width: "100%", height: "100%" }}>
                <img
                  src={singleCharity?.image}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt=""
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
