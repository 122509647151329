import React from "react";
import "./privacyPolicy.css";

const PrivacyPolicyContent = () => {
  return (
    <div className="container">
      <div id="inner-container">
          <img id="centered-image"
            src={"../tests/Minga Logo.svg"}
            alt=""
          />
        <div className="title common-styles">
          <h1>Minga Privacy Policy</h1>
        </div>
        <div className="date common-styles">
          <p>Effective Date: 3/4/24</p>
        </div>
        <div className="privacy-text common-styles">
          <p>
            Welcome to Minga! Your privacy is of paramount importance to us.
            This Privacy Policy outlines the types of information Minga collects
            from its users, how that information is used, and how we protect
            your data. By using Minga's platform, you consent to the data
            practices described in this policy.
          </p>
        </div>
        <div className="title--2 common-styles">
          <h1>Information Collection</h1>
        </div>
        <div className="service--text common-styles">
          <p>
            Minga collects information to provide better services to all our
            users. We collect information in the following ways:
          </p>
        </div>
        <div className="info--text--one common-styles">
          <p>
            1. Information you give us. For example, Minga requires users to
            sign up for an account. When you do, we’ll ask for personal
            information like your name, email address, and telephone number to
            store with your account.
          </p>
        </div>
        <div className="info--text--two common-styles">
          <p>
            2. Information we get from your use of our services. We may collect
            information about the services that you use and how you use them.
            This information includes:
          </p>
        </div>
        <div className="info--text--three common-styles">
          <p>3. Device information</p>
        </div>
        <div className="info--text--four common-styles">
          <p>4. Log information</p>
        </div>
        <div className="info--text--five common-styles">
          <p>5. Location information</p>
        </div>
        <div className="info--text--six common-styles">
          <p>6. Local storage</p>
        </div>
        <div className="info--text--seven common-styles">
          <p>7. Cookies and similar technologies</p>
        </div>
        <div className="title--3 common-styles">
          <h1>Use of Information</h1>
        </div>
        <div className="minga--text common-styles">
          <p>
            Minga uses the information we collect from all our services to
            provide, maintain, protect, and improve them, to develop new ones,
            and to protect Minga and our users. We also use this information to
            offer you tailored content – like giving you more relevant search
            results and ads.
          </p>
        </div>

        <div className="title--4 common-styles">
          <h1>Information Sharing</h1>
        </div>
        <div className="service--text--2 common-styles">
          <p>
            Minga does not share personal information with companies,
            organizations, and individuals outside of Minga unless one of the
            following circumstances applies:
          </p>
        </div>
        <div className="service--text--one common-styles">
          <p>1. Log information</p>
        </div>
        <div className="service--text--two common-styles">
          <p>2. Location information</p>
        </div>
        <div className="service--text--three common-styles">
          <p>3. Local storage</p>
        </div>

        <div className="title--5 common-styles">
          <h1>Your Privacy Controls</h1>
        </div>
        <div className="privacy--text--2 common-styles">
          <p>
            You have choices regarding the information we collect and how it's
            used. This includes:
          </p>
        </div>
        <div className="privacy--text--one common-styles">
          <p>1. Accessing and updating your personal information</p>
        </div>
        <div className="privacy--text--two common-styles">
          <p>2.Controlling the information shared with us</p>
        </div>
        <div className="privacy--text--three common-styles">
          <p>3. Managing your information and privacy settings</p>
        </div>

        <div className="title--6 common-styles">
          <h1>Data Security</h1>
        </div>
        <div className="minga--text--2 common-styles">
          <p>
            Minga works hard to protect our users from unauthorized access to or
            unauthorized alteration, disclosure, or destruction of information
            we hold. In particular:
          </p>
        </div>
        <div className="minga--text--one common-styles">
          <p>1. We encrypt many of our services using SSL.</p>
        </div>
        <div className="minga--text--two common-styles">
          <p>
            2.We review our information collection, storage, and processing
            practices, including physical security measures, to guard against
            unauthorized access to systems.
          </p>
        </div>
        <div className="minga--text--three common-styles">
          <p>
            3. We restrict access to personal information to Minga employees,
            contractors, and agents who need to know that information to process
            it for us, and who are subject to strict contractual confidentiality
            obligations.
          </p>
        </div>

        <div className="title--7 common-styles">
          <h1>Changes</h1>
        </div>
        <div className="change--text common-styles">
          <p>
            Our Privacy Policy may change from time to time. We will post any
            privacy policy changes on this page and, if the changes are
            significant, we will provide a more prominent notice.
          </p>
        </div>

        <div className="title--8 common-styles">
          <h1>Contact Us</h1>
        </div>
        <div className="contact--text common-styles">
          <p>
            If you have any questions about this Privacy Policy, please contact
            us
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
