import { useDispatch, useSelector } from "react-redux";
import Todo from "./Todo";
import "./checklist.css";
import { RootState } from "../../../../../../app/store";
import { useEffect, useState } from "react";
import axios from "axios";
import { fetchTodoStatus } from "../../../../../../apis/getApis";
import { setText } from "../../../../../../app/slices/CheckListStatus";
import {
  addIsCharityUploader,
  addIsSponserUploader,
  lockBountyMethod,
} from "../../../../../../app/slices/singleStates";

const CheckList = ({ selectedId }: { selectedId: any }) => {
  const checkListData = useSelector(
    (state: RootState) => state.CheckListStatus
  );
  const [pp, setP] = useState<any>("0%");
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${fetchTodoStatus}/${selectedId}`)
      .then((res: any) => {
        console.log("RESPONSE", res.data);
        dispatch(setText([res.data.checkList.AdditionalDetail, 16]));
        dispatch(setText([res.data.checkList.BountiImages, 3]));
        dispatch(setText([res.data.checkList.BrandName, 10]));
        dispatch(setText([res.data.checkList.BrandLogo, 11]));
        dispatch(setText([res.data.checkList.Categories, 6]));
        dispatch(setText([res.data.checkList.Charity, 13]));
        dispatch(setText([res.data.checkList.Description, 2]));
        dispatch(setText([res.data.checkList.Formats, 15]));
        dispatch(setText([res.data.checkList.LockBounti, 17]));
        dispatch(setText([res.data.checkList.Reward, 4]));
        dispatch(setText([res.data.checkList.Sponser, 12]));
        dispatch(setText([res.data.checkList.SubCategories, 7]));
        dispatch(setText([res.data.checkList.Submissions, 9]));
        dispatch(setText([res.data.checkList.Tags, 14]));
        dispatch(setText([res.data.checkList.TimeLimit, 5]));
        dispatch(setText([res.data.checkList.Title, 1]));
        dispatch(setText([res.data.checkList.Unlocks, 8]));
        // dispatch(lockBountyMethod(true));
        dispatch(lockBountyMethod(res.data.checkList.LockBounti));
      })
      .catch((err: any) => {
        console.log("CheckData", err);
      });
  }, []);

  useEffect(() => {
    let p = 0;
    checkListData.forEach((element: any, ind: any) => {
      if (element.isChecked) {
        if (ind <= 8) {
          p += 11.11;
        }
      }
    });
    setP(JSON.stringify(p));
    console.log(pp,"checklist state")
  }, [checkListData]);

  return (
    <div
      className="checkList"
      onClick={() => {
        dispatch(addIsCharityUploader(false));
        dispatch(addIsSponserUploader(false));
      }}
    >
      <header className="checklistHeader">CheckList</header>
      <main className="checklistMain">
        {checkListData.map((todoData: any) => {
          console.log(checkListData, "todoData");
          return (
            <Todo
              label={todoData?.text}
              isChecked={todoData?.isChecked}
              color={todoData?.color}
              id={todoData?.number}
              isRequired={todoData?.isRequired}
            />
          );
        })}
      </main>
      <footer className="checklistFooter">
        <div
          className="chklist__progress"
          style={{
            width: `${pp}%`,
            background: "var(--greenType)",
            height: "1rem",
          }}
        ></div>
        <div className="flex progressDetail__checklist">
          Bounty{" "}
          <p style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
            {pp == "99.99" ? "100%" : `${(+pp).toFixed(2)}%`}
          </p>{" "}
          complete
        </div>
      </footer>
    </div>
  );
};

export default CheckList;
