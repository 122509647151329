import { useDispatch, useSelector } from "react-redux";
import { deleteRewards } from "../../../../../../Functions/MediaUploaderF";
import {
  addRewardIdForDelete,
  addRewardMainIdForDelete,
  addRewardPopupMode,
  addRewardViewData,
  addSetForRewardDelete,
  toggleRewardDeleteDialog,
  toggleRewardDialogCreation,
} from "../../../../../../app/slices/singleStates";
import { useEffect } from "react";
import { RootState } from "../../../../../../app/store";

const RewardEditCard = ({
  status = "RWD_READER",
  data,
  setF,
  currentSwiperInd,
}: any) => {
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });
  useEffect(() => {
    const rewardCarouselImage = document.getElementById("rewardCarouselImage");
    if (rewardCarouselImage) {
      // rewardCarouselImage.click();
    }
  }, []);
  const dispatch = useDispatch();
  const rewardMainId: any = localStorage.getItem("reward-create");
  if (status === "RWD_EDITOR") {
    return (
      <div>
        <div
          className="rec__image flex items-center"
          style={{ marginRight: "1rem" }}
        >
          <div
            className="flex justify-center items-center"
            style={{
              width: "3rem",
              height: "3rem",
              objectFit: "cover",
              borderRadius: "0.5rem",
              background: "var(--sidebar_secondory)",
            }}
          >
            <img src="../assets/upload.svg" alt="" />
          </div>
          <textarea
            placeholder={"Type custom reward Two lines max"}
            style={{
              height: "3rem",
              width: "11rem",
              border: "1px solid var(--orange)",
              outline: "none",
              borderRadius: "0.3rem",
              marginLeft: "0.5rem",
              paddingLeft: "0.5rem",
              paddingTop: "0.2rem",
              lineHeight: "1.4rem",
            }}
          ></textarea>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className="rec__image flex items-center"
          style={{ marginRight: "1rem" }}
        >
          <button
            onClick={() => {         
              dispatch(addRewardViewData(currentSwiperInd));
              dispatch(toggleRewardDialogCreation(true));
              dispatch(addRewardPopupMode("View"));
            }}

            style={{
              width: "3rem",
              height: "3rem",
              objectFit: "cover",
              borderRadius: "0.5rem",
              cursor:"pointer"
            }}
          >
            <img
              src={data?.imagePath}
              style={{
                width: "3rem",
                height: "3rem",
                objectFit: "cover",
                borderRadius: "0.5rem",
                background: "var(--sidebar_secondory)",
              }}
              id="rewardCarouselImage"
            />
          </button>

          <p
            style={{
              height: "3rem",
              border: "none",
              outline: "none",
              borderRadius: "0.3rem",
              marginLeft: "0.5rem",
              paddingLeft: "0.5rem",
              paddingTop: "0.2rem",
              lineHeight: "1.4rem",
              width: "11rem",
            }}
            onClick={() => {
              dispatch(addRewardViewData(currentSwiperInd));
              dispatch(toggleRewardDialogCreation(true));
              dispatch(addRewardPopupMode("View"));
            }}
          >
            {data?.title}
          </p>
          <button
            style={{
              border: "none",
              marginLeft: "0.5rem",
              outline: "none",
              background: "var(--orange)",
              color: "var(--pure)",
              fontWeight: "bold",
              borderRadius: "0.5rem",
              padding: "0.3rem",
              fontSize: "1.2rem",
              width: "2rem",
              height: "2rem",
              cursor: "pointer",
            }}
            disabled={isLock}
            onClick={() => {
              dispatch(toggleRewardDeleteDialog(true));
              dispatch(addRewardMainIdForDelete(rewardMainId));
              dispatch(addRewardIdForDelete(data._id));
              dispatch(addSetForRewardDelete(setF));
            }}
            // onClick={()=>{deleteRewards(data._id,rewardMainId,setF,dispatch)}}
          >
            -
          </button>
        </div>
      </div>
    );
  }
};

export default RewardEditCard;
