import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import "./phases.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { CircularProgress, Slider } from "@mui/material";
import { Alert } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ClockIcon, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { togglePhasesDialog } from "../../app/slices/singleStates";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { getPhasesDates } from "../../apis/getApis";
import { Date_Time_Picker } from "../../apis/postApis";
import { RootState } from "../../app/store";
import { setText } from "../../app/slices/CheckListStatus";

const useStyles: any = makeStyles({
  timePicker: {
    "& .MuiIconButton-root, & .MuiInputBase-root, & .MuiTypography-root": {
      color: "var(--pure)",
      background: "var(--sidebar_secondory)",
      height: "2.3rem",
    },
  },
});
interface CustomTheme {
  components?: {
    MuiTimePickerToolbar?: {
      styleOverrides?: {
        icon?: {
          color?: string;
        };
      };
    };
  };
}

const baseTheme = createTheme();

const customTheme: CustomTheme = {
  ...baseTheme,
  components: {
    MuiTimePickerToolbar: {
      styleOverrides: {
        icon: {
          color: "red !important", // Replace with your desired icon color
        },
      },
    },
  },
};

const PhasesDialog = ({ selectedId }: { selectedId: any }) => {
  const [toggleCalender, setToggleCalender] = useState<any>(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Month is zero-based, so we add 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
  const [startdate, setStartdate] = useState<any>(formattedDate);
  // const [isAlert, setIsAlert] = useState<any>({
  //   is: false,
  //   type: "",
  //   message: "",
  // });
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);
  const [startdateReal, setStartdateReal] = useState<any>(1);
  const [pendingDay, setPendingDay] = useState<any>(1);
  const [pendingDate, setPendingDate] = useState<any>("enddate");
  const [submissionDay, setSubmissionDay] = useState<any>(1);
  const [submissionDate, setSubmissionDate] = useState<any>("enddate");
  const [communityDay, setCommunityDay] = useState<any>(1);
  const [communityDate, setCommunityDate] = useState<any>("enddate");
  const [reviewDay, setReviewDay] = useState<any>(1);
  const [reviewDate, setReviewDate] = useState<any>("enddate");
  const [winnerDay, setWinnerDay] = useState<any>(1);
  const [winnerDate, setWinnerDate] = useState<any>("enddate");
  const [isDynamic, setIsDynamic] = useState<any>(false);
  const [fromCalender, setFromCalender] = useState<boolean>(false);
  const [submissionStartDate, setSubmissionStartDate] =
    useState<any>("startdate");
  const [communityStartDate, setCommunityStartDate] =
    useState<any>("startdate");
  const [reviewStartDate, setReviewStartDate] = useState<any>("startdate");
  const [winnerStartDate, setWinnerStartDate] = useState<any>("startdate");
  const [isLoading, setIsLoading] = useState<any>(false);

  const [pendingTime, setPendingTime] = useState<any>("11:59 AM");
  const [submissionTime, setSubmissionTime] = useState<any>("11:59 AM");
  const [communityTime, setCommunityTime] = useState<any>("11:59 AM");
  const [reviewTime, setReviewTime] = useState<any>("11:59 AM");
  const [winnerTime, setWinnerTime] = useState<any>("11:59 AM");
  const [isGet, setIsGet] = useState<any>(false);
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock;
  });

  useEffect(() => {
    axios
      .get(`${getPhasesDates}/${selectedId}`)
      .then((res: any) => {
        console.log("Callhogya");
        //
        if (res.data.data.length > 0) {
          setIsDynamic(true);
          setIsGet(true);
          setStartdate(res.data.data[0].startDate);
          // setPendingDate(res.data.data[0].timeline.pending.startDate);
          console.log("Callhogya", res);
          setStartdateReal(new Date(res.data.data[0].startDate));
          setPendingDate(res.data.data[0].timeline[0].pending.endDate);
          setPendingTime(res.data.data[0].timeline[0].pending.endTime);
          setSubmissionTime(res.data.data[0].timeline[0].submission.endTime);
          setCommunityTime(res.data.data[0].timeline[0].community.endTime);
          setReviewTime(res.data.data[0].timeline[0].review.endTime);
          setWinnerTime(res.data.data[0].timeline[0].winner.endTime);
          setPendingDay(res.data.data[0].timeline[0].pending.difference);
          setSubmissionDay(res.data.data[0].timeline[0].submission.difference);
          setCommunityDay(res.data.data[0].timeline[0].community.difference);
          setReviewDay(res.data.data[0].timeline[0].review.difference);
          setWinnerDay(res.data.data[0].timeline[0].winner.difference);
          setSubmissionDate(res.data.data[0].timeline[0].submission.endDate);
          setCommunityDate(res.data.data[0].timeline[0].community.endDate);
          setReviewDate(res.data.data[0].timeline[0].review.endDate);
          setWinnerDate(res.data.data[0].timeline[0].winner.endDate);
          setSubmissionStartDate(
            res.data.data[0].timeline[0].submission.startDate
          );
          setCommunityStartDate(
            res.data.data[0].timeline[0].community.startDate
          );
          setReviewStartDate(res.data.data[0].timeline[0].review.startDate);
          setWinnerStartDate(res.data.data[0].timeline[0].winner.startDate);
        } else {
          // If no start date is retrieved, set to today's date
          setStartdate(new Date().toISOString());
        }
      })
      .catch((err: any) => {});
  }, []);

  console.log("pending...Date", pendingTime, startdate);

  // convert to US Formate Date goes here
  function convertToUSDateFormat(inputDateString: string): string {
    const inputDate = new Date(inputDateString);
    if (isNaN(inputDate.getTime())) {
      return "Invalid Date";
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const usDateFormat = inputDate.toLocaleDateString("en-US", options);
    console.log("USAFORMATE", usDateFormat);
    return usDateFormat;
  }

  // add day to us formate date
  function addDaysToUSDate(usDate: string, daysToAdd: number): string {
    console.log(usDate, "addDaysToUSDate");
    console.log(daysToAdd, "addDaysToUSDate");
    const inputDate = new Date(usDate);
    if (isNaN(inputDate.getTime())) {
      return "Invalid Date";
    }
    inputDate.setDate(inputDate.getDate() + daysToAdd);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const newUSDate = inputDate.toLocaleDateString("en-US", options);
    return newUSDate;
  }

  // startDate Change
  const startDateChangeHandle = (value: any) => {
    setFromCalender(true);
    setStartdate(convertToUSDateFormat(value));
    console.log("USAForamte", convertToUSDateFormat(value));
    setStartdateReal(value);
  };

  useEffect(() => {
    if (!isDynamic) {
      setPendingDate(addDaysToUSDate(startdate, pendingDay - 1));
    }
    setIsDynamic(false);
    if (!isDynamic && fromCalender) {
      setPendingDate(addDaysToUSDate(startdate, pendingDay));
    }
  }, [startdate]);

  // when pending date change
  useEffect(() => {
    setSubmissionStartDate(addDaysToUSDate(pendingDate, 1));
    setSubmissionDate(
      addDaysToUSDate(addDaysToUSDate(pendingDate, 0), submissionDay)
    );
    setCommunityStartDate(
      addDaysToUSDate(
        addDaysToUSDate(addDaysToUSDate(pendingDate, 0), submissionDay),
        1
      )
    );
    setCommunityDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(addDaysToUSDate(pendingDate, 0), submissionDay),
          0
        ),
        communityDay
      )
    );

    setReviewStartDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(
            addDaysToUSDate(addDaysToUSDate(pendingDate, 0), submissionDay),
            0
          ),
          communityDay
        ),
        1
      )
    );

    setReviewDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(
            addDaysToUSDate(
              addDaysToUSDate(addDaysToUSDate(pendingDate, 0), submissionDay),
              0
            ),
            communityDay
          ),
          0
        ),
        reviewDay
      )
    );

    setWinnerStartDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(
            addDaysToUSDate(
              addDaysToUSDate(
                addDaysToUSDate(addDaysToUSDate(pendingDate, 0), submissionDay),
                0
              ),
              communityDay
            ),
            0
          ),
          reviewDay
        ),
        1
      )
    );
    setWinnerDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(
            addDaysToUSDate(
              addDaysToUSDate(
                addDaysToUSDate(
                  addDaysToUSDate(
                    addDaysToUSDate(pendingDate, 0),
                    submissionDay
                  ),
                  0
                ),
                communityDay
              ),
              0
            ),
            reviewDay
          ),
          0
        ),
        winnerDay
      )
    );
  }, [pendingDay, pendingDate]);

  // when submission date change
  useEffect(() => {
    setCommunityStartDate(addDaysToUSDate(submissionDate, 1));
    setCommunityDate(
      addDaysToUSDate(addDaysToUSDate(submissionDate, 0), communityDay)
    );

    setReviewStartDate(
      addDaysToUSDate(
        addDaysToUSDate(addDaysToUSDate(submissionDate, 0), communityDay),
        1
      )
    );
    setReviewDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(addDaysToUSDate(submissionDate, 0), communityDay),
          0
        ),
        reviewDay
      )
    );

    setWinnerStartDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(
            addDaysToUSDate(addDaysToUSDate(submissionDate, 0), communityDay),
            0
          ),
          reviewDay
        ),
        1
      )
    );
    setWinnerDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(
            addDaysToUSDate(
              addDaysToUSDate(addDaysToUSDate(submissionDate, 0), communityDay),
              0
            ),
            reviewDay
          ),
          0
        ),
        winnerDay
      )
    );
  }, [submissionDay]);

  // when community date change
  useEffect(() => {
    setReviewStartDate(addDaysToUSDate(communityDate, 1));
    setReviewDate(
      addDaysToUSDate(addDaysToUSDate(communityDate, 0), reviewDay)
    );

    setWinnerStartDate(
      addDaysToUSDate(
        addDaysToUSDate(addDaysToUSDate(communityDate, 0), reviewDay),
        1
      )
    );
    setWinnerDate(
      addDaysToUSDate(
        addDaysToUSDate(
          addDaysToUSDate(addDaysToUSDate(communityDate, 0), reviewDay),
          0
        ),
        winnerDay
      )
    );
  }, [communityDay]);

  // when review date change
  useEffect(() => {
    setWinnerStartDate(addDaysToUSDate(reviewDate, 1));
    setWinnerDate(addDaysToUSDate(reviewDate, winnerDay));
  }, [reviewDay]);

  // pending textfield change
  const pendingDayInputChange = (value: any) => {
    setPendingDay(value);
    setPendingDate(addDaysToUSDate(startdate, value));
  };

  // submission textfield change
  const submissionDayInputChange = (value: any) => {
    setSubmissionDay(value);
    setSubmissionStartDate(addDaysToUSDate(pendingDate, 1));
    setSubmissionDate(addDaysToUSDate(pendingDate, value));
  };

  // community textfield change
  const communityDayInputChange = (value: any) => {
    setCommunityDay(value);
    setCommunityStartDate(addDaysToUSDate(submissionDate, 1));
    setCommunityDate(addDaysToUSDate(submissionDate, value));
  };

  // review textfield change
  const reviewDayInputChange = (value: any) => {
    setReviewDay(value);
    setReviewStartDate(addDaysToUSDate(communityDate, 1));
    setReviewDate(addDaysToUSDate(communityDate, value));
  };

  // winner textfield change
  const winnerDayInputChange = (value: any) => {
    setWinnerDay(value);
    setWinnerStartDate(addDaysToUSDate(reviewDate, 1));
    setWinnerDate(addDaysToUSDate(reviewDate, value));
  };

  // send Data to backend or save timeline
  const submitTimeline = (): any => {
    setIsLoading(true);
    axios
      .post(`${Date_Time_Picker}?bountiId=${selectedId}`, {
        startDate: startdate,
        endDate: winnerDate,
        timeline: [
          {
            pending: {
              startDate: startdate,
              endDate: pendingDate,
              endTime: pendingTime,
            },
            submission: {
              startDate: submissionStartDate,
              endDate: submissionDate,
              endTime: submissionTime,
            },
            community: {
              startDate: communityStartDate,
              endDate: communityDate,
              endTime: communityTime,
            },
            review: {
              startDate: reviewStartDate,
              endDate: reviewDate,
              endTime: reviewTime,
            },
            winner: {
              startDate: winnerStartDate,
              endDate: winnerDate,
              endTime: winnerTime,
            },
          },
        ],
      })
      .then((res) => {
        console.log(res, "response_submittimeline");
        dispatch(setText([res.data.checkList.TimeLimit, 5]));
        setIsLoading(false);
        setIsAlertSuccess(true);
        setTimeout(() => {
          setIsAlertSuccess(false);
        }, 2300);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsAlertError(true);
        setTimeout(() => {
          setIsAlertError(false);
        }, 2300);
      });
  };

  console.log("pendingK", dayjs(`2022-04-17T${pendingTime?.split(" ")[0]}`));

  return (
    <section className="phases-dialog___wrapper">
      {/* success alert */}
      {isAlertSuccess && (
        <Alert
          message="submission phases succesfully change"
          type="success"
          style={{
            position: "absolute",
            top: "0",
            left: "65%",
            fontSize: "1.2rem",
            // fontWeight: "bolder",
          }}
          showIcon
          closable
          onClose={() => setIsAlertSuccess(false)}
        />
      )}
      {/* error alert */}
      {isAlertError && (
        <Alert
          message="please try again"
          type="error"
          style={{
            position: "absolute",
            top: "0",
            left: "65%",
            fontSize: "1.2rem",
            // fontWeight: "bolder",
          }}
          showIcon
          closable
          onClose={() => setIsAlertError(false)}
        />
      )}

      {/* <Alert
        style={
          isAlert.is
            ? {
                position: "absolute",
                top: "0",
                left: "60%",
                fontSize: "1.2rem",
                // fontWeight: "bolder",
              }
            : { display: "none" }
        }
        severity={isAlert.type}
      >
        {isAlert?.message}
      </Alert> */}
      <div className="phases-dialog___container">
        <h1 className="phases-dialog___heading">Phases</h1>
        <p className="phases-dialog___text">
          Choose how long each phase of your bounty will last
        </p>
        <div className="phases-dialog___header">
          {/* Start Date */}
          <button className="phases-dialog___button phases-dialog___left">
            Start Date
          </button>
          <button
            className="phases-dialog___button phases-dialog___Act"
            disabled={isLock}
            onClick={() => {
              toggleCalender
                ? setToggleCalender(false)
                : setToggleCalender(true);
            }}
          >
            <p>{startdate}</p>
            {!toggleCalender ? (
              <ArrowDropDown className="phases-dialog___header__icon" />
            ) : (
              <p className="phases-dialog___header__icon">x</p>
            )}
          </button>
        </div>
        <div
          className="phases-dialog___calender__body"
          style={toggleCalender ? { display: "flex" } : { display: "none" }}
        >
          <Calendar
            className={"phases__calender"}
            value={startdateReal}
            onChange={(e: any) => {
              startDateChangeHandle(e);
              setToggleCalender(false);
            }}
            minDate={new Date()}
          />
        </div>
        {/* mainbody goes here */}
        <div className="phases-dialog___mainBody">
          {/* preview Date*/}
          <div className="pdmb__row">
            <div className="pdmb__row_label">Preview</div>
            <div className="pdmb__row_input pdmb__row_input__preview">
              <Input
                type="number"
                min={0}
                value={pendingDay < 0 ? 0 : pendingDay}
                className="pdmb__input"
                disabled={isLock}
                onChange={(e) => {
                  console.log(e);
                  pendingDayInputChange(e.target.valueAsNumber);
                }}
              />
            </div>
            <div className="pdmb__row_slider pdmb-right_row">
              <Slider
                aria-labelledby="pendingDays"
                valueLabelDisplay="auto"
                defaultValue={pendingDay}
                value={pendingDay}
                step={1}
                marks
                size="small"
                min={0}
                max={30}
                disabled={isLock}
                onChange={(e: any) => {
                  pendingDayInputChange(e.target.value);
                }}
                style={{ color: "var(--sidebar_secondory)" }}
              />
            </div>
            <div className="pdmb__row_tag pdmb-right_row">
              <p>{pendingDate}</p>
            </div>
            <div className="pdmb__row__timepicker">
              <ThemeProvider theme={customTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <div style={{ width: "9rem" }}>
                      <TimePicker
                        disabled={isLock}
                        className={`${classes.timePicker} phase__timePicker`}
                        components={{
                          OpenPickerIcon: ClockIcon,
                        }}
                        defaultValue={dayjs(
                          `2029-04-17T${pendingTime?.split(" ")[0]}`
                        )}
                        value={dayjs(
                          `2029-04-17T${pendingTime?.split(" ")[0]}`
                        )}
                        ampm={true}
                        onChange={(e: any) => {
                          const ampm = e.$H < 12 ? "AM" : "PM";

                          const formattedTime = `${e.$H}:${e.$m} ${ampm}`;

                          setPendingTime(formattedTime);
                        }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </ThemeProvider>
            </div>
          </div>

          {/* submission */}
          <div className="pdmb__row">
            <div className="pdmb__row_label">Submission</div>
            <div className="pdmb__row_input pdmb__row_input__submission">
              <Input
                value={submissionDay}
                min={1}
                type="number"
                disabled={isLock}
                onChange={(e: any) => {
                  submissionDayInputChange(e.target.valueAsNumber);
                }}
                className="pdmb__input"
              />
            </div>
            <div className="pdmb__row_slider pdmb-right_row">
              <Slider
                size="small"
                min={1}
                max={30}
                aria-label="Days"
                valueLabelDisplay="auto"
                step={1}
                marks
                value={submissionDay}
                disabled={isLock}
                onChange={(e: any) => {
                  submissionDayInputChange(e.target.value);
                }}
                style={{ color: "var(--sidebar_secondory)" }}
              />
            </div>
            <div className="pdmb__row_tag pdmb-right_row">
              <p>{submissionDate}</p>
            </div>
            <div className="pdmb__row__timepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div style={{ width: "9rem" }}>
                    <TimePicker
                      disabled={isLock}
                      className={`${classes.timePicker} phase__timePicker`}
                      // value={new Date(`${submissionDate} ${submissionTime}`)}
                      defaultValue={dayjs(
                        `2022-04-17T${submissionTime?.split(" ")[0]}`
                      )}
                      value={dayjs(
                        `2022-04-17T${submissionTime?.split(" ")[0]}`
                      )}
                      onChange={(e: any) => {
                        const ampm = e.$H < 12 ? "AM" : "PM";

                        // let hours12 = e.$H > 12 ? e.$H - 12 : e.$H;
                        // if (hours12 === 0) hours12 = 12;

                        const formattedTime = `${e.$H}:${e.$m} ${ampm}`;
                        setSubmissionTime(formattedTime);
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          {/* community */}
          <div className="pdmb__row">
            <div className="pdmb__row_label">Community</div>
            <div className="pdmb__row_input pdmb__row_input__community">
              <Input
                type="number"
                value={communityDay}
                disabled={isLock}
                onChange={(e: any) => {
                  communityDayInputChange(e.target.valueAsNumber);
                }}
                min={1}
                className="pdmb__input"
              />
            </div>
            <div className="pdmb__row_slider pdmb-right_row">
              <Slider
                aria-label="Days"
                defaultValue={communityDay}
                value={communityDay}
                valueLabelDisplay="auto"
                step={1}
                disabled={isLock}
                marks
                size="small"
                min={1}
                max={30}
                onChange={(e: any) => {
                  communityDayInputChange(e.target.value);
                }}
                style={{ color: "var(--sidebar_secondory)" }}
              />
            </div>
            <div className="pdmb__row_tag pdmb-right_row">
              <p>{communityDate}</p>
            </div>
            <div className="pdmb__row__timepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div style={{ width: "9rem" }}>
                    <TimePicker
                      disabled={isLock}
                      className={`${classes.timePicker} phase__timePicker`}
                      // value={new Date(`${communityDate} ${communityTime}`)}
                      defaultValue={dayjs(
                        `2022-04-17T${communityTime?.split(" ")[0]}`
                      )}
                      value={dayjs(
                        `2022-04-17T${communityTime?.split(" ")[0]}`
                      )}
                      onChange={(e: any) => {
                        const ampm = e.$H < 12 ? "AM" : "PM";

                        // let hours12 = e.$H > 12 ? e.$H - 12 : e.$H;
                        // if (hours12 === 0) hours12 = 12;

                        const formattedTime = `${e.$H}:${e.$m} ${ampm}`;
                        setCommunityTime(formattedTime);
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          {/* review */}
          <div className="pdmb__row">
            <div className="pdmb__row_label">Review</div>
            <div className="pdmb__row_input pdmb__row_input__review">
              <Input
                type="number"
                value={reviewDay}
                onChange={(e: any) => {
                  reviewDayInputChange(e.target.valueAsNumber);
                }}
                min={1}
                disabled={isLock}
                className="pdmb__input"
              />
            </div>
            <div className="pdmb__row_slider pdmb-right_row">
              <Slider
                aria-label="Days"
                value={reviewDay}
                valueLabelDisplay="auto"
                step={1}
                marks
                size="small"
                disabled={isLock}
                min={1}
                max={30}
                onChange={(e: any) => {
                  reviewDayInputChange(e.target.value);
                }}
                style={{ color: "var(--sidebar_secondory)" }}
              />
            </div>
            <div className="pdmb__row_tag pdmb-right_row">
              <p>{reviewDate}</p>
            </div>
            <div className="pdmb__row__timepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div style={{ width: "9rem" }}>
                    <TimePicker
                      disabled={isLock}
                      className={`${classes.timePicker} phase__timePicker`}
                      defaultValue={dayjs(
                        `2022-04-17T${reviewTime?.split(" ")[0]}`
                      )}
                      value={dayjs(`2022-04-17T${reviewTime?.split(" ")[0]}`)}
                      // value={new Date(`${reviewDate} ${reviewTime}`)}
                      onChange={(e: any) => {
                        const ampm = e.$H < 12 ? "AM" : "PM";

                        // let hours12 = e.$H > 12 ? e.$H - 12 : e.$H;
                        // if (hours12 === 0) hours12 = 12;

                        const formattedTime = `${e.$H}:${e.$m} ${ampm}`;
                        setReviewTime(formattedTime);
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          {/* winner */}
          <div className="pdmb__row">
            <div className="pdmb__row_label">Winner</div>
            <div className="pdmb__row_input ">
              <Input
                type="number"
                value={winnerDay}
                onChange={(e: any) => {
                  winnerDayInputChange(e.target.valueAsNumber);
                }}
                disabled={isLock}
                min={1}
                className="pdmb__input pdmb__row_input__winner"
              />
            </div>
            <div className="pdmb__row_slider pdmb-right_row">
              <Slider
                aria-label="Days"
                value={winnerDay}
                valueLabelDisplay="auto"
                step={1}
                marks
                disabled={isLock}
                size="small"
                min={1}
                max={30}
                onChange={(e: any) => {
                  winnerDayInputChange(e.target.value);
                }}
                style={{ color: "var(--sidebar_secondory)" }}
              />
            </div>
            <div className="pdmb__row_tag pdmb-right_row">
              <p>{winnerDate}</p>
            </div>
            <div className="pdmb__row__timepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div style={{ width: "9rem" }}>
                    <TimePicker
                      disabled={isLock}
                      className={`${classes.timePicker} phase__timePicker`}
                      defaultValue={dayjs(
                        `2022-04-17T${winnerTime?.split(" ")[0]}`
                      )}
                      value={dayjs(`2022-04-17T${winnerTime?.split(" ")[0]}`)}
                      onChange={(e: any) => {
                        const ampm = e.$H < 12 ? "AM" : "PM";

                        // let hours12 = e.$H > 12 ? e.$H - 12 : e.$H;
                        // if (hours12 === 0) hours12 = 12;

                        const formattedTime = `${e.$H}:${e.$m} ${ampm}`;
                        setWinnerTime(formattedTime);
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <button
            className="pdmd__Butn"
            onClick={submitTimeline}
            disabled={isLoading || isLock}
          >
            {" "}
            {isLoading ? (
              <>
                <CircularProgress size={30} color="inherit" />
              </>
            ) : (
              "Save"
            )}
          </button>
          <button
            className="pdmd__close"
            onClick={() => {
              dispatch(togglePhasesDialog(false));
            }}
          >
            X
          </button>
        </div>
        {/* mainbody end here */}
      </div>
    </section>
  );
};

export default PhasesDialog;
