import { Checkbox } from "antd";
import "./checklist.css";
import { useDispatch } from "react-redux";
import { setText } from "../../../../../../app/slices/CheckListStatus";

const TrieCheck = ({ id, isChecked, isRequired }: any) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center">
      <p className="checklist__status">
        {isRequired ? "Required" : "Optional"}
      </p>
      <Checkbox
        className="ckecklist_checkbox"
        checked={isChecked}
        onChange={(e: any) => {
          dispatch(setText([e.target.checked, id]));
        }}
        disabled
      />
    </div>
  );
};

export default TrieCheck;
