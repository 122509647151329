import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { FluidContainer } from "../../components";
import styles from "../bounti-pages/BountyPreview/bountipreview.module.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { getBounties } from "../../apis/getApis";
import { useNavigate } from "react-router-dom";
import { U } from "../../apis/main";
import {
  createCharity,
  createSponsor,
  create_bounty,
} from "../../apis/postApis";
import { Button } from "antd";
import BountyCard from "../Dashboard/components/BountyCard/BountyCard";
import {
  addRewardViewData,
  toggleRewardDialogCreation,
} from "../../app/slices/singleStates";

const CreatorsBounties = () => {
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lists, setLists] = useState<any>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const fetchBoutyForUserWise = async () => {
    await axios
      .get(`${getBounties}`)
      .then((res) => {
        console.log("lists", res);
        setLists(res.data.data.reverse());
      })
      .catch((err) => {});
  };
  const handleRowClick = (id: string, sponserId: string, charityId: string) => {
    setSelectedId(id);
    console.log("setSelectedId", id);
    if (!sponserId) {
      axios
        .post(`${createSponsor}/${id}`)
        .then((res: any) => {
          localStorage.setItem("createdSponserId", res.data.data);
          // dispatch(addRewardViewData(currentSwiperInd));
          dispatch(toggleRewardDialogCreation(false));
          // dispatch(addRewardPopupMode("View"));
        })
        .catch((err: any) => {});
    } else {
      localStorage.setItem("createdSponserId", sponserId);
    }

    if (!charityId) {
      axios
        .post(`${createCharity}/${id}`)
        .then((res: any) => {
          localStorage.setItem("createdCharityId", res.data.data);
          dispatch(toggleRewardDialogCreation(false));
        })
        .catch((err: any) => {
          console.log("createdCharityId", err);
        });
    } else {
      localStorage.setItem("createdCharityId", charityId);
    }
    dispatch(toggleRewardDialogCreation(false));

    // You can navigate to the new path with the selectedId in the state
    navigate(`/bouticreation`, { state: { selectedId: id } });
  };

  // function call when user want create new bounty
  const createBounty = async () => {
    await axios
      .post(`${create_bounty}/${U}`, {})
      .then((res) => {
        console.log("bhabille", res);
        localStorage.removeItem("createdSponserId");
        localStorage.removeItem("createdCharityId");
        navigate("/bouticreation", {
          state: { selectedId: res.data.bountiSaved._id },
        });
        dispatch(toggleRewardDialogCreation(false));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchBoutyForUserWise();
  }, []);

  return (
    <>
      <FluidContainer
        breadcrumb={["Home", "Bounty Manager", "Manage a Bounty"]}
      >
        <div className="flex justify-between bounti-preview-res bp-container">
          {/* 66% */}
          <div
            className={
              toggleD
                ? `${styles.div_67} div_67_respon`
                : `${styles.div_67} div_67_respon div_67_respon_collapse`
            }
          >
            <Button
              onClick={createBounty}
              style={{
                background: "var(--orange)",
                border: "none",
                color: "var(--pure)",
              }}
            >
              Create
            </Button>
            {lists && lists.length === 0 && (
              <p style={{ marginTop: "1rem", textAlign: "center" }}>
                No bounties yet...!!
              </p>
            )}
            <div
              className="flex justify-between flex-wrap"
              // style={{ marginTop: "2rem" }}
            >
              {lists?.map((bounty: any) => {
                return (
                  <div
                    key={bounty._id}
                    // onClick={() =>
                    //   handleRowClick(
                    //     bounty._id,
                    //     bounty.sponsorId,
                    //     bounty.charityId
                    //   )
                    // }
                    style={{ marginTop: "2rem" }}
                  >
                    <BountyCard
                      bountyId={bounty?._id}
                      bountyCreatePageRedirect={handleRowClick}
                      sponserId={bounty.sponsorId}
                      charityId={bounty.charityId}
                      setLists={setLists}
                    />
                  </div>
                );
              })}
            </div>
            {/* <table>
              <thead>
                <tr>
                  <th style={{ border: "2px solid grey", width: "17rem" }}>
                    id
                  </th>
                  <th style={{ border: "2px solid grey", width: "17rem" }}>
                    title
                  </th>
                  <th style={{ border: "2px solid grey", width: "17rem" }}>
                    description
                  </th>
                </tr>
              </thead>
              <tbody>
                {lists?.map((elm: any, index: any) => {
                  return (
                    <tr
                      key={elm._id}
                      onClick={() =>
                        handleRowClick(elm._id, elm.sponsorId, elm.charityId)
                      }
                    >
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid var(--pure)",
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid var(--pure)",
                          cursor: "pointer",
                        }}
                      >
                        {elm.title != "" ? elm.title : "-"}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid var(--pure)",
                          cursor: "pointer",
                        }}
                      >
                        {elm.description != "" ? elm.description : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </div>

          {/* 30% */}
          {/* <div className={`${styles.div_30} div_30_responsive`}> */}
          {/* <ChatRoom /> */}
          {/* </div> */}
        </div>
      </FluidContainer>
    </>
  );
};

export default CreatorsBounties;
