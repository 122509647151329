import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// initialize states
const initialState: any = {
  frame: "",
  language: "en",
  slide: "",
  isDateTimePopup: false,
  isReset: false,
  isRegister: false,
  toggleStatusOfRewardDialogBox: false,
  isUploadPopup: false,
  activeSlide: 0,
  isLikePopup: false,
  isSharePopup: false,
  user: "",
  isSidebarToggle: false,
  isRefresh: false,
  isSidebarToggleD: true,
  currentRoutesStage: 0,
  breadCrumb: "default",
  currentMediaTab: 0,
  isMediaDialog: false,
  isPhasesDialog: false,
  isRewardDialog: false,
  isLoginDialog: false,
  isRegisterDialog: false,
  isConfirmRegister: false,
  rewardUploaderRef: "",
  subCategory: [],
  currentStoredAssetTab: "IMAGE",
  isMediaDeletePopup: false,
  deleteAssetId: "",
  deleteMainId: "",
  currentImageCardInd: "",
  isSponserUploader: false,
  isCharityUploader: false,
  isRewardDeleteDialog: false,
  rewardIdForDelete: true,
  rewardMainIdForDelete: true,
  setForRewardDelete: true,
  uploadedRewardMessage: "",
  isUploadReward: false,
  isUploadRewardOnlyCreate: false,
  rewardViewId: null,
  clikedRewardId: null,
  rewardPopupMode: "",
  isCaptcha: false,
  registerAccessToken: "",
  isMediaViewer: false,
  currentReplieId: null,
  isFormateEmailDialog: false,
  isForgotConfirm: false,
  isResetDialog: false,
  isLock: false,
  isPinCall: 0,
  isSubmissionScreen: false,
  submissionScreen: 1,
  activeFeedAdURL: "",
  idAdDialog: false,
  currentSubmission: null,
  announcement:null,
  submissionReplyId:null,
  isCategoryDialog: false,
  forgotEmail: "",
  profileData: {},
  sidebarStats: {},
};

export const booleanStatus = createSlice({
  name: "booleanStatus",
  initialState,
  reducers: {
    toggleRewardPopopOnlyCreate: (state, action: PayloadAction<any>) => {
      state.isUploadRewardOnlyCreate = action.payload;
    },
    toggleIsRefresh: (state, action: PayloadAction<any>) => {
      console.log("i have changedd", action.payload)
      state.isRefresh = action.payload;
    },
    addFeedAdURL: (state, action: PayloadAction<any>) => {
      state.activeFeedAdURL = action.payload;
    },
    setSubmissionScreen: (state, action: PayloadAction<any>) => {
      state.submissionScreen = action.payload;
    },
    toggleSubmissionScreen: (state, action: PayloadAction<any>) => {
      state.isSubmissionScreen = action.payload;
    },
    changePinCall: (state, action: PayloadAction<any>) => {
      state.isPinCall = action.payload;
    },
    lockBountyMethod: (state, action: PayloadAction<any>) => {
      state.isLock = action.payload;
    },
    toggleForgotConfirmDialog: (state, action: PayloadAction<any>) => {
      state.isForgotConfirm = action.payload;
    },
    toggleResetDialog: (state, action: PayloadAction<any>) => {
      state.isResetDialog = action.payload;
    },
    toggleFormateEmailDialog: (state, action: PayloadAction<any>) => {
      state.isFormateEmailDialog = action.payload;
    },
    addCurrentRepieId: (state, action: PayloadAction<any>) => {
      state.currentReplieId = action.payload;
    },
    toggleMediaViewer: (state, action: PayloadAction<any>) => {
      state.isMediaViewer = action.payload;
    },
    addRegisterToken: (state, action: PayloadAction<any>) => {
      state.registerAccessToken = action.payload;
    },
    toggleRegisterCaptcha: (state, action: PayloadAction<any>) => {
      state.isCaptcha = action.payload;
    },
    toggleRewardDeleteDialog: (state, action: PayloadAction<any>) => {
      state.isRewardDeleteDialog = action.payload;
    },
    addClickedRewardId: (state, action: PayloadAction<any>) => {
      state.clikedRewardId = action.payload;
    },
    addRewardPopupMode: (state, action: PayloadAction<any>) => {
      state.rewardPopupMode = action.payload;
    },
    toggleRewardUploadAlert: (state, action: PayloadAction<any>) => {
      state.isUploadReward = action.payload;
    },
    addSetForRewardDelete: (state, action: PayloadAction<any>) => {
      state.setForRewardDelete = action.payload;
    },
    addRewardViewData: (state, action: PayloadAction<any>) => {
      state.rewardViewId = action.payload;
    },
    addUploadedRewardMessage: (state, action: PayloadAction<any>) => {
      state.uploadedRewardMessage = action.payload;
    },
    addRewardMainIdForDelete: (state, action: PayloadAction<any>) => {
      state.rewardMainIdForDelete = action.payload;
    },
    addRewardIdForDelete: (state, action: PayloadAction<any>) => {
      state.rewardIdForDelete = action.payload;
    },
    addFrame: (state, action: PayloadAction<any>) => {
      state.frame = action.payload;
    },
    addLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    addCurrentSlide: (state, action: PayloadAction<any>) => {
      state.slide = action.payload;
    },
    addDatePopup: (state, action: PayloadAction<boolean>) => {
      state.isDateTimePopup = action.payload;
    },
    addIsReset: (state, action: PayloadAction<boolean>) => {
      state.isReset = action.payload;
    },
    toggleRewardDialog: (state, action: PayloadAction<boolean>) => {
      state.toggleStatusOfRewardDialogBox = action.payload;
    },
    addIsRegister: (state, action: PayloadAction<boolean>) => {
      state.isRegister = action.payload;
    },
    addUploadPopup: (state, action: PayloadAction<boolean>) => {
      state.isUploadPopup = action.payload;
    },
    addActiveSlide: (state, action: PayloadAction<number>) => {
      state.activeSlide = action.payload;
    },
    addLikePopup: (state, action: PayloadAction<any>) => {
      state.isLikePopup = action.payload;
    },
    addSharePopup: (state, action: PayloadAction<any>) => {
      state.isSharePopup = action.payload;
    },
    addUserId: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    addSidebarToggle: (state, action: PayloadAction<boolean>) => {
      state.isSidebarToggle = action.payload;
    },
    addSidebarToggleD: (state, action: PayloadAction<boolean>) => {
      state.isSidebarToggleD = action.payload;
    },
    addCurrentRouteStage: (state, action: PayloadAction<any>) => {
      state.currentRoutesStage = action.payload;
    },
    toggleBreadCrumb: (state, action: PayloadAction<string>) => {
      state.breadCrumb = action.payload;
    },
    addCurrentMediaTab: (state, action: PayloadAction<any>) => {
      state.currentMediaTab = action.payload;
    },
    toggleMediaUploder: (state, action: PayloadAction<any>) => {
      state.isMediaDialog = action.payload;
    },
    togglePhasesDialog: (state, action: PayloadAction<any>) => {
      state.isPhasesDialog = action.payload;
    },
    toggleRewardDialogCreation: (state, action: PayloadAction<any>) => {
      state.isRewardDialog = action.payload;
    },
    toggleLoginDialogCreation: (state, action: PayloadAction<any>) => {
      state.isLoginDialog = action.payload;
    },
    toggleRegisterDialogCreation: (state, action: PayloadAction<any>) => {
      state.isRegisterDialog = action.payload;
    },
    toggleConfirmRegisterCreation: (state, action: PayloadAction<any>) => {
      state.isConfirmRegister = action.payload;
    },
    addRewardUploaderRef: (state, action: PayloadAction<any>) => {
      state.rewardUploaderRef = action.payload;
    },
    addSubCategory: (state, action: PayloadAction<any>) => {
      state.subCategory = action.payload;
    },
    addCurrentStoredAssetTab: (state, action: PayloadAction<any>) => {
      state.currentStoredAssetTab = action.payload;
    },
    addIsMediaDeletePopup: (state, action: PayloadAction<boolean>) => {
      state.isMediaDeletePopup = action.payload;
    },
    addIsDeleteAssetId: (state, action: PayloadAction<any>) => {
      state.deleteAssetId = action.payload;
    },
    addIsDeleteMainId: (state, action: PayloadAction<any>) => {
      state.deleteMainId = action.payload;
    },
    addCurrentImageCardInd: (state, action: PayloadAction<any>) => {
      state.currentImageCardInd = action.payload;
    },
    addIsSponserUploader: (state, action: PayloadAction<any>) => {
      state.isSponserUploader = action.payload;
    },
    addIsCharityUploader: (state, action: PayloadAction<any>) => {
      state.isCharityUploader = action.payload;
    },
    getAdDialog: (state, action: PayloadAction<any>) => {
      state.idAdDialog = action.payload;
    },
    setcurrentSubmission: (state, action: PayloadAction<any>) => {
      state.currentSubmission = action.payload;
    },
    setAnnonceData: (state, action: PayloadAction<any>) => {
      state.announcement = action.payload;
    },
    addSubmissionReplyId: (state, action: PayloadAction<any>) => {
      state.submissionReplyId = action.payload;
    },
    toggleCategoryDialogCreation: (state, action: PayloadAction<any>) => {
      state.isCategoryDialog = action.payload;
    },
    setForgotEmail: (state, action: PayloadAction<any>) => {
      state.forgotEmail = action.payload;
    },
    setProfileDataRedux(state, action) {
      state.profileData = action.payload;
    },
    setSidebarStatsRedux(state, action) {
      state.sidebarStats = action.payload;
    },
  },
});

// Export action creators
export const {
  addFrame,
  addIsSponserUploader,
  setSubmissionScreen,
  addIsCharityUploader,
  changePinCall,
  addLanguage,
  addCurrentSlide,
  addDatePopup,
  addIsReset,
  toggleRewardDialog,
  addIsRegister,
  addUploadPopup,
  addActiveSlide,
  addLikePopup,
  addRewardPopupMode,
  addSharePopup,
  addUserId,
  addSidebarToggle,
  addFeedAdURL,
  addSidebarToggleD,
  addRewardMainIdForDelete,
  addSetForRewardDelete,
  addRewardIdForDelete,
  addCurrentRouteStage,
  toggleBreadCrumb,
  addCurrentMediaTab,
  toggleFormateEmailDialog,
  toggleMediaUploder,
  togglePhasesDialog,
  addRewardUploaderRef,
  addSubCategory,
  addCurrentStoredAssetTab,
  addIsMediaDeletePopup,
  addIsDeleteAssetId,
  addIsDeleteMainId,
  addCurrentImageCardInd,
  toggleRewardDialogCreation,
  toggleRewardDeleteDialog,
  addUploadedRewardMessage,
  toggleRewardUploadAlert,
  addRewardViewData,
  lockBountyMethod,
  addClickedRewardId,
  toggleRewardPopopOnlyCreate,
  toggleLoginDialogCreation,
  toggleRegisterDialogCreation,
  toggleConfirmRegisterCreation,
  toggleRegisterCaptcha,
  addRegisterToken,
  toggleMediaViewer,
  addCurrentRepieId,
  toggleForgotConfirmDialog,
  toggleResetDialog,
  toggleSubmissionScreen,
  getAdDialog,
  setcurrentSubmission,
  setAnnonceData,
  addSubmissionReplyId,
  toggleCategoryDialogCreation,
  toggleIsRefresh,
  setForgotEmail,
  setProfileDataRedux,
  setSidebarStatsRedux,
} = booleanStatus.actions;

export default booleanStatus.reducer;
