import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

// Custom styling for the badge
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ChatBubble = ({
  styles,
  classes,
  message,
  time,
  user,
  isOnline,
  userEmail,
  userImage = "https://cdn.pixabay.com/photo/2023/07/28/09/50/ai-generated-8154910_1280.jpg",
}) => {
  let mediaContent = null;

  // Function to determine media type from URL
  const getMediaType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "image";
    } else if (["mp4", "webm", "ogg"].includes(extension)) {
      return "video";
    }
    // Add more checks for other media types if needed
    return "unknown";
  };

  // Function to render media content
  const renderMediaContent = (url, type) => {
    if (type === "image") {
      return (
        <img
          className="very_dark_blue_bg"
          src={url}
          alt="Image"
          style={{ width: "16rem", height: "16rem", objectFit: "cover" }}
        />
      );
    } else if (type === "video") {
      return (
        <video
          className="very_dark_blue_bg"
          src={url}
          style={{ width: "16rem", height: "16rem", objectFit: "cover" }}
          controls
        />
      );
    }
    // Handle other media types if needed
    return null;
  };

  // Check if message is a URL and handle media content
  if (message.startsWith("http")) {
    const mediaType = getMediaType(message);
    if (mediaType !== "unknown") {
      mediaContent = renderMediaContent(message, mediaType);
    }
  }

  return (
    <div className={`${classes} chat_bubble_warpper`}>
      <div className="chat_bubble">
        <div className="chat_bubble_top flex justify-between">
          <div className="chat_bubble_left flex">
            {/* Display user profile image */}
            {userEmail === user || user === "me" ? (
              <img src={userImage} className="c_b_profile" />
            ) : (
              <img
                src={`https://mingalinks.com:5000/v1/getUserProfile/${user}`}
                className="c_b_profile"
              />
            )}
            {/* Display online status badge */}
            {/* {isOnline ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right"}}
                variant="dot"
              >
                <Avatar alt="Remy Sharp" src={`https://mingalinks.com:5000/v1/getUserProfile/${user}`} />
              </StyledBadge>
            ) : (
              <img  src={`https://mingalinks.com:5000/v1/getUserProfile/${user}`} className="c_b_profile" />
            )} */}
            {/* Display user name */}
            <div className="c_b_profileName">
              <label>
                {/* Display "Me" if the user is the current user, otherwise display the user's name */}
                {userEmail === user ? "Me" : user.split("@")[0]}
              </label>
              {/* Display online status */}
              <div>Offline</div>
              {/* <div> <img src="./tests/icons/online.svg" alt="online" /></div> */}
            </div>
          </div>
          <div className="chat_bubble_right">
            {/* Display message time */}
            <div className="chat_time">{time}</div>
            {/* Display like icon */}
            {/* <div className="c_b_likesOptions">
              <FavoriteIcon className="chat_bubble_likeicon" />
            </div> */}
          </div>
        </div>
        {/* <div className="chat_text">{message}</div> */}
        {/* Display message text if there is no media content */}
        {!mediaContent && <div className="chat_text">{message}</div>}
        {/* Display media content */}
        {mediaContent && <div>{mediaContent}</div>}
      </div>
    </div>
  );
};

export default ChatBubble;
