import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Forgot_Link_Verify } from "../../apis/getApis";
import { toggleResetDialog } from "../../app/slices/singleStates";
import { useDispatch } from "react-redux";

const ForgotVarification = () => {
  const { id, token } = useParams<{ id: string; token: string }>();
  const [isInValid, setIsInValid] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  // forgot password link verify and redirect /login page
  useEffect(() => {
    axios
      .get(`${Forgot_Link_Verify}/${id}/${token}`)
      .then((res) => {
        if (res.data) {
          dispatch(toggleResetDialog(true));
          nav("/login", { state: id });
        }
      })
      .catch((err) => {
        setIsInValid(true);
      });
  }, []);

  if (isInValid) {
    return <>404 page not found</>;
  }
  return <div>Verifying Link..</div>;
};

export default ForgotVarification;
