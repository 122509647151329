import axios from "axios";
import React, { useEffect, useState } from "react";
import { allAsset2, getAllMediaComments } from "../../../../../apis/getApis";
import { MediaWrapper } from "../../../../../components";
import "swiper/css";
import { SwiperSlide, Swiper } from "swiper/react";
import { addCurrentMediaView } from "../../../../../app/slices/mediaViewerData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  addCurrentRepieId,
  toggleMediaViewer,
} from "../../../../../app/slices/singleStates";
import {
  assetBookmark,
  assetLike,
  commentCApi,
  mediaCommentLike,
  replyOnMediaComment,
} from "../../../../../apis/postApis";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import Header from "../../../../../apis/header";

const MediaPreviewDialog = ({ reduxBountyId }: any) => {
  const [selectedEmoji, setSelectedEmoji] = useState<any>("1f60a");
  const [isEmojiSection, setIsEmojiSection] = useState<any>(false);
  const [medias, setMedias] = useState<any>([]);
  const [comment, setComment] = useState<any>("");
  const [swiper, setSwiper] = useState<any>();
  const [isFav, setIsFav] = useState(false);
  const [reRender, setReRender] = useState<any>(false);
  const [slideNumber, setSlideNumber] = useState<any>(0);
  const dispatch = useDispatch();
  const currentMedia = useSelector((state: RootState) => {
    return state.mediaViewerData;
  });
  console.log("CurrentMediaKa", currentMedia);
  const currentCommentId = useSelector((state: RootState) => {
    return state.booleanStatus.currentReplieId;
  });
  const currentCommentHandle = (
    inputRefName: string,
    commentId: string,
    user: string
  ) => {
    dispatch(addCurrentRepieId(commentId));
    setComment(`@${user.split("@")[0]} `);
    const input = document.getElementById(inputRefName);
    if (input) {
      setReRender(!reRender);
      input.focus();
    }
  };
  // onEnter submit
  const inputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      submitComment(event);
    }
  };

  // is current user reply or not functionality
  const checkCurrentUserReplyOrNot = (comment: any, userName: any) => {
    if (
      Array.isArray(comment.results) &&
      comment.results.some((result: any) => result.userDetails?.userName === userName)
    ) {
      // return true;
      return comment.results.length > 0;
    }
    return false;
  };

  const bookmarkMediaAsset = async () => {
    await axios
      .post(`${assetBookmark}/${currentMedia?.id}`, {}, Header)
      .then((res: any) => {
        console.log("vixzxizxz", res);
        // setIsFav(res?.data?.data?.userFav);
        setReRender(!reRender);
      })
      .catch((err: any) => {});
  };

  // like comment
  const likeComment = async (commentId: any) => {
    const loginData = localStorage.getItem("loginData");
    axios
      .post(`${mediaCommentLike}/${commentId}`, {}, Header)
      .then((res: any) => {
        setReRender(!reRender);
      })
      .catch((err) => {});
  };
  // like media asset
  const likeMediaAsset = () => {
    axios
      .post(`${assetLike}/${currentMedia?.id}`, {}, Header)
      .then((res: any) => {
        console.log("likeResponse", res);
        setReRender(!reRender);
      })
      .catch((err) => {
        console.log("likeResponse", err);
      });
  };

  const [commentData, setCommentData] = useState<any>([]);
  // set emoji to message goes here
  const setEmojiWithMessage = (emojiData: any) => {
    setComment(
      (comment: any) =>
        comment + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
    );
    setSelectedEmoji(emojiData.unified);
  };
  // ser emoji to message function end here
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }
  console.log("loginData", loginData);
  const [flag, setFlag] = useState(0);
  const [currentCommentUser, setCommentUser] = useState("");
  const typingComment = (e: any) => {
    if (comment.slice(0, 1) === "@") {
      if (flag === 0) {
        setCommentUser(comment.split(" ")[0]);
        setFlag(1);
      }
      if (currentCommentUser?.length >= comment?.length) {
        setComment("");
        return;
      }
      setComment(e.target.value);
      console.log("changeUser", currentCommentUser, comment);
      return;
    }
    setComment(e.target.value);
  };

  useEffect(() => {
    axios
      .get(`${getAllMediaComments}/${loginData?.userId}/${currentMedia?.id}`)
      .then((res: any) => {
        console.log("commentKDatas", res.data.response);
        setCommentData(res.data.response);
      })
      .catch((error: any) => {
        console.log(error);
      });
    setReRender(false);
  }, [reRender, currentMedia]);

  const submitComment = (e: any) => {
    if (comment.slice(0, 1) === "@") {
      // // reply api
      const realReply = comment.replace(`${comment?.split(" ")[0]} `, "");
      axios
        .post(
          `${replyOnMediaComment}/${loginData?.userId}/${currentMedia?.id}/${reduxBountyId}/${currentCommentId}`,
          {
            mediaReply: realReply,
          }
        )
        .then((res: any) => {
          setReRender(true);
          setComment("");
          console.log("replyPost", res);
        })
        .catch((err: any) => {});
    } else {
      axios
        .post(
          `${commentCApi}/${loginData?.userId}/${currentMedia?.id}/${reduxBountyId}`,
          {
            mediaCreatComment: comment,
          }
        )
        .then((res: any) => {
          setReRender(true);
          setIsEmojiSection(false);
          setComment("");
        })
        .catch((err: any) => {});
    }
  };

  // fetch medias
  const fetchMedias = async () => {
    await axios
      .get(`${allAsset2}/${reduxBountyId}?type=undefined`)
      .then((response: any) => {
        console.log("response.data.data", response.data.data);
        setMedias(response.data.data);
        // setIsFav(response.data.data[0].favorites.includes(loginData?.userId));
        dispatch(
          addCurrentMediaView({
            id: response.data.data[slideNumber].id,
            mediaId: response.data.data[slideNumber].mediaId,
            title: response.data.data[slideNumber].title,
            description: response.data.data[slideNumber].description,
            imageUrl: response.data.data[slideNumber].imageUrl,
            likes: response.data.data[slideNumber].likes,
            favs: response.data.data[slideNumber].favorites,
          })
        );
      })
      .catch((error: any) => {
        console.log("AllBountiesData2", error);
      });
  };
  useEffect(() => {
    fetchMedias();
  }, []);

  useEffect(() => {
    fetchMedias();
  }, [reRender]);

  const mediaSwiperInit = (swiperInstance: any) => {
    setSwiper(swiperInstance);
  };

  const saveCurrentView = (currentSelectMedia: any, ind: any) => {
    setSlideNumber(ind);
    dispatch(
      addCurrentMediaView({
        id: currentSelectMedia.id,
        mediaId: currentSelectMedia.mediaId,
        title: currentSelectMedia.title,
        description: currentSelectMedia.description,
        imageUrl: currentSelectMedia.imageUrl,
        likes: currentSelectMedia.likes,
        favs: currentSelectMedia.favorites,
      })
    );
  };

  const toggleRepliBox = (id: any) => {
    const repiesBox = document.getElementById(id);
    if (repiesBox) {
      if (repiesBox.style.display == "block") {
        repiesBox.style.display = "none";
      } else {
        repiesBox.style.display = "block";
      }
    }
  };

  return (
    <>
      <div className="media--wrapper" style={{ position: "relative" }}>
        {/* close button goes here*/}
        <button
          style={{
            color: "var(--sidebar)",
            fontSize: "1.2rem",
            fontWeight: "600",
            cursor: "pointer",
            position: "absolute",
            top: "1rem",
            right: "1rem",
            background: "none",
            border: "none",
            outline: "none",
          }}
          onClick={() => {
            dispatch(toggleMediaViewer(false));
          }}
        >
          X
        </button>
        {/* close button end here*/}
        {/* left */}
        <div style={{ width: "53%" }}>
          <div className="media--wrapper1">
            <MediaWrapper
              src={currentMedia.imageUrl ? currentMedia.imageUrl : ""}
              type={
                currentMedia.imageUrl
                  ? currentMedia.imageUrl?.split("/")[5]
                  : ""
              }
              width={"100%"}
              height={"100%"}
              wrapper={{ width: "100%", height: "100%" }}
            />
          </div>

          <div className="media--wrapper-container">
            <div className="scrollbar--left">
              <img
                src="../tests/icons/prev.svg"
                onClick={() => {
                  swiper?.slidePrev();
                }}
                alt=""
                style={{ width: "1.2rem" }}
              />
            </div>

            {/* swiper slider goes here */}
            <Swiper
              className="media__view__swiper"
              slidesPerView={4}
              spaceBetween={15}
              onInit={mediaSwiperInit}
            >
              {medias?.map((media: any, ind: any) => {
                return (
                  <SwiperSlide
                    className="media-view-slide"
                    onClick={() => {
                      saveCurrentView(media, ind);
                    }}
                  >
                    <MediaWrapper
                      src={media ? media["imageUrl"] : ""}
                      type={media ? media["imageUrl"]?.split("/")[5] : ""}
                      width={"100%"}
                      height={"100%"}
                      wrapper={{ width: "100%", height: "100%" }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {/* swiper slider end here */}

            <div className="scrollbar--right">
              <img
                src="../tests/icons/next.svg"
                onClick={() => {
                  swiper?.slideNext();
                }}
                style={{ width: "1.2rem" }}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* right */}
        <div className="media--right" style={{ width: "43%" }}>
          {/* 1 */}
          <div className="media--right-text">
            <div className="text-container" style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  className="image-name"
                  style={{ marginTop: "0rem", width: "17rem" }}
                >
                  {currentMedia?.title}
                </p>
                <div className="bounty-container">
                  <div className="image_logo_one">
                    <img
                      src={
                        currentMedia?.likes?.includes(loginData?.userId)
                          ? "./assets/fillHeart.svg"
                          : "../tests/icons/heart.svg"
                      }
                      // src="../tests/icons/heart.svg"
                      onClick={() => {
                        likeMediaAsset();
                      }}
                      style={{ cursor: "pointer" }}
                      alt="no"
                    />
                    <span style={{ color: "var(--orange)" }}>
                      {currentMedia?.likes?.length}
                    </span>
                  </div>
                  <div className="image_logo_two">
                    <img
                      src={
                        currentMedia?.favs?.includes(loginData?.userId)
                          ? "../tests/icons/BookmarkFill.svg"
                          : "../tests/icons/bookmarkOutline.svg"
                      }
                      onClick={() => {
                        bookmarkMediaAsset();
                      }}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="image_logo_three">
                    <img src="../tests/icons/boost.svg" alt="" />
                  </div>
                  <div className="image_logo_four">
                    <img src="../tests/icons/share.svg" alt="" />
                  </div>
                </div>
              </div>
              <p className="description">{currentMedia?.description}</p>
            </div>
          </div>

          {/* 2 */}
          <div className="media--right-box">
            {/* Your box with blue border here */}
            <div className="scrollbar">
              {commentData
                ?.slice()
                .reverse()
                .map((elm: any, ind: any) => {
                  return (
                    <div
                      style={
                        ind === 0
                          ? { position: "relative" }
                          : { position: "relative" }
                      }
                    >
                      <div
                        className="media--right--innerbox--one"
                        style={{
                          position: "relative",
                          zIndex: "999",
                          cursor: "pointer",
                          textAlign:"left",
                        }}
                        onClick={() => {
                          toggleRepliBox(elm?._id);
                        }}
                      >
                        <div className="innerbox--image--one">
                          <img
                            src={elm.userDetails.userImage}
                            style={{ width: "2rem" }}
                            alt=""
                          />
                        </div>
                        <div className="innerbox--name">
                          <p>{elm.userDetails.userName}</p>
                          <img
                            onClick={() => {
                              currentCommentHandle(
                                "comment__input_mediView",
                                elm._id,
                                elm.userDetails.userName
                              );
                            }}
                            src={
                              checkCurrentUserReplyOrNot(
                                elm,
                                elm.userDetails.userName
                              )
                                ? "../tests/icons/reply.svg"
                                : "../tests/icons/reply-border.svg"
                            }
                            alt="reply"
                            style={{
                              position: "absolute",
                              right: "2.5rem",
                              top: "1rem",
                              cursor: "pointer",
                              width: "1rem",
                            }}
                          />
                          <p
                            style={{
                              position: "absolute",
                              right: "2.2rem",
                              fontSize: "0.7rem",
                              color: "var(--orange)",
                            }}
                          >
                            {elm.results && elm.results.length}
                          </p>
                          <img
                            src={
                              !elm?.likes.includes(loginData?.userId)
                                ? "../tests/icons/heart.svg"
                                : "../assets/fillHeart.svg"
                            }
                            alt="none"
                            style={{
                              position: "absolute",
                              right: "1rem",
                              top: "1rem",
                              cursor: "pointer",
                              width: "1rem",
                            }}
                            onClick={() => {
                              likeComment(elm?._id);
                            }}
                          />
                          <p
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              fontSize: "0.7rem",
                              color: "var(--orange)",
                            }}
                          >
                            {elm.likes && elm?.likes.length}
                          </p>
                        </div>
                        <div className="innerbox--description">
                          <p>{elm.mediaComment}</p>
                        </div>
                      </div>
                      {/* replies */}

                      <div
                        className="mediaV__replise"
                        id={elm?._id}
                        style={{
                          // position: "absolute",
                          display: "none",
                          background: "var(--veryDarkblue)",
                          borderEndEndRadius: "1rem",
                          borderEndStartRadius: "1rem",
                          marginTop: "-2rem",
                          zIndex: "-999999999",
                          textAlign:"left",
                        }}
                      >
                        {elm?.results.map((reply: any) => {
                          return (
                            <div
                              className="media--right--innerbox--one"
                              style={{
                                background: "none",
                                height: "auto",
                                paddingBottom: "1rem",
                              }}
                            >
                              <div className="innerbox--image--one">
                                <img
                                  src={reply.userDetails.userImage}
                                  style={{ width: "2rem" }}
                                  alt=""
                                />
                              </div>
                              <div className="innerbox--name">
                                <p>{reply.userDetails.userName}</p>
                              </div>
                              <div className="innerbox--description">
                                <p>{reply?.mediaReplyComment}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="media--right--commentbox">
              {/* Plus button inside the comment box */}
              <div className="media--right--plusbutton">+</div>
              <div className="media--right--textbox">
                <input
                  type="text"
                  value={comment}
                  onChange={(e: any) => {
                    typingComment(e);
                  }}
                  placeholder={"Write here..."}
                  onKeyDown={inputKeyDown}
                  id="comment__input_mediView"
                  onFocus={() => {
                    const input = document.getElementById(
                      "comment__input_mediView"
                    );
                    if (input) {
                      input.style.border = "1px solid grey";
                    }
                  }}
                  onBlur={() => {
                    const input = document.getElementById(
                      "comment__input_mediView"
                    );
                    if (input) {
                      input.style.border = "none";
                    }
                  }}
                />
                <div className="textbox_image_one">
                  <img
                    src="../tests/icons/emoji.svg"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={(e: any) => {
                      isEmojiSection
                        ? setIsEmojiSection(false)
                        : setIsEmojiSection(true);
                    }}
                  />
                </div>
                <div className="textbox_image_two">
                  <img
                    src="../tests/icons/text_share.svg"
                    onClick={(e: any) => {
                      submitComment(e);
                    }}
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={
              isEmojiSection
                ? {
                    display: "block",
                    position: "absolute",
                    zIndex: "999999",
                    top: "12rem",
                    right: "-16rem",
                  }
                : { display: "none" }
            }
          >
            <EmojiPicker
              onEmojiClick={setEmojiWithMessage}
              autoFocusSearch={false}
              emojiStyle={EmojiStyle.NATIVE}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaPreviewDialog;
