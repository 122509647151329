// React Component
import React from "react";
import "./pinbar.css";

const P_Filter = ({ onFilterChange, activeFilter }: any) => {
  const [isDropdown, setDropDown] = React.useState(false);
  // My world page right top side filter change
  const handleFilterChange = (filter: string) => {
    console.log("onFilterChange called", filter);
    onFilterChange(filter);
  };
  console.log("onFilterChange", activeFilter);
  return (
    <React.Fragment>
      <div
        className="p_filter"
        onClick={() => {
          isDropdown ? setDropDown(false) : setDropDown(true);
        }}
      >
        <img src="./tests/icons/filterIcon.svg" alt="" />
        <div style={{ color: "var(--pure)" }} className="filer__label">
          {/* Relevancy */}
          {activeFilter}
        </div>
        <img src="./tests/icons/arrowDW.svg" alt="" />
        {/* filer dropdown goes here */}
        <section
          style={isDropdown ? { display: "block" } : { display: "none" }}
          className="p_filter__dropdown"
        >
          {/* Latest - filter */}
          <li
            onClick={() => handleFilterChange("latest")}
            className={activeFilter === "latest" ? "active" : ""}
          >
            Latest
          </li>
          {/* Oldest - filter */}
          <li
            onClick={() => handleFilterChange("oldest")}
            className={activeFilter === "oldest" ? "active" : ""}
          >
            Oldest
          </li>
          {/* Relevant -filter */}
          <li>Relevant</li>
          {/* Popular - filter */}
          <li>Popular</li>
        </section>
        {/* filer dropdown end here */}
      </div>
    </React.Fragment>
  );
};

export default P_Filter;
