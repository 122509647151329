import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { editMimeType, setBountiSubCategory } from "../../apis/putApis";
import axios from "axios";
import { allMimeTypeListUrl } from "../../apis/getApis";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setText } from "../../app/slices/CheckListStatus";

interface Option {
  name: string;
  id: number;
}

const MultiSelectGlobal = ({
  data,
  label,
  keyValue,
  selectedValues,
  styles,
  bountyId,
}: any) => {
  const [selectSubCategory, setSelectSubCategory] = useState<Option[]>([]); // State for selected subcategories
  const toggleD = useSelector(
    (state: RootState) => state.booleanStatus.isSidebarToggleD // Redux state for sidebar toggle status
  );
  const subCategory = useSelector(
    (state: RootState) => state.booleanStatus.subCategory // Redux state for subcategory
  );
  const isLock = useSelector((state: RootState) => {
    return state.booleanStatus.isLock; // Redux state for lock status
  });

  useEffect(() => {
    setSelectSubCategory([]); // Resetting selected subcategories when subcategory changes
  }, [subCategory]);

  const dispatch = useDispatch(); // useDispatch hook for dispatching actions

  useEffect(() => {
    if (selectedValues) {
      setSelectSubCategory(selectedValues); // Setting selected subcategories initially
    }
  }, [selectedValues]);

  // Function to handle selection of an option
  const onSelect = (selectedList: Option[], selectedItem: Option) => {
    if (keyValue === "value") {
      setSelectSubCategory(selectedList); // Setting selected subcategories
      axios
        .put(`${setBountiSubCategory}/${bountyId}`, {
          subcategories: selectedList,
        }) // Sending API request to set subcategories
        .then((res: any) => {
          dispatch(setText([res.data.checkList.SubCategories, 7])); // Dispatching action to update checklist status
        })
        .catch((err: any) => {});
    }
    if (keyValue === "_id") {
      axios
        .put(`${editMimeType}/${bountyId}`, {
          mimeTypes: selectedList,
        }) // Sending API request to edit MIME types
        .then((res: any) => {
          dispatch(setText([res.data.checkList.Formats, 15])); // Dispatching action to update checklist status
        })
        .catch((err: any) => {});
    }
  };

  // Function to handle removal of an option
  const onRemove = (selectedList: Option[], removedItem: Option) => {
    if (keyValue === "value") {
      setSelectSubCategory(selectedList); // Setting selected subcategories
      axios
        .put(`${setBountiSubCategory}/${bountyId}`, {
          subcategories: selectedList,
        }) // Sending API request to set subcategories
        .then((res: any) => {
          dispatch(setText([res.data.checkList.SubCategories, 7])); // Dispatching action to update checklist status
        })
        .catch((err: any) => {});
    }
    if (keyValue === "_id") {
      axios
        .put(`${editMimeType}/${bountyId}`, {
          mimeTypes: selectedList,
        }) // Sending API request to edit MIME types
        .then((res: any) => {
          dispatch(setText([res.data.checkList.Formats, 15])); // Dispatching action to update checklist status
        })
        .catch((err: any) => {});
    }
  };

  return (
    <Multiselect
      options={data}
      selectedValues={selectSubCategory}
      onSelect={onSelect}
      onRemove={onRemove}
      selectionLimit={keyValue === "_id" ? 10 : 3}
      placeholder={label}
      displayValue={keyValue}
      disable={isLock}
      className={
        keyValue === "_id"
          ? toggleD
            ? "multipleSelectWith__Sidebar_hash hashtagsMulti"
            : "multipleSelectWith__Collapse_hash hashtagsMulti"
          : toggleD
          ? "multipleSelectWith__Sidebar formatesMulti"
          : "multipleSelectWith__Collapse formatesMulti"
      }
    />
  );
};

export default MultiSelectGlobal;
