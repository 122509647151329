import { Badge } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./sidebar.module.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import ArrowRightOutlined from "@mui/icons-material/ArrowRightOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../apis/getApis";
import Header from "../../apis/header";
import { Tooltip } from "antd";
import AntDMenu from "./AntDMenu";
import {
  addSidebarToggleD,
  setProfileDataRedux,
  toggleIsRefresh,
} from "../../app/slices/singleStates";
import {
  BountiManagerIcon,
  BountisIcon,
  BrandBuildIcon,
  CommunitiesIcon,
  MingaeverythingIcon,
  MyworldIcon,
  SearchIcon,
  SubmissionIcon,
} from "../../svgicons";
import { RootState } from "../../app/store";
import axios from "axios";
import NotificationDropDown from "./NotificationDropDown";
import AntDMenuCollapse from "./AntDMenuCollapse";

const CollapSidebar = ({ dynamic }: any) => {
  const dispatch = useDispatch();
  const currentRoutesStage = useSelector(
    (state: RootState) => state.booleanStatus.currentRoutesStage
  );
  const profileDataRedux = useSelector(
    (state: RootState) => state.booleanStatus.profileData
  );
  const isRefresh = useSelector(
    (state: RootState) => state.booleanStatus.isRefresh
  );
  const [profileData, setProfileData] = useState<any>({});
  const [totalNotifications, setTotalNotification] = useState(0);
  const [isNotification, setIsNotification] = useState(false);
  let [isClickOnNotification, setIsClickOnNotification] = useState(0);
  const fetchProfileData = async () => {
    await axios
      .get(getUserProfile, Header)
      .then((res: any) => {
        // console.log("sidebarProfile", res);
        setProfileData(res?.data);
        dispatch(setProfileDataRedux(res.data));
        dispatch(toggleIsRefresh(false));
      })
      .catch((err: any) => {});
  };
  useEffect(() => {
    if (profileDataRedux) {
      fetchProfileData();
    } else {
      fetchProfileData();
    }
  }, []);
  useEffect(() => {
    fetchProfileData();
    console.log("update update update ", isRefresh);
  }, [isRefresh]);
  return (
    <>
      <aside className={`${styles.c_sidebar} collapseSidebar`}>
        <div className={`${styles.c_inner}`}>
          {/* branding section goes here */}
          <section className={`${styles.c_branding} flex-center`}>
            {/* logo */}
            <div className={`${styles.c_logo}`}>L</div>
          </section>
          {/* branding section end here */}

          {/* profile goes here */}
          <div className="flex-center">
            <img
              src={
                dynamic
                  ? profileDataRedux?.userImage || profileData?.userImage
                  : profileDataRedux?.userImage || profileData?.userImage
              }
              alt="profile"
              className={styles.c_profile}
            />
          </div>
          {/* profile end here */}

          {/* notification and message badges */}
          <section className={`${styles.c_badges}`}>
            <div>
              {/* <Badge badgeContent={4} className={styles.badge}> */}
              <MailIcon className={styles.icons} />
              {/* </Badge> */}
            </div>

            <div
              onClick={() => {
                isNotification
                  ? setIsNotification(false)
                  : setIsNotification(true);
                setIsClickOnNotification(isClickOnNotification + 1);
              }}
              style={{ cursor: "pointer" }}
            >
              <Badge badgeContent={totalNotifications} className={styles.badge}>
                <NotificationsIcon className={`${styles.icons}`} />
              </Badge>
            </div>
          </section>

          {/* diff-area */}
          <section className={`${styles.c_diff} flex justify-center`}>
            <div className={styles.c_stats}>
              <b className={`${styles.highlight}`}>
                <img
                  src={dynamic ? "../assets/coins.svg" : "./assets/coins.svg"}
                  style={{ width: "2em" }}
                />
              </b>
              <b className={`${styles.st_text}`}>Coins</b>
            </div>

            <div className={styles.toggleB}>
              <ArrowRightOutlined
                onClick={() => {
                  dispatch(addSidebarToggleD(true));
                }}
                className={styles.toggleBIcon}
              />
            </div>
          </section>

          {/* links */}
          <div className={styles.c_linksList}>
            <Link to={"/home"} className={`${styles.c_links}`}>
              <MyworldIcon />
            </Link>
            <Link to={"/mySubmisisons"} className={`${styles.c_links}`}>
              <SubmissionIcon />
            </Link>
            <Link to={"/viewBounties"} className={`${styles.c_links}`}>
              <BountisIcon />
            </Link>
            <Tooltip title="COMING SOON">
              <Link to={"#"} className={`${styles.c_links}`}>
                <CommunitiesIcon />
              </Link>
            </Tooltip>

            <Link
              to={"/creator-bouties-list"}
              className={`${styles.c_links}`}
              style={{ paddingLeft: "0.9em" }}
            >
              <BountiManagerIcon />
            </Link>
            <Tooltip title="COMING SOON">
              <Link to={"#"} className={`${styles.c_links}`}>
                <BrandBuildIcon />
              </Link>
            </Tooltip>
            <Link to={"/profile"} className={`${styles.c_links}`}>
              <MingaeverythingIcon />
            </Link>
          </div>
          {/* <ul className={`${styles.linkList_P}`}> */}
          {/* <ul className={`${styles.linkList_P}`}>
            <AntDMenuCollapse />
          </ul> */}
          {/* </ul> */}
        </div>

        {/* bottom */}
        <section className={`${styles.c_sidebar_bottom}`}>
          <div>
            <InfoOutlinedIcon
              style={{ color: "var(--pure)", fontSize: "0.9em" }}
            />
          </div>
          <div className={`${styles.pureBG}`}>
            <SearchIcon />
          </div>
        </section>
        <NotificationDropDown
          isNotification={isNotification}
          setIsNotification={setIsNotification}
          setTotalNotification={setTotalNotification}
          isClickOnNotification={isClickOnNotification}
        />
      </aside>
    </>
  );
};

export default CollapSidebar;
