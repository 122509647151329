import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// initialize states
const initialState: any = [];

// Create a slice for managing media uploader data
export const mediaUploaderData = createSlice({
  name: "mediaUploaderData", // Slice name
  initialState, // Initial state
  reducers: {
    addFilesData: (state: any, action: PayloadAction<any>) => {
      state.push(action.payload);
    },
    removeMedia: (state: any, action: PayloadAction<any>) => {
      state?.splice(action.payload, 1);
    },
    editTitle: (state: any, action: PayloadAction<any>) => {
      state[action.payload[0]].title = action.payload[1];
      state[action.payload[0]].edited = true;
    },
    editDescription: (state: any, action: PayloadAction<any>) => {
      state[action.payload[0]].description = action.payload[1];
      state[action.payload[0]].edited = true;
    },
    resetNewElement: (state: any, action: PayloadAction<any>) => {
      state.length = 0;
      let arr: [] = action.payload;
      console.log(arr, "arrr Updated Elements");
      if (arr) {
        arr.forEach((element) => {
          console.log(element, "element Updated Elements");
          state.push(element);
        });
      }
    },
    // Reducer to clear media uploader data
    clearMediaUploaderData: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

// Export action creators
export const {
  addFilesData,
  removeMedia,
  editTitle,
  editDescription,
  resetNewElement,
  clearMediaUploaderData,
} = mediaUploaderData.actions;

// Export reducer
export default mediaUploaderData.reducer;
