import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { SwiperSlide, Swiper } from "swiper/react";
import axios from "axios";
import { updateAnnouncementDesc } from "../../apis/putApis";
import { likeAnouncement } from "../../apis/postApis";

import Header from "../../apis/header";
import { getAnnouncementDescription } from "../../apis/getApis";
import { D, U } from "../../apis/main";
import { createAnnouncement } from "../../apis/postApis";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addCurrentImageCardInd,
  addIsDeleteAssetId, addIsDeleteMainId,
  addIsMediaDeletePopup,
  setAnnonceData
} from "../../app/slices/singleStates";
import {removeMedia} from "../../app/slices/mediaUploaderData";
const AnnouncementPop = ({
  setToggle,
  setIsAlertSuccess,
  setIsAlertError,
  // currentSelectAnnouncementId,
  // innerAnnounceId,
  bountyId,
  setAnnouncementList,
  isToggle,
  mode,
}: any) => {
  // localstate
  const dispatch = useDispatch();
  const { announcement } = useSelector((state: any) => state.booleanStatus);
  // console.log(announcement, "1252342");
  const [swiper, setSwiper] = React.useState<any>();
  const [textArea, setTextArea] = React.useState<any>(
    announcement?.description || ""
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mainLikeStatus, setMainLikeStatus] = useState<boolean>(false);
  const [mainLikes, setMainLikes] = useState<boolean>(false);
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);
  const [filesUploadedtoshow, setFilesUploadedtoshow] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentSelectAnnouncementId, setCurrentSelectAnnouncementId] =
    useState<any>("");
  const [innerAnnounceId, setInnerAnnounceId] = useState<any>("");
  useState<any>("");
  // useEffect(() => {
  //   if (announcement) {
  //     setTextArea(announcement.description || "");
  //   }
  // }, [announcement]);
  // // Modify your useEffect to set filesUploaded state when announcement changes
  // useEffect(() => {
  //   if (announcement) {
  //     setTextArea(announcement.description || "");
  //     if (announcement.mediaData && announcement.mediaData.length > 0) {
  //       const files = announcement.mediaData.map((url: any) => {
  //         // Create a File object from the URL
  //         // const filename = url.substring(url.lastIndexOf('/') + 1);
  //         // Modify your code to extract the filename correctly
  //         const filename = url
  //           .substring(url.lastIndexOf("/") + 1)
  //           .split("?")[0];

  //         console.log(filename, "filenamemanf");
  //         return new File([url], filename);
  //       });
  //       console.log(files, "filenamemanf2");
  //       setFilesUploaded(files);
  //     }
  //   }
  // }, [announcement]);
  // const urls = announcement.mediaData;

  // Function to create a FileList-like object from an array of File objects
  // function createFileList(files: any) {
  //   console.log(files,"files URL")
  //   const dataTransfer = new DataTransfer();
  //   files.forEach((file: any) => {
  //     dataTransfer.items.add(file);
  //   });
  //   console.log(dataTransfer,"dataTransfer URL")
  //   return dataTransfer.files;
  // }

  // async function convertToFiles(urls:any) {
  //   try {
  //     const promises = urls.map(async (url:any) => {
  //       const response = await axios.get(url, { responseType: 'blob' });
  //       const blob = response.data;
  //       return new File([blob], url.substring(url.lastIndexOf('/') + 1));
  //     });
  //     const files = await Promise.all(promises);
  //     return createFileList(files);
  //   } catch (error) {
  //     console.error("Error converting to files URL:", error);
  //     throw error;
  //   }
  // }

  useEffect(() => {
    if (announcement) {
      console.log("Announcemnet data received v:", announcement);
      setTextArea(announcement.description || "");

      // console.log("ssst:", announcement.mediaData);

      //  convertToFiles(announcement.mediaData)
      //     .then((fileList) => {
      //       console.log(fileList, "fileList URL");
      //       // Now you have a FileList object containing the files fetched from the URLs
      //     })
      //     .catch((error) => {
      //       console.error("Error converting to files URL:", error);
      //     });

      // setFilesUploaded(image_adv_list || []);
      // announcement.mediaData.map()
      setFilesUploadedtoshow(announcement.mediaData)
    } else {
      setFilesUploadedtoshow([])
      console.log("URL No announcement data received.");
    }
  }, [announcement]);

  // assests upload function
  const uploadAnnouncementAsset = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      console.log(Array.from(files), "filessss upload");
      const filess = filesUploaded
      // filess.push(Array.from(files))
      setFilesUploaded(Array.from(files));
      // For example, you can iterate over the files array to access each file
          await (async () => {
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              await new Promise<void>((resolve, reject) => {
                setFilesUploadedtoshow((prevFiles) => [...prevFiles, URL.createObjectURL(file)]);
                console.log(file, "fileeee upload");
                resolve();
              });
            }
          })();
    }
  };

  // annoucement save function
  const handleSaveAnnouncement = async () => {
    setIsLoading(true);
    try {
      if (mode === "create") {
        console.log("Creating a new announcement...");

        const createResponse = await axios.post(
          `${createAnnouncement}/${bountyId}`,
          {},
          Header
        );

        console.log("Response after creating announcement:", createResponse);

        if (
          createResponse.data &&
          createResponse.data.announcements &&
          createResponse.data.announcements[0]
        ) {
          const newAnnouncement = createResponse.data;
          setCurrentSelectAnnouncementId(newAnnouncement._id);
          setInnerAnnounceId(createResponse.data.announcements[0]._id);
          setIsLoading(false);
          console.log("New announcement created:", newAnnouncement);

          // Save description
          console.log("Saving description...");
          await axios.put(`${updateAnnouncementDesc}/${newAnnouncement._id}`, {
            description: textArea,
          });

          console.log("Description saved successfully.");

          // Check if any files are uploaded
          if (filesUploaded.length === 0) {
            // No files uploaded, show success message directly
            setIsAlertSuccess(true);
            setTimeout(() => {
              setIsAlertSuccess(false);
            }, 5000);
            closeThisPopup();
            return; // Exit the function
          }

          // Upload files
          console.log("Uploading files upload...");
          const formData = new FormData();
          console.log("Files to upload:", filesUploaded);
          filesUploaded.forEach((file) => {
            console.log(file,"filesUploaded upload ")
            formData.append("media", file);
          });

          console.log("FormData object upload:", formData);

          await axios.post(
            `${D}/announcement/uploadMedia/${bountyId}/${U}/${newAnnouncement._id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          console.log("Files uploaded successfully upload.");
        }
        closeThisPopup();
        setIsAlertSuccess(true);
        setTimeout(() => {
          setIsAlertSuccess(false);
        }, 5000);
      } else {
        // Logic for updating an existing announcement
      }
    } catch (error) {
      console.error("Error saving announcement:", error);
      setIsLoading(false);
      setIsAlertError(true);
      setTimeout(() => {
        setIsAlertError(false);
      }, 5000);
      closeThisPopup();
    }
  };

  // close popup function
  const closeThisPopup = async () => {
    setToggle(false);
    setTextArea("");

    setFilesUploaded([]);
    dispatch(setAnnonceData(null));
    await axios
      .get(`${D}/get/announcemetList/${U}/${bountyId}`)
      .then((response: any) => {
        console.log("fetchAnnoucement", response);
        setAnnouncementList(response.data);
      })
      .catch((error: any) => {});
  };

  // media swiper init
  const mediaSwiperInit = (e: any) => {
    setSwiper(e);
  };

  // when file select to upload
  const openFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.log("File input not found");
    }
  };

  return (
    <>
      <div className="annoucemnet__popup">
        {/* annoucement body goes here */}
        <div className="annoucement__popup__body flex justify-between">
          <div className="annoucement__popup__body__left">
            <textarea
              className="anc__subbmission__desc"
              spellCheck={false}
              style={{ resize: "none" }}
              value={textArea}
              placeholder="write announcement description here"
              onChange={(e) => {
                setTextArea(e.target.value);
              }}
            />
          </div>

          {/* media upload */}
          <div className="annoucement__popup__body__right annoucement__media__view">
            {/* main asset */}

            <div className="ancmnt__main__media__frame" onClick={openFile}>
              <></>
              {filesUploadedtoshow.length > 0 ? (
                <div className="uploaded-file">
                  {/* Display the image at the first index */}
                  <img
                    src={filesUploadedtoshow[0]}
                    style={{
                      maxWidth: "63%",
                      borderRadius: "1rem",
                      marginRight: "1rem",
                    }}
                  />
                </div>
              ) : (
                <img
                  src="./assets/upload.svg"
                  alt="unload"
                  style={{ width: "10rem", borderRadius: "1rem" }}
                />
              )}
              <input
                type="file"
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  visibility: "hidden",
                }}
                ref={fileInputRef}
                id="file__announcement"
                multiple={true}
                onChange={uploadAnnouncementAsset}
                accept="image/*"
              />
            </div>

            {/* media slider */}
            <div
              className="ancmnt__main__media__swiper flex"
              style={{ marginTop: "1rem" }}
            >
              <img
                src="./assets/backward.svg"
                alt="prev"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  swiper?.slidePrev();
                }}
              />

              {filesUploadedtoshow.length > 1 ? (
                <Swiper
                  className="annoucement__swiper"
                  slidesPerView={3}
                  spaceBetween={15}
                  onInit={mediaSwiperInit}
                  style={{ paddingLeft: "1rem" }}
                >
                  {filesUploadedtoshow.map((file: string, index: number) => (
                    <SwiperSlide
                      className="annoucement-slide"
                      key={index}
                      onClick={openFile}
                    >
                      {/* <div className="FM__IC__header">
                        <button
                            style={{border: "none"}}
                            className="FM__IC__Remove"
                        >
                          <b>-</b>
                        </button>
                      </div> */}
                      <img
                        src={file}
                        style={{ width: "100%", borderRadius: "1rem" }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <Swiper
                  className="annoucement__swiper"
                  slidesPerView={3}
                  spaceBetween={15}
                  onInit={mediaSwiperInit}
                  style={{ paddingLeft: "1rem" }}
                >
                  <SwiperSlide className="annoucement-slide" onClick={openFile}>
                    <img
                      src="./assets/upload.svg"
                      alt="upload"
                      style={{ width: "2rem", borderRadius: "1rem" }}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="annoucement-slide" onClick={openFile}>
                    <img
                      src="./assets/upload.svg"
                      alt="upload"
                      style={{ width: "2rem", borderRadius: "1rem" }}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="annoucement-slide" onClick={openFile}>
                    <img
                      src="./assets/upload.svg"
                      alt="upload"
                      style={{ width: "2rem", borderRadius: "1rem" }}
                    />
                  </SwiperSlide>
                </Swiper>
              )}

              <img
                src="./assets/forward.svg"
                alt="next"
                style={{ cursor: "pointer", marginLeft: "1rem" }}
                onClick={() => {
                  swiper?.slideNext();
                }}
              />
            </div>
            {/* media slider end */}
          </div>
          {/* media uplodad end */}
        </div>
        {/* annoucement body end here */}

        {/* close button goes here */}
        <div
          className="close__anoucement_btn"
          onClick={() => {
            closeThisPopup();
           
          }}
        >
          X
        </div>
        {/* close button end here */}

        <div className="button">
          {/* <!-- Save button --> */}
          <button
            className="announcement__popup__SaveBTN"
            onClick={handleSaveAnnouncement}
            disabled={isLoading}
          >
            {mode === "create" ? "Save" : "Update"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AnnouncementPop;
