const userData: any = localStorage.getItem("loginData");

const D = "https://mingalinks.com:5000/v1";
// const D = "http://localhost:5000/v1";
// const D = "https://bountihunt.com:5000/v1";
let U = "";
let WD: string = "https://mingalinks.com:5000";
// let WD: string = "https://bountihunt.com:5000";

if (userData) {
  U = JSON.parse(userData).userId;
}

export { D, U, WD };
