import axios from "axios";
import { isCurrentLike } from "../apis/getApis";
import Header from "../apis/header";
import { bookMarkCurrentBounti, likeCurrentBounti } from "../apis/postApis";
import { Empty } from "antd";

// like current bounti
const likeCurrentBountiNow = async (
  currentComponent: any,
  setcurrentComponent: any,
  reduxBountyId: any
) => {
  setcurrentComponent({ ...currentComponent, isLiking: true });
  await axios
    .post(`${likeCurrentBounti}/${reduxBountyId}`, Empty, Header)
    .then((response) => {
      // get like status
      console.log("likeStatus", response);
      axios
        .get(`${isCurrentLike}/${reduxBountyId}`)
        .then((res) => {
          console.log("likesFromPreview",res)
          setcurrentComponent({ ...currentComponent, isLiking: false });
          setcurrentComponent({
            ...currentComponent,
            isMeLikeBouti: res?.data.likeStatus,
            totalBoutiLikes: res?.data.likeStatus
              ? currentComponent?.totalBoutiLikes + 1
              : currentComponent?.totalBoutiLikes - 1,
          });
        })
        .catch((err) => {});
    })
    .catch((err) => {
      console.log("likeStatus", err);
      setcurrentComponent({ ...currentComponent, isLiking: false });
    });
};

// bookmark current bounti
const bookmarkCurrentBountiNow = async (
  currentComponent: any,
  setcurrentComponent: any,
  reduxBountyId:any
) => {
  setcurrentComponent({ ...currentComponent, isMarking: true });
  axios
    .post(`${bookMarkCurrentBounti}/${reduxBountyId}`, Empty, Header)
    .then((res) => {
      setcurrentComponent({
        ...currentComponent,
        isMeMarkBouti: res?.data.message === "favorite" ? true : false,
        isMarking: false,
      });
      console.log(res?.data.message);
    })
    .catch((err) => {
      setcurrentComponent({ ...currentComponent, isMarking: false });
      console.log(err);
    });
};

export { likeCurrentBountiNow, bookmarkCurrentBountiNow };
