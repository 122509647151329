import { useDispatch } from "react-redux"
import TrieCheck from "./TrieCheck"
import "./checklist.css"

const Todo = ({label,color,id,isChecked,isRequired}:any) => {
  
  return (
    <div className="checklist__todo" style={{background:color}}>
      <div className="checklist__label">{label}</div>
      <TrieCheck id={id} isChecked={isChecked} isRequired={isRequired}/>
    </div>
  )
}

export default Todo