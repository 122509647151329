import React from "react";

const AlertPopup = ({ isAlert, close, message }: any) => {
  return (
    <>
      {/* mediaUploader Dialog Box goes here */}
      <div
        className="flex"
        style={
          isAlert
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "999999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--dialogOverlay)",
              }
            : { display: "none" }
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {/* <div className="mediaAsset__delete__popup flex items-center">
            <p className="close__FM_message" style={{ fontSize: "1.3rem" }}>
              {message}
            </p>
            <button className="close__FM_alert" onClick={()=>{close()}}>X</button>
          </div> */}
          <div className="mediaAsset__delete__popup">
            <div className="close_BTNmediaAsset__delete__popup">
              <button onClick={()=>{close()}}>x</button>
            </div>
            <p style={{ marginTop: "0.5remrem" }}>
         
               
            {message}
            </p>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertPopup;
