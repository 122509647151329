import { useEffect, useState } from "react";
import { FluidContainer, Footer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import "./auth.css";
import LoginDialog from "./LoginDialog";
import CapthaDialog from "./CapthaDialog";
import RegisterConfirm from "./RegisterConfirm";
import RegisterDialog from "./RegisterDialog";
import {
  toggleRegisterDialogCreation,
  toggleLoginDialogCreation,
} from "../../app/slices/singleStates";
import HomeMasonary from "../Dashboard/components/HomeMasonary/HomeMasonary";
import { Button, Tooltip } from "antd";
import FacebookIcon from "@mui/icons-material/Facebook";
import ForgotScreen from "./ForgotScreen";
import ForgotConfirm from "./ForgotConfirm";
import ResetDialog from "./ResetDialog";
import { getLoginStats } from "../../apis/getApis";
import axios from "axios";

const Login = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [stats, setStats] = useState<any>({});
  const isLoginDialog = useSelector(
    (state: RootState) => state.booleanStatus.isLoginDialog
  );
  const isCaptcha: any = useSelector((state: RootState) => {
    return state.booleanStatus.isCaptcha;
  });

  const isRegisterDialog = useSelector(
    (state: RootState) => state.booleanStatus.isRegisterDialog
  );

  const isConfirmRegister = useSelector(
    (state: RootState) => state.booleanStatus.isConfirmRegister
  );
  const isFormateEmailDialog = useSelector(
    (state: RootState) => state.booleanStatus.isFormateEmailDialog
  );
  const isForgotConfirm = useSelector(
    (state: RootState) => state.booleanStatus.isForgotConfirm
  );
  const isResetDialog = useSelector(
    (state: RootState) => state.booleanStatus.isResetDialog
  );
  const dispatch = useDispatch();

  // get login status API
  useEffect(() => {
    axios
      .get(getLoginStats)
      .then((res: any) => {
        console.log("loginstat", res);
        setStats(res.data);
      })
      .catch((err: any) => {});
  }, []);

  return (
    <>
      <FluidContainer
        clearUi={true}
        parentStyles={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        fluiOverStyles={{
          height: "auto",
          background: "var(--pure)",
        }}
      >
        <div className="login__inner_container" style={{ width: "97%" }}>
          {/* topbar goes here */}
          <header className="login___header">
            <div className="login___header__left login___left">
              <img src="./tests/Minga Logo.svg" className="login___logo" />
            </div>
            <div className="login___header__right login___right">
              <div className="login__header_tab">
                <button
                  onClick={() => {
                    setCurrentTab(0);
                  }}
                  className={
                    currentTab === 0
                      ? "auth__tab auth__act auth__tab__left"
                      : "auth__tab auth__tab__left"
                  }
                >
                  Discover
                </button>
                <Tooltip title={"coming soon"}>
                  <button
                    onClick={() => {
                      setCurrentTab(1);
                    }}
                    className={
                      currentTab === 1 ? "auth__tab auth__act " : "auth__tab "
                    }
                    disabled={true}
                  >
                    Top Rewards
                  </button>
                </Tooltip>
                <Tooltip title={"coming soon"}>
                  <button
                    onClick={() => {
                      setCurrentTab(2);
                    }}
                    className={
                      currentTab === 2 ? "auth__tab auth__act " : "auth__tab "
                    }
                    disabled={true}
                  >
                    Communities
                  </button>
                </Tooltip>
                <Tooltip title={"coming soon"}>
                  <button
                    onClick={() => {
                      setCurrentTab(3);
                    }}
                    className={
                      currentTab === 3
                        ? "auth__tab auth__act auth__tab__right "
                        : "auth__tab auth__tab__right "
                    }
                    disabled={true}
                  >
                    Charities
                  </button>
                </Tooltip>
                {/* login,singup button */}
                <button
                  className={"auth__login"}
                  onClick={() => {
                    dispatch(toggleLoginDialogCreation(true));
                  }}
                >
                  Login
                </button>
                <button
                  className={"auth__signup"}
                  onClick={() => {
                    dispatch(toggleRegisterDialogCreation(true));
                  }}
                >
                  Sign Up
                </button>
              </div>
              {/* <div className="login__header_sponserOfMonth">
                <span className="login__header_sponserOfMonth__label">
                  Sponser Of the Month
                </span>
                <div className="login__header_sponserOfMonth__image">
                  <img src="./tests/deadpool.webp" alt="sponser image here" />
                </div>
              </div> */}
            </div>
          </header>
          {/* topabar end here */}

          {/* left goes here */}
          {/* <LoginComponent /> */}
          {/* left end here */}

          {/* right goes here */}
          {/* right end here */}
        </div>

        {/* hero secrion goes here */}
        <section className="login__hero__section">
          <h1>
            Where every <span>interaction</span> counts.
          </h1>
          <p className="login__hero__paragraph">
            Minga brings minds, goals and missions together, work with a brand,
            get feedback your own creations.
          </p>
          <button
            className={"auth__signup"}
            style={{ marginTop: "1rem", width: "10rem" }}
            onClick={() => {
              dispatch(toggleRegisterDialogCreation(true));
            }}
          >
            Sign Up for free
          </button>
        </section>
        {/* hero secrion goes end */}

        {/* stats info section goes here */}
        <section
          className="stats__info_section flex justify-between"
          style={{ width: "80%", margin: "3rem auto" }}
        >
          <div
            className="login__stats"
            style={{
              color: "var(--sidebar)",
              width: "32%",
              textAlign: "center",
            }}
          >
            {stats?.open_Bounties} open bounties
          </div>
          <div
            style={{
              color: "var(--golden)",
              width: "32%",
              textAlign: "center",
            }}
            className="login__stats"
          >
            {stats?.communities} Communities
          </div>
          <div
            style={{
              color: "var(--greenType)",
              width: "32%",
              textAlign: "center",
            }}
            className="login__stats"
          >
            {stats?.active_Users} active users
          </div>
        </section>
        {/* stats info section end here */}

        <HomeMasonary isLogin={false} />

        {/* how it works section goes here */}
        <section className="how__it__work">
          <h1 className="how__it__work__heading">How it all works...</h1>
          <h1 className="how__it__work__flexBox flex">
            <div className="how__it__work__flexBox___left how__it__work__flexBox__part">
              <img
                src="./tests/howitwork.png"
                style={{ borderRadius: "1rem", width: "80%" }}
              />
            </div>
            <div className="how__it__work__flexBox___right how__it__work__flexBox__part">
              <div>
                <h1>An Experience Influenced by You</h1>
                <p>
                  Discover content and communities that align with your unique
                  interests while being able to help influence the features and
                  future of Minga. At Minga, you matter.
                </p>
                <button
                  className="auth_btn_2"
                  style={{ width: "15rem", marginTop: "5rem" }}
                >
                  Check out our Communities
                </button>
              </div>
            </div>
          </h1>
        </section>
        <section className="how__it__work how__it__work2">
          <h1 className="how__it__work__flexBox how__it__work__flexBox2 flex">
            <div className="how__it__work__flexBox___right how__it__work__flexBox__part">
              <img
                src="./tests/howit2.png"
                style={{ borderRadius: "1rem", width: "100%" }}
              />
            </div>
            <div className="how__it__work__flexBox___left how__it__work__flexBox__part">
              <div>
                <h1>Engagement that Pays Off</h1>
                <p>
                  Participate in challenges and connect with others to earn
                  exclusive rewards, from unique profile customizations to cash
                  prizes and special access to your favorite brands.
                </p>
                <button
                  className="auth_btn_2"
                  style={{ width: "15rem", marginTop: "5rem" }}
                >
                  Unveil our top Rewards
                </button>
              </div>
            </div>
          </h1>
        </section>
        <section className="how__it__work">
          <h1 className="how__it__work__flexBox flex">
            <div className="how__it__work__flexBox___left how__it__work__flexBox__part">
              <img
                src="./tests/howitwork.png"
                style={{ borderRadius: "1rem", width: "80%" }}
              />
            </div>
            <div className="how__it__work__flexBox___right how__it__work__flexBox__part">
              <div>
                <h1>Transparent Monetization For All</h1>
                <p>
                  Minga turns your everyday interactions into opportunities for
                  earning. Whether you're a user, creator, or brand, you can
                  earn for your engagement in a transparent ecosystem that
                  values your contribution and protects your data.
                </p>
                <button
                  className="auth_btn_2"
                  style={{ width: "15rem", marginTop: "5rem" }}
                >
                  Read our Private Privacy
                </button>
              </div>
            </div>
          </h1>
        </section>
        <section className="how__it__work" style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              dispatch(toggleRegisterDialogCreation(true));
              window.scrollTo(0, 0);
            }}
            className="auth__signup"
            style={{ width: "auto" }}
          >
            Try Minga for Free
          </Button>
        </section>
        {/* how it works section end here */}

        <Footer />

        {/* Login Dialog */}
        {/* <div
        className="flex"
        style={
          true
            ? {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "var(--dialogOverlay)",
                zIndex: "99999",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : { display: "none" }
        }
      >
        <LoginDialog />
      </div> */}

        {/* captcha dialog goes here*/}
        <div
          className="flex global__dialog__minga"
          style={
            isCaptcha
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          {isCaptcha ? <CapthaDialog /> : <></>}
        </div>
        {/* captcha dialog end here*/}
        {/* Login Dialog */}
        <div
          className="flex global__dialog__minga"
          style={
            isLoginDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <LoginDialog />
        </div>

        {/* Registration Dialog */}
        <div
          className="flex global__dialog__minga"
          style={
            isRegisterDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <RegisterDialog />
        </div>

        {/* Confirm Registartion */}
        <div
          className="flex global__dialog__minga"
          style={
            isConfirmRegister
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  // flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <RegisterConfirm />
        </div>

        {/* forgot password screen 1 */}
        <div
          className="flex global__dialog__minga"
          style={
            isFormateEmailDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  // flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <ForgotScreen />
        </div>

        {/* forgot confirm email popup*/}
        <div
          className="flex global__dialog__minga"
          style={
            isForgotConfirm
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  // flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <ForgotConfirm />
        </div>

        {/* reset password*/}
        <div
          className="flex global__dialog__minga"
          style={
            isResetDialog
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "var(--dialogOverlay)",
                  zIndex: "99999",
                  // flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                }
              : { display: "none" }
          }
        >
          <ResetDialog />
        </div>
      </FluidContainer>
    </>
  );
};

export default Login;
