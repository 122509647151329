import { SelectGlobal } from "../../../../../components";
import type { SelectProps } from "antd";
import MultiSelectGlobal from "../../../../../components/MultiSelect/MultiSelectGlobal";

const options: SelectProps["options"] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const handleChange = (value: string[]) => {
  console.log(`selected ${value}`);
};

const SelectorWithHeading: any = ({
  loopCount = 1,
  heading,
  type = 1,
  data,
  label,
  dataOne,
  keyValue,
  selectedValues,
  styles,
  bountyId
}: any) => {
  
  const arrayToMap = Array.from({ length: loopCount }, (_, index) => index);
  if (type === 1) {
    return (
      <div>
        <p
          style={{
            color: "var(--paragraphText)",
            fontSize: "1.2rem",
          }}
        >
          {heading}:
        </p>
        {arrayToMap?.map((elm: any, index: any) => {
          return <SelectGlobal bountyId={bountyId} data={dataOne}/>;
        })}
      </div>
    );
  }
  return (
    <div>
      <p
        style={{
          color: "var(--paragraphText)",
          marginBottom: "0.5rem",
          fontSize: "1.2rem",
        }}
      >
        {heading}:
      </p>
      <MultiSelectGlobal bountyId={bountyId} styles={styles} selectedValues={selectedValues} keyValue={keyValue} data={data} label={label}/>
    </div>
  );
};

export default SelectorWithHeading;
