import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Initial state for the checklist
const initialState: any = [
  {
    number: 1,
    color: "var(--receiveChat)",
    text: "Bounty Title",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 2,
    color: "var(--receiveChat)",
    text: "Description",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 3,
    color: "var(--receiveChat)",
    text: "Bounty Images",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 4,
    color: "var(--receiveChat)",
    text: "Reward",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 5,
    color: "var(--receiveChat)",
    text: "Time Limit",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 6,
    color: "var(--receiveChat)",
    text: "Categories",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 7,
    color: "var(--receiveChat)",
    text: "Sub Categories",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 8,
    color: "var(--receiveChat)",
    text: "Unlocks",
    isChecked: false,
    isRequired: true,
  },
  {
    number: 9,
    color: "var(--receiveChat)",
    text: "Submissions",
    isChecked: false,
    isRequired: true,
  },
  // {
  //   number: 10,
  //   color: "var(--receiveChat)",
  //   text: "Brand",
  //   isChecked: false,
  //   isRequired:false,
  // },
  // {
  //   number: 11,
  //   color: "var(--receiveChat)",
  //   text: "Brand Logo",
  //   isChecked: false,
  //   isRequired:false,
  // },
  {
    number: 12,
    color: "var(--receiveChat)",
    text: "Sponsor",
    isChecked: false,
    isRequired: false,
  },
  {
    number: 13,
    color: "var(--receiveChat)",
    text: "Charity",
    isChecked: false,
    isRequired: false,
  },
  {
    number: 14,
    color: "var(--receiveChat)",
    text: "Tags",
    isChecked: false,
    isRequired: false,
  },
  {
    number: 15,
    color: "var(--receiveChat)",
    text: "Formats",
    isChecked: false,
    isRequired: false,
  },
  {
    number: 16,
    color: "var(--receiveChat)",
    text: "Additional Detail",
    isChecked: false,
    isRequired: false,
  },
  // {
  //   number: 17,
  //   color: "var(--receiveChat)",
  //   text: "Lock Bounty",
  //   isChecked: false,
  //   isRequired: false,
  // },
];

// Create a slice for managing checklist status
export const CheckListStatus = createSlice({
  name: "CheckListStatus",
  initialState,
  reducers: {
    setText: (state: any, action: PayloadAction<any>) => {
      state.forEach((element: any) => {
        if (action.payload[1] === element.number) {
          element.isChecked = action.payload[0];
          if (action.payload[0]) {
            element.color = "var(--veryDarkblue)";
          } else {
            element.color = "var(--receiveChat)";
          }
        }
      });
    },
  },
});

// Export action creators
export const { setText } = CheckListStatus.actions;

// Export reducer
export default CheckListStatus.reducer;
