import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

 // Define initial state
const initialState: any = [
  {
    id: 0,
    tabname: "Announcements",
  },
];

// Create a slice for managing tabs
export const bountiCreatedTabs = createSlice({
  name: "bountiCreatedTabs",
  initialState,
  reducers: {
    // Action to set a new tab
    setTab: (state: any, action: PayloadAction<any>) => {
      state.push({ id: action.payload[0], tabname: action.payload[1] });
    },

    // Action to edit a tab name
    editTabname: (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.forEach((obj: any) => {
          if (obj.id === action.payload[0]) {
            obj.tabname = action.payload[1];
          }
        });
      }
    },

    // Action to delete a tab
    deleteTab: (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.splice(action.payload, 1);
      }
    },
  },
});

// Export action creators
export const { setTab, editTabname, deleteTab } = bountiCreatedTabs.actions;

// Export reducer
export default bountiCreatedTabs.reducer;
