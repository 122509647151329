import styles from "./given.module.css";

const USTCard = ({ children, styling }: any) => {
  return (
    <div className={`${styles.ust_card} r_ust_card`} style={styling}>
      {children}
    </div>
  );
};

export default USTCard;
