const userData: any = localStorage.getItem("loginData");

// get user access_token from localstorage
const Header2: any = {
  headers: {
    authorization: userData
      ? `Bearer ${JSON.parse(userData).access_token}`
      : ``,
    "Content-Type": "multipart/form-data",
  },
};

export default Header2;
