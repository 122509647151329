import axios from "axios";
import { createReward } from "../apis/postApis";
import { fetchRewardCategory, getRewards } from "../apis/getApis";
import { deleteReward } from "../apis/delete";
import { setText } from "../app/slices/CheckListStatus";
import {
  addUploadedRewardMessage,
  toggleRewardDeleteDialog,
  toggleRewardUploadAlert,
} from "../app/slices/singleStates";
import { updateBountyReward } from "../apis/putApis";

async function uploadReward(
  postData: any,
  setPostData: any,
  dispatch: any,
  setLoading: any,
  bountyId: any
) {
  const formData = new FormData();
  formData.append("image", postData?.image);
  formData.append("type", postData?.type);
  formData.append("deliveryMethod", postData?.deliveryMethod);
  formData.append("value", postData?.value);
  formData.append("title", postData?.title);
  formData.append("description", postData?.description);
  formData.append("categories", postData?.categories);

  axios
    .post(`${createReward}/${bountyId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: any) => {
      dispatch(addUploadedRewardMessage("reward uploaded"));
      localStorage.setItem("reward-create", res.data.message._id);
      setPostData({
        categories: "",
        value: "",
        description: "",
        title: "",
        image: "",
        type: "",
        deliveryMethod: "",
      });
      console.log("res.data.CheckListStatus.reward", res.data);
      dispatch(setText([res.data.checkList.Reward, 4]));
      dispatch(toggleRewardUploadAlert(true));

      setTimeout(() => {
        dispatch(toggleRewardUploadAlert(false));
      }, 2300);

      setLoading(false);
    })
    .catch((error: any) => {
      dispatch(addUploadedRewardMessage(error.response?.data.error));
      dispatch(toggleRewardUploadAlert(true));
      console.log("uploaded-Data", error);
      setTimeout(() => {
        dispatch(toggleRewardUploadAlert(false));
      }, 2300);

      setLoading(false);
    });
}

const editReward = async (
  rewardId: any,
  data: any,
  dispatch: any,
  setLoading: any,
  selectedId: any
) => {
  setLoading(true);
  await axios
    .put(`${updateBountyReward}/${selectedId}/${rewardId}`, data)
    .then((response: any) => {
      console.log("RewardEditCode", response.data);
      dispatch(addUploadedRewardMessage(response.data.message));
      dispatch(toggleRewardUploadAlert(true));
      setLoading(false);
      setTimeout(() => {
        dispatch(toggleRewardUploadAlert(false));
      }, 2300);
    })
    .catch((err: any) => {
      dispatch(addUploadedRewardMessage(err.response.data.error));
      dispatch(toggleRewardUploadAlert(true));

      setLoading(false);
      setTimeout(() => {
        dispatch(toggleRewardUploadAlert(false));
      }, 2300);
    });
};

const fetchRewardsCategory = async (setRewardCategory: any, bountyId: any) => {
  await axios
    .get(fetchRewardCategory)
    .then((res: any) => {
      setRewardCategory(res.data);
    })
    .catch((err: any) => {});
};

const fetchAllRewards = async (setRewards: any, selectedId: any) => {
  await axios
    .get(`${getRewards}/${selectedId}`)
    .then((res: any) => {
      console.log("fetchedReward", res);
      setRewards(res.data.rewards);
  })
    .catch((err: any) => {
      console.log("fetchedReward", err);
    });
};

const deleteRewards = async (
  rewardId: string,
  rewardMainId: string,
  setF: any,
  dispatch: any,
  setLoading: any,
  bountyId: any
) => {
  setLoading(true);
  await axios
    .delete(`${deleteReward}/${rewardMainId}/${rewardId}`)
    .then((response) => {
      fetchAllRewards(setF, bountyId);
      dispatch(setText([response.data.checklist.checkList.Reward, 4]));
      dispatch(toggleRewardDeleteDialog(false));
      setLoading(false);
    })
    .catch((error) => {
      window.alert("please try again!");
      setLoading(false);
    });
};

const convertImageToFile = async (
  setImageFile: any,
  url: any,
  bountyId: any
) => {
  try {
    // Fetch the image from the public directory
    const response = await fetch(url);
    const blob = await response.blob();

    // Read the blob as a data URL
    const reader = new FileReader();
    reader.onload = () => {
      // Create a new File object from the data URL
      const convertedFile = new File([blob], "converted_image.jpg", {
        type: blob.type,
      });

      // Set the new File object in the state
      setImageFile(convertedFile);
    };

    reader.readAsDataURL(blob);
  } catch (error) {
    console.error("Error converting image to file:", error);
  }
};

export {
  uploadReward,
  fetchRewardsCategory,
  fetchAllRewards,
  deleteRewards,
  convertImageToFile,
  editReward,
};
