import React, { useEffect, useState, useRef } from "react";
import "./profile.css";
import StatCard from "./StatCard";
import axios from "axios";
import {
  categoryRemove,
  categorySelect,
  profileUpdate,
} from "../../apis/putApis";
import {
  getAllCategoryList,
  getAllSubCategoryList,
  getUserProfile,
  profileSubCategory,
  profileWithUID,
  getCategoryList,
  getCategoryNew,
  getSubNew,
  getXpPoints,
} from "../../apis/getApis";
import Header from "../../apis/header";
import { Skeleton, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Header2 from "../../apis/header-multi";
import Multiselect from "multiselect-react-dropdown";
import {
  toggleIsRefresh,
} from "../../app/slices/singleStates";
import { U } from "../../apis/main";

import { isNull } from "lodash";
import { Console } from "console";
// local states...

const UserCard = ({ isDynamicRoute, profileStats }: any) => {
  const [username, setUsername] = useState("");
  const [tagline, setTagline] = useState("");
  const [category, setCategory] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categoryId, setCategoryId] = useState([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [sucessMessage, setSucessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bio, setBio] = useState("");
  const [multiSelectKey, setMultiSelectKey] = useState(0);
  const [file, setFile] = useState(null);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [isLoading, setIsloading] = useState(false);
  const [completeCategoryList, setCompleteCategoryList] = useState<any>([]);
  const [xpPoints, setXpPoints] = useState<any>([]);
  const [completeSubCategoryList, setCompleteSubCategoryList] = useState<any>(
    []
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [profileDetail, setProfileDetail] = useState<any>({});
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertError, setIsAlertError] = useState(false);
  const [instances, setInstances] = useState([1]);
  const [lastSelectedId, setLastSelectedId] = useState("");
  const [lastSelectedCategoryIdName, setLastSelectedCategoryName] = useState(
    []
  );
  const [lastSelectedCategoryIdfromgetid, setLastSelectedCategoryIdfromgetId] =
    useState("");
  const [userProfileData, setProfileData] = useState<any>([]);
  const dispatch = useDispatch();

  const addInstance = () => {
    setInstances([...instances, instances.length + 1]);
  };

  // for XP Points get
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${getXpPoints}`);
        console.log(response.data.points, "points");
        setXpPoints(response.data.points);
      } catch (error: any) {
        console.error("Error fetching XP data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAlertSuccess(false);
      setIsAlertError(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isAlertSuccess, isAlertError]);

  const SubCat = () => {
    let newArray: any = [];
    selectedSubCategory?.forEach((elm: any) => {
      newArray.push(elm?.value);
    });
    return (
      <div
        style={{
          color: "var(--orange)",
          marginTop: "0.5rem",
          fontSize: "0.8rem",
        }}
      >
        {newArray?.join(" , ")}
      </div>
    );
  };

  // open hidden input file manager for profile image
  const openFileManager = (e: any) => {
    const uploadProfile: any = document.getElementById("upload__profile");
    if (uploadProfile) {
      uploadProfile.click();
    }
  };

  // upload your avtar
  const uploadYourAvtar = async (e: any) => {
    await axios
      .put(`${profileUpdate}`, { image: e.target.files[0] }, Header2)
      .then(() => {})
      .catch((err) => {});
    setProfileImage(URL.createObjectURL(e.target.files[0]));
  };

  // update category
  // const updateCategory = async (value: any) => {
  //   console.log("updateCategory called");
  //   await axios
  //     .put(`${profileUpdate}`, { categories: value }, Header)
  //     .then()
  //     .catch((err) => {});
  //   // Call fetchSubCategory with the new categoryId
  //   // fetchSubCategory(value);
  //   console.log("categoryvalue_update", value);
  // };
  // fetch profile data
  // const fetchProfileData = async () => {
  //   setIsloading(true);
  //   await axios
  //     .get(getUserProfile, Header)
  //     .then((res: any) => {
  //       console.log("get fetchprofile called");
  //       setIsloading(false);
  //       console.log("PROFILEDATA", res.data);
  //       console.log("res.data.userImage.length", res.data.userImage.length);

  //       // set tagline
  //       // if (res.data.tagline.length === 0) {
  //       //   setTagline("Write your tagline here.");
  //       // } else {
  //       setTagline(res?.data?.tagline);
  //       // }

  //       // set userBio
  //       // if (res.data.userBio.length === 0) {
  //       //   setBio("Write your bio here...");
  //       // } else {
  //       setBio(res?.data?.userBio);
  //       // }

  //       // set userName
  //       // if (res.data.userName.length === 0) {
  //       //   setUsername(res?.data?.userName);
  //       // } else {
  //       setUsername(res?.data?.userName);
  //       // }

  //       // set categories
  //       // if (res.data.categories.length === 0) {
  //       //   setCategory("Please select category");
  //       // } else {
  //         setCategory(res?.data?.categories.categories);
  //         setCategoryId(res?.data?.categories._id);
  //       // }

  //       // set subCategories
  //       // if (res.data.subCategories.length === 0) {
  //       //   setSelectedSubCategory([]);
  //       // } else {
  //         let obj: any = [];
  //         res.data.subCategories.forEach((elm: any) => {
  //           obj.push({ value: elm });
  //         });
  //         setSelectedSubCategory(obj);
  //       // }

  //       // set userimage
  //       // if (res.data.userImage.length === 0) {
  //       //   setProfileImage(() => {
  //       //     return !isDynamicRoute
  //       //       ? "./tests/profile.png"
  //       //       : "../tests/profile.png";
  //       //   });
  //       // } else {
  //         setProfileImage(res?.data?.userImage);
  //     //   }
  //     })
  //     .catch((err: any) => {
  //       console.log("catch fetchprofile called");
  //       // setTagline("Write your tagline here.");
  //       // setBio("Write your bio here...");
  //       // setUsername("Username");
  //       // setCategory("Please select category");
  //       // setProfileImage(() => {
  //       //   return !isDynamicRoute
  //       //     ? "./tests/profile.png"
  //       //     : "../tests/profile.png";
  //       // });
  //       setIsloading(false);
  //     });
  // };

  // Get Profile Data
  const fetchProfileData = async () => {
    setIsloading(true);
    try {
      const response = await axios.get(getUserProfile, Header);
      console.log("get fetchprofile called");
      setIsloading(false);
      console.log("PROFILEDATA", response.data);
      setProfileData(response.data);
      console.log("res.data.userImage.length", response.data.userImage.length);
      // set tagline
      setTagline(response?.data?.tagline);
      // set userBio
      setBio(response?.data?.userBio);
      // set userName
      setUsername(response?.data?.userName);

      const fetchCategories = async () => {
        try {
          const response = await axios.get(`${getCategoryNew}?userId=${U}`);
          // Map over response.data.categories to extract 'value' from each category
          const categoryValues = response.data.categories;
          console.log(
            categoryValues,
            "categoryValues lastSelectedCategoryIdName"
          );
          const lastSelectedCategoryIdName = categoryValues.map(
            (item: any) => item.categories
          );
          const lastSelectedCategoryIdfromgetid =
            categoryValues[categoryValues.length - 1]._id;
          console.log(lastSelectedCategoryIdName, "lastSelectedCategoryIdName");
          setLastSelectedCategoryName(lastSelectedCategoryIdName);
          setLastSelectedCategoryIdfromgetId(lastSelectedCategoryIdfromgetid);
          // Update the category state with the extracted values
          setCategory([...category, ...response.data.categories]);
          console.log(categoryValues, "categoryValues"); // Log the extracted values

          const fetchsubCategories = async () => {
            try {
              console.log(
                response.data.categories[categoryValues.length - 1]._id,
                "ap102"
              );
              const responses = await axios.get(`${getSubNew}?userId=${U}`);
              console.log(responses, "responseresponse");
              setSubCategories([
                ...subCategories,
                ...responses.data.subcategories,
              ]);
            } catch (error) {
              console.error("Error fetching subcategories:", error);
            }
          };

          fetchsubCategories();
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };

      fetchCategories();
      // set userimage
      if (response.data.userImage.length === 0) {
        setProfileImage(() => {
          return !isDynamicRoute
            ? "./tests/profile.png"
            : "../tests/profile.png";
        });
      } else {
        setProfileImage(response?.data?.userImage);
      }
    } catch (error) {
      console.error("Error fetchprofile profile data:", error);
      // setCategory("Please select category");
      setProfileImage(() => {
        return !isDynamicRoute ? "./tests/profile.png" : "../tests/profile.png";
      });
      // Handle errors here, e.g., set default values or display an error message to the user
    } finally {
      setIsloading(false);
    }
  };

  // Get Category List
  const fetchCategoriesList = async () => {
    await axios
      .get(getCategoryList)
      .then((res: any) => {
        console.log("completecategory", res);
        // Transform response data to options format required by react-select
        const categoryOptions = res.data;
        console.log(categoryOptions, "categoryOptions");
        setCompleteCategoryList(categoryOptions);
      })
      .catch((err: any) => {});
  };

  // Get Subcategory List
  const fetchSubCategory = async (value: any) => {
    {
      console.log(value, "value");
    }
    const categoriesParam = lastSelectedCategoryIdName.join(",");
    console.log(categoriesParam, "categoriesParam lastSelectedCategoryIdName");
    await axios
      .get(
        `${profileSubCategory}?categories=${encodeURIComponent(
          categoriesParam
        )}`
      )
      .then((res: any) => {
        console.log("subCat", res);
        setCompleteSubCategoryList(res.data.subValues);
        console.log(res.data.subValues, "subCatygeoryOptions");
      })
      .catch((err: any) => {
        console.log(err, "erooorrrrr");
      });
  };

  // fetch subcategory according to category
  useEffect(() => {
    console.log(category, "category_useEffect");
    fetchSubCategory(category);
  }, [category]);

  //update user name
  // useEffect(() => {
  //   const getData = setTimeout(async () => {
  //     await axios
  //       .put(`${profileUpdate}`, { userName: username }, Header)
  //       .then()
  //       .catch((err) => {});
  //   }, 2000);
  //   return () => clearTimeout(getData);
  // }, [username]);

  // //update user name
  // useEffect(() => {
  //   const getData = setTimeout(async () => {
  //     await axios
  //       .put(`${profileImage}`, { profileImage: profileImage }, Header)
  //       .then()
  //       .catch((err) => {});
  //   }, 2000);
  //   return () => clearTimeout(getData);
  // }, [profileImage]);

  // // update tagline
  // useEffect(() => {
  //   const getData = setTimeout(async () => {
  //     await axios
  //       .put(`${profileUpdate}`, { tagline: tagline }, Header)
  //       .then()
  //       .catch((err) => {});
  //   }, 2000);
  //   // return () => clearTimeout(getData);
  // }, [tagline]);

  // // update bio textarea
  // useEffect(() => {
  //   const getData = setTimeout(async () => {
  //     await axios
  //       .put(`${profileUpdate}`, { userBio: bio }, Header)
  //       .then()
  //       .catch((err) => {});
  //   }, 2000);
  //   return () => clearTimeout(getData);
  // }, [bio]);

  // useEffect
  React.useEffect(() => {
    fetchProfileData();
    fetchCategoriesList();
  }, []);

  //
  const saveSelectedSubCategory = async (e: any) => {
    let sendArr: any = [];
    e?.forEach((elm: any) => {
      sendArr.push(elm.value);
    });

    try {
      // Update subCategories in the state
      setSelectedSubCategory(e);

      // Save selected subCategories to the backend
      await axios.put(`${profileUpdate}`, { subCategories: sendArr }, Header);
    } catch (error) {
      console.error("Error saving selected subcategories:", error);
      // Handle error, show error message to user, etc.
    }
  };

  // profile details Save
  const handleSaveProfile = async () => {
    try {
      console.log("handleSaveProfile called");
      const response = await axios.put(
        `${profileUpdate}`,
        {
          userName: username,
          tagline: tagline,
          userBio: bio,
          categoryDetails: category,
          // subCategories: selectedSubCategory.map((item: any) => item.value),
        },
        Header
      );
      console.log("Profile Updated Successfully!", response.data.userProfile);

      dispatch(toggleIsRefresh(true));
      // Update state with userProfile object from response data
      setProfileDetail(response.data.userProfile);
      console.log(profileDetail, "fhug");

      if (response.status === 200) {
        setSucessMessage(response.data.message);
        setIsAlertSuccess(true); // Show the alert
      }
    } catch (error: any) {
      // Specify the type of error as 'any' or the type with 'response' property
      console.error("Error updating profile:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while updating profile.");
      }
      setIsAlertError(true);
      // Handle error, show error message to user, etc.
    }
  };
  // Update profileDetail state after successful API call

  // category Select
  const handleCategorySelect = async (
    selectedList: any,
    selectedCategory: any
  ) => {
    console.log(selectedList, "selectedList");
    // const lastId = selectedList[selectedList.length - 1].categories;
    const lastId = selectedList.map((item: any) => item.categories);
    const tempobj = {
      _id: selectedList[selectedList.length - 1]._id,
      categories: selectedList[selectedList.length - 1].categories,
    };
    console.log(lastId, "last id");
    setCategory([...category, tempobj]);
    console.log(category, "ap100");
    console.log(tempobj, "ap101");
    //  const lastSelectedCategoryIdName = categoryValues.map((item:any) => item.categories);
    setLastSelectedCategoryName(lastId);
    // const fetchsubCategories = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${getSubNew}&categoryId=${lastSelectedCategoryIdfromgetid}`
    //     );
    //     console.log(response, "responseresponse");
    //     setSubCategories([...subCategories, ...response.data.subcategories]);
    //   } catch (error) {
    //     console.error("Error fetching subcategories:", error);
    //   }
    // };

    //fetchsubCategories();
    setIsDropdownOpen(false);
    try {
      // Extract category IDs from the selected list
      const lastSelectedCategoryId = selectedList[selectedList.length - 1]._id;
      // console.log(lastSelectedCategoryId, "lastSelectedCategoryId");

      setLastSelectedId(lastSelectedCategoryId);
      // Make API call to update user profile data
      const response = await axios.put(
        `${categorySelect}?categoryId=${lastSelectedCategoryId}`,
        { categoryDetails: selectedList },
        Header
      );

      console.log(
        "Profile data updated successfully categoryIds:",
        response.data
      );
      // Handle success scenario as needed, show success message, update state, etc.
    } catch (error) {
      console.error("Error updating profile data categoryIds:", error);
      // Handle error scenario, show error message, etc.
    }
    // Force the Multiselect component to remount
    setMultiSelectKey((prevKey) => prevKey + 1);
  };

  // Remove Category
  const handleCategoryRemove = async (
    selectedList: any,
    removedCategory: any
  ) => {
    console.log(selectedList, "selectedListselectedList");
    try {
      // Extract category ID of the removed category
      const categoryId = removedCategory._id;
      console.log(categoryId, "categoryId111");

      // Filter out the removed category from the category state
      const updatedCategory = category.filter(
        (cat: any) => cat._id !== categoryId
      );
      console.log("updatedCategory", updatedCategory);
      setCategory(updatedCategory);
      const updatedSubCategories = subCategories.filter(
        (subCategory: any) => subCategory.categoryId !== categoryId
      );
      console.log(subCategories, "subCategories");
      setSubCategories(updatedSubCategories);
      console.log("updatedSubCategories", updatedSubCategories);

      if (selectedList.length > 0) {
        // Update lastSelectedCategoryId to the category of the last item in selectedList
        // const lastCategoryId = selectedList[selectedList.length - 1].categories;
        const lastCategoryId = selectedList.map((item: any) => item.categories);
        setLastSelectedCategoryName(lastCategoryId);

        // Make API call to update user profile data
      } else {
        // If selectedList is empty, set lastSelectedCategoryId to null or any appropriate value
        setLastSelectedCategoryName([]);
        setLastSelectedCategoryIdfromgetId("");
      }
      const response = await axios.put(
        `${categoryRemove}?removeCategoryId=${categoryId}`,
        { categoryDetails: selectedList },
        Header
      );
      console.log(
        "Profile data updated successfully categoryIds:",
        response.data
      );
      //  Update subcategory list state after category removal

      // Handle success scenario as needed, show success message, update state, etc.
    } catch (error) {
      console.error("Error updating profile data categoryIds:", error);
      // Handle error scenario, show error message, etc.
    }
    // Force the Multiselect component to remount
    setMultiSelectKey((prevKey) => prevKey + 1);
  };

  // Subcategory Select as per selected Category
  const handleSubCategorySelect = async (selectedList: any) => {
    console.log(selectedList, "subcategoryyyyy");
    try {
      // Extract category IDs from the selected list
      const subId = selectedList[selectedList.length - 1]._id;
      console.log(subId, "subId");
      setLastSelectedId(subId);

      // Make API call to update user profile data
      const response = await axios.put(
        `${categorySelect}?categoryId=${
          selectedList[selectedList.length - 1].categoryId
        }&subCategoryId=${subId}`,
        { subCategories: selectedList },
        Header
      );
      setSubCategories(selectedList);
      console.log(subCategories, "12345");
      console.log(
        "Profile data updated successfully categoryIds:",
        response.data
      );

      // Handle success scenario as needed, show success message, update state, etc.
    } catch (error) {
      console.error("Error updating profile data categoryIds:", error);
    }
    setMultiSelectKey((prevKey) => prevKey + 1);
  };

  // Remove SubCategory
  const handleSubCategoryRemove = async (
    selectedList: any,
    removedSubCategory: any
  ) => {
    console.log(selectedList, "subcategoryyyyy");
    try {
      // Extract category IDs from the selected list
      const categoryId = removedSubCategory.categoryId;
      const subCategoryId = removedSubCategory._id;
      console.log(removedSubCategory, "subCategoryId111");

      // Make API call to update user profile data
      const response = await axios.put(
        `${categoryRemove}?removeCategoryId=${categoryId}&removeSubCategoryId=${subCategoryId}`,
        { subCategories: selectedList },
        Header
      );
      console.log(
        "Profile data updated successfully categoryIds:",
        response.data
      );

      // Handle success scenario as needed, show success message, update state, etc.
    } catch (error) {
      console.error("Error updating profile data categoryIds:", error);

      // Handle error scenario, show error message, etc.
    }
    setMultiSelectKey((prevKey) => prevKey + 1);
    // return [...subCategories, selectedList.value];
  };
  useEffect(() => {
    if (Object.keys(profileDetail).length > 0) {
      console.log("Profile Detail updated:", profileDetail);
    }
  }, [userProfileData]);
  return (
    <>
      {/* Success Alert */}
      <div>
        {isAlertSuccess && (
          <div className="profile-alert">
            <Alert
              message={sucessMessage}
              type="success"
              showIcon
              closable
              onClose={() => setIsAlertSuccess(false)}
            />
          </div>
        )}
      </div>
      {/* Error Alert */}
      <div>
        {isAlertError && (
          <div className="profile-alert">
            <Alert
              message="erroe"
              type="error"
              showIcon
              closable
              onClose={() => setIsAlertError(false)}
            />
          </div>
        )}
      </div>

      <div className="profile__usercard">
        {/* hidden input */}
        <input
          type="file"
          style={{ width: "0.1px", height: "0.1px", visibility: "hidden" }}
          id="upload__profile"
          onChange={uploadYourAvtar}
          disabled={isDynamicRoute}
        />
        {/* user card left goes here */}
        <div className="profile__usercard__left">
          <div className="profile__userMeta">
            {/* User Name */}
            {!isLoading ? (
              <input
                type="text"
                value={username}
                placeholder="Enter Username"
                className="profile__userName"
                disabled={isDynamicRoute}
                onChange={(e: any) => {
                  setUsername(e.target.value);
                }}
              />
            ) : (
              <Skeleton.Input
                className="profile_username_skeleton"
                active={true}
              />
            )}

            {/* Tagline */}
            {!isLoading ? (
              <input
                type="text"
                placeholder="Write your tagline here."
                value={tagline}
                onChange={(e: any) => {
                  setTagline(e.target.value);
                }}
                className="profile__tagline"
                disabled={isDynamicRoute}
              />
            ) : (
              <Skeleton.Input
                active={true}
                className="profile_tagline_skeleton"
              />
            )}
          </div>
          {/* profile Image */}
          <div
            className="profile__image"
            style={{ cursor: "pointer" }}
            onClick={openFileManager}
          >
            {!isLoading ? (
              <img
                src={profileImage}
                style={{
                  width: "90%",
                  // height: "20.5rem",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px ",
                }}
                alt=""
              />
            ) : (
              <Skeleton.Image
                className="profile__image__skeleton"
                active={true}
              />
            )}
          </div>
          <div className="profile__progress"></div>
          <div className="profile__levelInfo">
            <b>{xpPoints?.points}xp</b> points to <b>Level 1</b>
          </div>

          <div className="profile__statics">
            {/* Submission count */}
            <StatCard num={profileStats?.submission} label={"Submissions"} />
            {/* Bounties won count */}
            <StatCard
              num={profileStats?.bountiesWon}
              label={"Bounties Won"}
              style={{ marginLeft: "1rem" }}
            />
            {/* achievements count */}
            <StatCard
              num={profileStats?.achievements}
              label={"Achievements"}
              style={{ marginLeft: "1rem" }}
            />
          </div>

        </div>
        {/* user card left end here */}

        {/* user card right goes here */}
        <div className="profile__usercard__right">
          {/* <!-- user bio section goes here --> */}
          <div className="profile__usercard__bio">
            {/* <!-- bio header --> */}
            <div className="profile__bio__header">
              {/* <h1>Bio</h1> */}

              <div className="profile__bio__left">
                {/* <p style={{textAlign:'left',fontWeight:'600'}}>Select categories:</p> */}
                <div
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  {/* multiselect category */}
                  <Multiselect
                    key={`category-select-${multiSelectKey}`}
                    options={completeCategoryList}
                    onSelect={(selectedList, selectedCategory) =>
                      handleCategorySelect(selectedList, selectedCategory)
                    }
                    onRemove={(selectedId, removedCategory) =>
                      handleCategoryRemove(selectedId, removedCategory)
                    }
                    selectedValues={category}
                    displayValue="categories"
                    placeholder="Select Interests"
                  />
                </div>
                {/* <p style={{textAlign:'left',fontWeight:'600'}}>Select Subcategories:</p> */}

                {/* multiselect subcategory */}
                <Multiselect
                  key={`subcategory-select-${multiSelectKey}`}
                  options={completeSubCategoryList}
                  onSelect={handleSubCategorySelect}
                  onRemove={(selectedId, removedSubCategory) =>
                    handleSubCategoryRemove(selectedId, removedSubCategory)
                  }
                  selectedValues={subCategories}
                  displayValue="value"
                  placeholder="Select Sub-Interests"
                />
              </div>

              <div
                className="profile__bio__right profile__add__friend"
                style={
                  isDynamicRoute ? { display: "block" } : { display: "none" }
                }
              >
                <div
                  className="flex items-center"
                  style={{ cursor: "pointer" }}
                >
                  <b style={{ color: "var(--orange)" }}>+</b>
                  <img src="../tests/person-border.svg" alt="" />
                </div>
              </div>
            </div>
            {/* <!-- bio header end here--> */}

            {/* <!-- bio description goes here --> */}
            <div className="profile__bio__description">
              {!isLoading ? (
                <textarea
                  className="profile__bio__description__textArea"
                  placeholder="Write your bio here..."
                  style={{ width: "100%", height: "18rem", resize: "none" }}
                  value={bio}
                  onChange={(e: any) => {
                    setBio(e.target.value);
                  }}
                  maxLength={1500}
                  disabled={isDynamicRoute}
                />
              ) : (
                <Skeleton active={true} className="profile__bio__skeleton" />
              )}
            </div>
            {/* <!-- bio description end here --> */}

            {/* <!-- tempary coming soon section --> */}
            {/* <div className={"profile__coming__soon"}>
              <p>Coming Soon</p>
            </div> */}
          </div>
          {/* <!-- user bio section end here --> */}
        </div>
        <div className="button">
          {/* <!-- Save button --> */}
          <button
            className="mainEDitprofile__SaveBTN"
            onClick={handleSaveProfile}
          >
            Save
          </button>
        </div>
        {/* user card right end here */}
      </div>
    </>
  );
};

export default UserCard;
