import { D, U } from "./main";

const setBountiCategory = `${D}/update/category`;
const setBountiSubCategory = `${D}/update/subcategories`;
const editCurrentBounti = `${D}/update`;
const editMimeType = `${D}/mimeType`;
const editBountiBrand = `${D}/updateBrandDetail`;
const additionalDetail = `${D}/updateAdditionalDetail`;
const multiSubmissionEdit = `${D}/update/multipleSubmissions`;
const uploadBrandImage = `${D}/updateBrandDetail`;
const unlockUpdate = `${D}/update/unlocks`;
const updateMediaSequence = `${D}/update/mediaSequence/`;
const submissionUpdate = `${D}/update/totalsubmission`;
const editSponsors = `${D}/bounti-sponsor`;
const editCharity = `${D}/bounti-charity`;
const editTags = `${D}/updatetags`;
const editMediaData = `${D}/updateTitleAndDes`;
const editTermsAndConditions = `${D}/update/termsandcondition`;
const updateBountyReward = `${D}/put/reward`;
let Reset_Password: string = `${D}/reset-password`;
const profileUpdate = `${D}/create/userProfileData`;
const updateAnnouncementDesc = `${D}/update/description`;
// const editPostSubmission = `${D}/update/submission/${U}`;
const editPostSubmission = `${D}/update/submission/${U}`;
const acceptOrDecline = `${D}/respond-to-request`;
const publishBounty = `${D}/update/public`;
const putAdUnlock =`${D}/bountyUnlocks/${U}`;
const adminWatchLater = `${D}//adminWatchLater`;
const changePassword = `${D}/change-password`;
const categorySelect = `${D}/create/userProfileData`;
const categoryRemove = `${D}/create/userProfileData`;
const winnerUpdate = `${D}/winner_update`;

export {
  editTags,
  acceptOrDecline,
  editCharity,
  updateAnnouncementDesc,
  profileUpdate,
  setBountiCategory,
  editSponsors,
  submissionUpdate,
  unlockUpdate,
  updateMediaSequence,
  uploadBrandImage,
  setBountiSubCategory,
  editCurrentBounti,
  editMimeType,
  editBountiBrand,
  additionalDetail,
  multiSubmissionEdit,
  editMediaData,
  editTermsAndConditions,
  updateBountyReward,
  Reset_Password,
  editPostSubmission,
  publishBounty,
  putAdUnlock,
  adminWatchLater,
  changePassword,
  categorySelect,
  categoryRemove,
  winnerUpdate,

};
