import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Initialize states
const initialState: any = {
  id: "", // ID of the media
  mediaId: "", // Media ID
  title: "", // Title of the media
  description: "", // Description of the media
  imageUrl: "", // Image URL of the media
  likes: null, // Number of likes for the media
  favs: [], // List of favorite media
};

// Create a slice for managing media viewer data
export const mediaViewerData = createSlice({
  name: "mediaViewerData", // Slice name
  initialState, // Initial state
  reducers: {
    // Reducer to add current media view
    addCurrentMediaView: (state: any, action: PayloadAction<any>) => {
      const { id, mediaId, title, description, imageUrl } = action.payload;
      state.id = id;
      state.mediaId = mediaId;
      state.title = title;
      state.description = description;
      state.imageUrl = imageUrl;
      state.likes = action.payload["likes"];
      state.favs = action.payload["favs"];
    },
  },
});

// Export action creators
export const { addCurrentMediaView } = mediaViewerData.actions;

// Export reducer
export default mediaViewerData.reducer;
