import React, { useEffect, useState } from "react";
import "./style.css";
import { SwiperSlide, Swiper } from "swiper/react";
import axios from "axios";
import { updateAnnouncementDesc } from "../../apis/putApis";
import { likeAnouncement } from "../../apis/postApis";
import Header from "../../apis/header";
import { getAnnouncementDescription } from "../../apis/getApis";
import { D, U } from "../../apis/main";
import { useDispatch, useSelector } from "react-redux";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import {
  getSubmissionComment,
  getReplySubmissionComment,
  getSubmissionCommentReply,
} from "../../apis/getApis";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import {
  addSubmissionComment,
  addSubmissionCommentReply,
} from "../../apis/postApis";
import { RootState } from "../../app/store";
import { addSubmissionReplyId } from "../../app/slices/singleStates";

const SubmissionViewEditBounty = ({
  setToggle,
  currentSelectAnnouncementId,
  innerAnnounceId,
  bountyId,
  onClose,
  reduxBountyId,
}: any) => {
  // localstates
  const { currentSubmission } = useSelector(
    (state: any) => state.booleanStatus
  );
  // console.log(currentSubmission, "submission_view");
  const [swiper, setSwiper] = React.useState<any>();
  const [textArea, setTextArea] = React.useState<any>(
    currentSubmission?.title || ""
  );
  const [description, setDescription] = React.useState<any>(
    currentSubmission?.description || ""
  );
  const [mainLikeStatus, setMainLikeStatus] = useState<boolean>(false);
  const [mainLikes, setMainLikes] = useState<boolean>(false);
  const [files, setFiles] = useState<any>(currentSubmission?.media || []);
  const [commentData, setCommentData] = useState<any>([]);
  const [replyData, setReplyData] = useState<any>([]);
  const [reRender, setReRender] = useState<any>(false);
  const [comment, setComment] = useState<any>("");
  const [isEmojiSection, setIsEmojiSection] = useState<any>(false);
  const [selectedEmoji, setSelectedEmoji] = useState<any>("1f60a");
  const [currentCommentUser, setCommentUser] = useState("");
  const [flag, setFlag] = useState(0);
  const [showChatBox, setShowChatBox] = useState(false);
  const dispatch = useDispatch();
  const currentCommentId = useSelector((state: RootState) => {
    return state.booleanStatus.submissionReplyId;
  });
  // loginData detail
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }
  // console.log("loginData", loginData);

  useEffect(() => {
    if (currentSubmission) {
      setTextArea(currentSubmission.title || "");
      setFiles(currentSubmission.media || []);
    }
  }, [currentSubmission]);

  const closePopup = () => {
    onClose();
  };
  // media swiper init
  const mediaSwiperInit = (e: any) => {
    setSwiper(e);
  };

  // change annoucement description function
  const changeAnnoucement = async (e: any) => {};

  // like current Annoucement
  const likeCurrentAnnouncement = async (e: any) => {
    await axios
      .post(`${likeAnouncement}/${innerAnnounceId}`, {}, Header)
      .then((response: any) => {
        setMainLikeStatus(response.data.data.userLiked);
        setMainLikes(response.data.data.likes);
      })
      .catch((error: any) => {});
  };

  // Get comment API
  useEffect(() => {
    axios
      .get(
        `${getSubmissionCommentReply}/${loginData?.userId}/${currentSubmission?.submissionId}`
      )
      .then((res: any) => {
        console.log("comment_submission", res);
        setCommentData(res.data.response);
      })
      .catch((error: any) => {
        console.log(error);
      });
    setReRender(false);
  }, [reRender, currentSubmission, commentData]);
  // get comment reply API
  // useEffect(() => {
  //   axios
  //     .get(`${getReplySubmissionComment}/${currentSubmission?.submissionId}`)
  //     .then((res: any) => {
  //       console.log("comment_reply_submission", res);
  //       setReplyData(res.data.submission);
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //     });
  //   setReRender(false);
  // }, [reRender, currentSubmission]);

  // Submit Comment
  const submitComment = (e: any) => {
    if (comment.slice(0, 1) === "@") {
      // Extracting the actual reply content
      const usernameEndIndex = comment.indexOf(" ", comment.indexOf(" ") + 1); // Find the index of the second space
      const realReply = comment.slice(usernameEndIndex + 1);

      axios
        .post(
          `${addSubmissionCommentReply}/${loginData?.userId}/${currentSubmission?.submissionId}/${reduxBountyId}/${currentCommentId}`,
          {
            submissionReply: realReply,
          }
        )
        .then((res: any) => {
          setReRender(true);
          setReplyData(res.data);
          setComment("");
          // console.log("replyPost", currentCommentId, res);
        })
        .catch((err: any) => {});
    } else {
      axios
        .post(
          `${addSubmissionComment}/${loginData?.userId}/${currentSubmission?.submissionId}/${reduxBountyId}`,
          {
            submissionComment: comment,
          }
        )
        .then((res: any) => {
          // console.log(res, "response_addcomment");
          setReRender(true);
          setIsEmojiSection(false);
          setComment("");
        })
        .catch((err: any) => {});
    }
  };

  const inputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      submitComment(event);
    }
  };

  // set emoji to message goes here
  const setEmojiWithMessage = (emojiData: any) => {
    setComment(
      (comment: any) =>
        comment + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
    );
    setSelectedEmoji(emojiData.unified);
  };

  // current comment handle
  const currentCommentHandle = (
    inputRefName: string,
    commentId: string,
    user: string
  ) => {
    dispatch(addSubmissionReplyId(commentId));
    setComment(`@${user.split("@")[0]} `);
    const input = document.getElementById(inputRefName);
    if (input) {
      setReRender(!reRender);
      input.focus();
    }
  };

  const typingComment = (e: any) => {
    if (comment.slice(0, 1) === "@") {
      if (flag === 0) {
        setCommentUser(comment.split(" ")[0]);
        setFlag(1);
      }
      if (currentCommentUser?.length >= comment?.length) {
        setComment("");
        return;
      }
      setComment(e.target.value);
      // console.log("changeUser", currentCommentUser, comment);
      return;
    }
    setComment(e.target.value);
  };
  // is current user reply or not functionality
  const checkCurrentUserReplyOrNot = (comment: any, userName: any) => {
    if (
      Array.isArray(comment.results) &&
      comment.results.some(
        (result: any) => result.userDetails?.userName === userName
      )
    ) {
      // return true;
      return comment.results.length > 0;
    }
    return false;
  };

  const toggleRepliBox = (id: any) => {
    const repiesBox = document.getElementById(id);
    console.log("repiesBox", repiesBox);
    if (repiesBox) {
      if (repiesBox.style.display == "block") {
        console.log("repiesBox.style.display", repiesBox.style.display);
        repiesBox.style.display = "none";
      } else {
        repiesBox.style.display = "block";
      }
      // Update reRender state to trigger useEffect
      setReRender((prevState: any) => !prevState);
    }
  };
  //   const toggleRepliBox = (id: any) => {
  //       const repiesBox = document.getElementById(id);
  //       console.log("repiesBox",repiesBox)
  //       if (repiesBox) {
  //         if (repiesBox.style.display == "block") {
  //           repiesBox.style.display = "none";
  //         } else {
  //           repiesBox.style.display = "block";
  //         }
  //       }
  //     };

  // Toggle chat box visibility when comment icon is clicked
  const toggleChatBox = () => {
    setShowChatBox(!showChatBox);
  };
  return (
    <div className="annoucemnet__popup">
      {/* annoucement header goes here */}
      <div className="annoucement__popup__header flex justify-between">
        <div className="annoucement__popup__header__left flex">
          <img
            src={currentSubmission?.userImage}
            alt="user image"
            style={{ width: "4rem", borderRadius: "0.4rem" }}
          />
          <div style={{ marginLeft: "0.5rem" }}>
            <b style={{ color: "var(--orange)" }}>
              {currentSubmission?.userName}
            </b>{" "}
            posted ...
          </div>
        </div>
        <div className="annoucement__popup__header__right">
          <div className="annoucement__popup__header__time">
            <b style={{ color: "var(--orange)" }}>
              {currentSubmission?.timeAgo}
            </b>
          </div>
          <div className="annoucement__popup__header__engage">
            {/* boost */}
            <div className="boostHere social__cursor flex">
              {/* <img src="./tests/icons/boost.svg" alt="boost_icon" />
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                  marginLeft: "-0.5rem",
                }}
              >
                11
              </span> */}
              {!mainLikeStatus ? (
                <button className="noBtn">
                  <ThumbUpOffAltOutlinedIcon
                    onClick={likeCurrentAnnouncement}
                    className="icon_thumbup"
                  />
                </button>
              ) : (
                <button className="noBtn" disabled>
                  <ThumbUpAltIcon
                    onClick={likeCurrentAnnouncement}
                    className="icon_thumbup"
                  />
                </button>
              )}
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                }}
              >
                {mainLikes}
              </span>
            </div>
            {/* like */}
            <div className="likehere social__cursor flex">
              {/* <img
                src={
                  !mainLikeStatus
                    ? "./tests/icons/heart.svg"
                    : "./assets/fillHeart.svg"
                }
                alt="heart.svg"
                onClick={likeCurrentAnnouncement}
              /> */}
              {!mainLikeStatus ? (
                <button className="noBtn">
                  <ThumbDownOutlinedIcon
                    onClick={likeCurrentAnnouncement}
                    className="icon_thumbup"
                  />
                </button>
              ) : (
                <button className="noBtn" disabled>
                  <ThumbDownIcon
                    onClick={likeCurrentAnnouncement}
                    className="icon_thumbup"
                  />
                </button>
              )}
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                }}
              >
                {mainLikes}
              </span>
            </div>
            {/* comment */}
            <div
              className="commenthere social__cursor flex"
              onClick={() => toggleChatBox()}
            >
              <img src="./tests/icons/comment2.svg" alt="comment" />
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "0.8rem",
                }}
              >
                {commentData?.length}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* annoucement header end here */}

      {/* annoucement body goes here */}
      <div className="annoucement__popup__body flex justify-between">
        <div className="annoucement__popup__body__left">
          <textarea
            className="anc__subbmission__desc"
            spellCheck={false}
            style={{ resize: "none",height:'3rem' }}
            value={textArea}
            onChange={(e) => {
              setTextArea(e.target.value);
            }}
          />
           <textarea
            className="anc__subbmission__desc"
            spellCheck={false}
            style={{ resize: "none"}}
            value={description}
            onChange={(e) => {
              setTextArea(e.target.value);
            }}
          />
        </div>

        {/* media UI */}
        <div className="annoucement__popup__body__right annoucement__media__view">
          {showChatBox ? null : (
            <div>
              <div
                className="ancmnt__main__media__frame"
                // onClick={openFile}
              >
                <img
                  src={files[0]}
                  alt=""
                  style={{ width: "10rem", borderRadius: "1rem" }}
                />
              </div>

              <div
                className="ancmnt__main__media__swiper flex"
                style={{ marginTop: "1rem" }}
              >
                <img
                  src="./assets/backward.svg"
                  alt="prev"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    swiper?.slidePrev();
                  }}
                />
                <Swiper
                  className="annoucement__swiper"
                  slidesPerView={3}
                  spaceBetween={15}
                  onInit={mediaSwiperInit}
                  style={{ paddingLeft: "1rem" }}
                >
                  {files.slice(1).map((file: any, index: number) => (
                    <SwiperSlide className="annoucement-slide" key={index}>
                      <img
                        src={file}
                        alt=""
                        style={{ width: "5rem", borderRadius: "1rem" }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <img
                  src="./assets/forward.svg"
                  alt="prev"
                  style={{ cursor: "pointer", marginLeft: "1rem" }}
                  onClick={() => {
                    swiper?.slideNext();
                  }}
                />
              </div>
            </div>
          )}
          {/* chatbox UI */}
          {showChatBox && (
            <div className="media--right-box">
              {/* Your box with blue border here */}
              {commentData.length === 0 ? (
                <>
                  <p
                    style={{
                      fontSize: "1.3rem",
                      textAlign: "left",
                      color: "var(--veryDarkblue)",
                      marginLeft: "1rem",
                      marginTop: "0.8rem",
                    }}
                  >
                    Comment Thread
                  </p>
                  <div style={{textAlign:'center',marginTop:'4rem',}}>No comments yet..</div>
                </>
              ) : (
                <div className="scrollbar">
                  <p
                    style={{
                      fontSize: "1.3rem",
                      textAlign: "left",
                      color: "var(--veryDarkblue)",
                      marginLeft: "1rem",
                      marginTop: "0.8rem",
                    }}
                  >
                    Comment Thread
                  </p>
                  {commentData
                    ?.slice()
                    .reverse()
                    .map((elm: any, ind: any) => {
                      return (
                        <>
                          {/* {console.log(elm,commentData,"fsdgfhdsgf")} */}
                          <div
                            style={
                              ind === 0
                                ? { position: "relative" }
                                : { position: "relative" }
                            }
                          >
                            <div
                              className="media--right--innerbox--one"
                              style={{
                                position: "relative",
                                zIndex: "999",
                                cursor: "pointer",
                                textAlign: "left",
                              }}
                              onClick={() => {
                                toggleRepliBox(elm?._id);
                              }}
                            >
                              <div className="innerbox--image--one">
                                <img
                                  src={elm.userDetails.userImage}
                                  style={{ width: "2rem" }}
                                  alt=""
                                />
                              </div>
                              <div className="innerbox--name">
                                <p> {elm.userDetails.userName} </p>
                                <img
                                  onClick={() => {
                                    currentCommentHandle(
                                      "comment__input_mediView",
                                      elm._id,
                                      elm.userDetails.userName
                                    );
                                  }}
                                  src={
                                    checkCurrentUserReplyOrNot(
                                      elm,
                                      elm.userDetails.userName
                                    )
                                      ? "../tests/icons/reply.svg"
                                      : "../tests/icons/reply-border.svg"
                                  }
                                  alt="reply"
                                  style={{
                                    position: "absolute",
                                    right: "2.5rem",
                                    top: "1rem",
                                    cursor: "pointer",
                                    width: "1rem",
                                  }}
                                />
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "2.2rem",
                                    fontSize: "0.7rem",
                                    color: "var(--orange)",
                                  }}
                                >
                                  {elm.results && elm.results.length}
                                </p>
                                <img
                                  // src={
                                  //   !elm?.likes.includes(loginData?.userId)
                                  //     ? "../tests/icons/heart.svg"
                                  //     : "../assets/fillHeart.svg"
                                  // }
                                  src="../tests/icons/heart.svg"
                                  alt="none"
                                  style={{
                                    position: "absolute",
                                    right: "1rem",
                                    top: "1rem",
                                    cursor: "pointer",
                                    width: "1rem",
                                  }}
                                  // onClick={() => {
                                  //   likeComment(elm?._id);
                                  // }}
                                />
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "0.5rem",
                                    fontSize: "0.7rem",
                                    color: "var(--orange)",
                                  }}
                                >
                                  {/* {elm.likes && elm?.likes.length} */}
                                </p>
                              </div>
                              <div className="innerbox--description">
                                <p>{elm.submissionComment}</p>
                              </div>
                            </div>
                            {/* replies */}
                            <div
                              className="mediaV__replise"
                              id={elm?._id}
                              style={{
                                // position: "absolute",
                                // display: "none",
                                background: "var(--veryDarkblue)",
                                borderEndEndRadius: "1rem",
                                borderEndStartRadius: "1rem",
                                marginTop: "-2rem",
                                zIndex: "-999999999",
                                textAlign: "left",
                              }}
                            >
                              {elm?.results.map((reply: any) => {
                                return (
                                  <div
                                    className="media--right--innerbox--one"
                                    style={{
                                      background: "none",
                                      height: "auto",
                                      paddingBottom: "1rem",
                                    }}
                                  >
                                    <div className="innerbox--image--one">
                                      <img
                                        src={reply.userDetails.userImage}
                                        style={{ width: "2rem" }}
                                        alt=""
                                      />
                                    </div>
                                    <div className="innerbox--name">
                                      <p>{reply.userDetails.userName}</p>
                                    </div>
                                    <div className="innerbox--description">
                                      <p>{reply?.submissionReplyComment}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
              {/* <div className="media--right--commentbox">
              
                <div className="media--right--plusbutton">+</div>
                <div className="media--right--textbox">
                  <input
                    type="text"
                    value={comment}
                    onChange={(e: any) => {
                      typingComment(e);
                    }}
                    placeholder={"Write here..."}
                    onKeyDown={inputKeyDown}
                    id="comment__input_mediView"
                    onFocus={() => {
                      const input = document.getElementById(
                        "comment__input_mediView"
                      );
                      if (input) {
                        input.style.border = "1px solid grey";
                      }
                    }}
                    onBlur={() => {
                      const input = document.getElementById(
                        "comment__input_mediView"
                      );
                      if (input) {
                        input.style.border = "none";
                      }
                    }}
                  />
                  <div className="textbox_image_one">
                    <img
                      src="../tests/icons/emoji.svg"
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={(e: any) => {
                        isEmojiSection
                          ? setIsEmojiSection(false)
                          : setIsEmojiSection(true);
                      }}
                    />
                  </div>
                  <div className="textbox_image_two">
                    <img
                      src="../tests/icons/text_share.svg"
                      onClick={(e: any) => {
                        submitComment(e);
                      }}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>

        <div
          style={
            isEmojiSection
              ? {
                  display: "block",
                  position: "absolute",
                  zIndex: "999999",
                  top: "12rem",
                  right: "-16rem",
                }
              : { display: "none" }
          }
        >
          <EmojiPicker
            onEmojiClick={setEmojiWithMessage}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
          />
        </div>
      </div>
      {/* annoucement body end here */}

      {/* close button goes here */}
      <div
        className="close__anoucement_btn"
        onClick={() => {
          closePopup();
        }}
      >
        X
      </div>
      {/* close button end here */}

      <input
        type="file"
        style={{ width: "0.1px", height: "0.1px", visibility: "hidden" }}
        id="file__annoucemnt"
        multiple={true}
        // onChange={uploadAnnoucementAsset}
      />
    </div>
  );
};

export default SubmissionViewEditBounty;
