import React, { useState, useEffect } from "react";
import { FluidContainer } from "../../components";
import axios from "axios";
import { D } from "../../apis/main";
import { useParams } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import Header from "../../apis/header";
import EmailChangeForm from "./EmailChnageForm";
import { getUsers } from "../../apis/getApis";
import { changePassword } from "../../apis/putApis";

const Setting = () => {
  const [form] = Form.useForm();
  const [formEmail] = Form.useForm();
  const [userData, setUserData] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  let loginData: any = localStorage.getItem("loginData");
  if (loginData) {
    loginData = JSON.parse(loginData);
  }
console.log(loginData,"loginData")
  const handleDefaultTerms = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${getUsers}/${loginData?.userId}`
        );
        setUserData(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);
  const handleSubmit = async (values: any) => {
    // Perform form validation
    if (values.newPassword !== values.confirmPassword) {
      message.error("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await axios.put(
        `${changePassword}`,
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
        Header
      );

      message.success("Password changed successfully!");

      // Reset the form fields
      form.resetFields();
    } catch (error: any) {
      console.error("Error changing password", error);
      if (error.response && error.response.status === 401) {
        message.error(error.response.data.message || "Unauthorized access.");
      } else {
        message.error("Error changing password. Please try again.");
      }
    }
  };

  return (
    <FluidContainer>
      <main className="setting__main flex justify-between">
        <div
          className="flex"
          style={
            showModal
              ? {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "999999",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "var(--dialogOverlay)",
                }
              : { display: "none" }
          }
        >
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div className="mediaAsset__delete__popup2">
              <div className="close_BTNmediaAsset__delete__popup">
                <button onClick={handleCancel}>x</button>
              </div>
              <EmailChangeForm form={formEmail} onCancel={handleCancel} />
            </div>
          </div>
        </div>

        <div className="setting_wrapper">
          {userData && userData.email && (
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item label="Email">
                <Input
                  name="email"
                  defaultValue={userData?.email}
                  disabled
                  style={{ color: "black" }}
                />
              </Form.Item>
              <Button
                type="primary"
                onClick={handleDefaultTerms}
                style={{ marginBottom: 16 }}
              >
                Change Email
              </Button>
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your current password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Change Password
                </Button>
              </Form.Item>
              {/* Password change form */}
            </Form>
          )}
        </div>
      </main>
    </FluidContainer>
  );
};

export default Setting;
