import { createSlice } from "@reduxjs/toolkit";

// Initial state for totalSizeSlice
const initialState = {
  totalSize: 0, // Total size in MB
  sizeUnit: "", // Unit of size (e.g., MB, GB)
  timeRemaining: "", // Time remaining
  overallProgress: 0, // Overall progress percentage
};

// Create a slice for managing total size state
const totalSizeSlice = createSlice({
  name: "totalSize",
  initialState,
  reducers: {
    updateTotalSize: (state, action) => {
      let totalSize: number = action.payload.totalSizeBytes / 1000 / 1024;
      state.totalSize = parseFloat(totalSize.toFixed(2));
      state.sizeUnit = action.payload.sizeUnit;
      state.timeRemaining = action.payload.timeRemaining;
      state.overallProgress = action.payload.overallProgress;
    },
  },
});

// Export action creators
export const { updateTotalSize } = totalSizeSlice.actions;

// Export reducer
export default totalSizeSlice.reducer;
